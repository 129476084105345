import Buffer from 'buffer/'
import byteSize from 'byte-size'
import axios from 'axios';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
// import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const EXCEL_EXTENSION = '.xlsx';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { Parser } from 'json2csv';
import React, { Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ReactTable from 'react-table';
import secureRandomPassword from 'secure-random-password';
import countries from './assets/drzave.json';
import flags from './assets/flags.json';
import { DEV, ALERT_SERVER_REQUESTS } from './cfg';
import { codeRegister, education, sections, sex, specialPermits, usertype, title, working, workingStatus, workPosition, yesno } from './coderegister';
import {
  ChangeUserData, DocumentStatus, DocumentStatusConfirmed,
  DocumentStatusFinalized, DocumentStatusInserted, DocumentStatusNew,
  DocumentStatusSaved, DocumentStatusString, DocumentStatusUpdated, DocumentStatusUpdatedPlus, Navigation,
  NavigationText, NDN, NRN, PasswordStrength, PointType, TabIndex, UserType, vehicleDesc, VehicleType, vehicleType
} from './enum'
import {
  AnalyticsCount, Attachment, Averages, CodeRegister, CompanyData, CountryData, DocumentData, Expense, ExpenseData, Files, Filter, FilterDocumentsParams, InsertDocument, Kedata,
  KedataAll, MileageData, OpenDocumentParams, PointData, PointExposedData,
  ProjectData, Props, regexemail, RegisterData, SectionData, SetAdminData, State, Statistics, Subsistence, SubsistenceData, UpdateDocument, UserData, VehicleData, VehicleOccupancyParams
} from './interfaces';
import {
  analytics, company, country, document as documentRoute, expense, general, googlepath, init, mileage, point, project, register, reservation, section, sequential, settings, status, subsistence, user, useractive, vehicle, pointexposed
} from './routes';
import {
  defaultPageSize,
  extendedPageSize,
  extendedPlusPageSize, linesPerPage, passwordMaxLength, passwordMinLength, PASSWORD_REQUIREMENTS, specialCharacters, stringDefaultLength, stringDescriptionLength, stringHourMinuteLength, stringLongLength,
  stringMaxLength, stringShortLength
} from './utils';

import html2pdf from 'html2pdf.js';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Loader from './assets/loader.svg';
import logo from './assets/logoirgo.png';
import logo_irgo_horizontal from './assets/logo_irgo_horizontal.png';
import logo_irgo_consulting_horizontal from './assets/logo_irgo_consulting_horizontal.png';
import logo_gri from './assets/logo_gri.png';
// import logo from './assets/car_side.svg'
import sl from 'date-fns/locale/sl';
import {c1s, c2s, c3s, startWork, startWork_, workHere, hoursWeekCompany1, hoursWeekCompany2, hoursWeekCompany3} from './settings'
const locale = 'sl'
registerLocale(locale, sl);

const placeholderDatePicker = 'izberi datum';

const reactTableTranslation = {
  previousText: 'Prejšnja stran',
  nextText:'Naslednja stran',
  loadingText:'Nalaganje...',
  noDataText:'Ni podatkov',
  pageText:'Stran',
  ofText:'od',
  rowsText:'vrstic',
  pageJumpText:'skoči na stran',
  rowsSelectorText:'vrstic na stran'
}

const daysinweek: string[] = [
  'Nedelja',
  'Ponedeljek',
  'Torek',
  'Sreda',
  'Četrtek',
  'Petek',
  'Sobota',
];
const PASSWORD_REQUIREMENTS_NOT_NECESSARY = 1;
const PASSWORD_REQUIREMENTS_DESCRIPTION: string[] = [
  'veliko črko',
  'malo črko',
  'številko',
  'posebni znak',
];

const orientation: Array<{
  name: 'privzeto' | '90°' | '180°' | '270°';
  value: 0 | 90 | 180 | 270;
}> = [
  { name: 'privzeto', value: 0 },
  { name: '90°', value: 90 },
  { name: '180°', value: 180 },
  { name: '270°', value: 270 },
];

const dateFormatSlash: string = 'dd/MM/yyyy'
const dateFormatDot: string = 'dd.MM.yyyy'

const ddmmllll: string = 'dd.mm.llll'
const llmmdd: string = 'll.mm.dd'

const ffp: string[] = [
  ddmmllll,
  ddmmllll,
  ddmmllll,
  null,
  llmmdd,
  llmmdd,
  llmmdd,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  'ur',
  null,
  null,
  'let',
  null,
  null,
  null,
  null,
  null,
  ddmmllll,
  null,
  ddmmllll,
  'ur',
  'ur',
  'ur',
  null,
  ddmmllll,
]
export default class Main extends React.Component<Props, State> {
  public calculateMileageGoogle = false;
  public initVehicle = false;
  public tabList: string[] = ['Obvestila', 'Rezervacija', 'Potni nalog'];
  public tabPanel = [
    this.getNotifications,
    this.getNewReservation,
    this.getNewDocument,
  ];
  /*
  initCompanyuuid(companies: CompanyData[]): string {
    if (companies) {
      for (const item of companies) {
        if (item.uuid === this.props.userData.companyuuid) {
          return item.uuid
        }
      }
    }
    return undefined
  }
*/
  initPointuuid(companies: CompanyData[]): string {
    if (companies) {
      for (const item of companies) {
        if (item.uuid === this.props.userData.companyuuid) {
          return item.pointuuid;
        }
      }
    }
    return undefined;
  }

  getPointUuid(that, name: string): string|undefined {
    if (that.state.points) {
      for (const item of that.state.points) {
        if (item.name === name) {
          return item.uuid
        }
      }
    }
    return undefined
  }

  getPointname(that, uuid: string): string|undefined {
    if (that.state.points) {
      for (const item of that.state.points) {
        if (item.uuid === uuid) {
          return item.name
        }
      }
    }
    return ''
  }

  getPointuuid(uuid: string, data: CompanyData[]): string {
    if (data) {
      for (const item of data) {
        if (item.uuid === uuid) {
          return item.pointuuid;
        }
      }
    }
    return '';
  }

  initMileageuuid(mileage: MileageData[]): string {
    if (mileage && mileage.length) {
      return mileage[0].uuid;
    }
    return '';
  }

  initVehicleuuid(vehicles: VehicleData[]): string {
    if (vehicles) {
      let companion: string;
      let company: string;
      for (const item of vehicles) {
        if (item.type === VehicleType.Private) {
          return item.uuid;
        } else if (!companion && item.type === VehicleType.Companion) {
          companion = item.uuid;
        } else if (
          !companion &&
          !company &&
          item.type === VehicleType.Company
        ) {
          company = item.uuid;
        }
      }
      if (companion) {
        return companion;
      }
      if (company) {
        return company;
      }
    }
    return undefined;
  }

  public initDocument(): DocumentData {
    return {
      status: DocumentStatusNew,
      companyuuidPlanned: '',
      intentPlanned: '',
      companionsPlanned: '',
      vehicleTypePlanned: VehicleType.None,
      companions: '',
      vehicletype: VehicleType.None,
      projectuuidPlanned: '',
      descriptionPlanned: '',
      departureHourPlanned: '',
      arrivalHourPlanned: '',
      departureMinutePlanned: '',
      arrivalMinutePlanned: '',
      vehicleuuidPlanned: '',
      orderuuidPlanned: '',
      attachments: [],
      Attachments: [],
      attachment: {
        name: '',
        description: '',
        files: [],
      },
      expenses: [],
      uuid: null,
      useruuid: this.props.userData.uuid,
      email: this.props.userData.email,
      userUuid: this.props.userData.lastname + ' ' + this.props.userData.firstname,

      companyuuid: this.props.userData.companyuuid,
      vehicleuuid: '',
      projectuuid: '',
      orderuuid: '',
      departureDate: null,
      arrivalDate: null,
      departureDatePlanned: null,
      arrivalDatePlanned: null,
      departureHour: '',
      arrivalHour: '',
      departureMinute: '',
      arrivalMinute: '',
      name: '',
      departuremileage: '',
      arrivalmileage: '',
      mileage: '',
      mileageGoogle: '',
      description: '',
      intent: '',
      advance: '',
      advancedate: null,
      path: '',
      pointuuids: [undefined, undefined, undefined],
      Pointuuids: ['', '', ''],
      subsistence: [],
      mileageuuid: '',
    };
  }

  initState(): State {
    return {
      reservationsDay: [],
      fCountry: '',
      showProblematic: false,
      showJustMine: true,
      showMineBoss: false,
      Documents: [],
      viewingCurrentMonthData: [],
      fStatus: '*',
      vehicleuuid: undefined,
      analyticsDataCount: {},
      analyticsUserCount: {},
      mean: [],
      median: [],
      // continentEurope: true,
      // mean: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null],
      // median: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null],
      // groupsColors: ["#e3e3ff", "#dff2fd", "#e2fce6", "#fcfade", "#ffeee2", "#ffdbdb", "#f2bfd7", "#f6d7e8", "#f6edee", "#dcd0ea", "#f1dcc5", "#eab6ab", "#f2d0ca", "#fce6e9", "#d9e6f3", "#cee1cb", "#c2d8c1", "#e4d5d0", "#fcdbd2"],
      groupColor: false,
      groupNumber: false,
      groupEmpty: false,
      groupsColors: [],
      groupsColorsDefault: [],
      f: [],
      variablesNull: [],
      variablesOrderType: [],
      variablesOrder: [],
      variablesSelected: [],
      variables: [],
      orientation: 0,
      scale: 1,
      popup: false,
      randomCharacters: 12,
      FUseruuid: '',
      fUseruuid: '',
      FOrderuuid: '',
      fOrderuuid: '',
      FVehicleuuid: '',
      fVehicleuuid: '',
      FProjectuuid: '',
      fProjectuuid: '',
      fVehiclePlate: '',
      fCompanyuuid: '',
      FCompanyuuid: '',
      fDep: this.state && this.state.fDep ? this.state.fDep : new Date(),
      fArr: this.state && this.state.fArr ? this.state.fArr : new Date(),
      fDeparture: this.state && this.state.fDeparture ? this.state.fDeparture : new Date(),
      fdeparture: this.state && this.state.fdeparture ? this.state.fdeparture : new Date(),
      farrival: this.state && this.state.farrival ? this.state.farrival : new Date(),
      fCompanyVehicle: false,
      vehicleOccupancy: false,
      viewingCurrentMonth: false,
      pageNumber: 0,
      numPages: 0,
      FUserUuid: '',
      fUserUuid: '',
      ExposedPoint: '',
      Country: '',
      attachment: -1,
      enlargeImage: false,
      lastTabIndex: TabIndex.Notifications,
      tabIndex: TabIndex.Notifications,
      ndn: NDN.BasicInformation,
      nrn: NRN.BasicInformation,
      messages: [],
      leaders: [],
      Leaders: [],
      fCompany: '',
      document: this.initDocument(),
      SectionCompanyuuid: '',
      Section: '',
      MileageValue: '',
      Mileage: '',
      Expense: '',
      SubsistenceValue: '',
      SubsistenceBreakfast: '',
      SubsistenceLunch: '',
      SubsistenceDinner: '',
      Subsistence: '',
      SubsistenceCountry: '',
      fVehicleType: VehicleType.None,
      fPointType: PointType.None,
      // Companyuuid: '',
      CustomerPointuuid: '',
      CompanyPointuuid: '',
      PointShortName: '',
      Company: '',
      Point: '',
      PointAddress: '',
      Customer: '',
      CustomerAddress: '',
      Project: '',
      ProjectCode: '',
      Vehicle: '',
      VehicleType: VehicleType.None,
      VehicleUseruuid: '',
      VehiclePlate: '',
      VehicleMy: '',
      VehiclePlateMy: '',
      SectionLeaderuuid: '',
      CompanyLeaderuuid: '',
      showRegistration: false,
      showRegistrationExpense: false,
      showRegistrationCountry: false,
      showRegistrationSection: false,
      showRegistrationMileage: false,
      showRegistrationCompany: false,
      showRegistrationPoint: false,
      showRegistrationCustomer: false,
      showRegistrationProject: false,
      showRegistrationVehicle: false,
      showRegistrationSubsistence: false,
      showRegistrationExposedPoint: false,
      Level: UserType.Regular,
      fLevel: UserType.None,

      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      companyuuid: '',
      Firstname: '',
      Lastname: '',
      Email: '',
      Telephone: '',

      navigation:
        this.props.userData.usertype !== undefined &&
        this.props.userData.usertype !== UserType.Regular &&
        window.screen.width >= 1024
          ? Navigation.Analytics
          : Navigation.Dashboard,
      platform: window.screen.width < 1024 ? 'mobile' : 'desktop',
      pNum0: 0,
      pSize0: extendedPlusPageSize,
      pNum1: 0,
      pSize1: defaultPageSize,
      pNum2: 0,
      pSize2: defaultPageSize,
      pNum3: 0,
      pSize3: defaultPageSize,
      pNum4: 0,
      pSize4: defaultPageSize,
      pNum5: 0,
      pSize5: defaultPageSize,
      pNum6: 0,
      pSize6: defaultPageSize,
      pNum7: 0,
      pSize7: defaultPageSize,
      pNum8: 0,
      pSize8: extendedPageSize,
      pNum9: 0,
      pSize9: defaultPageSize,
      pNum10: 0,
      pSize10: defaultPageSize,
      pNum11: 0,
      pSize11: defaultPageSize,
      pNum12: 0,
      pSize12: extendedPlusPageSize,
      pNum13: 0,
      pSize13: extendedPlusPageSize,
      pNum14: 0,
      pSize14: defaultPageSize,
      pNum15: 0,
      pSize15: defaultPageSize,
      password1: '',
      password2: '',
      Password1: '',
      Password2: '',
      showPassword: false,
      ShowPassword: false,
      loader: false,
      PointUseruuid: '',
      PointShortMy: '',
      PointNameMy: '',
      PointAddressMy: '',
      addingPoints: false
    };
  }

  public constructor(props) {
    super(props);
    this.state = this.initState();
    Cookies.set('path', 'main', { path: '/', secure: true });
  }

  public async navigationAction(i: Navigation) {
    switch (i) {
      case Navigation.Dashboard:
        this.setState({
          popup: false,
          navigation: i,
          document: this.initDocument(),
          documents:
            this.state.documents === undefined ? [] : this.state.documents,
          Documents: new Array(this.state.documents === undefined ? 0 : this.state.documents.length),
          tabIndex: TabIndex.Notifications,
          lastTabIndex: TabIndex.Notifications,
          kedatall: undefined
        });
        break;

      default:
        this.setState({ popup: false, navigation: i });
      break;
    }
  }

  public getNavigation(): JSX.Element[] {
    const nav: JSX.Element[] = [];
    nav.push(<img key="-1" className="logo dbl fl" src={logo} />);
    const Nav: JSX.Element[] = [];
    for (let i = 0; i < NavigationText.length; i++) {
      if (
        i < Navigation.Dashboard &&
        this.props.userData.usertype === UserType.Regular
      ) {
        continue;
      }
      //  if (i === Navigation.AnalyticsUser && this.props.userData.usertype !== UserType.Regular) {continue}
      Nav.push(
        <a
          key={i}
          className={clsx(
            'dbl fl bold hide-mobile',
            this.state.navigation === (i as Navigation) ? 'active' : '',
          )}
          onClick={() =>
            i !== this.state.navigation &&
            this.navigationAction(i as Navigation)
          }
        >
          {NavigationText[i]}
        </a>,
      );
      if (i === Navigation.Dashboard) {
        const count = this.getNotificationsNavigation(this).count
        if (count > 0) {
          Nav.push(<div className="information" key="information">
            {count}
          </div>)
        }
      }
    }
    nav.push(<div key="0" className="dbl fl Nav pr">{Nav}</div>)
    return nav;
  }

  public getTablist(): JSX.Element {
    const tablist: JSX.Element[] = [];
    for (let i = 0; i < this.tabList.length; i++) {
      tablist.push(
        <Tab

        disabled={
            (i === TabIndex.Document &&
              this.state.lastTabIndex !== TabIndex.Document) ||
            (i === TabIndex.Reservation &&
              this.state.lastTabIndex === TabIndex.Document)
          }

          key={i}
        >
          {this.tabList[i]}
          {/*this.tabList[i] === 'STOP' ? <img className = "tabicon" src = {Stop}/> :
             this.tabList[i] === 'TYPES' ? <img className = "tabicon1" src = {cricketIconBlack}/> : this.tabList[i]*/}
        </Tab>,
      );
    }
    return (
      <TabList /*onClick = {() => this.processTabListClick()}*/>
        {tablist}
      </TabList>
    );
  }

  public getTabpanel(): JSX.Element[] {
    const tabpanel: JSX.Element[] = [];
    for (let i = 0; i < this.tabPanel.length; i++) {
      tabpanel.push(
        <TabPanel
          /*onSelect={(index, lastIndex, event) => this.selectTabpanel(index, lastIndex, event)}*/ key={
            i
          }
        >
          {this.tabPanel[i](this)}
        </TabPanel>,
      );
    }
    return tabpanel;
  }

  // selectTabpanel(index, lastIndex, event) {console.log (index, lastIndex, event)}

  public getDateTime(dt: string): string {
    return (
      dt.substring(8, 10) +
      '/' +
      dt.substring(5, 7) +
      '/' +
      dt.substring(2, 4) +
      ' ' +
      dt.substring(11, 19)
    );
  }

  public getDate(dt: string, symbol?: string): string {
    return (
      dt.substring(8, 10) +
      (symbol ? symbol : '/') +
      dt.substring(5, 7) +
      (symbol ? symbol : '/') +
      dt.substring(2, 4)
    );
  }

  public async deleteDocument(that, uuid: string, fromViewingData?: boolean, fromAnalytics?: boolean) {
    if (confirm('Ali resnično želite izbrisati potni nalog?')) {
      if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: deleteDocument)');
      const response = await axios.delete(documentRoute +
        '?uuid=' +
        uuid +
        '&token=' +
        that.props.userData.token)
      if (response && response.data && response.data.resolved) {
        if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: deleteDocument)');
        let index: number = -1

        if (fromAnalytics) {
          for (let i: number = 0; i < that.state.analyticsData.length; i++) {
            if (that.state.analyticsData[i].uuid === uuid) {
              index = i
              break
            }
          }

          if (index !== -1) {
            const analyticsData: DocumentData[] = Object.assign([], this.state.analyticsData)
            analyticsData.splice(index, 1)
            this.setState({analyticsData})
          }

        } else if (fromViewingData) {
          for (let i: number = 0; i < that.state.viewingCurrentMonthData.length; i++) {
            if (that.state.viewingCurrentMonthData[i].uuid === uuid) {
              index = i
              break
            }
          }
          if (index !== -1) {
            const viewingCurrentMonthData: DocumentData[] = Object.assign([], this.state.viewingCurrentMonthData)
            viewingCurrentMonthData.splice(index, 1)
            this.setState({viewingCurrentMonthData, document: this.initDocument(), attachment: -1,})
          }
        } else {
          for (let i: number = 0; i < that.state.documents.length; i++) {
            if (that.state.documents[i].uuid === uuid) {
              index = i
              break
            }
          }

          if (index !== -1) {
            const documents: DocumentData[] = Object.assign([], this.state.documents)
            const Documents: number[] = Object.assign([], this.state.Documents)
            documents.splice(index, 1)
            Documents.splice(index, 1)
            this.setState({Documents, documents, document: this.initDocument(), attachment: -1,})
          }
        }
      }
      else if (response && response.data && response.data.resolved === false && response.data.message) {
        if (ALERT_SERVER_REQUESTS) alert(`Error: Failed response from server! (func: deleteDocument)\nmsg: ${response.data.message}`);
      }
      else {
        if (ALERT_SERVER_REQUESTS) alert('Error: Failed response from server! (func: deleteDocument)');
      }

    }
  }

  public async deactivateData(uuid: string, index: number) {
    const response = await axios.put(useractive, {uuid, token: this.props.userData.token})
    if (response && response.data && response.data.resolved) {
      const userData: UserData[] = Object.assign([], this.state.userData)
      userData[index].active = !userData[index].active
      this.setState({userData})
    }
  }

  public isArrayBufferSupported = (Buffer.Buffer.from(new Uint8Array([1]).buffer)[0] === 1);

  public arrayBufferToBuffer = this.isArrayBufferSupported ? this.arrayBufferToBufferAsArgument : this.arrayBufferToBufferCycle;

  public arrayBufferToBufferAsArgument(ab) {
    return Buffer.Buffer.from(ab);
  }

  public arrayBufferToBufferCycle(ab) {
    var buffer = Buffer.Buffer.from(ab.byteLength);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buffer.length; ++i) {
        buffer[i] = view[i];
    }
    return buffer;
  }

  public async deleteData(
    stateVariable:
      'userData'
      | 'countries'
      | 'companies'
      | 'customers'
      | 'points'
      | 'projects'
      | 'vehicles'
      | 'subsistence'
      // | 'mileage'
      | 'expense'
      | 'sections'
      | 'pointExposed',
    table:
      'user'
      | 'company'
      | 'customer'
      | 'point'
      | 'project'
      | 'vehicle'
      | 'subsistence'
      // | 'mileage'
      | 'expense'
      | 'country'
      | 'section'
      | 'pointexposed',
    uuid: string,
    index: number,
  ) {
    const response = await axios.delete(
      general +
        '?uuid=' +
        uuid +
        '&table=' +
        table +
        '&token=' +
        this.props.userData.token,
    );
    if (
      response &&
      response.data &&
      response.data.resolved &&
      response.data.message === 'success'
    ) {
      const state: State = Object.assign({}, this.state);
      state[stateVariable].splice(index, 1);
      this.setState(state);
    } else {
      alert(response.data.message);
    }
  }

  public async deleteUser(email: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati uporabnika z e-pošto "' +
          email +
          '"?',
      )
    ) {
      this.deleteData('userData', 'user', uuid, index);
    }
  }

  public async deactivateUser(email: string, uuid: string, index: number, value: boolean) {
    if (
      confirm(
        'Ali si resnično želite ' + (value ? 'de' : '') + 'aktivirati uporabnika z e-pošto "' +
          email +
          '"?',
      )
    ) {
      this.deactivateData(uuid, index);
    }
  }

  public async deleteCompany(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati podjetje z nazivom "' + name + '"?',
      )
    ) {
      this.deleteData('companies', 'company', uuid, index);
    }
  }

  public async deleteSection(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati oddelek z nazivom "' + name + '"?',
      )
    ) {
      this.deleteData('sections', 'section', uuid, index);
    }
  }

  public async deleteCustomer(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati stranko z nazivom "' + name + '"?',
      )
    ) {
      this.deleteData('customers', 'customer', uuid, index);
    }
  }

  public async deleteCountry(name: string, uuid: string, index: number) {
    if (confirm('Ali si resnično želite izbrisati državo "' + name + '"?')) {
      this.deleteData('countries', 'country', uuid, index);
    }
  }

  public async deleteExposedPoint(name: string, uuid: string, index: number) {
    if (confirm('Ali si resnično želite izbrisati točko "' + name + '"?')) {
      this.deleteData('pointExposed', 'pointexposed', uuid, index);
    }
  }

  public async deletePoint(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati točko z nazivom "' + name + '"?',
      )
    ) {
      this.deleteData('points', 'point', uuid, index);
    }
  }

  public async deleteProject(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati projekt z nazivom "' + name + '"?',
      )
    ) {
      this.deleteData('projects', 'project', uuid, index);
    }
  }
/*
  public async deleteSubsistence(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati tip dnevnice z nazivom "' +
          name +
          '"?',
      )
    ) {
      this.deleteData('subsistence', 'subsistence', uuid, index);
    }
  }
*/
/*
  public async deleteMileage(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati tip kilometrine z nazivom "' +
          name +
          '"?',
      )
    ) {
      this.deleteData('mileage', 'mileage', uuid, index);
    }
  }

  public async deleteExpense(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati tip stroška z nazivom "' +
          name +
          '"?',
      )
    ) {
      this.deleteData('expense', 'expense', uuid, index);
    }
  }
*/
  public async deleteVehicle(name: string, uuid: string, index: number) {
    if (
      confirm(
        'Ali si resnično želite izbrisati vozilo z nazivom "' + name + '"?',
      )
    ) {
      this.deleteData('vehicles', 'vehicle', uuid, index);
    }
  }

  public async setAdmin(
    uuid: string,
    usertype: UserType.Admin | UserType.Regular | UserType.Super,
    lastname: string,
    firstname: string
  ) {
    const level: string = usertype === UserType.Admin ? 'Admin' : usertype === UserType.Super ? 'Super' : usertype === UserType.Regular ? 'Navaden' : ''
    if (confirm('Ali si resnično želiti spremeniti nivo uporabnika "' + lastname + ' ' + firstname + '" na nivo "' + level  + '"')) {
      const setAdminData: SetAdminData = {
        token: this.props.userData.token,
        uuid,
        usertype,
      };
      const response = await axios.post(user, setAdminData);
      if (response && response.data && response.data.resolved) {
        const userData: UserData[] = Object.assign([], this.state.userData);
        for (let i = 0; i < userData.length; i++) {
          if (userData[i].uuid === uuid) {
            userData[i].usertype = usertype;
            break;
          }
        }
        this.setState({ userData });
      }
    }
  }

  public async changeUserData(changeUserData: ChangeUserData) {
    let value: string;
    let data: string;
    switch (changeUserData) {
      case ChangeUserData.Firstname:
        value = this.state.firstname;
        data = 'Ime je bilo uspešno spremenjeno!';
        break;
      case ChangeUserData.Lastname:
        value = this.state.lastname;
        data = 'Priimek je bil uspešno spremenjen!';
        break;
      case ChangeUserData.Telephone:
        value = this.state.telephone;
        data = 'Telefonska št. je bil uspešno spremenjena!';
        break;

      case ChangeUserData.Email:
        const regexEmail = new RegExp(regexemail);

        if (!regexEmail.test(this.state.email)) {
          alert('E-pošta vsebuje neveljavne znake, oz. je napačnega formata!');
          return;
        }

        value = this.state.email;
        data = 'E-pošta je bila uspešno spremenjena!';
        break;
      case ChangeUserData.Password:
        value = this.state.password1;
        data = 'Geslo je bilo uspešno spremenjeno!';
        break;
      case ChangeUserData.Company:
        value = this.state.companyuuid;
        data = 'Podjetje je bilo uspešno spremenjeno!';
        break;
    }
    const response = await axios.put(user, {
      token: this.props.userData.token,
      uuid: this.props.userData.uuid,
      changeUserData,
      value,
    });
    if (response && response.data && response.data.resolved) {
      if (
        changeUserData !== ChangeUserData.Password &&
        response.data.what &&
        response.data.what !== 'password'
      ) {
        this.props.changeUserData(response.data.what, value);
      }
      alert(data);
    } else if (response && response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public getAboutMe(): JSX.Element {
    const myVehicles: JSX.Element[] = this.getMyVehicles();
    const myPoints: JSX.Element[] = this.getMyPoints();
    const backButton: JSX.Element = <button
      onClick={() =>
        this.setState({
          navigation:
            this.props.userData.usertype === UserType.Regular ||
            window.screen.width < 1024
              ? Navigation.Dashboard
              : Navigation.Analytics,
        })
      }
      className="button button-green button-thin"
    >
      Nazaj
    </button>

    return (
      <div>
        <h4 className="mb">Sprememba osebnih podatkov</h4>
        <div className="clearfix">
          <div className="tar beforeinput fl">E-pošta:</div>
          <input
            maxLength={stringMaxLength}
            onChange={e => this.setState({ email: e.target.value })}
            className={clsx(
              'input dbl fl',
              this.state.email === this.props.userData.email
                ? 'check-nothing'
                : this.state.email.length > 0
                ? 'check-success'
                : 'check-failed',
            )}
            type="text"
            value={this.state.email}
          />
          <button
            onClick={() => this.changeUserData(ChangeUserData.Email)}
            disabled={
              this.state.email.length === 0 ||
              this.state.email === this.props.userData.email
            }
            className="button button-green button-thin ml"
          >
            Spremeni
          </button>
        </div>

        <div className="clearfix">
          <div className="tar beforeinput fl">Ime:</div>
          <input
            maxLength={stringDefaultLength}
            onChange={e => this.setState({ firstname: e.target.value })}
            className={clsx(
              'input dbl fl',
              this.state.firstname === this.props.userData.firstname
                ? 'check-nothing'
                : this.state.firstname.length > 0
                ? 'check-success'
                : 'check-failed',
            )}
            type="text"
            value={this.state.firstname}
          />
          <button
            onClick={() => this.changeUserData(ChangeUserData.Firstname)}
            disabled={
              this.state.firstname.length === 0 ||
              this.state.firstname === this.props.userData.firstname
            }
            className="button button-green button-thin ml"
          >
            Spremeni
          </button>
        </div>
        <div className="clearfix">
          <div className="tar beforeinput fl">Priimek:</div>
          <input
            maxLength={stringDefaultLength}
            onChange={e => this.setState({ lastname: e.target.value })}
            className={clsx(
              'input dbl fl',
              this.state.lastname === this.props.userData.lastname
                ? 'check-nothing'
                : this.state.lastname.length > 0
                ? 'check-success'
                : 'check-failed',
            )}
            type="text"
            value={this.state.lastname}
          />
          <button
            onClick={() => this.changeUserData(ChangeUserData.Lastname)}
            disabled={
              this.state.lastname.length === 0 ||
              this.state.lastname === this.props.userData.lastname
            }
            className="button button-green button-thin ml"
          >
            Spremeni
          </button>
        </div>
        {/*
        <div className="clearfix">
          <div className="tar beforeinput fl">Telefon:</div>
          <input
            maxLength={stringDefaultLength}
            onChange={e => this.setState({ telephone: e.target.value })}
            className={clsx(
              'input dbl fl',
              this.state.telephone === this.props.userData.telephone
                ? 'check-nothing'
                : this.state.telephone.length > 0
                ? 'check-success'
                : 'check-failed',
            )}
            type="text"
            value={this.state.telephone}
          />
          <button
            onClick={() => this.changeUserData(ChangeUserData.Telephone)}
            disabled={
              this.state.telephone === null || this.state.telephone.length === 0 ||
              this.state.telephone === this.props.userData.telephone
            }
            className="button button-green button-thin ml"
          >
            Spremeni
          </button>
        </div>
        */}
        <div className="clearfix">
          <div className="tar beforeinput fl">Podjetje:</div>
          <select
            className={clsx(
              'ml select select-input dbl fl mt3',
              this.state.companyuuid === this.props.userData.companyuuid ||
                (this.state.companyuuid.length === 0 &&
                  this.props.userData.companyuuid === null)
                ? 'check-nothing'
                : 'check-success',
            )}
            value={this.state.companyuuid}
            onChange={e => this.edit('companyuuid', e.target.value)}
          >
            {this.getCompanyOptions(undefined, 'Brez podjetja')}
          </select>

          <button
            onClick={() => this.changeUserData(ChangeUserData.Company)}
            disabled={
              this.state.companyuuid === this.props.userData.companyuuid ||
              (this.state.companyuuid.length === 0 &&
                this.props.userData.companyuuid === null)
            }
            className="button button-green button-thin ml"
          >
            Spremeni
          </button>
        </div>

        <div className="clearfix">
          <div className="tar beforeinput fl">Geslo:</div>
          <input
            maxLength={stringMaxLength}
            onChange={e => this.changePassword1(e.target.value)}
            className="input dbl fl"
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password1}
          />
          <input
            autoComplete="new-password"
            type="checkbox"
            className="dbl fl checkbox ml"
            onChange={() =>
              this.setState({ showPassword: !this.state.showPassword })
            }
            checked={this.state.showPassword}
          />
          <div title="Prikaži geslo" className="fl">Prikaži</div>
        </div>
        <div className="clearfix">
          <div title = "Ponovi geslo" className="tar beforeinput fl">Ponovi:</div>
          <input
            autoComplete="new-password"
            maxLength={stringMaxLength}
            onChange={e => this.changePassword2(e.target.value)}
            className="input dbl fl"
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password2}
          />
          {this.passwordsDoNotMatch() ? (
            <div className="fl ml red">Gesli se ne ujemata!</div>
            ) :(
              <button
                onClick={() => this.changeUserData(ChangeUserData.Password)}
                disabled={this.state.password1.length === 0 || this.state.password2.length === 0
                  || !(this.state.password1.length >= passwordMinLength
                  && this.state.password1.length <= passwordMaxLength
                  && this.getPasswordCriteriaNumber(this.state.password1) >=
                  PASSWORD_REQUIREMENTS.length - PASSWORD_REQUIREMENTS_NOT_NECESSARY)
                }
                className="button button-green button-thin ml"
              >
                Spremeni
              </button>
            )}
        </div>

        {this.state.password1 && this.state.password1.length ? (
          <div className="mt">
            <div className="bold">
              Geslo mora biti dolgo od {passwordMinLength} do {passwordMaxLength} znakov. {this.getBooleanValue(
                this.state.password1 &&
                  this.state.password1.length >= passwordMinLength &&
                  this.state.password1.length <= passwordMaxLength,
              )}
            </div>
            <div className="bold">
              Geslo mora izpolnjevati vsaj {PASSWORD_REQUIREMENTS.length -
                PASSWORD_REQUIREMENTS_NOT_NECESSARY} od {PASSWORD_REQUIREMENTS.length} pogojev: {this.getBooleanValue(
                this.getPasswordCriteriaNumber(this.state.password1) >=
                  PASSWORD_REQUIREMENTS.length -
                    PASSWORD_REQUIREMENTS_NOT_NECESSARY,
              )}
            </div>
            <ol className="password-criteria">
              {this.getPasswordCriteria(this.state.password1)}
            </ol>
          </div>
        ) : null}

        {/*
        <h4 className = "mb">Nastavitve</h4>
        <div className = "clearfix">
          <input
            autoComplete="new-password"
            type="checkbox"
            className="dbl fl checkbox"
            onChange={() =>
              this.setState({ continentEurope: !this.state.continentEurope })
            }
            checked={this.state.continentEurope}
          />
          <div className="fl ml">Filtriraj samo evropske države</div>
        </div>
        */}
        <div className="mb mt">
          {backButton}
        </div>
        <h4 className="mb">Moja vozila <small className="red">{myVehicles.length ? '' : 'Shranjenega ni nobenega vozila.'}</small></h4>
        {myVehicles.length ? (
          <>
            <hr />
            <div className="clearfix">{myVehicles}</div>
            <hr />
          </>
        ) : null}
        <div className="clearfix">
          <strong>Vnos novega vozila</strong>
          <div className="mt5 clearfix">
            <div className="tar beforeinput fl">Naziv:</div>
            <input
              autoComplete="new-password"
              maxLength={stringDefaultLength}
              onChange={e => this.edit('VehicleMy', e.target.value)}
              className="input dbl fl"
              type="text"
              value={this.state.VehicleMy}
            />
          </div>
          <div className="tar beforeinput fl">Tablica:</div>
          <input
            autoComplete="new-password"
            maxLength={stringShortLength}
            onChange={e => this.edit('VehiclePlateMy', e.target.value)}
            className="input dbl fl"
            type="text"
            value={this.state.VehiclePlateMy}
          />
        </div>
        <div className="mt">
          {backButton}
          <button
            disabled={this.isMyNewVehicleDisabled()}
            onClick={() => this.newVehicleMy()}
            className="button button-green button-thin ml"
          >
            Vnesi moje novo vozilo
          </button>

        </div>

        <h4 className="mb">Moje točke <small className="red ml">{myPoints.length ? '' : 'Shranjene ni nobene točke.'}</small></h4>
        {myPoints.length ? (
          <>
            <hr />
            <div className="clearfix">{myPoints}</div>
            <hr />
          </>
        ) : null}
        <div className="clearfix">
          <strong>Vnos nove točke</strong>
          <div className="mt5 clearfix">
            <div className="tar beforeinput fl">Naziv:</div>
            <input
              autoComplete="new-password"
              maxLength={stringDefaultLength}
              onChange={e => this.edit('PointNameMy', e.target.value)}
              className="input dbl fl"
              type="text"
              value={this.state.PointNameMy}
            />
          </div>

          <div className="clearfix">
            <div className="tar beforeinput fl">Naslov:</div>
            <input
              autoComplete="new-password"
              maxLength={stringDefaultLength}
              onChange={e => this.edit('PointAddressMy', e.target.value)}
              className="input dbl fl"
              type="text"
              value={this.state.PointAddressMy}
            />
          </div>
          <div className="clearfix">
            <div className="tar beforeinput fl">Kratko:</div>
            <input
              autoComplete="new-password"
              maxLength={stringShortLength}
              onChange={e => this.edit('PointShortMy', e.target.value)}
              className="input dbl fl"
              type="text"
              value={this.state.PointShortMy}
            />
          </div>
        </div>
        <div className="mt">
          {backButton}
          <button
            disabled={this.isMyNewPointDisabled()}
            onClick={() => this.newPointMy()}
            className="button button-green button-thin ml"
          >
            Vnesi mojo novo točko
          </button>
        </div>
      </div>
    );
  }

  public getPasswordCriteria(password: string): JSX.Element[] {
    const pc: JSX.Element[] = [];
    for (let i = 0; i < PASSWORD_REQUIREMENTS.length; i++) {
      pc.push(
        <li key={i}>
          Geslo mora imeti vsaj {PASSWORD_REQUIREMENTS[i]} {PASSWORD_REQUIREMENTS_DESCRIPTION[i]}. {this.getBooleanValue(
            this.checkPasswordStrengthIndividual(password, i),
          )}
        </li>,
      );
    }
    return pc;
  }

  public getPasswordCriteriaNumber(password: string): number {
    let criteria = 0;
    for (let i = 0; i < PASSWORD_REQUIREMENTS.length; i++) {
      if (this.checkPasswordStrengthIndividual(password, i)) {
        criteria++;
      }
    }
    return criteria;
  }

  public checkPasswordStrengthIndividual(
    password: string,
    strength: PasswordStrength,
  ): boolean {
    if (!password || password.length === 0) {
      return false;
    }
    let count = 0;
    for (let i = 0; i < password.length; i++) {
      if (
        strength === PasswordStrength.UppercaseLetter &&
        password.charAt(i) >= 'A' &&
        password.charAt(i) <= 'Z'
      ) {
        count++;
      }
      if (
        strength === PasswordStrength.LowercaseLetter &&
        password.charAt(i) >= 'a' &&
        password.charAt(i) <= 'z'
      ) {
        count++;
      }
      if (
        strength === PasswordStrength.Number &&
        password.charAt(i) >= '0' &&
        password.charAt(i) <= '9'
      ) {
        count++;
      }
      if (
        strength === PasswordStrength.SpecialCharacter &&
        this.isSpecialCharacter(password.charAt(i))
      ) {
        count++;
      }
    }

    return count >= PASSWORD_REQUIREMENTS[strength];
  }

  public isSpecialCharacter(character: string): boolean {
    for (let i = 0; i < specialCharacters.length; i++) {
      if (character === specialCharacters.charAt(i)) {
        return true;
      }
    }
    return false;
  }

  public getBooleanValue(bool: boolean): JSX.Element {
    return bool ? (
      <strong className="symbol green">✓</strong>
    ) : (
      <strong className="symbol red">✗</strong>
    );
  }

  public getMyVehicles(): JSX.Element[] {
    const v: JSX.Element[] = [];
    if (this.state.vehicles) {
      for (let i = 0; i < this.state.vehicles.length; i++) {
        if (this.state.vehicles[i].useruuid !== this.props.userData.uuid) {
          continue;
        }
        v.push(
          <div key={i} className="clearfix mt5">
            <div className="fl vehicle-name">{this.state.vehicles[i].name}</div>
            <button
              onClick={() =>
                this.updateValue(
                  'vehicles',
                  'vehicle',
                  'active',
                  i,
                  this.state.vehicles[i].uuid,
                  !this.state.vehicles[i].active,
                )
              }
              title={
                this.state.vehicles[i].active
                  ? 'Aktivno vozilo'
                  : 'Neaktivno vozilo'
              }
              className={clsx(
                'dbl fl vehicle-active button button-thin',
                this.state.vehicles[i].active ? 'button-green' : 'button-red',
              )}
            >
              {this.state.vehicles[i].active ? 'A' : 'N'}
            </button>
            <div className="fl vehicle-plate">
              {this.state.vehicles[i].plate}
            </div>
          </div>,
        );
      }
    }
    return v;
  }

  public getMyPoints(): JSX.Element[] {
    const p: JSX.Element[] = [];
    if (this.state.points) {
      for (let i = 0; i < this.state.points.length; i++) {
        if (this.state.points[i].useruuid !== this.props.userData.uuid) {
          continue;
        }
        p.push(
          <div key={i} className="mt5">
            <div className="clearfix">
              <div className="fl point-name">{this.state.points[i].name}</div>
              <button
              onClick={() =>
                this.updateValue(
                  'points',
                  'point',
                  'active',
                  i,
                  this.state.points[i].uuid,
                  !this.state.points[i].active,
                )
              }
              title={
                this.state.points[i].active
                  ? 'Aktivna točka'
                  : 'Neaktivna točka'
              }
              className={clsx(
                'dbl fl vehicle-active button button-thin',
                this.state.points[i].active ? 'button-green' : 'button-red',
              )}
            >
              {this.state.points[i].active ? 'A' : 'N'}
            </button>

              <div className="fl point-short">{this.state.points[i].shortname}</div>
            </div>
            <div className="point-address">{this.state.points[i].address}</div>
          </div>,
        );
      }
    }
    return p;
  }

  public passwordsDoNotMatch(): boolean {
    return (
      this.state.password1.length > 0 &&
      this.state.password2.length > 0 &&
      this.state.password1 !== this.state.password2
    );
  }

  public PasswordsDoNotMatch(): boolean {
    return (
      this.state.Password1.length > 0 &&
      this.state.Password2.length > 0 &&
      this.state.Password1 !== this.state.Password2
    );
  }

  public changePassword1(password1: string) {
    this.setState({ password1 });
  }

  public changePassword2(password2: string) {
    this.setState({ password2 });
  }

  public getUserType(usertype: UserType): string {
    return usertype === UserType.Regular
      ? 'Navaden'
      : usertype === UserType.Admin
      ? 'Admin'
      : 'Super';
  }

  public isNewRegistrationDisabled(): boolean {
    if (this.state.Password1.length === 0) {
      return true;
    }
    if (this.state.Password2.length === 0) {
      return true;
    }
    if (this.state.Firstname.length === 0) {
      return true;
    }
    if (this.state.Lastname.length === 0) {
      return true;
    }
    if (this.state.Email.length === 0) {
      return true;
    }
    if (this.state.Password2 !== this.state.Password1) {
      return true;
    }
    return false;
  }

  public isNewCompanyDisabled(): boolean {
    if (this.state.Company.length === 0) {
      return true;
    }
    if (
      !this.state.CompanyLeaderuuid ||
      this.state.CompanyLeaderuuid.length === 0
    ) {
      return true;
    }
    return false;
  }

  public isNewSectionDisabled(): boolean {
    if (this.state.Section.length === 0) {
      return true;
    }
    if (
      !this.state.SectionCompanyuuid ||
      this.state.SectionCompanyuuid.length === 0
    ) {
      return true;
    }
    if (
      !this.state.SectionLeaderuuid ||
      this.state.SectionLeaderuuid.length === 0
    ) {
      return true;
    }
    return false;
  }
/*
  public isNewCustomerDisabled(): boolean {
    if (this.state.Customer.length === 0) {
      return true;
    }
    if (this.state.CustomerAddress.length === 0) {
      return true;
    }
    return false;
  }
*/
  public isNewPointDisabled(): boolean {
    if (this.state.Point.length === 0) {
      return true;
    }
    // if (this.state.PointAddress.length === 0) {return true;}
    return false;
  }

  public isNewProjectDisabled(): boolean {
    if (this.state.Project.length === 0) {
      return true;
    }
    if (this.state.ProjectCode.length === 0) {
      return true;
    }
    return false;
  }

  public isMyNewVehicleDisabled(): boolean {
    if (this.state.VehiclePlateMy.length === 0) {
      return true;
    }
    return false;
  }

  public isMyNewPointDisabled(): boolean {
    if (this.state.PointNameMy.length === 0 || this.state.PointShortMy.length === 0) {
      return true;
    }
    return false;
  }

  public isNewVehicleDisabled(): boolean {
    if (this.state.VehiclePlate.length === 0) {
      return true;
    }
    if (this.state.VehicleType === VehicleType.None) {
      return true;
    }
    return false;
  }

  public isNewSubsistenceDisabled(): boolean {
    if (this.state.Subsistence.length === 0) {
      return true;
    }
    if (this.state.SubsistenceCountry.length === 0) {
      return true;
    }
    if (this.state.SubsistenceValue.length === 0) {
      return true;
    }
    return false;
  }

  public isNewMileageDisabled(): boolean {
    if (this.state.Mileage.length === 0) {
      return true;
    }
    if (this.state.MileageValue.length === 0) {
      return true;
    }
    return false;
  }

  public isNewExpenseDisabled(): boolean {
    if (this.state.Expense.length === 0) {
      return true;
    }
    return false;
  }

  public isNewCountryDisabled(): boolean {
    if (this.state.Country.length === 0) {
      return true;
    }

    return false;
  }

  public isNewExposedPointDisabled(): boolean {
    if (this.state.ExposedPoint.length === 0) {
      return true;
    }

    return false;
  }

  public async newRegistraton() {
    const regexEmail = new RegExp(regexemail);
    if (!regexEmail.test(this.state.Email)) {
      alert('E-pošta vsebuje neveljavne znake, oz. je napačnega formata!');
      return;
    }
    const registerData: RegisterData = {
      password1: this.state.Password1,
      password2: this.state.Password2,
      userData: {
        firstname: this.state.Firstname,
        lastname: this.state.Lastname,
        email: this.state.Email,
        usertype: this.state.Level,
        telephone: this.state.Telephone,
        companyuuid: '',
      },
      token: this.props.userData.token,
    };
    const response = await axios.post(register, registerData);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const userData: UserData[] = Object.assign([], this.state.userData);
      userData.push({
        uuid: response.data.data.uuid,
        firstname: this.state.Firstname,
        lastname: this.state.Lastname,
        email: this.state.Email,
        usertype: this.state.Level,
        telephone: this.state.Telephone,
        registered: response.data.data.registered,
        companyuuid: ''
      });
      this.setState({ userData, Email: '', Firstname: '', Lastname: '', Level: UserType.Regular, Telephone: '', Password1: '', Password2: ''});
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newCompany() {
    const data = {
      company: this.state.Company,
      pointuuid: this.state.CompanyPointuuid,
      leaderuuid: this.state.CompanyLeaderuuid,
      token: this.props.userData.token,
    };
    const response = await axios.post(company, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const companies: CompanyData[] = Object.assign([], this.state.companies);
      companies.push({
        uuid: response.data.data.uuid,
        name: this.state.Company,
        pointuuid: this.state.CompanyPointuuid.length
          ? this.state.CompanyPointuuid
          : null,
        leaderuuid: this.state.CompanyLeaderuuid.length
          ? this.state.CompanyLeaderuuid
          : null,
          n: 'X',
      });
      this.setState({ companies, Company: '', CompanyPointuuid: '', CompanyLeaderuuid: ''});
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newSection() {
    const data = {
      section: this.state.Section,
      companyuuid: this.state.SectionCompanyuuid,
      leaderuuid: this.state.SectionLeaderuuid,
      token: this.props.userData.token,
    };
    const response = await axios.post(section, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const sections: SectionData[] = Object.assign([], this.state.sections);
      sections.push({
        uuid: response.data.data.uuid,
        name: this.state.Section,
        companyuuid: this.state.SectionCompanyuuid.length
          ? this.state.SectionCompanyuuid
          : null,
        leaderuuid: this.state.SectionLeaderuuid.length
          ? this.state.SectionLeaderuuid
          : null,
      });
      this.setState({ sections, Section: '', SectionCompanyuuid: '', SectionLeaderuuid: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newVehicleMy() {
    const data: {
      token: string;
      vehicle: string;
      type: VehicleType;
      useruuid: string;
      plate: string;
    } = {
      vehicle: this.state.VehicleMy,
      type: VehicleType.Private,
      token: this.props.userData.token,
      plate: this.state.VehiclePlateMy,
      useruuid: this.props.userData.uuid,
    };

    const response = await axios.post(vehicle, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.uuid
    ) {
      const vehicles: VehicleData[] = Object.assign([], this.state.vehicles);
      vehicles.push({
        uuid: response.data.uuid,
        name: this.state.VehicleMy,
        plate: this.state.VehiclePlateMy,
        useruuid: this.props.userData.uuid,
        type: VehicleType.Private,
        active: true,
      });
      this.setState({ vehicles, VehicleMy: '', VehiclePlateMy: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newPointMy() {
    const data = {
      point: this.state.PointNameMy,
      address: this.state.PointAddressMy,
      shortname: this.state.PointShortMy,
      useruuid: this.props.userData.uuid,
      token: this.props.userData.token,
    };
    const response = await axios.post(point, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.uuid
    ) {
      const points: PointData[] = Object.assign([], this.state.points);
      points.push({
        uuid: response.data.uuid,
        name: this.state.PointNameMy,
        address: this.state.PointAddressMy,
        shortname: this.state.PointShortMy,
        useruuid: this.props.userData.uuid,
        active: true
      });
      this.setState({ points, PointNameMy: '', PointAddressMy: '', PointShortMy: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newVehicle() {
    const data: {
      token: string;
      vehicle: string;
      type: VehicleType;
      useruuid?: string;
      plate?: string;
    } = {
      vehicle: this.state.Vehicle,
      type: this.state.VehicleType,
      token: this.props.userData.token,
    };

    if (
      this.state.VehicleType === VehicleType.Company ||
      this.state.VehicleType === VehicleType.Private
    ) {
      data.plate = this.state.VehiclePlate;
    }
    if (this.state.VehicleType === VehicleType.Private) {
      data.useruuid = this.state.VehicleUseruuid;
    }

    const response = await axios.post(vehicle, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.uuid
    ) {
      const vehicles: VehicleData[] = Object.assign([], this.state.vehicles);
      vehicles.push({
        uuid: response.data.uuid,
        name: this.state.Vehicle,
        plate: this.state.VehiclePlate,
        useruuid: this.state.VehicleUseruuid.length
          ? this.state.VehicleUseruuid
          : null,
        type: this.state.VehicleType,
        active: true,
      });
      this.setState({ vehicles, Vehicle: '', VehiclePlate: '', VehicleUseruuid: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }
/*
  public async newCustomer() {
    const data = {
      customer: this.state.Customer,
      address: this.state.CustomerAddress,
      pointuuid: this.state.CustomerPointuuid,
      token: this.props.userData.token,
    };
    const response = await axios.post(customer, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const customers: CustomerData[] = Object.assign([], this.state.customers);
      customers.push({
        uuid: response.data.data.uuid,
        name: this.state.Customer,
        address: this.state.CustomerAddress,
        pointuuid: this.state.CustomerPointuuid.length
          ? this.state.CustomerPointuuid
          : null,
      });
      this.setState({ customers, Customer: '', CustomerAddress: '', CustomerPointuuid: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }
*/
  public async newPoint(disableUser?: boolean) {
    const data = {
      point: this.state.Point,
      address: this.state.PointAddress,
      shortname: this.state.PointShortName,
      useruuid: disableUser ? '' : this.state.PointUseruuid,
      token: this.props.userData.token,
    };
    const response = await axios.post(point, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.uuid
    ) {
      const points: PointData[] = Object.assign([], this.state.points);
      points.push({
        uuid: response.data.uuid,
        name: this.state.Point,
        address: this.state.PointAddress,
        shortname: this.state.PointShortName.length
          ? this.state.PointShortName
          : null,
        useruuid: disableUser ? '' : this.state.PointUseruuid,
        active: true
      });
      this.setState({ points, Point: '', PointAddress: '', PointShortName: '', PointUseruuid: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newProject() {
    const data = {
      project: this.state.Project,
      code: this.state.ProjectCode,
      token: this.props.userData.token,
    };
    const response = await axios.post(project, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const projects: ProjectData[] = Object.assign([], this.state.projects);
      projects.push({
        uuid: response.data.data.uuid,
        name: this.state.Project,
        code: this.state.ProjectCode,
        active: true
      });
      this.setState({ projects, Project: '', ProjectCode: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newSubsistence() {
    const data = {
      subsistence: this.state.Subsistence,
      country: this.state.SubsistenceCountry,
      value: parseFloat(this.state.SubsistenceValue),
      breakfast: this.state.SubsistenceBreakfast.length
        ? parseFloat(this.state.SubsistenceBreakfast)
        : null,
      lunch: this.state.SubsistenceLunch.length
        ? parseFloat(this.state.SubsistenceLunch)
        : null,
      dinner: this.state.SubsistenceDinner.length
        ? parseFloat(this.state.SubsistenceDinner)
        : null,
      token: this.props.userData.token,
    };
    const response = await axios.post(subsistence, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const Subsistence: SubsistenceData[] = Object.assign(
        [],
        this.state.subsistence,
      );
      Subsistence.push({
        uuid: response.data.data.uuid,
        name: this.state.Subsistence,
        country: this.state.SubsistenceCountry,
        value: this.state.SubsistenceValue,
        breakfast: this.state.SubsistenceBreakfast,
        lunch: this.state.SubsistenceLunch,
        dinner: this.state.SubsistenceDinner,
        active: true
      });
      this.setState({ subsistence: Subsistence, Subsistence: '', SubsistenceCountry: '', SubsistenceValue: '', SubsistenceBreakfast: '', SubsistenceLunch: '', SubsistenceDinner: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newMileage() {
    const data = {
      mileage: this.state.Mileage,
      value: parseFloat(this.state.MileageValue),
      token: this.props.userData.token,
    };
    const response = await axios.post(mileage, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const Mileage: MileageData[] = Object.assign([], this.state.mileage);
      Mileage.push({
        uuid: response.data.data.uuid,
        name: this.state.Mileage,
        value: this.state.MileageValue,
        active: true
      });
      this.setState({ mileage: Mileage, Mileage: '', MileageValue: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newExpense() {
    const data = {
      expense: this.state.Expense,
      token: this.props.userData.token,
    };
    const response = await axios.post(expense, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    ) {
      const Expense: ExpenseData[] = Object.assign([], this.state.expense);
      Expense.push({
        uuid: response.data.data.uuid,
        name: this.state.Expense,
        active: true
      });
      this.setState({ expense: Expense, Expense: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newCountry() {
    const data = {
      uuid: this.state.Country,
      name: this.getCountry(this.state.Country),
      size: this.state.countries.length,
      token: this.props.userData.token,
    };
    const response = await axios.post(country, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.message === 'success'
    ) {
      const countries: CountryData[] = Object.assign([], this.state.countries);
      countries.push({
        uuid: this.state.Country,
        name: this.getCountry(this.state.Country),
        ordinal: this.state.countries.length + 1,
      });
      this.setState({ countries, Country: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async newExposedPoint() {
    const data = {
      uuid: this.state.ExposedPoint,
      name: this.getPointName(this.state.ExposedPoint),
      size: this.state.pointExposed.length,
      token: this.props.userData.token,
    };
    const response = await axios.post(pointexposed, data);
    if (
      response.data &&
      response.data.resolved &&
      response.data.message === 'success'
    ) {
      const pointExposed: PointExposedData[] = Object.assign([], this.state.pointExposed);
      pointExposed.push({
        uuid: this.state.ExposedPoint,
        name: this.getPointName(this.state.ExposedPoint),
        ordinal: this.state.pointExposed.length + 1,
      });
      this.setState({ pointExposed, ExposedPoint: '' });
    } else if (response.data && response.data.message) {
      alert(response.data.message);
    }
  }

  public async edit(
    what:
      'Expense'
      | 'SectionLeaderuuid'
      | 'CompanyLeaderuuid'
      | 'SectionCompanyuuid'
      | 'Section'
      | 'Telephone'
      | 'MileageValue'
      | 'Mileage'
      | 'SubsistenceValue'
      | 'SubsistenceBreakfast'
      | 'SubsistenceLunch'
      | 'SubsistenceDinner'
      | 'SubsistenceCountry'
      | 'Subsistence'
      | 'VehicleType'
      | 'Vehicle'
      | 'VehiclePlate'
      | 'VehicleMy'
      | 'VehiclePlateMy'
      | 'PointAddressMy'
      | 'PointNameMy'
      | 'PointShortMy'
      | 'VehicleUseruuid'
      | 'Password1'
      | 'Password2'
      | 'Firstname'
      | 'Lastname'
      | 'Email'
      | 'Company'
      | 'Point'
      | 'PointAddress'
      | 'PointShortName'
      | 'PointUseruuid'
      | 'companyuuid'
      | 'Companyuuid'
      | 'CustomerPointuuid'
      | 'CompanyPointuuid'
      | 'Customer'
      | 'ProjectCode'
      | 'Project',
    value: string,
  ) {
    const state: State = Object.assign({}, this.state);
    if (what === 'VehicleType') {
      state[what] = +value;
    } else if (
      what === 'SubsistenceValue' ||
      what === 'SubsistenceBreakfast' ||
      what === 'SubsistenceLunch' ||
      what === 'SubsistenceDinner' ||
      what === 'MileageValue'
    ) {
      if (!isNaN(Number(value)) && value.indexOf('-') === -1) {
        state[what] = value;
      }
    } else {
      state[what] = value;
    }
    this.setState(state);
  }

  public getLevels(none?: boolean): JSX.Element {
    return (
      <>
        {none ? <option value={UserType.None}>Nivo?</option> : null}
        <option value={UserType.Super}>Super</option>
        <option value={UserType.Admin}>Admin</option>
        <option value={UserType.Regular}>Navaden</option>
      </>
    );
  }

  public getUsersByFilter(): number {
    let n = 0;
    if (this.state.userData) {
      if (this.state.fLevel === UserType.None) {
        return this.state.userData.length;
      }
      for (const item of this.state.userData) {
        if (this.state.fLevel === item.usertype) {
          n++;
        }
      }
    }
    return n;
  }

  public getSectionsByFilter(): number {
    let n = 0;
    if (this.state.userData) {
      if (!this.state.fCompany || this.state.fCompany.length === 0) {
        return this.state.sections.length;
      }
      for (const item of this.state.sections) {
        if (this.state.fCompany === item.companyuuid) {
          n++;
        }
      }
    }
    return n;
  }

  public getVehicleTypeOptions(
    table?: boolean,
    filter?: boolean,
  ): JSX.Element[] {
    const po: JSX.Element[] = [];
    if (!table) {
      po.push(
        <option key="" value="">
          {filter ? 'Tip vozila?' : table ? '-' : 'Izberi tip vozila'}
        </option>,
      );
    }
    po.push(
      <option key={VehicleType.Company} value={VehicleType.Company}>
        Službeno vozilo
      </option>,
    );
    po.push(
      <option key={VehicleType.Private} value={VehicleType.Private}>
        Zasebno vozilo
      </option>,
    );
    po.push(
      <option key={VehicleType.Companion} value={VehicleType.Companion}>
        Sopotnik
      </option>,
    );

    return po;
  }

  public getPointTypeOptions(
    table?: boolean,
    filter?: boolean,
  ): JSX.Element[] {
    const po: JSX.Element[] = [];
    if (!table) {
      po.push(
        <option key="" value="">
          {filter ? 'Tip točke?' : table ? '-' : 'Izberi tip točke'}
        </option>,
      );
    }
    po.push(
      <option key={PointType.Public} value={PointType.Public}>
        Skupna točka
      </option>,
    );
    po.push(
      <option key={PointType.Private} value={PointType.Private}>
        Zasebna točka
      </option>,
    );

    return po;
  }

  public getPointsDatalist(that, useruuid?: string): JSX.Element[] {
    const po: JSX.Element[] = [];
    if (that.state.points) {
      for (const item of that.state.points) {
        if (item.useruuid && useruuid && item.useruuid !== useruuid) {continue}
        if (!item.active) {continue}
        po.push(
          <option key={item.uuid} value={item.name}>
            {item.name}
          </option>,
        );
      }
    }
    return po;
  }

  public isExposedPoint(uuid: string): boolean {
    if (this.state.pointExposed) {
      for (const item of this.state.pointExposed) {
        if (item.uuid === uuid) {
          return true
        }
      }
    }
    return false
  }

  public getPointOptions(table?: boolean, message?: string, useruuid?: string, excludeExposedPoints?: boolean): JSX.Element[] {
    const po: JSX.Element[] = [];
    po.push(
      <option key="" value="">
        {table ? '-' : (message ? message : 'Izberi točko')}
      </option>,
    );

    if (this.state.points) {
      for (const item of this.state.points) {
        if (item.useruuid && useruuid && item.useruuid !== useruuid) {continue}
        if (!item.active) {continue}
        if (excludeExposedPoints && (item.useruuid || this.isExposedPoint(item.uuid))) {continue}
        po.push(
          <option key={item.uuid} value={item.uuid}>
            {item.name}
          </option>,
        );
      }
    }
    return po;
  }

  public getSubsistenceValue(item: Subsistence): number {
    for (const Item of this.state.subsistence) {
      if (item.uuid === Item.uuid) {
        const Value: number = Item.value ? parseFloat(Item.value) : 0;
        const factor: number =
          100 -
          (item.breakfast && Item.breakfast ? parseFloat(Item.breakfast) : 0) -
          (item.lunch && Item.lunch ? parseFloat(Item.lunch) : 0) -
          (item.dinner && Item.dinner ? parseFloat(Item.dinner) : 0);
        return Math.round(((Value * factor) / 100) * 100) / 100;
      }
    }
    return 0;
  }

  public getSubsistenceCountry(uuid: string) {
    if (this.state.subsistence) {
      for (const item of this.state.subsistence) {
        if (item.uuid === uuid) {
          return this.getCountry(item.country)
        }
      }
    }
    return '-'
  }

  public getSubsistenceName(uuid: string) {
    if (this.state.subsistence) {
      for (const item of this.state.subsistence) {
        if (item.uuid === uuid) {
          return item.name;
        }
      }
    }
    return '?';
  }

  public getExpenseName(uuid: string) {
    if (this.state.expense) {
      for (const item of this.state.expense) {
        if (item.uuid === uuid) {
          return item.name;
        }
      }
    }
    return '?';
  }

  public getSubsistenceOptions(that, table: boolean, subsistenceuuid: string): JSX.Element[] {
    const so: JSX.Element[] = [];
    so.push(
      <option key="" value="">
        {table ? '-' : 'Izberi vrsto dnevnice'}
      </option>,
    );

    if (that.state.subsistence) {
      for (const item of that.state.subsistence) {
        if (item.active || subsistenceuuid === item.uuid) {
          so.push(
            <option key={item.uuid} value={item.uuid}>
              {item.country} ({item.value} €), {item.name}
            </option>,
          );
        }
      }
    }

    return so;
  }

  public getExpenseOptions(table?: boolean): JSX.Element[] {
    const so: JSX.Element[] = [];
    so.push(
      <option key="" value="">
        {table ? '-' : 'Izberi strošek'}
      </option>,
    );

    if (this.state.expense) {
      for (const item of this.state.expense) {
        so.push(
          <option key={item.uuid} value={item.uuid}>
            {item.name}
          </option>,
        );
      }
    }

    return so;
  }

  public getCompanyDatalist(
    table?: boolean,
    message?: string,
    skip?: boolean,
    userWorking?: boolean
  ): JSX.Element[] {
    const po: JSX.Element[] = [];
    if (!skip) {
      po.push(
        <option key="" value="">
          {message ? message : table ? '-' : 'Izberi podjetje'}
        </option>,
      );
    }
    if (this.state.companies) {
      for (const item of this.state.companies) {
        let disabled: boolean = false
        if (userWorking && this.state.kedata && this.state.kedata.userWorking) {
          for (const Item of this.state.kedata.userWorking) {
            if (Item.companyuuid === item.uuid) {
              disabled = true
              break
            }
          }
        }
        po.push(
          <option disabled={disabled} key={item.uuid} value={item.name}>
            {item.name}
          </option>,
        );
      }
    }

    return po;
  }

  public getCompanyOptions(
    table?: boolean,
    message?: string,
    skip?: boolean,
  ): JSX.Element[] {
    const po: JSX.Element[] = [];
    if (!skip) {
      po.push(
        <option key="" value="">
          {message ? message : table ? '-' : 'Izberi podjetje'}
        </option>,
      );
    }
    if (this.state.companies) {
      for (const item of this.state.companies) {
/*
        let disabled: boolean = false
        if (userWorking && this.state.kedata && this.state.kedata.userWorking) {
          for (const Item of this.state.kedata.userWorking) {
            if (Item.companyuuid === item.uuid) {
              disabled = true
              break
            }
          }
        }
*/
        po.push(
          <option /*disabled={disabled}*/ key={item.uuid} value={item.uuid}>
            {item.name}
          </option>,
        );
      }
    }

    return po;
  }

  public getSectionOptions(
    table?: boolean,
    message?: string,
    skip?: boolean,
    companyuuid?: string
  ): JSX.Element[] {
    const po: JSX.Element[] = [];
    if (!skip) {
      po.push(
        <option key="" value="">
          {message ? message : table ? '-' : 'Izberi oddelek'}
        </option>,
      );
    }
    if (this.state.sections) {
      for (const item of this.state.sections) {
        if (companyuuid && companyuuid !== item.companyuuid) {continue}
        po.push(
          <option key={item.uuid} value={item.uuid}>
            {item.name}
          </option>,
        );
      }
    }

    return po;
  }

  public getMileageOptions(that, table: boolean, message: string, mileageuuid: string): JSX.Element[] {
    const po: JSX.Element[] = [];
    po.push(
      <option key="" value="">
        {message ? message : table ? '-' : ''}
      </option>,
    );
    if (that.state.mileage) {
      for (const item of that.state.mileage) {
        if (item.active || mileageuuid === item.uuid) {
          po.push(
            <option key={item.uuid} value={item.uuid}>
              {item.name} {item.value} €
            </option>,
          );
        }
      }
    }

    return po;
  }

  public getProjectOptions(companyuuid: string): JSX.Element[] {
    const po: JSX.Element[] = [];
    po.push(<option key="" value=""></option>);

    if (this.state.projects) {
      for (const item of this.state.projects) {
        if (!item.active) {continue}

        let n: string
        if (this.state.companies) {
          for (const Item of this.state.companies) {
            if (Item.uuid === companyuuid) {
              n = Item.n
              break
            }
          }
        }
        const option: string = n ? `${item.code.replace('X', n)} - ${item.name}` : `${item.code} - ${item.name}`
        po.push(
          <option key={item.uuid} value={item.uuid}>
            {option}
          </option>,
        );
      }
    }

    return po;
  }

  public getProjectName(uuid: string, companyuuid: string): string {
    if (this.state.projects) {
      for (const item of this.state.projects) {
        if (item.uuid === uuid) {
          let n: string
          if (this.state.companies) {
            for (const Item of this.state.companies) {
              if (Item.uuid === companyuuid) {
                n = Item.n
                break
              }
            }
          }
          const option: string = n ? `${item.code.replace('X', n)} - ${item.name}` : `${item.code} - ${item.name}`
          return option
        }
      }
    }

    return '-';
  }

  public getVehiclePlateOptions(
    vehicleType?: VehicleType,
    message?: string,
  ): JSX.Element[] {
    const vpo: JSX.Element[] = [];
    vpo.push(
      <option key="" value="">
        {message ? message : 'Izberi vozilo'}
      </option>,
    );
    if (this.state.vehicles) {
      for (const item of this.state.vehicles) {
        if (vehicleType && vehicleType !== item.type) {
          continue;
        }
        if (this.state.fCompanyVehicle && item.type !== VehicleType.Company) {
          continue;
        }
        if (item.plate === '' || item.plate === null) {
          continue;
        }
        if (!item.active) {
          continue;
        }
        vpo.push(
          <option key={item.uuid} value={item.uuid}>
            {item.plate}
          </option>,
        );
      }
    }
    return vpo;
  }

  public getVehicleOptions(vehicleType?: VehicleType, message?: string): JSX.Element[] {
    const vo: JSX.Element[] = [];
    if (vehicleType !== undefined) {
      vo.push(
        <option key="" value="">
          {message ? message : 'Izberi vozilo'}
        </option>,
      );
    }
    if (this.state.vehicles) {
      for (const item of this.state.vehicles) {
        if (vehicleType && vehicleType !== item.type) {
          continue;
        }
        if (!item.active) {
          continue;
        }
        let vehicle = '?';
        if (item.type === VehicleType.Companion) {
          vehicle = item.name;
        }
        if (item.type === VehicleType.Private) {
          if (item.useruuid !== this.state.document.useruuid) {
            continue
          }
          vehicle = `Zasebno (${item.plate})`;
        }
        if (item.type === VehicleType.Company) {
          vehicle = `${item.name} (${item.plate})`;
        }
        vo.push(
          <option key={item.uuid} value={item.uuid}>
            {vehicle}
          </option>,
        );
      }
    }

    return vo;
  }

  public isUserLeader(leaderuuids: string[], uuid: string): boolean {
    for (const leaderuuid of leaderuuids) {
      if (leaderuuid === uuid) {
        return true;
      }
    }
    return false;
  }

  public getUserOptions(
    table?: boolean,
    message?: string,
    leader?: boolean,
    Leader?: boolean,
  ): JSX.Element[] {
    const uo: JSX.Element[] = [];
    uo.push(
      <option key="" value="">
        {table ? '-' : message ? message : ''}
      </option>,
    );

    if (this.state.userData) {
      for (const item of this.state.userData) {
        if (leader && !this.isUserLeader(this.state.leaders, item.uuid)) {
          continue;
        }
        if (Leader && !this.isUserLeader(this.state.Leaders, item.uuid)) {
          continue;
        }
        uo.push(
          <option key={item.uuid} value={item.uuid}>
            {item.lastname} {item.firstname}
          </option>,
        );
      }
    }

    return uo;
  }

  public countDatalist(codeRegister: CodeRegister[], value: string): boolean {
    if (value === null || value === undefined) {return true}
    if (codeRegister) {
      for (const item of codeRegister) {
        if ((item.value).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          return true
        }
      }
    }
    return false
  }

  public getCodeRegisterValue(codeRegister: CodeRegister[], id: number, Null?: boolean): string {
    if (codeRegister) {
      for (const item of codeRegister) {
        if (item.id === id) {
          return item.value
        }
      }
    }
    return Null ? null : ''
  }

  public getDatalist(codeRegister: CodeRegister[]): JSX.Element[] {
    const datalist: JSX.Element[] = [];
    if (codeRegister) {
      for (const item of codeRegister) {
        datalist.push(
          <option key={item.id} value={item.value}>
            {item.value}
          </option>
        );
      }
    }

    return datalist;
  }

  public getCodeRegister(codeRegister: CodeRegister[], value: string): number {
    for (const item of codeRegister) {
      if (item.value === value) {
        return item.id
      }
    }
    return 0
  }

  public setCodeRegister(field: 'title'|'education'|'section'|'working'|'workposition'|'specialpermits', codeRegister: CodeRegister[],
                         field_: 'title_'|'education_'|'section_'|'working_'|'workposition_'|'specialpermits_', value: string) {

    const kedata: Kedata = Object.assign({}, this.state.kedata)
    kedata[field_] = value
    kedata[field] = this.getCodeRegister(codeRegister, value)
    this.setState({kedata})
  }

  public getUserDatalist(
    nameSearchString?: string,
    table?: boolean,
    message?: string,
    leader?: boolean,
    Leader?: boolean,
    excludeInActiveUsers?: boolean,
  ): JSX.Element[] {
    const uo: JSX.Element[] = [];
    uo.push(
      <option key="" value="">
        {table ? '-' : message ? message : ''}
      </option>,
    );

    if (this.state.userData) {
      for (const item of this.state.userData) {
        if (excludeInActiveUsers) {
            if (item.active === false) {
                continue;
            }
        }
        if (leader && !this.isUserLeader(this.state.leaders, item.uuid)) {
          continue;
        }
        if (Leader && !this.isUserLeader(this.state.Leaders, item.uuid)) {
          continue;
        }

        if (nameSearchString) {
            if (item.lastname.toLowerCase().indexOf(nameSearchString.toLowerCase()) !== -1
                ||
                item.firstname.toLowerCase().indexOf(nameSearchString.toLowerCase()) !== -1)
            {
                uo.push(
                    <option key={item.uuid} value={item.lastname + ' ' + item.firstname}>
                        {item.lastname} {item.firstname}
                    </option>,
                );
            }
        }
        else {
            uo.push(
              <option key={item.uuid} value={item.lastname + ' ' + item.firstname}>
                {item.lastname} {item.firstname}
              </option>,
            );
        }
      } //for (const item of this.state.userData)
    }

    return uo;
  }

  public countCompanyDatalist(fullName: string): number {
    if (!fullName || fullName.length === 0) {return 1}
    if (this.state.companies) {
      for (const item of this.state.companies) {
        if (item && item.name && item.name.toLowerCase().indexOf(fullName.toLowerCase()) !== -1) {
          return 1
        }
      }
    }
    return 0
  }

  public countProjectDatalist(fullName: string): number {
    if (this.state.projects && this.state.companies) {
      const n1: string = '1'
      const n2: string = '2'
      const n3: string = '3'

      for (const item of this.state.projects) {
        if (fullName === item.code.replace('X', n1) + ' - ' + item.name
        || fullName === item.code.replace('X', n2) + ' - ' + item.name
        || fullName === item.code.replace('X', n3) + ' - ' + item.name
        || (item.name && item.name.toLowerCase().indexOf(fullName.toLowerCase()) !== -1)
        || (item.code && item.code.replace('X', n1).toLowerCase().indexOf(fullName.toLowerCase()) !== -1)
        || (item.code && item.code.replace('X', n2).toLowerCase().indexOf(fullName.toLowerCase()) !== -1)
        || (item.code && item.code.replace('X', n3).toLowerCase().indexOf(fullName.toLowerCase()) !== -1)

        ) {
          return 1
        }
      }
    }
    return 0
  }

  public getProjectDatalist(
    table?: boolean,
    message?: string,
  ): JSX.Element[] {
    const uo: JSX.Element[] = [];
    uo.push(
      <option key="" value="">
        {table ? '-' : message ? message : ''}
      </option>,
    );

    if (this.state.projects) {
      const replaces = ['1', '2', '3']
      for (const item of this.state.projects) {
        for (const Item of replaces) {
          uo.push(
            <option key={item.uuid+Item} value={item.code.replace('X', Item) + ' - ' + item.name}>
              {item.code.replace('X', Item) + ' - ' + item.name}
            </option>,
          );
        }
      }
    }

    return uo;
  }

  public countVehiclePlateDatalist(plate: string): number {
    if (plate.length === 0) {return 1}
    if (plate && this.state.vehicles) {
      for (const item of this.state.vehicles) {
        if (this.state.fCompanyVehicle && item.type !== VehicleType.Company) {continue}
        if (item.name && (item.name + (item.plate ? ' ' + item.plate : '')).toLowerCase().indexOf(plate.toLowerCase()) !== -1) {
          return 1
        }
      }
    }
    return 0
  }

  public getVehiclePlateDatalist(
    table?: boolean,
    message?: string,
  ): JSX.Element[] {
    const uo: JSX.Element[] = [];
    uo.push(
      <option key="" value="">
        {table ? '-' : message ? message : ''}
      </option>,
    );

    if (this.state.vehicles) {
      for (const item of this.state.vehicles) {
        if (this.state.fCompanyVehicle && item.type !== VehicleType.Company) {continue}
        uo.push(
          <option key={item.uuid} value={(item.plate ? item.plate : '')}>
            {item.name + (item.plate ? ' ' + item.plate : '')}
          </option>,
        );
      }
    }

    return uo;
  }
/*
  public getCustomerOptions(): JSX.Element[] {
    const co: JSX.Element[] = [];
    co.push(<option key="" value=""></option>);
    if (this.state.customers) {
      for (const item of this.state.customers) {
        co.push(
          <option key={item.uuid} value={item.uuid}>
            {item.name}
          </option>,
        );
      }
    }
    return co;
  }
*/
  public getPointName(uuid: string): string {
    if (!uuid) {
      return '-';
    }
    if (this.state.points) {
      for (const item of this.state.points) {
        if (item.uuid === uuid) {
          return item.name;
        }
      }
    }
    return '?';
  }

  public getPointShortName(that, uuid: string): string {
    if (!uuid) {
      return '-';
    }
    if (that.state.points) {
      for (const item of that.state.points) {
        if (item.uuid === uuid) {
          return item.shortname;
        }
      }
    }
    return '?';
  }

  public getVehicleType(vehicleType: VehicleType): string {
    if (vehicleType === VehicleType.None) {
      return vehicleDesc[vehicleType];
    }
    if (vehicleType === VehicleType.Companion) {
      return vehicleDesc[vehicleType];
    }
    if (vehicleType === VehicleType.Company) {
      return vehicleDesc[vehicleType];
    }
    if (vehicleType === VehicleType.Private) {
      return vehicleDesc[vehicleType];
    }
    return '?';
  }

  public getVehicle(that, uuid: string): string {
    if (that.state.vehicles) {
      for (const item of that.state.vehicles) {
        if (item.uuid === uuid) {
          return item.name.length ? item.name : '-';
        }
      }
    }
    return '?';
  }

  public getVehicleEither(that, uuid: string, vehicleType: string): JSX.Element {
    if (that.state.vehicles) {
      for (const item of that.state.vehicles) {
        if (item.uuid === uuid) {
          return <span>
            {(item.name && item.name.length) && (item.plate && item.plate.length) ? item.name + ' ' + item.plate :
            (item.name && item.name.length) ? item.name :
            (item.plate && item.plate.length) ? item.plate : '-'}
          </span>
        }
      }
    }

    if (vehicleType === VehicleType.Private) {
      return <>Zasebno</>;
    }
    else {
      return <>?</>;
    }
  }

  public getVehiclePlate(that, uuid: string): string {
    if (that.state.vehicles) {
      for (const item of that.state.vehicles) {
        if (item.uuid === uuid) {
          return item.plate && item.plate.length ? item.plate : '-';
        }
      }
    }
    return '?';
  }

  public getMileageValue(uuid: string): string {
    if (this.state.mileage) {
      for (const item of this.state.mileage) {
        if (item.uuid === uuid) {
          return item.value;
        }
      }
    }
    return '?';
  }

  public getUserName(uuid: string, that?): string {
    if (that) {
      if (that.state && that.state.userData) {
        for (const item of that.state.userData) {
          if (item.uuid === uuid) {
            return item.lastname + ' ' + item.firstname;
          }
        }
      }
      return '?';
    }
    if (this.state && this.state.userData) {
      for (const item of this.state.userData) {
        if (item.uuid === uuid) {
          return item.lastname + ' ' + item.firstname;
        }
      }
    }
    return '?';
  }

  public getCompanyName(uuid: string): string {
    if (this.state.companies) {
      for (const item of this.state.companies) {
        if (item.uuid === uuid) {
          return item.name;
        }
      }
    }
    return '?';
  }

  public getCompanyBasicInformation(uuid: string): JSX.Element {
    switch (uuid) {
    case "11c65c97-84fc-4acd-b7b6-dd208a88da03": //IRGO
    {
      return (
        <>
        <div>
          {<img key="-1" style={{height: '50px'}} src={logo_irgo_horizontal} />}
        </div>
        <div className="companyDetails">Inštitut za rudarstvo, geotehnologijo in okolje</div>
        <div className="companyDetails">Slovenčeva 93</div>
        <div className="companyDetails">1000 Ljubljana</div>
        <div className="companyDetails">Slovenija</div>
        <div className="companyDetails">DŠ: 44739222</div>
        <div>Podpis: Dr. Jože Ratej, direktor</div>
        </>
      )
    }
    case "89ab3de6-02d9-4c7b-a8bb-bf56c24b9156": //IRGO Consulting
    {
      return (
        <>
        <div>
          {<img key="-1" style={{height: '50px'}} src={logo_irgo_consulting_horizontal} />}
        </div>
        <div className="companyDetails">IRGO Consulting d.o.o.</div>
        <div className="companyDetails">Slovenčeva 93</div>
        <div className="companyDetails">1000 Ljubljana</div>
        <div className="companyDetails">Slovenija</div>
        <div className="companyDetails">DŠ: 57488924</div>
        <div>Podpis: Dr. Vladimir Vukadin, direktor</div>
        </>
      )
    }
    case "e5037aef-667a-44a3-9dd9-1863be64962f": //GRI
    {
      return (
        <>
        <div>
          {<img key="-1" style={{height: '50px'}} src={logo_gri} />}
        </div>
        <div className="companyDetails">GR Investicije d.o.o.</div>
        <div className="companyDetails">Slovenčeva 93</div>
        <div className="companyDetails">1000 Ljubljana</div>
        <div className="companyDetails">Slovenija</div>
        <div className="companyDetails">DŠ: 12894290</div>
        <div>Podpis: Dr. Vojkan Jovičić, direktor</div>
        </>
      )
    }
    default:
      return (
        <div>?</div>
      )
    }
  }
/*
  public getCustomerName(uuid: string): string {
    if (this.state.customers) {
      for (const item of this.state.customers) {
        if (item.uuid === uuid) {
          return item.name;
        }
      }
    }
    return '?';
  }

  public getProjectName(uuid: string): string {
    if (this.state.projects) {
      for (const item of this.state.projects) {
        if (item.uuid === uuid) {
          return item.name;
        }
      }
    }
    return '?';
  }
*/
  public async updateValue(
    stateVariable:
      | 'countries'
      | 'companies'
      | 'customers'
      | 'points'
      | 'projects'
      | 'vehicles'
      | 'subsistence'
      | 'mileage'
      | 'sections'
      | 'expense'
      | 'pointExposed',
    table:
      | 'country'
      | 'company'
      | 'customer'
      | 'point'
      | 'project'
      | 'vehicle'
      | 'subsistence'
      | 'mileage'
      | 'expense'
      | 'section'
      | 'pointexposed',
    field:
      | 'name'
      | 'address'
      | 'pointuuid'
      | 'shortname'
      | 'code'
      | 'plate'
      | 'useruuid'
      | 'companyuuid'
      | 'type'
      | 'country'
      | 'value'
      | 'breakfast'
      | 'lunch'
      | 'dinner'
      | 'leaderuuid'
      | 'uuid'
      | 'active'
      | 'ordinal',
    index: number,
    uuid: string,
    value,
  ) {
    let proceed = true
    if (field === 'value' || field === 'breakfast' || field === 'lunch' || field === 'dinner') {
      proceed = false
      if (!isNaN(value) && value.indexOf('-') === -1) {
        proceed = true
      }
    }
    if (proceed) {
      const state: State = Object.assign({}, this.state);
      if (ALERT_SERVER_REQUESTS) alert(`A request will be sent to server... table=${table}, field=${field}, value=${value}, value2=${field === 'ordinal' ? state[stateVariable][index][field] : undefined}, (func: updateValue)`);
      const response = await axios.put(general, {
        table,
        field,
        uuid,
        value,
        value2: field === 'ordinal' ? state[stateVariable][index][field] : undefined,
        token: this.props.userData.token,
      });
      if (response.data && response.data.resolved) {
        if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: updateValue)');
        if (field === 'type' || field === 'ordinal') {
          for (let index0 = 0; index0 < state[stateVariable].length; index0++) {
            if (state[stateVariable][index0][field] === +value) {
              state[stateVariable][index0][field] = state[stateVariable][index][field];
              break;
            }
          }
          state[stateVariable][index][field] = +value;
        } else {
          state[stateVariable][index][field] = value;
        }
        this.setState(state);
      }
      else if (response && response.data && response.data.resolved === false && response.data.message) {
        if (ALERT_SERVER_REQUESTS) alert(`Error: Failed response from server! (func: updateValue)\nmsg: ${response.data.message}`);
      }
      else {
        if (ALERT_SERVER_REQUESTS) alert('Error: Failed response from server! (func: updateValue)');
      }
    }
  }

  public getCompanies(): JSX.Element {
    return (
      <div>
        {this.state.companies ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.companies}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize1) {
                  this.setState({ pSize1: pageSize, pNum1: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum1: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  width: 30,
                  Header: '#',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'n',
                  Cell: row => (
                    <div>
                      {row.value}.
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Naziv podjetja',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'companies',
                              'company',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className="input input-table"
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Direktor podjetja',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'leaderuuid',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getUserName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'companies',
                              'company',
                              'leaderuuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className="select select-table"
                        >
                          {this.getUserOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Točka sedeža',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'pointuuid',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getPointName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'companies',
                              'company',
                              'pointuuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className="select select-table"
                        >
                          {this.getPointOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },
/*
                {
                  sortable: false,
                  Header: <span title="Zamenjaj X v imenu projekta s tem znakom">X</span>,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tac',
                  accessor: 'n',
                  width: 30,
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'companies',
                              'company',
                              'n',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className="input input-table"
                          value={row.value}
                          maxLength={1}
                          style = {{paddingLeft: '4px'}}
                        />
                      )}
                    </div>
                  ),
                },
*/
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'usertype',
                  Cell: row => (
                    <div>
                      <a
                        onClick={() =>
                          this.deleteCompany(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.companies.length <= this.state.pSize1 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.companies.length < this.state.pSize1
                  ? this.state.companies.length
                  : this.state.pSize1
              }
              page={this.state.pNum1}
              pageSizeOptions={[defaultPageSize]}
            />
            <div>
              Skupno št. podjetij: <strong>{this.state.companies.length}</strong>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega podjetja</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationCompany:
                      !this.state.showRegistrationCompany,
                  })
                }
              >
                {this.state.showRegistrationCompany ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}

            {this.state.showRegistrationCompany ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Company', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Company}
                  />
                </div>

                <div className="clearfix">
                  <div title="Direktor podjetja" className="tar beforeinput fl">
                    Direktor:
                  </div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.CompanyLeaderuuid}
                    onChange={e =>
                      this.edit('CompanyLeaderuuid', e.target.value)
                    }
                  >
                    {this.getUserOptions(
                      undefined,
                      'Izberi direktorja podjetja',
                    )}
                  </select>
                </div>

                <div className="clearfix">
                  <div className="tar beforeinput fl">Točka:</div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.CompanyPointuuid}
                    onChange={e =>
                      this.edit('CompanyPointuuid', e.target.value)
                    }
                  >
                    {this.getPointOptions()}
                  </select>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewCompanyDisabled()}
                    onClick={() => this.newCompany()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi novo podjetje
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getSections(): JSX.Element {
    const filtered: Filter[] = [];
    if (this.state.fCompany && this.state.fCompany.length) {
      filtered.push({ id: 'companyuuid', value: this.state.fCompany });
    }
    return (
      <div>
        <div className = "clearfix">
          <div className="fl bold">Filter:</div>
          <select
            value={this.state.fCompany}
            onChange={e =>
              this.setState({ pNum8: 0, fCompany: e.target.value })
            }
            className="ml dbl fl select select-input-no-width"
          >
            {this.getCompanyOptions()}
          </select>
        </div>
        {this.state.sections ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.sections}
              filtered={filtered}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize8) {
                  this.setState({ pSize8: pageSize, pNum8: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum8: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv oddelka',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'sections',
                              'section',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className="input input-table"
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Vodja oddelka',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'leaderuuid',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getUserName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'sections',
                              'section',
                              'leaderuuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className="select select-table"
                        >
                          {this.getUserOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Podjetje',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'companyuuid',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getCompanyName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'sections',
                              'section',
                              'companyuuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className="select select-table"
                        >
                          {this.getCompanyOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'usertype',
                  Cell: row => (
                    <div>
                      <a
                        onClick={() =>
                          this.deleteSection(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.sections.length <= this.state.pSize8 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.sections.length < this.state.pSize8
                  ? this.state.sections.length
                  : this.state.pSize8
              }
              page={this.state.pNum8}
              pageSizeOptions={[defaultPageSize, extendedPageSize]}
            />
            <div className="pr">
              {filtered.length ? (
                <div className="pa paright">
                  Filtrirano št. oddelkov: <strong>{this.getSectionsByFilter()}</strong>
                </div>
              ) : null}
              <div>
                Skupno št. oddelkov: <strong>{this.state.sections.length}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega oddelka</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationSection:
                      !this.state.showRegistrationSection,
                  })
                }
              >
                {this.state.showRegistrationSection ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationSection ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Section', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Section}
                  />
                </div>

                <div className="clearfix">
                  <div title="Vodja oddelka" className="tar beforeinput fl">
                    Vodja:
                  </div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.SectionLeaderuuid}
                    onChange={e =>
                      this.edit('SectionLeaderuuid', e.target.value)
                    }
                  >
                    {this.getUserOptions(undefined, 'Izberi vodjo oddelka')}
                  </select>
                </div>

                <div className="clearfix">
                  <div className="tar beforeinput fl">Podjetje:</div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.SectionCompanyuuid}
                    onChange={e =>
                      this.edit('SectionCompanyuuid', e.target.value)
                    }
                  >
                    {this.getCompanyOptions()}
                  </select>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewSectionDisabled()}
                    onClick={() => this.newSection()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi nov oddelek
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
/*
  public getCustomers(): JSX.Element {
    return (
      <div>
        {this.state.customers ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.customers}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize3) {
                  this.setState({ pSize3: pageSize, pNum3: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum3: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv stranke',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'customers',
                              'customer',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className="input input-table"
                          value={row.value}
                          maxLength={stringLongLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Naslov stranke',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'address',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'customers',
                              'customer',
                              'address',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className="input input-table"
                          value={row.value}
                          maxLength={stringLongLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Točka sedeža stranke',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'pointuuid',
                  Cell: row => (
                    <div>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getPointName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'customers',
                              'customer',
                              'pointuuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className="select select-table"
                        >
                          {this.getPointOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div>
                      <a
                        onClick={() =>
                          this.deleteCustomer(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.customers.length <= this.state.pSize3 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.customers.length < this.state.pSize3
                  ? this.state.customers.length
                  : this.state.pSize3
              }
              page={this.state.pNum3}
              pageSizeOptions={[defaultPageSize]}
            />
            <div>
              Skupno št. strank: <strong>{this.state.customers.length}</strong>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija nove stranke</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationCustomer:
                      !this.state.showRegistrationCustomer,
                  })
                }
              >
                {this.state.showRegistrationCustomer ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}

            {this.state.showRegistrationCustomer ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringLongLength}
                    onChange={e => this.edit('Customer', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Customer}
                  />
                </div>

                <div className="clearfix">
                  <div className="tar beforeinput fl">Naslov:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringLongLength}
                    onChange={e => this.edit('CustomerAddress', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.CustomerAddress}
                  />
                </div>

                <div className="clearfix">
                  <div className="tar beforeinput fl">Točka:</div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.CustomerPointuuid}
                    onChange={e =>
                      this.edit('CustomerPointuuid', e.target.value)
                    }
                  >
                    {this.getPointOptions()}
                  </select>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewCustomerDisabled()}
                    onClick={() => this.newCustomer()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi novo stranko
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
*/

  // public doesValueExistBackground(value: any): string {return value ? 'bg-blue' : ''}
  public getExposedPoints(): JSX.Element {
    return (
      <div>
        {this.state.pointExposed ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.pointExposed}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize14) {
                  this.setState({ pSize14: pageSize, pNum14: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum14: pageIndex });
              }}
              columns={[
/*
                {
                  sortable: false,
                  Header: '',
                  width: 75,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'uuid',
                  Cell: row => (
                    <div className="pr">
                      &nbsp;
                      <img
                        className="flag flag-centered pa"
                        src={this.getFlagName(row.value)}
                      />
                    </div>
                  ),
                },
*/
                {
                  sortable: false,
                  Header: 'Ime točke',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'uuid',
                  Cell: row =>
                    this.props.userData.usertype === UserType.Admin ? (
                      <div>{row.original.name}</div>
                    ) : (
                      <select
                        onChange={e =>
                          this.updateValue(
                            'pointExposed',
                            'pointexposed',
                            'uuid',
                            row.index,
                            row.original.uuid,
                            e.target.value,
                          )
                        }
                        className="select select-table mt2"
                        value={row.value}
                      >
                        {this.getPointOptions(true)}
                      </select>
                    ),
                },
                {
                  sortable: false,
                  Header: 'Vrstni red',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  width: 90,
                  accessor: 'ordinal',
                  Cell: row =>
                    this.props.userData.usertype === UserType.Admin ? (
                      <div>{row.value}.</div>
                    ) : (
                      <select
                        onChange={e =>
                          this.updateValue(
                            'pointExposed',
                            'pointexposed',
                            'ordinal',
                            row.index,
                            row.original.uuid,
                            e.target.value,
                          )
                        }
                        className="select select-table mt2"
                        value={row.value}
                      >
                        {this.getOrdinalOptions(this.state.pointExposed.length)}
                      </select>
                    ),
                },
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div>
                      {this.state.pointExposed.length === row.original.ordinal ? (
                        <a
                          onClick={() =>
                            this.deleteExposedPoint(
                              row.original.name,
                              row.original.uuid,
                              row.index,
                            )
                          }
                          className="delete"
                        >
                          ✗
                        </a>
                      ) : (
                        <span
                          title="Brisanje iz spiska izpostavljenih točk je možno samo za zadnjo točko"
                          className="no-delete"
                        >
                          ✗
                        </span>
                      )}
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.pointExposed.length <= this.state.pSize14 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.pointExposed.length < this.state.pSize14
                  ? this.state.pointExposed.length
                  : this.state.pSize14
              }
              page={this.state.pNum14}
              pageSizeOptions={[defaultPageSize]}
            />
            <div>
              Skupno št. izpostavljenih točk: <strong>{this.state.pointExposed.length}</strong>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">
                Registracija nove izpostavljene točke
              </span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationExposedPoint:
                      !this.state.showRegistrationExposedPoint,
                  })
                }
              >
                {this.state.showRegistrationExposedPoint ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationExposedPoint ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Točka:</div>
                  <select
                    className="ml fl dbl select select-input mt3"
                    value={this.state.ExposedPoint}
                    onChange={e => this.setState({ ExposedPoint: e.target.value })}
                  >
                    {this.getPointOptions(undefined, undefined, undefined, true)}
                  </select>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewExposedPointDisabled()}
                    onClick={() => this.newExposedPoint()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi novo izpostavljeno točko
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getPoints(): JSX.Element {
    return <div className = "clearfix">
      <div className = "fl two-thirds-narrow">
        {this.getPointsTable()}
      </div>
      <div className = "fl third-narrow" style={{marginTop: '10px'}}>
        <h4>Izpostavljene točke</h4>
        {this.getExposedPoints()}
      </div>
    </div>
  }

  public getPointsTable(): JSX.Element {
    const filtered: Filter[] = [];
    if (this.state.fPointType !== PointType.None) {
      filtered.push({ id: 'useruuid', value: this.state.fPointType.toString() });
    }
    // console.log(filtered)
    // console.log(points)
    return (
      <div>
        {this.state.points ? (
          <>
            <div className = "clearfix" style={{marginTop: '38px'}}>
              <div className="fl bold">Filter:</div>
              <select
                value={this.state.fPointType}
                onChange={e =>
                  this.setState({ pNum2: 0, fPointType: +e.target.value })
                }
                className="dbl fl ml select select-input-no-width"
                style={{marginTop: '3px'}}
              >
                {this.getPointTypeOptions(undefined, true)}
              </select>
            </div>
            <ReactTable
              {...reactTableTranslation}
              filtered={filtered}
              defaultFilterMethod={(filter, row) => filter.id === 'useruuid' ?
                filter.value === PointType.Private.toString() ? row._original.useruuid !== null :
                filter.value === PointType.Public.toString() ? row._original.useruuid === null :
                true :
              true}
              className="rt-no-white-space mt20"
              data={this.state.points}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize2) {
                  this.setState({ pSize2: pageSize, pNum2: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum2: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv točke',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'points',
                              'point',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringLongLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Točka pripada',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'useruuid',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getUserName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'points',
                              'point',
                              'useruuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value ? row.value : ''}
                          className={clsx('select select-table', this.getActiveBackground(row.original.active))}
                        >
                          {this.getUserOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Naslov',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'address',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'points',
                              'point',
                              'address',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value ? row.value : ''}
                          maxLength={stringLongLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Kratko',
                  width: 100,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'shortname',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'points',
                              'point',
                              'shortname',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value ? row.value : ''}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Status',
                  width: 78,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <button
                        onClick={() =>
                          this.updateValue(
                            'points',
                            'point',
                            'active',
                            row.index,
                            row.original.uuid,
                            !row.value,
                          )
                        }
                        title={
                          row.value ? 'Aktivna točka' : 'Neaktivna točka'
                        }
                        className={clsx(
                          'mt2 button button-thin',
                          row.value ? 'button-green' : 'button-red',
                        )}
                      >
                        {row.value ? 'Aktivna' : 'Neakt.'}
                      </button>
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <a
                        onClick={() =>
                          this.deletePoint(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.points.length <= this.state.pSize2 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.points.length < this.state.pSize2
                  ? this.state.points.length
                  : this.state.pSize2
              }
              page={this.state.pNum2}
              pageSizeOptions={[defaultPageSize]}
            />
            <div className = "pr">
              Skupno št. točk: <strong>{this.state.points.length}</strong>
              <div className="pa paright0">
                od tega aktivnih: <strong>{this.getActive(this.state.points)}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija nove točke</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationPoint: !this.state.showRegistrationPoint,
                  })
                }
              >
                {this.state.showRegistrationPoint ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}

            {this.state.showRegistrationPoint ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringLongLength}
                    onChange={e => this.edit('Point', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Point}
                  />
                </div>
                <div className="clearfix">
                  <div title = "Točka pripada" className="tar beforeinput fl">Pripada:</div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.PointUseruuid}
                    onChange={e =>
                      this.edit('PointUseruuid', e.target.value)
                    }
                  >
                    {this.getUserOptions()}
                  </select>
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Naslov:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringLongLength}
                    onChange={e => this.edit('PointAddress', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.PointAddress}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Kratko:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringShortLength}
                    onChange={e => this.edit('PointShortName', e.target.value)}
                    className="input input-narrow dbl fl"
                    type="text"
                    value={this.state.PointShortName}
                  />
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewPointDisabled()}
                    onClick={() => this.newPoint()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi novo točko
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public async refreshReservedVehicles(type: 'departure' | 'arrival', value: Date) {
    const departure: Date = type === 'departure' ? value : this.state.fdeparture;
    const arrival: Date = type === 'arrival' ? value : this.state.farrival;

    if (type === 'departure') {
      departure.setHours(12)
    } else if (type === 'arrival') {
      arrival.setHours(12)
    }

    const params: VehicleOccupancyParams = {
      token: this.props.userData.token,
      departure: this.toLocalString(departure),
      arrival: this.toLocalString(arrival),
    };
    const response = await axios.get(reservation, { params });
    if (response && response.data && response.data.resolved) {
      if (type === 'departure') {
        this.setState({
          fdeparture: departure,
          reservationsDay: response.data.data,
        });
      } else if (type === 'arrival') {
        this.setState({ farrival: arrival, reservationsDay: response.data.data });
      }
    }
  }

  public vehicleIsFree(uuid: string): boolean {
    for (const item of this.state.reservationsDay) {
      if (item.vehicleuuid === uuid) {
        return false
      }
    }
    return true
  }

  public getFreeVehicles(): JSX.Element {
    return <div className="free-vehicles">
      {this.state.vehicles && this.state.reservationsDay ?
        this.state.vehicles.map((vehicle) => vehicle.type === VehicleType.Company && vehicle.active && this.vehicleIsFree(vehicle.uuid) ?
        <div key={vehicle.uuid} className="free-vehicle">
          {this.getVehicle(this, vehicle.uuid)} <span>{this.getVehiclePlate(this, vehicle.uuid)}</span>
        </div> : null) : null}
    </div>
  }

  public getReservedVehicles(): JSX.Element {
    return <div className="pr">
      <ReactTable
        {...reactTableTranslation}
        rowsSelectorText="vrstic na stran"
        className="rt-no-white-space mt20"
        data={this.state.reservationsDay}
        onPageSizeChange={(pageSize, pageIndex) => {
          if (pageSize !== this.state.pSize15) {
            this.setState({ pSize15: pageSize, pNum15: pageIndex });
          }
        }}
        onPageChange={pageIndex => {
          this.setState({ pNum15: pageIndex });
        }}
        columns={[
          {
            sortable: false,
            Header: 'Uporabnik',
            className: 'rt-smallrow tal',
            headerClassName: 'tal',
            accessor: 'useruuid',
            Cell: row => (
              <div>
                {this.getUserName(row.value)}
              </div>
            ),
          },
          {
            sortable: false,
            Header: 'Vozilo',
            className: 'rt-smallrow tal',
            headerClassName: 'tal',
            accessor: 'vehicleuuid',
            Cell: row => (
              <div>
                {this.getVehicle(this, row.value)}
              </div>
            ),
          },
          {
            width: 120,
            sortable: false,
            Header: 'Tablica',
            className: 'rt-smallrow tal',
            headerClassName: 'tal',
            accessor: 'vehicleuuid',
            Cell: row => (
              <div className="license-plate-table">
                {this.getVehiclePlate(this, row.value)}
              </div>
            ),
          },
          {
            sortable: false,
            width: 140,
            Header: 'Začetek poti',
            className: 'rt-smallrow tal',
            headerClassName: 'tal',
            accessor: 'departure',
            Cell: row => (
              <div>
                {row.value}
              </div>
            ),
          },

          {
            sortable: false,
            width: 140,
            Header: 'Konec poti',
            className: 'rt-smallrow tal',
            headerClassName: 'tal',
            accessor: 'arrival',
            Cell: row => (
              <div>
                {row.value}
              </div>
            ),
          },
          {
            sortable: false,
            Header: 'Pot',
            className: 'rt-smallrow tal',
            headerClassName: 'tal',
            accessor: 'path',
            Cell: row => (
              <div>
                {row.value}
              </div>
            ),
          },
        ]}
        showPaginationTop={
          this.state.reservationsDay.length <= this.state.pSize15 ? false : true
        }
        showPaginationBottom={false}
        pageSize={
          this.state.reservationsDay.length < this.state.pSize15
            ? this.state.reservationsDay.length
            : this.state.pSize15
        }
        page={this.state.pNum15}
        pageSizeOptions={[defaultPageSize]}
      />
    </div>
  }

  public getVehicles(): JSX.Element {
    return <div className = "clearfix">
      <div className = "fl half-narrow">
        {this.getVehiclesTable()}
      </div>
      <div className = "fl half-narrow pr" style={{marginTop: '10px'}}>
        <h4>Prosta službena vozila</h4>
        <div style={{position: 'absolute', top: '25px', right: '0px', width: '400px'}}>
          <div className="fr">
            <DatePicker
              calendarStartDay={1}
              onKeyDown={this.handleDateChangeRaw}
              placeholderText={placeholderDatePicker}
              dateFormat={dateFormatSlash}
              locale={locale}
              selected={this.state.farrival}
              excludeDateIntervals={this.getExcludeDateIntervals(
                'before',
                this.state.fdeparture,
              )}
              onChange={farrival =>
                this.refreshReservedVehicles('arrival', farrival)
              }
            />
          </div>
          <div style = {{marginLeft: '15px'}} className="fr">do:</div>
          <div className="fr">
            <DatePicker
              calendarStartDay={1}
              onKeyDown={this.handleDateChangeRaw}
              placeholderText={placeholderDatePicker}
              dateFormat={dateFormatSlash}
              locale={locale}
              selected={this.state.fdeparture}
              excludeDateIntervals={this.getExcludeDateIntervals(
                'after',
                this.state.farrival,
              )}
              onChange={fdeparture =>
                this.refreshReservedVehicles('departure', fdeparture)
              }
            />
          </div>
          <div className="fr">Od: </div>
        </div>
        {this.getFreeVehicles()}
        {this.state.reservationsDay && this.state.reservationsDay.length ? <>
        <h4>Zasedena službena vozila</h4>
        {this.getReservedVehicles()}
        </> : null}
      </div>
    </div>
  }

  public getVehiclesTable(): JSX.Element {
    const filtered: Filter[] = [];
    if (this.state.fVehicleType !== VehicleType.None) {
      filtered.push({ id: 'type', value: this.state.fVehicleType.toString() });
    }
    return (
      <div>
        <div className = "clearfix" style={{marginTop: '38px'}}>
          <div className="fl bold">Filter:</div>
          <select
            value={this.state.fVehicleType}
            onChange={e =>
              this.setState({ pNum5: 0, fVehicleType: +e.target.value })
            }
            className="dbl fl ml select select-input-no-width"
            style={{marginTop: '3px'}}
          >
            {this.getVehicleTypeOptions(undefined, true)}
          </select>
        </div>
        {this.state.vehicles ? (
          <>
            <ReactTable
              filtered={filtered}
              {...reactTableTranslation}
              rowsSelectorText="vrstic na stran"
              className="rt-no-white-space mt20"
              data={this.state.vehicles}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize5) {
                  this.setState({ pSize5: pageSize, pNum5: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum5: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv vozila',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'vehicles',
                              'vehicle',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Tip vozila',
                  width: 150,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'type',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        this.getVehicleType(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'vehicles',
                              'vehicle',
                              'type',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className={clsx('select select-table', this.getActiveBackground(row.original.active))}
                        >
                          {this.getVehicleTypeOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Tablica',
                  width: 150,
                  className: 'rt-smallrow tal license-plate',
                  headerClassName: 'tal',
                  accessor: 'plate',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {row.original.type === VehicleType.Companion ? (
                        '-'
                      ) : this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'vehicles',
                              'vehicle',
                              'plate',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Status',
                  width: 100,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <button
                        disabled={row.original.type === VehicleType.Companion}
                        onClick={() =>
                          this.updateValue(
                            'vehicles',
                            'vehicle',
                            'active',
                            row.index,
                            row.original.uuid,
                            !row.value,
                          )
                        }
                        title={
                          row.value ? 'Aktivno vozilo' : 'Neaktivno vozilo'
                        }
                        className={clsx(
                          'mt2 button button-thin',
                          row.value ? 'button-green' : 'button-red',
                        )}
                      >
                        {row.value ? 'Aktivno' : 'Neaktivno'}
                      </button>
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Lastnik zasebnega vozila',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'useruuid',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {row.original.type === VehicleType.Companion ||
                      row.original.type === VehicleType.Company ? (
                        '-'
                      ) : this.props.userData.usertype === UserType.Admin ? (
                        this.getUserName(row.value)
                      ) : (
                        <select
                          onChange={e =>
                            this.updateValue(
                              'vehicles',
                              'vehicle',
                              'useruuid',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          value={row.value}
                          className={clsx('select select-table', this.getActiveBackground(row.original.active))}
                        >
                          {this.getUserOptions(true)}
                        </select>
                      )}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {row.original.type === VehicleType.Companion ?
                        <span
                        title="Brisanje sopotnika iz spiska vozil ni dovoljeno"
                        className="no-delete"
                      >
                        ✗
                      </span>
                      :
                      <a
                        onClick={() =>
                          this.deleteVehicle(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>}
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.vehicles.length <= this.state.pSize5 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.vehicles.length < this.state.pSize5
                  ? this.state.vehicles.length
                  : this.state.pSize5
              }
              page={this.state.pNum5}
              pageSizeOptions={[defaultPageSize]}
            />
            <div className="pr">
              Skupno št. vozil: <strong>{this.state.vehicles.length}</strong>
              <div className="pa paright0">
                od tega aktivnih: <strong>{this.getActive(this.state.vehicles)}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega vozila</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationVehicle:
                      !this.state.showRegistrationVehicle,
                  })
                }
              >
                {this.state.showRegistrationVehicle ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationVehicle ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Vehicle', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Vehicle}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Tip voz.:</div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.VehicleType}
                    onChange={e => this.edit('VehicleType', e.target.value)}
                  >
                    {this.getVehicleTypeOptions()}
                  </select>
                </div>
                {this.state.VehicleType === VehicleType.Company ||
                this.state.VehicleType === VehicleType.Private ? (
                  <div className="clearfix">
                    <div className="tar beforeinput fl">Tablica:</div>
                    <input
                      autoComplete="new-password"
                      maxLength={stringShortLength}
                      onChange={e => this.edit('VehiclePlate', e.target.value)}
                      className="input dbl fl"
                      type="text"
                      value={this.state.VehiclePlate}
                    />
                  </div>
                ) : null}

                {this.state.VehicleType === VehicleType.Private ? (
                  <div className="clearfix">
                    <div className="tar beforeinput fl">Lastnik:</div>
                    <select
                      className="ml select select-input dbl fl mt3"
                      value={this.state.VehicleUseruuid}
                      onChange={e =>
                        this.edit('VehicleUseruuid', e.target.value)
                      }
                    >
                      {this.getUserOptions(undefined, 'Izberi lastnika vozila')}
                    </select>
                  </div>
                ) : null}

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewVehicleDisabled()}
                    onClick={() => this.newVehicle()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi novo vozilo
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getFlagName(code: string): string {
    if (flags) {
      for (const flag of flags) {
        if (flag.code === code) {
          return flag.flag_image_url;
        }
      }
    }
    return null;
  }

  public getCountry(Code: string): string {
    if (countries) {
      for (const country of countries) {
        if (country.Code === Code) {
          return country.Name;
        }
      }
    }
    return '?';
  }

  public isSelectedCountry(code: string): boolean {
    if (this.state.countries) {
      for (const item of this.state.countries) {
        if (item.uuid === code) {
          return true;
        }
      }
    }
    return false;
  }

  public getOrdinalOptions(max: number): JSX.Element[] {
    const oo: JSX.Element[] = [];
    for (let i = 1; i <= max; i++) {
      oo.push(
        <option key={i} value={i}>
          {i}.
        </option>,
      );
    }
    return oo;
  }

  public getCountriesOptions(
    table?: boolean,
    excludeSpecialCountries?: boolean,
    filter?: boolean
  ) {
    const c: JSX.Element[] = [];
    c.push(
      <option value="" key="">
        {filter ? 'Država?' : table ? '-' : 'Izberi državo'}
      </option>,
    );
    if (!excludeSpecialCountries && this.state.countries) {
      for (const country of this.state.countries) {
        c.push(
          <option value={country.uuid} key={country.uuid + '_'}>
            {country.name}
          </option>,
        );
      }
      c.push(
        <option value="-" key="-" disabled={true}>
          ─────────────────────────
        </option>,
      );
    }
    if (countries) {
      for (const country of countries) {
        // if (this.state.continentEurope && country.Continent !== 'EU') {continue}
        c.push(
          <option
            disabled={
              excludeSpecialCountries
                ? this.isSelectedCountry(country.Code)
                : false
            }
            value={country.Code}
            key={country.Code}
          >
            {country.Name}
          </option>,
        );
      }
    }
    return c;
  }

  public getSubsistence(): JSX.Element {
    return <div className = "clearfix">
      <div className = "fl two-thirds-narrow">
        {this.getSubsistenceTable()}
      </div>
      <div className = "fl third-narrow">
        <h4>Izpostavljene države</h4>
        {this.getExposedCountries()}
      </div>
    </div>
  }

  public getSubsistenceTable(): JSX.Element {
    const filtered: Filter[] = [];
    if (this.state.fCountry) {
      filtered.push({ id: 'country', value: this.state.fCountry });
    }
    return <>
      <div style={{marginTop: '28px'}} className = "clearfix">
        <div className="fl bold">Filter:</div>
          <select
            value={this.state.fCountry}
            onChange={e =>
              this.setState({ pNum6: 0, fCountry: e.target.value })
            }
            className="dbl fl ml select select-input-no-width"
            style={{marginTop: '3px'}}
          >
            {this.getCountriesOptions(undefined, undefined, true)}
          </select>
      </div>
      <div>
        {this.state.subsistence ? (
          <>
            <ReactTable
            {...reactTableTranslation}
              filtered={filtered}
              className="rt-no-white-space mt"
              data={this.state.subsistence}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize6) {
                  this.setState({ pSize6: pageSize, pNum6: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum6: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv tipa dnevnice',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'subsistence',
                              'subsistence',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className = {'input input-table ' + this.getActiveBackground(row.original.active)}
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: '',
                  width: 75,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'country',
                  Cell: row => (
                    <div className = {'pr ' + this.getActiveBackground(row.original.active)}>
                      &nbsp;
                      <img
                        className="flag flag-centered pa"
                        src={this.getFlagName(row.value)}
                      />
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Država',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'country',
                  Cell: row =>
                    this.props.userData.usertype === UserType.Admin ? (
                      <div className = {this.getActiveBackground(row.original.active)}>
                        {this.getCountry(row.value)}
                      </div>
                    ) : (
                      <select
                        onChange={e =>
                          this.updateValue(
                            'subsistence',
                            'subsistence',
                            'country',
                            row.index,
                            row.original.uuid,
                            e.target.value,
                          )
                        }
                        className = {'select select-table mt2 ' + this.getActiveBackground(row.original.active)}
                        value={row.value}
                      >
                        {this.getCountriesOptions(true)}
                      </select>
                    ),
                },

                {
                  sortable: false,
                  Header: 'Znesek (€)',
                  width: 100,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'value',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            e.target.value.length
                              ? this.updateValue(
                                  'subsistence',
                                  'subsistence',
                                  'value',
                                  row.index,
                                  row.original.uuid,
                                  e.target.value,
                                )
                              : {}
                          }
                          type="text"
                          className = {'input input-table ' + this.getActiveBackground(row.original.active)}
                          value={row.value}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Zajtrk (%)',
                  width: 100,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'breakfast',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            e.target.value.length
                              ? this.updateValue(
                                  'subsistence',
                                  'subsistence',
                                  'breakfast',
                                  row.index,
                                  row.original.uuid,
                                  e.target.value,
                                )
                              : {}
                          }
                          type="text"
                          className = {'input input-table ' + this.getActiveBackground(row.original.active)}
                          value={row.value}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Kosilo (%)',
                  width: 100,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'lunch',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            e.target.value.length
                              ? this.updateValue(
                                  'subsistence',
                                  'subsistence',
                                  'lunch',
                                  row.index,
                                  row.original.uuid,
                                  e.target.value,
                                )
                              : {}
                          }
                          type="text"
                          className = {'input input-table ' + this.getActiveBackground(row.original.active)}
                          value={row.value}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Večerja (%)',
                  width: 100,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'dinner',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            e.target.value.length
                              ? this.updateValue(
                                  'subsistence',
                                  'subsistence',
                                  'dinner',
                                  row.index,
                                  row.original.uuid,
                                  e.target.value,
                                )
                              : {}
                          }
                          type="text"
                          className = {'input input-table ' + this.getActiveBackground(row.original.active)}
                          value={row.value}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Status',
                  width: 75,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <button
                        onClick={() =>
                          this.updateValue(
                            'subsistence',
                            'subsistence',
                            'active',
                            row.index,
                            row.original.uuid,
                            !row.value,
                          )
                        }
                        title={
                          row.value ? 'Aktivna dnevnica' : 'Neaktivna dnevnica'
                        }
                        className={clsx(
                          'mt2 button button-thin',
                          row.value ? 'button-green' : 'button-red',
                        )}
                      >
                        {row.value ? 'Aktivna' : 'Neakt.'}
                      </button>
                    </div>
                  ),
                },
                /*
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div>
                      <a
                        onClick={() =>
                          this.deleteSubsistence(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
                */
              ]}
              showPaginationTop={
                this.state.subsistence.length <= this.state.pSize6
                  ? false
                  : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.subsistence.length < this.state.pSize6
                  ? this.state.subsistence.length
                  : this.state.pSize6
              }
              page={this.state.pNum6}
              pageSizeOptions={[defaultPageSize]}
            />
            <div className="pr">
              Skupno št. tipov dnevnic: <strong>{this.state.subsistence.length}</strong>
              <div className="pa paright0">
                od tega aktivnih: <strong>{this.getActive(this.state.subsistence)}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega tipa dnevnice</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationSubsistence:
                      !this.state.showRegistrationSubsistence,
                  })
                }
              >
                {this.state.showRegistrationSubsistence ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationSubsistence ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Subsistence', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Subsistence}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Država:</div>
                  <select
                    className="select select-input dbl fl ml mt3"
                    value={this.state.SubsistenceCountry}
                    onChange={e =>
                      this.edit('SubsistenceCountry', e.target.value)
                    }
                  >
                    {this.getCountriesOptions()}
                  </select>
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Znesek:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringShortLength}
                    onChange={e =>
                      this.edit('SubsistenceValue', e.target.value)
                    }
                    className="input dbl fl input-narrow"
                    type="text"
                    value={this.state.SubsistenceValue}
                  />
                  <div className="ml fl">€</div>
                </div>

                <div className="clearfix">
                  <div
                    className="tar beforeinput fl"
                    title="Odstotek odbitka za zajtrk"
                  >
                    Zajtrk:
                  </div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringShortLength}
                    onChange={e =>
                      this.edit('SubsistenceBreakfast', e.target.value)
                    }
                    className="input dbl fl input-narrow"
                    type="text"
                    value={this.state.SubsistenceBreakfast}
                  />
                  <div className="ml fl">%</div>
                </div>

                <div className="clearfix">
                  <div
                    className="tar beforeinput fl"
                    title="Odstotek odbitka za kosilo"
                  >
                    Kosilo:
                  </div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringShortLength}
                    onChange={e =>
                      this.edit('SubsistenceLunch', e.target.value)
                    }
                    className="input dbl fl input-narrow"
                    type="text"
                    value={this.state.SubsistenceLunch}
                  />
                  <div className="ml fl">%</div>
                </div>

                <div className="clearfix">
                  <div
                    className="tar beforeinput fl"
                    title="Odstotek odbitka za večerjo"
                  >
                    Večerja:
                  </div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringShortLength}
                    onChange={e =>
                      this.edit('SubsistenceDinner', e.target.value)
                    }
                    className="input dbl fl input-narrow "
                    type="text"
                    value={this.state.SubsistenceDinner}
                  />
                  <div className="ml fl">%</div>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewSubsistenceDisabled()}
                    onClick={() => this.newSubsistence()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi nov tip dnevnice
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
      </>
  }

  public getSettings(): JSX.Element {
    return <div className = "clearfix">
      <div className = "fl third-narrow">
        <h4>Projekti</h4>
        {this.getProjects()}
      </div>
      <div className = "fl third-narrow">
        <h4>Tipi kilometrine</h4>
        {this.getMileage()}
      </div>
      <div className = "fl third-narrow">
        <h4>Tipi dodatnih stroškov</h4>
        {this.getExpense()}
      </div>
    </div>
  }

  public getStructure(): JSX.Element {
    return <div className = "clearfix">
      <div className = "fl half-narrow">
        <h4>Podjetja</h4>
        {this.getCompanies()}
      </div>
      <div className = "fl half-narrow pr">
        <h4>Oddelki</h4>
        <div style={{position: 'absolute', top: '25px', right: '0px'}} className = "clearfix">
          <div className="fl bold">Filter:</div>
          <select
            value={this.state.fCompany}
            onChange={e =>
              this.setState({ pNum8: 0, fCompany: e.target.value })
            }
            className="ml dbl fl select select-input-no-width"
          >
            {this.getCompanyOptions()}
          </select>
        </div>
        {this.getSections()}
      </div>
    </div>
  }

  public getProjectPoint(): JSX.Element {
    return <div className = "clearfix">
      <div className = "fl third-narrow">
        <h4>Projekti</h4>
        {this.getProjects()}
      </div>

      <div className = "fl two-thirds-narrow">
        <h4>Točke</h4>
        {this.getPoints()}
      </div>
    </div>
  }

  public getDashboard(): JSX.Element {
    return (
      <div className="dashboard">
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={tabIndex => this.setState({ tabIndex })}
        >
          {this.getTablist()}
          {this.getTabpanel()}
        </Tabs>
      </div>
    );
  }

  public setNewDocumentNavigation(that, ndn: NDN) {
    that.setState({ ndn });
  }

  public setNewReservationNavigation(that, nrn: NRN) {
    that.setState({ nrn });
  }

  public getNewDocumentNavigation(that): JSX.Element {
    return (
      <>
        <button
          onClick={() =>
            that.setNewDocumentNavigation(that, NDN.BasicInformation)
          }
          className={clsx(
            'dbl fl button button-thin button-orange',
            that.state.ndn === NDN.BasicInformation
              ? 'button-orange-active'
              : '',
          )}
        >
          Osnovne informacije
        </button>
        <button
          onClick={() =>
            that.setNewDocumentNavigation(that, NDN.AccountingInformation)
          }
          className={clsx(
            'dbl fl button button-thin button-orange ml',
            that.state.ndn === NDN.AccountingInformation
              ? 'button-orange-active'
              : '',
          )}
        >
          Obračunske informacije
        </button>
        <button
          onClick={() => that.setNewDocumentNavigation(that, NDN.Attachments)}
          className={clsx(
            'dbl fl button button-thin button-orange ml',
            that.state.ndn === NDN.Attachments ? 'button-orange-active' : '',
          )}
        >
          Priloge
        </button>
      </>
    );
  }

  public getNewReservationNavigation(that): JSX.Element {
    return (
      <>
        <button
          onClick={() =>
            this.setNewReservationNavigation(that, NRN.BasicInformation)
          }
          className={clsx(
            'dbl fl button button-thin button-orange',
            that.state.nrn === NRN.BasicInformation
              ? 'button-orange-active'
              : '',
          )}
        >
          Osnovne informacije
        </button>
        <button
          onClick={() =>
            this.setNewReservationNavigation(that, NRN.Notification)
          }
          className={clsx(
            'dbl fl button button-thin button-orange ml',
            that.state.nrn === NRN.Notification ? 'button-orange-active' : '',
          )}
        >
          Obveščanje nadrejenega
        </button>
        <button
          onClick={() =>
            this.setNewReservationNavigation(that, NRN.PathInformation)
          }
          className={clsx(
            'dbl fl button button-thin button-orange ml',
            that.state.nrn === NRN.PathInformation
              ? 'button-orange-active'
              : '',
          )}
        >
          Podatki o poti
        </button>
      </>
    );
  }

  public setNewDocumentAttachment(that, value: string, property: string) {
    const document: DocumentData = Object.assign({}, that.state.document);
    document.attachment[property] = value;
    that.setState({ document });
  }

  public getHoursMinutes(seconds: number): string {
    const hours: number = Math.floor(seconds / 3600);
    seconds -= 3600 * hours;
    const minutes: number = Math.round(seconds / 60);
    return hours ? hours + 'h, ' + minutes + 'min' : minutes + 'min';
  }

  public async getMileageGoogle(that, pointuuids: string[]): Promise<string> {
    if (!this.calculateMileageGoogle) {
      return '';
    }
    if (that.state.points) {
      const addresses: string[] = [];
      let fullPath = true;
      for (const item of pointuuids) {
        if (!item || item.length === 0) {
          fullPath = false;
          break;
        }
        for (const Item of that.state.points) {
          if (Item.uuid === item) {
            addresses.push(Item.address);
            break;
          }
        }
      }
      if (fullPath && addresses.length === pointuuids.length) {
        const response = await axios.post(googlepath, {
          addresses,
          token: this.props.userData.token,
        });
        if (
          response &&
          response.data &&
          response.data.resolved &&
          response.data.data &&
          response.data.data.twoWay !== undefined &&
          response.data.data.pathLengthMeters !== undefined &&
          response.data.data.pathDurationSeconds !== undefined
        ) {
          if (response.data.data.twoWay) {
            response.data.data.pathLengthMeters *= 2;
          }
          return (
            Math.round(response.data.data.pathLengthMeters / 100) / 10 +
            ' (' +
            that.getHoursMinutes(response.data.data.pathDurationSeconds) +
            (response.data.data.twoWay ? ' v eno smer' : '') +
            ')'
          );
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public getVehicleSpecific(that, vehicleType: VehicleType): string {
    if (that.state.vehicles) {
      for (const item of that.state.vehicles) {
        if (item.type === vehicleType) {
          return item.uuid;
        }
      }
    }
    return '';
  }

  public async setNewDocument(
    that,
    field: string,
    value,
    index?: number,
    property?: string,
    focus?: string
  ) {
    let proceed = true;
    if (
      field === 'mileage' ||
      field === 'departuremileage' ||
      field === 'arrivalmileage' ||
      field === 'advance' ||
      field === 'departureHour' ||
      field === 'arrivalHour' ||
      field === 'departureMinute' ||
      field === 'arrivalMinute' ||
      (field === 'expenses' && property === 'value') ||
      field === 'departureHourPlanned' ||
      field === 'arrivalHourPlanned' ||
      field === 'departureMinutePlanned' ||
      field === 'arrivalMinutePlanned'
    ) {
      if (field === 'advance' || field === 'expenses') {
        if (isNaN(value.replace(',', '.'))) {
          proceed = false;
        }
      } else {
        if (isNaN(value)) {
          proceed = false;
        }
      }
      let dot: number = value.indexOf(',');
      if (dot === -1) {
        dot = value.indexOf('.');
      }
      if (
        proceed &&
        field !== 'advance' &&
        field !== 'expenses' &&
        dot !== -1
      ) {
        proceed = false;
      }
      if (
        proceed &&
        (field === 'advance' || field === 'expenses') &&
        dot !== -1
      ) {
        if (value.indexOf(',', dot + 1) !== -1 && value.indexOf('.', dot + 1) !== -1) {
          proceed = false;
        } else if (dot < value.length - 3) {
          proceed = false;
        }
      }
      if (proceed && value.indexOf('-') !== -1) {
        proceed = false;
      }
    }
    if (
      field === 'departureHour' ||
      field === 'arrivalHour' ||
      field === 'departureHourPlanned' ||
      field === 'arrivalHourPlanned'
    ) {
      if (+value >= 24) {
        proceed = false;
      }
    }
    if (
      field === 'departureMinute' ||
      field === 'arrivalMinute' ||
      field === 'departureMinutePlanned' ||
      field === 'arrivalMinutePlanned'
    ) {
      if (+value >= 60) {
        proceed = false;
      }
    }

    if (proceed) {
      const doc: DocumentData = Object.assign({}, that.state.document);

      if (
        (field === 'customeruuid') &&
        doc &&
        doc.pointuuids &&
        doc.pointuuids.length === 3
      ) {
        doc.pointuuids[1] = that.getPointuuid(value, that.state.customers);
        doc.Pointuuids[1] = that.getPointname(that, doc.pointuuids[1])
      }

      if (field === 'subsistence' && index !== undefined) {
        if (property === 'uuid') {
          doc.subsistence[index][property] = value;
        } else {
          doc.subsistence[index][property] = !doc.subsistence[index][property];
        }
      }

      if (
        field === 'customeruuid' ||
        field === 'Pointuuids' && index !== undefined
      ) {
        let path = '';
        let canPathBeCalculated = true;
        if (field === 'Pointuuids') {
          doc.Pointuuids[index] = value
          doc.pointuuids[index] = that.getPointUuid(that, value)
        }
        for (const item of doc.pointuuids) {
          if (item && item.length) {
            if (path.length) {
              path += ', ';
            }
            path += that.getPointShortName(that, item);
          } else {
            canPathBeCalculated = false;
          }
        }
        if (canPathBeCalculated) {
          doc.path = path;
          doc.mileageGoogle = await that.getMileageGoogle(that, doc.pointuuids);
        } else {
          doc.path = '';
          doc.mileageGoogle = '';
        }
      }

      if (field === 'expenses' && index !== undefined && property) {
        doc.expenses[index][property] = value;
      }


      if (field === 'departuremileage' && doc.arrivalmileage.length) {
        const Value: number = parseInt(doc.arrivalmileage) - parseInt(value);
        if (Value >= 0) {
          doc.mileage = Value.toString();
        } else {
          doc.mileage = '';
        }
      } else if (field === 'arrivalmileage' && doc.departuremileage.length) {
        const Value: number = parseInt(value) - parseInt(doc.departuremileage);
        if (Value >= 0) {
          doc.mileage = Value.toString();
        } else {
          doc.mileage = '';
        }
      }
      if (field === 'vehicleTypePlanned') {
        doc[field] = +value as VehicleType;
        if (doc[field] === VehicleType.Companion) {
          doc.vehicleuuidPlanned = that.getVehicleSpecific(
            that,
            VehicleType.Companion,
          );
        } else {
          doc.vehicleuuidPlanned = '';
        }
      } else if (field === 'vehicletype') {
        doc[field] = +value as VehicleType;
        if (doc[field] === VehicleType.Companion) {
          doc.vehicleuuid = that.getVehicleSpecific(
            that,
            VehicleType.Companion,
          );
        } else {
          doc.vehicleuuid = '';
        }
      } else if (index === undefined) {
        doc[field] = value;
      }
      if (focus) {
        const focusTo = document.getElementById(focus);
        if (focusTo) {
          focusTo.focus();
        }
      }

      if (field === 'userUuid') {
        doc.userUuid = value
        doc.useruuid = this.getUserUuid(value)
      }

      that.setState({ document: doc });
    }
  }

  public async setNewDocumentRemove(that, what, index: number) {
    const document: DocumentData = Object.assign({}, that.state.document);
    document[what].splice(index, 1);
    if (what === 'pointuuids') {
      document.Pointuuids.splice(index, 1);
      let path = '';
      let canPathBeCalculated = true;
      for (const item of document.pointuuids) {
        if (item && item.length) {
          if (path.length) {
            path += ', ';
          }
          path += that.getPointShortName(that, item);
        } else {
          canPathBeCalculated = false;
        }
      }
      if (canPathBeCalculated) {
        document.path = path;
        document.mileageGoogle = await that.getMileageGoogle(
          that,
          document.pointuuids,
        );
      } else {
        document.path = '';
        document.mileageGoogle = '';
      }
    }
    that.setState({ document });
  }

  public setNewDocumentAdd(that, what) {
    const document_: DocumentData = Object.assign({}, that.state.document);
    let scrollDown: HTMLDivElement
    if (what === 'subsistence') {
      let uuid = '';
      if (document_.subsistence && document_.subsistence.length) {
        uuid = document_.subsistence[document_.subsistence.length - 1].uuid;
      } else if (that.state.subsistence && that.state.subsistence.length) {
        for (const item of that.state.subsistence) {
          if (item.active) {
            uuid = item.uuid;
            break
          }
        }
      }
      if (!document_[what]) {
        document_[what] = [];
      }
      document_[what].push({
        uuid,
        breakfast: false,
        lunch: false,
        dinner: false,
      });
      if (this.state.navigation === Navigation.Analytics) {
        scrollDown = document.getElementById('subsistence-data') as HTMLDivElement
      }
    } else if (what === 'pointuuids') {
      if (!document_[what]) {
        document_[what] = [];
      }
      document_[what].push(undefined);
      document_.Pointuuids.push('');
      document_.path = '';
      document_.mileageGoogle = '';
    } else if (what === 'expenses') {
      if (!document_[what]) {
        document_[what] = [];
      }
      document_[what].push({ uuid: '', value: '' });
      if (this.state.navigation === Navigation.Analytics) {
        scrollDown = document.getElementById('expenses-data') as HTMLDivElement
      }
    } else {
      document_[what].push(undefined);
    }
    if (scrollDown) {
      setTimeout(() => scrollDown.scrollTo({top: 10000, behavior: 'smooth'}), 300)
    }
    that.setState({ document: document_ });
  }
  public getPath(that, disabled: boolean, doNotIncludeNavigation?: boolean): JSX.Element {
    return (
      <>
        {that.getPathData(that, disabled, doNotIncludeNavigation)}
        <div className="clearfix pr">
          {(that.state.tabIndex === TabIndex.Document || that.state.tabIndex === TabIndex.Reservation || doNotIncludeNavigation) &&
          that.state.document.path.length === 0
            ? that.showExclamation('mileage-planned')
            : null}
          <div className="tar before-input fl pr">
            Relacija:
            {disabled ? null : (
              <button
                onClick={() => that.setNewDocumentAdd(that, 'pointuuids')}
                className="pa button button-green button-thin add-point mt3"
              >
                +
              </button>
            )}
          </div>
          <input
            placeholder="Zgoraj vpiši podatke o poti."
            disabled={true}
            readOnly={true}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.path}
          />
        </div>
        <div className = "red semi-bold lh20">
          {(that.state.tabIndex === TabIndex.Document || that.state.tabIndex === TabIndex.Reservation || doNotIncludeNavigation) && that.state.document.path.length === 0 ?
          'Izberi destinacije, pomagaj si s filtrom!' : ''}
        </div>

        <div className = "red semi-bold lh20">
          {(that.state.tabIndex === TabIndex.Document || that.state.tabIndex === TabIndex.Reservation || doNotIncludeNavigation) && that.state.document.path.length === 0 ?
          'Točke je potrebno IZBRATI iz seznama!' : ''}
        </div>
        {this.calculateMileageGoogle ? (
          <div className="clearfix">
            <div className="tar before-input fl">Google (km):</div>
            <input
              disabled={true}
              readOnly={true}
              type="text"
              className="ml input dbl fl"
              value={that.state.document.mileageGoogle}
            />
          </div>
        ) : null}
      </>
    );
  }
  public getPathData(that, disabled: boolean): JSX.Element[] {
    const pd: JSX.Element[] = [];
    if (that.state.document.pointuuids) {
      for (let i = 0; i < that.state.document.pointuuids.length; i++) {
        pd.push(
          <div key={i} className="clearfix pr">
            <button
              style={{ opacity: disabled ? 0 : 1 }}
              disabled={disabled}
              onClick={() => that.setNewDocumentRemove(that, 'pointuuids', i)}
              className="dbl fl button button-thin button-wide button-red mt3"
            >
              Odstrani
            </button>

            <input
              placeholder="S filtrom izberi destinacijo!"
              disabled={disabled}
              className="ml select select-input dbl fl mt3"
              onChange={e =>
                that.setNewDocument(that, 'Pointuuids', e.target.value, i)
              }
              value={that.state.document.Pointuuids[i]}
              list="points"
            />
            {that.countPointsDatalist(that, that.state.document.Pointuuids[i]) === false ? <span className = "red pa nohit11">Brez zadetka!</span> : null}
          </div>,
        );
      }
    }
    pd.push(<datalist key = "-1" id="points">{that.getPointsDatalist(that, that.props.userData.uuid)}</datalist>)
    return pd;
  }

  public getPercentage(
    that,
    uuid: string,
    field: 'breakfast' | 'lunch' | 'dinner',
  ): string {
    if (that.state.subsistence) {
      for (const item of that.state.subsistence) {
        if (item.uuid === uuid) {
          if (item[field] === null) {
            return 'Brez odbitka';
          }
          return '-' + item[field] + '%';
        }
      }
    }

    return 'Brez odbitka';
  }

  public getExpensesData(that, disabled: boolean): JSX.Element[] {
    const ed: JSX.Element[] = [];
    if (that.state.document.expenses) {
      for (let i = 0; i < that.state.document.expenses.length; i++) {
        ed.push(
          <div key={i} className="clearfix">
            <button
              style={{ opacity: disabled ? 0 : 1 }}
              disabled={disabled}
              onClick={() => that.setNewDocumentRemove(that, 'expenses', i)}
              className="dbl fl button button-thin button-wide button-red mt3"
            >
              Odstrani
            </button>
            <select
              disabled={disabled}
              className="ml select select-expense dbl fl mt3"
              onChange={e =>
                that.setNewDocument(that, 'expenses', e.target.value, i, 'uuid')
              }
              value={that.state.document.expenses[i].uuid}
            >
              {that.getExpenseOptions()}
            </select>

            <input
              disabled={disabled}
              maxLength={stringShortLength}
              style = {{paddingRight: '3px'}}
              placeholder="znesek €"
              className="ml5 input input-expense-value dbl fl mt3 tar"
              onChange={e =>
                that.setNewDocument(
                  that,
                  'expenses',
                  e.target.value,
                  i,
                  'value',
                )
              }
              value={that.state.document.expenses[i].value}
            />
          </div>,
        );
      }
    }
    return ed;
  }

  public getSubsistenceData(that, disabled: boolean): JSX.Element[] {
    const sd: JSX.Element[] = [];
    if (that.state.document.subsistence) {
      for (let i = 0; i < that.state.document.subsistence.length; i++) {
        sd.push(
          <div key={i}>
            <div className="clearfix">
              <div className="tar fl before-input">Dan {i + 1}</div>

              <select
                disabled={disabled}
                className="ml select select-input dbl fl mt3"
                onChange={e =>
                  that.setNewDocument(
                    that,
                    'subsistence',
                    e.target.value,
                    i,
                    'uuid',
                  )
                }
                value={that.state.document.subsistence[i].uuid}
              >
                {that.getSubsistenceOptions(that, false, that.state.document.subsistence[i].uuid)}
              </select>
            </div>
            <div className="clearfix maxwidth">
              <button
                style={{ opacity: disabled ? 0 : 1 }}
                disabled={disabled}
                onClick={() =>
                  that.setNewDocumentRemove(that, 'subsistence', i)
                }
                className="dbl fl button button-thin button-wide button-red mt3"
              >
                Odstrani
              </button>

              <div
                title={that.getPercentage(
                  that,
                  that.state.document.subsistence[i].uuid,
                  'lunch',
                )}
                className="tar fr ml5"
              >
                Večerja
              </div>
              <input
                disabled={disabled}
                type="checkbox"
                className="ml input dbl fr input-checkbox"
                checked={that.state.document.subsistence[i].dinner}
                onChange={e =>
                  that.setNewDocument(
                    that,
                    'subsistence',
                    e.target.value,
                    i,
                    'dinner',
                  )
                }
              />
              <div
                title={that.getPercentage(
                  that,
                  that.state.document.subsistence[i].uuid,
                  'dinner',
                )}
                className="tar fr ml5"
              >
                Kosilo
              </div>
              <input
                disabled={disabled}
                type="checkbox"
                className="ml input dbl fr input-checkbox"
                checked={that.state.document.subsistence[i].lunch}
                onChange={e =>
                  that.setNewDocument(
                    that,
                    'subsistence',
                    e.target.value,
                    i,
                    'lunch',
                  )
                }
              />
              <div
                title={that.getPercentage(
                  that,
                  that.state.document.subsistence[i].uuid,
                  'breakfast',
                )}
                className="tar fr ml5"
              >
                Zajtrk
              </div>
              <input
                disabled={disabled}
                type="checkbox"
                className="input dbl fr input-checkbox "
                checked={that.state.document.subsistence[i].breakfast}
                onChange={e =>
                  that.setNewDocument(
                    that,
                    'subsistence',
                    e.target.value,
                    i,
                    'breakfast',
                  )
                }
              />
            </div>
          </div>,
        );
      }
    }
    return sd;
  }

  public convertBinaryStringToUint8Array(bStr) {
    const len = bStr.length,
      u8_array = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      u8_array[i] = bStr.charCodeAt(i);
    }
    return u8_array;
  }

  public async setNewDocumentImage(that, e) {
    const document: DocumentData = Object.assign({}, that.state.document);
    let size = 0;
    for (const file of e.target.files) {
      size += file.size;
    }
    if (size > 1024 * 1024 * 8) {
      alert('Vsota velikosti datotek je večja od 8 MB!');
    } else {
      document.attachment.files = [];
      for (const file of e.target.files) {
        document.attachment.files.push({
          size: file.size,
          type: file.type,
          name: file.name,
          // encoded: undefined,
          value: undefined,
          filename: undefined,
          binaryValue: undefined
        });
      }
      const files = Array.from(e.target.files).map(file => {
        const reader = new FileReader();
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result);
          // reader.readAsBinaryString(file as Blob);
          reader.readAsArrayBuffer(file as Blob);
        });

      });

      const res = await Promise.all(files);
      for (let i = 0; i < res.length; i++) {
        document.attachment.files[i].binaryValue = res[i] as ArrayBuffer
        // document.attachment.files[i].stringValue = res[i] as string;
        // document.attachment.files[i].encoded = this.convertBinaryStringToUint8Array(document.attachment.files[i].stringValue);
      }
      that.setState({ document });
    }
  }

  public getExpensesSum(that): string {
    let sum = 0;
    for (const item of that.state.document.expenses) {
      sum += item.value.length ? parseFloat(item.value.replace(',', '.')) : 0;
    }
    return (Math.round(sum * 100) / 100).toFixed(2) + ' €';
  }

  public calculateExpense(that, advance?: string): string {
    let sum = 0;
    if (
      that.state.document &&
      that.state.document.mileage &&
      that.state.document.mileage.length &&
      that.state.document.mileageuuid &&
      that.state.document.mileageuuid.length &&
      that.state.mileage &&
      that.state.expense &&
      that.state.vehicles
    ) {
      const vehicleType: VehicleType = that.state.document.vehicleType
      if (vehicleType !== VehicleType.None) {
        let value = 0;
        for (const item of that.state.mileage) {
          if (item.uuid === that.state.document.mileageuuid) {
            value = item.value ? parseFloat(item.value) : 0;
            break;
          }
        }
        sum += value * parseFloat(that.state.document.mileage);
      }
    }

    if (
      that.state.document.subsistence &&
      that.state.document.subsistence.length &&
      that.state.subsistence
    ) {
      let value = 0;
      for (const item of that.state.document.subsistence) {
        for (const Item of that.state.subsistence) {
          if (item.uuid === Item.uuid) {
            const Value: number = Item.value ? parseFloat(Item.value) : 0;
            const factor: number =
              100 -
              (item.breakfast && Item.breakfast
                ? parseFloat(Item.breakfast)
                : 0) -
              (item.lunch && Item.lunch ? parseFloat(Item.lunch) : 0) -
              (item.dinner && Item.dinner ? parseFloat(Item.dinner) : 0);
            value += Math.round(((Value * factor) / 100) * 100) / 100;
            break;
          }
        }
      }
      sum += value;
    }

    if (that.state.document.expenses && that.state.document.expenses.length) {
      let value = 0;
      for (const item of that.state.document.expenses) {
        const Value: number = item.value ? parseFloat(item.value.replace(',', '.')) : 0;
        value += Value;
      }

      sum += value;
    }
    return (
      (
        Math.round((sum - (advance ? parseFloat(advance.replace(',', '.')) : 0)) * 100) / 100
      ).toFixed(2) + ' €'
    );
  }

  public showExpenseMileage(doc: DocumentData): string {
    const vehicleType: VehicleType = doc.vehicletype
    if (vehicleType === VehicleType.Private && doc.mileage !== null) {
      let value = 0;
      for (const item of this.state.mileage) {
        if (item.uuid === doc.mileageuuid) {
          value = item.value ? parseFloat(item.value) : 0;
          break;
        }
      }
      return (Math.round(value * parseFloat(doc.mileage) * 100) / 100).toFixed(
        2,
      );
    } else {
      return '0.00';
    }
  }

  public showExpenseSubsistenceN(doc: DocumentData): number {
    return doc.subsistence ? doc.subsistence.length : 0
  }

  public showExpenseSubsistence(doc: DocumentData): string {
    let value = 0;

    for (const item of doc.subsistence) {
      for (const Item of this.state.subsistence) {
        if (item.uuid === Item.uuid) {
          const Value: number = Item.value ? parseFloat(Item.value) : 0;
          const factor: number =
            100 -
            (item.breakfast && Item.breakfast
              ? parseFloat(Item.breakfast)
              : 0) -
            (item.lunch && Item.lunch ? parseFloat(Item.lunch) : 0) -
            (item.dinner && Item.dinner ? parseFloat(Item.dinner) : 0);
          value += Math.round(((Value * factor) / 100) * 100) / 100;
          break;
        }
      }
    }
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  public showExpenseExpense(doc: DocumentData): string {
    let value = 0;
    for (const item of doc.expenses) {
      const Value: number = item.value ? parseFloat(item.value) : 0;
      value += Value;
    }
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  public showExpense(that, what: number, which: number): string {
    if (
      what === 0 &&
      that.state.document &&
      that.state.document.mileage &&
      that.state.document.mileage.length &&
      that.state.document.mileageuuid &&
      that.state.document.mileageuuid.length &&
      that.state.mileage &&
      that.state.vehicles
    ) {
      const vehicleType: VehicleType = that.state.document.vehicleType
      if (vehicleType !== VehicleType.None) {
        let value = 0;
        for (const item of that.state.mileage) {
          if (item.uuid === that.state.document.mileageuuid) {
            value = item.value ? parseFloat(item.value) : 0;
            break;
          }
        }
        if (which) {
          return (
            (
              Math.round(
                value * parseFloat(that.state.document.mileage) * 100,
              ) / 100
            ).toFixed(2) + ' €'
          );
        } else {
          return that.state.document.mileage + ' km × ' + value + ' €';
        }
      } else {
        return '0.00 €';
      }
    } else if (
      what === 1 &&
      that.state.document.subsistence &&
      that.state.document.subsistence.length &&
      that.state.subsistence
    ) {
      let value = 0;

      for (const item of that.state.document.subsistence) {
        for (const Item of that.state.subsistence) {
          if (item.uuid === Item.uuid) {
            const Value: number = Item.value ? parseFloat(Item.value) : 0;
            const factor: number =
              100 -
              (item.breakfast && Item.breakfast
                ? parseFloat(Item.breakfast)
                : 0) -
              (item.lunch && Item.lunch ? parseFloat(Item.lunch) : 0) -
              (item.dinner && Item.dinner ? parseFloat(Item.dinner) : 0);
            value += Math.round(((Value * factor) / 100) * 100) / 100;
            break;
          }
        }
      }
      if (which) {
        return (Math.round(value * 100) / 100).toFixed(2) + ' €';
      }
    } else if (
      what === 2 &&
      that.state.expense &&
      that.state.document.expenses &&
      that.state.document.expenses.length
    ) {
      let value = 0;
      for (const item of that.state.document.expenses) {
        const Value: number = item.value ? parseFloat(item.value.replace(',','.')) : 0;
        value += Value;
      }
      if (which) {
        return (Math.round(value * 100) / 100).toFixed(2) + ' €';
      }
    }
    return '';
  }
  /*
  getSize(value: number): string {
    return Math.round(value / 1024) + ' kB';
  }
  */
  public removeAttachmentFiles(that) {
    const document: DocumentData = Object.assign({}, that.state.document);
    document.attachment.files = [];
    that.setState({ document });
  }
  public closeAttachment(that) {
    const document: DocumentData = Object.assign({}, that.state.document);
    document.attachment = {
      name: '',
      description: '',
      files: [],
    };
    that.setState({ document, attachment: -1 });
  }
  public addAttachment(that) {
    const document: DocumentData = Object.assign({}, that.state.document);
    if (that.state.attachment === -1) {
      document.attachments.push(document.attachment);
    } else {
      document.attachments[that.state.attachment] = document.attachment;
    }
    document.attachment = {
      name: '',
      description: '',
      files: [],
    };
    that.setState({ document, attachment: -1 });
  }

  public removeAttachment(that, i: number) {
    const document: DocumentData = Object.assign({}, that.state.document);
    document.attachments.splice(i, 1);
    that.setState({ document });
  }

  public openAttachment(that, i: number) {
    const document: DocumentData = Object.assign({}, that.state.document);
    document.attachment = JSON.parse(JSON.stringify(document.attachments[i]));
    for (let j = 0; j < document.attachments[i].files.length; j++) {
      if (document.attachments[i].files[j].binaryValue !== undefined) {
        document.attachment.files[j].binaryValue = document.attachments[i].files[j].binaryValue
      }
    }
    that.setState({
      document,
      showImage: undefined,
      showPdf: undefined,
      enlargeImage: false,
      attachment: i,
    });
  }

  getTotalSize(that, files: Files[]): string {
    let size = 0;
    for (const item of files) {
      size += item.size;
    }
    return `${byteSize(size)}` // that.getSize(size);
  }

  public getAttachments(that, disabled: boolean): JSX.Element[] {
    const a: JSX.Element[] = [];
    if (that.state.document.attachments) {
      for (let i = 0; i < that.state.document.attachments.length; i++) {
        a.push(
          <div className="clearfix" key={i}>
            <button
              style={{ opacity: disabled ? 0 : 1 }}
              disabled={disabled}
              onClick={() => that.removeAttachment(that, i)}
              className={clsx(
                'dbl fl button button-thin button-wide button-red mt3',
                i === that.state.attachment ? 'active' : '',
              )}
            >
              Odstrani
            </button>
            <div
              onClick={() => that.openAttachment(that, i)}
              className={clsx(
                'fl ml attachment-name',
                i === that.state.attachment ? 'selected' : '',
              )}
              title={that.state.document.attachments[i].description}
            >
              {that.state.document.attachments[i].name}
            </div>
            <div
              className="fl ml attachment-size"
              title={
                'Št. datotek: ' +
                that.state.document.attachments[i].files.length
              }
            >
              {that.getTotalSize(
                that,
                that.state.document.attachments[i].files,
              )}
            </div>
          </div>,
        );
      }
    }
    return a;
  }

  public openImagePdf(
    that,
    type: string,
    showImage: string,
    file: string,
    Encoded: Uint8Array,
    binaryValue: ArrayBuffer
  ) {
    let encoded
    if (type === 'application/pdf') {
      if (Encoded && !Encoded.length) {
        encoded = Object.assign([], Encoded);
        encoded = Uint8Array.from(encoded);
      }
      const src: string = encoded
        ? URL.createObjectURL(new Blob([encoded])) :
        binaryValue ? URL.createObjectURL(new Blob([binaryValue]))
        : file;
      that.setState({
        showPdf: showImage,
        showImage: undefined,
        src,
        enlargeImage: false,
      });
    } else {
      if (Encoded && !Encoded.length) {
        encoded = Object.assign([], Encoded);
        encoded = Uint8Array.from(encoded);
      }
      const src: string = encoded
        ? URL.createObjectURL(new Blob([encoded])) :
        binaryValue ? URL.createObjectURL(new Blob([binaryValue]))
        : file;
      that.setState({
        showImage,
        showPdf: undefined,
        src,
        enlargeImage: false,
      });
    }
  }

  public getAttachment(that): JSX.Element[] {
    const a: JSX.Element[] = [];
    if (
      that.state.document.attachment &&
      that.state.document.attachment.files
    ) {
      for (let i = 0; i < that.state.document.attachment.files.length; i++) {
        a.push(
          <div key={i} className="clearfix">
            <a
              className={clsx(
                'fl dbl attach attach-0',
                that.state.showImage ===
                  that.state.document.attachment.files[i].name
                  ? 'selected'
                  : '',
              )}
              onClick={() =>
                that.state.showImage ===
                that.state.document.attachment.files[i].name
                  ? that.cancelImage(that)
                  : that.openImagePdf(
                      that,
                      that.state.document.attachment.files[i].type,
                      that.state.document.attachment.files[i].name,
                      that.state.document.attachment.files[i].value,
                      that.state.document.attachment.files[i].encoded as Uint8Array,
                      that.state.document.attachment.files[i].binaryValue,
                    )
              }
            >
              {that.state.document.attachment.files[i].name}
            </a>
            <div className="fl dbl attach attach-1">
              {`${byteSize(that.state.document.attachment.files[i].size)}`}
            </div>
          </div>,
        );
      }
    }
    return a;
  }

  public async addDocument(that, previewDocument?: boolean, toSuperior?: boolean) {
    const expenses: Expense[] = Object.assign([], this.state.document.expenses)
    if (expenses) {
      for (let i: number = 0; i < expenses.length; i++) {
        expenses[i].value = expenses[i].value.replace(',', '.')
      }
    }

    const data: UpdateDocument = {
      // whois: that.props.userData.uuid,
      uuid: that.state.document.uuid,
      useruuid: that.props.userData.usertype === UserType.Super && that.state.document.useruuid ? that.state.document.useruuid : that.props.userData.uuid,
      email: that.props.userData.usertype === UserType.Super && that.state.document.email ? that.state.document.email : that.props.userData.email,
      departure: (new Date((that.state.document.departureDate as Date).getTime() + 12 * 60 * 60 * 1000))
        .toISOString()
        .substring(0, 10),
      arrival: (new Date((that.state.document.arrivalDate as Date).getTime() + 12 * 60 * 60 * 1000))
        .toISOString()
        .substring(0, 10),
      departureDate: that.state.document.departureDate,
      arrivalDate: that.state.document.arrivalDate,
      departureHour:
        (that.state.document.departureHour.length === 1 ? '0' : '') +
        that.state.document.departureHour,
      arrivalHour:
        (that.state.document.arrivalHour.length === 1 ? '0' : '') +
        that.state.document.arrivalHour,
      departureMinute:
        (that.state.document.departureMinute.length === 1 ? '0' : '') +
        that.state.document.departureMinute,
      arrivalMinute:
        (that.state.document.arrivalMinute.length === 1 ? '0' : '') +
        that.state.document.arrivalMinute,

      vehicletype: that.state.document.vehicletype,
      vehicleuuid: that.state.document.vehicleuuid,
      projectuuid: that.state.document.projectuuid,
      customeruuid: that.state.document.customeruuid,
      intent: that.state.document.intent,
      description: that.state.document.description,
      companions: that.state.document.companions,

      orderuuid: that.state.document.orderuuid,

      mileage: that.state.document.mileage,
      pointuuids: that.state.document.pointuuids,
      path: that.state.document.path,
      mileageGoogle: that.state.document.mileageGoogle,
      status: toSuperior ? DocumentStatusUpdatedPlus : that.state.document.status === DocumentStatusInserted ? DocumentStatusUpdated : that.state.document.status,

      attachments: JSON.parse(JSON.stringify(that.state.document.attachments)), // not correct, but corrected below
      expenses,
      subsistence: that.state.document.subsistence,
      mileageuuid: that.state.document.mileageuuid,
      companyuuid: that.state.document.companyuuid,
      advance: that.state.document.advance !== null && that.state.document.advance !== undefined ? that.state.document.advance.replace(',', '.') : that.state.document.advance,
      advancedate: that.state.document.advancedate,
      departuremileage: that.state.document.departuremileage,
      arrivalmileage: that.state.document.arrivalmileage,
    };
    for(let i = 0; i < data.attachments.length; i++) {
      for (let j = 0; j < data.attachments[i].files.length; j++) {
        if (that.state.document.attachments[i].files[j].binaryValue) {
          data.attachments[i].files[j].binaryValue = that.state.document.attachments[i].files[j].binaryValue
        }
      }
    }

    data.arrival += ' ' + data.arrivalHour + ':' + data.arrivalMinute
    data.departure += ' ' + data.departureHour + ':' + data.departureMinute
    data['token'] = that.props.userData.token;
    if (previewDocument) {
      const params: FilterDocumentsParams = {
        token: '',
        departure: that.toLocalString(that.state.fDep),
        arrival: that.toLocalString(that.state.fArr),
        companyVehicle: that.state.fCompanyVehicle,
        companyuuid: that.state.fCompanyuuid,
        useruuid: that.state.fUseruuid,
        ownUseruuid: this.props.userData.uuid,
        vehicleuuid: that.state.fVehicleuuid,
      };
      data['arg'] = params;
    }

    const a: Attachment[] = JSON.parse(JSON.stringify(data.attachments))
    for(let i = 0; i < a.length; i++) {
      for (let j = 0; j < a[i].files.length; j++) {
        if (data.attachments[i].files[j].binaryValue) {
          a[i].files[j].binaryValue = data.attachments[i].files[j].binaryValue
        }
      }
    }

    for (let i: number = 0; i < data.attachments.length; i++) {
      for (let j: number = 0; j < data.attachments[i].files.length; j++) {
        if (!that.state.document.Attachments || !that.state.document.Attachments[i] || !that.state.document.Attachments[i].files) {
          continue
        }
        if (data.attachments[i].files[j].name !== that.state.document.Attachments[i].files[j].name
         || data.attachments[i].files[j].size !== that.state.document.Attachments[i].files[j].size
         || data.attachments[i].files[j].type !== that.state.document.Attachments[i].files[j].type)
         {
         }
         else {
          // console.log('binary value is supposed to be deleted!')
          delete(data.attachments[i].files[j].binaryValue)
         }
      }
    }
    /*
    const files: Files[][] = []
    for (let i = 0; i < data.attachments.length; i++) {
      if (data.attachments[i].files !== undefined) {
        files.push(data.attachments[i].files)
        delete(data.attachments[i].files)
      } else {
        files.push([])
      }
    }
    */
    // console.log(files)
    const fd = new FormData()
    for (let i = 0; i < data.attachments.length; i++) {
      for (let j = 0; j < data.attachments[i].files.length; j++) {
        if (data.attachments[i].files[j]) {
          // console.log('binary_data', i, j)
          // console.log(data.attachments[i].files[j].binaryValue)
          // console.log(new Blob([data.attachments[i].files[j].binaryValue]))
          fd.append('binary_data_' + i + '_' + j, new Blob([data.attachments[i].files[j].binaryValue]))
          delete(data.attachments[i].files[j].binaryValue)
        }
      }
    }

    // console.log(a)
    // console.log(data.attachments)
    //this.setState({loader: true}) //REMOVED THE LOADER in order not to make the app non-responsive
    data['previewDocument'] = previewDocument ? true : false
    data['DEV'] = DEV

    // console.log(data)
    fd.append('json_data', JSON.stringify(data))
    // also to append binary_data

    try {
        if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: addDocument)');

        const response = await axios.put(documentRoute, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response && response.data && response.data.resolved) {
          if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: addDocument)');
          delete data['token'];
          if (previewDocument) {
            delete data['arg'];
          }
          if (response.data.resolved) {
            const documents: DocumentData[] = Object.assign(
              [],
              that.state.documents,
            );
            let index = -1;
            if (documents) {
              for (let i = 0; i < documents.length; i++) {
                if (documents[i].uuid === data.uuid) {
                  index = i;
                  break;
                }
              }
            }
            const d: DocumentData = Object.assign({}, that.state.document)
            d.Attachments = JSON.parse(JSON.stringify(a))
            for(let i = 0; i < d.Attachments.length; i++) {
              for (let j = 0; j < d.Attachments[i].files.length; j++) {
                if (a[i].files[j].binaryValue) {
                  d.Attachments[i].files[j].binaryValue = a[i].files[j].binaryValue
                }
              }
            }
            d.attachments = JSON.parse(JSON.stringify(a))
            for(let i = 0; i < d.attachments.length; i++) {
              for (let j = 0; j < d.attachments[i].files.length; j++) {
                if (a[i].files[j].binaryValue) {
                  d.attachments[i].files[j].binaryValue = a[i].files[j].binaryValue
                }
              }
            }
            if (index !== -1) {
              documents[index] = data as unknown as DocumentData;
              documents[index].advancedate = new Date(documents[index].advancedate);
              if (
                previewDocument &&
                response.data.data &&
                response.data.data.d &&
                response.data.data.u
                ) {
                that.setState({ document: d, loader: false, documents, analyticsData: response.data.data.d.data, analyticsUser: response.data.data.u.data });
              }
              else {
                that.setState({ document: that.initDocument(), loader: false, documents, attachment: -1, tabIndex: TabIndex.Notifications, lastTabIndex: TabIndex.Reservation });
              }
              alert(toSuperior ? 'Potni nalog uspešno oddan nadrejenemu!' : 'Potni nalog uspešno dopolnjen!');
            }
            else if (
              previewDocument &&
              response.data.data &&
              response.data.data.d &&
              response.data.data.u
            )
            {
              that.setState({ document: d, loader: false, analyticsData: response.data.data.d.data, analyticsUser: response.data.data.u.data });
              alert(toSuperior ? 'Potni nalog uspešno oddan nadrejenemu!' : 'Potni nalog uspešno dopolnjen!');
            }
            else {
            }
          } //if (response.data.resolved)
        } //if (response && response.data && response.data.resolved)
        else if (response && response.data && response.data.resolved === false) {
            alert(`Error: Failed response from server! (func: addDocument)\nmsg: ${response.data.message}\n\nProsim, ponovno shranite spremembe!`);
        }
        else {
            alert(`Error: Failed response from server! (func: addDocument)\n\nProsim, ponovno shranite spremembe!`);
        }
    }
    catch (err) {
        alert(`Error (func: addDocument)\nmsg:${err.message}\n\nProsim, ponovno shranite spremembe!`);
    }
  }

  public async insertDocument(that, newStatus: DocumentStatus) {
    if (
      newStatus === DocumentStatusUpdated || newStatus === DocumentStatusUpdatedPlus
      // newStatus === DocumentStatusInserted || newStatus === DocumentStatusSaved
       )
    {
      const data: InsertDocument = {
        // whois: that.props.userData.uuid,
        uuid: that.state.document.uuid,
        useruuid: that.props.userData.usertype === UserType.Super && that.state.document.useruuid ? that.state.document.useruuid : that.props.userData.uuid,
        email: that.props.userData.usertype === UserType.Super && that.state.document.email ? that.state.document.email : that.props.userData.email,
        departure: that
          .toLocalString(that.state.document.departureDatePlanned)
          .substring(0, 10),
        arrival: that
          .toLocalString(that.state.document.arrivalDatePlanned)
          .substring(0, 10),
        departureDatePlanned: that.state.document.departureDatePlanned,
        arrivalDatePlanned: that.state.document.arrivalDatePlanned,
        departureHourPlanned:
          (that.state.document.departureHourPlanned.length === 1 ? '0' : '') +
          that.state.document.departureHourPlanned,
        arrivalHourPlanned:
          (that.state.document.arrivalHourPlanned.length === 1 ? '0' : '') +
          that.state.document.arrivalHourPlanned,
        departureMinutePlanned:
          (that.state.document.departureMinutePlanned.length === 1 ? '0' : '') +
          that.state.document.departureMinutePlanned,
        arrivalMinutePlanned:
          (that.state.document.arrivalMinutePlanned.length === 1 ? '0' : '') +
          that.state.document.arrivalMinutePlanned,

        vehicleTypePlanned: that.state.document.vehicleTypePlanned,
        vehicleuuidPlanned: that.state.document.vehicleuuidPlanned,
        projectuuidPlanned: that.state.document.projectuuidPlanned,
        companyuuidPlanned: that.state.document.companyuuidPlanned,
        intentPlanned: that.state.document.intentPlanned,
        descriptionPlanned: that.state.document.descriptionPlanned,
        companionsPlanned: that.state.document.companionsPlanned,

        orderuuidPlanned: that.state.document.orderuuidPlanned,

        pointuuids: that.state.document.pointuuids,
        path: that.state.document.path,
        mileageGoogle: that.state.document.mileageGoogle,
        status: newStatus,
      };

      data.departure +=
        ' ' + data.departureHourPlanned + ':' + data.departureMinutePlanned;
      data['departureHour'] = data.departureHourPlanned;
      data['departureMinute'] = data.departureMinutePlanned;

      data['departureDate'] = new Date(data.departure);


      data.arrival +=
        ' ' + data.arrivalHourPlanned + ':' + data.arrivalMinutePlanned;
      data['arrivalHour'] = data.arrivalHourPlanned;
      data['arrivalMinute'] = data.arrivalMinutePlanned;

      data['arrivalDate'] = new Date(data.arrival);

      data['vehicletype'] = data.vehicleTypePlanned;
      data['vehicleuuid'] = data.vehicleuuidPlanned;
      data['projectuuid'] = data.projectuuidPlanned;
      data['companyuuid'] = data.companyuuidPlanned;
      data['intent'] = data.intentPlanned;
      data['description'] = data.descriptionPlanned;
      data['companions'] = data.companionsPlanned;
      data['orderuuid'] = data.orderuuidPlanned;

      data['token'] = that.props.userData.token;

      if (that.state.document.status === DocumentStatusNew) {
        try {
            if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: insertDocument)');

            const response = await axios.post(documentRoute, data);
            if (
              response &&
              response.data &&
              response.data.resolved
            ) {
              if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: insertDocument)');

              delete data['token'];
              if (response.data.uuid) {
                data.uuid = response.data.uuid
              }
              if (newStatus === DocumentStatusUpdatedPlus) {
                this.setState({
                  document: that.initDocument(),
                  attachment: -1,
                  lastTabIndex: TabIndex.Notifications,
                  tabIndex: TabIndex.Notifications,
                });
              } else {
                const documents: DocumentData[] = Object.assign(
                  [],
                  that.state.documents,
                );
                documents.unshift(data as DocumentData);
                const Documents: number[] = Object.assign([], this.state.Documents)
                Documents.unshift(0)
                this.setState({
                  Documents,
                  documents,
                  document: that.initDocument(),
                  attachment: -1,
                  lastTabIndex: TabIndex.Notifications,
                  tabIndex: TabIndex.Notifications,
                });
              }
              alert(
                'Rezervacija uspešno ' +
                  (newStatus === DocumentStatusUpdated ? 'shranjena' : 'oddana') +
                  '!',
              );
            }
            else if (response && response.data.resolved === false && response.data.message) {
                alert(`Error: Failed response from server! (func: insertDocument)\nmsg: ${response.data.message}\n\nProsim, ponovno shranite spremembe!`);
            }
            else {
                alert('Error: Failed response from server! (func: insertDocument)\n\nProsim, ponovno shranite spremembe!');
            }
        }
        catch (err) {
            alert(`Error (func: insertDocument)\nmsg:${err.message}\n\nProsim, ponovno shranite spremembe!`);
        }
      } /* else if (
        that.state.document.status === DocumentStatusSaved ||
        that.state.document.status === DocumentStatusInserted
      ) {
        const response = await axios.put(documentRoute, data); DEPRECATED, NEEDS TO USE FORM DATA
        if (response && response.data) {
          delete data['token'];
          if (response.data.resolved) {
            const documents: DocumentData[] = Object.assign(
              [],
              that.state.documents,
            );
            let index = -1;
            for (let i = 0; i < documents.length; i++) {
              if (documents[i].uuid === data.uuid) {
                index = i;
                break;
              }
            }
            if (index !== -1) {
              documents[index] = data as DocumentData;
              this.setState({ documents });
              alert('Rezervacija uspešno popravljena!');
            }
          }
        }
      } */
    }
  }

  public async cancelDocument(that, uuid: string) {
    if (that.state.document.status === DocumentStatusInserted) {
      if (confirm('Ali si resnično želite izbrisati rezervacijo?')) {
        const response = await axios.delete(
          documentRoute + '?uuid=' + uuid + '&token=' + that.props.userData.token,
        );
        if (response && response.data && response.data.resolved) {
          let index = -1;
          for (let i = 0; i < that.state.documents.length; i++) {
            if (that.state.documents[i].uuid === uuid) {
              index = i;
              break;
            }
          }
          if (index !== -1) {
            const documents: DocumentData[] = Object.assign(
              [],
              that.state.documents,
            );
            const Documents: number[] = Object.assign([], that.state.Documents)
            documents.splice(index, 1);
            Documents.splice(index, 1);
            that.setState({
              documents,
              Documents,
              tabIndex: TabIndex.Notifications,
              document: this.initDocument(),
              attachment: -1,
            });
          } else {
            alert('Neznana napaka.');
          }
        } else {
          alert('Neznana napaka.');
        }
      }
    } else {
      that.setState({
        tabIndex: TabIndex.Notifications,
        document: this.initDocument(),
        attachment: -1,
      });
    }
  }
/*
  public async newDocument(that) {
    if (confirm('Podatki znotraj trenutne rezervacije bodo v primeru odprtja nove rezervacije izgubljeni!')) {
      this.setState({ document: that.initDocument(), nrn: NRN.BasicInformation });
    }
  }
*/
  public showExclamation(type: string): JSX.Element {
    return <div className={clsx('pa exclamation', type)}>!</div>;
  }

  public isNewDocumentPossible(that, doc: DocumentData, toSuperior?: boolean): boolean {

    if (toSuperior && doc.orderuuidPlanned.length === 0) {
      return false;
    }

    if (!doc.departureDatePlanned) {
      return false;
    }
    if (!doc.arrivalDatePlanned) {
      return false;
    }

    if (doc.departureHourPlanned.length < 1) {
      return false;
    }
    if (doc.departureMinutePlanned.length < 1) {
      return false;
    }
    if (doc.arrivalHourPlanned.length < 1) {
      return false;
    }
    if (doc.arrivalMinutePlanned.length < 1) {
      return false;
    }

    if (doc.vehicleTypePlanned === VehicleType.None) {
      return false;
    }
    if (doc.vehicleuuidPlanned.length === 0) {
      return false;
    }

    if (that.isSameDateAndTimeWrongPlanned(doc)) {
      return false;
    }

    if (doc.pointuuids) {
      for (const item of doc.pointuuids) {
        if (item === undefined) {
          return false
        }
      }
    }

    return true;
  }

  public isAddDocumentPossible(that, doc: DocumentData, toSuperior?: boolean): boolean {

    if (toSuperior && doc.orderuuid.length === 0) {
      return false
    }
    if (doc.vehicletype !== VehicleType.Companion) {
      if (doc.mileage.length === 0 || +doc.mileage === 0) {
        return false;
      }
    }

    if (!doc.departureDate) {
      return false;
    }
    if (!doc.arrivalDate) {
      return false;
    }

    if (doc.departureHour.length < 1) {
      return false;
    }
    if (doc.departureMinute.length < 1) {
      return false;
    }
    if (doc.arrivalHour.length < 1) {
      return false;
    }
    if (doc.arrivalMinute.length < 1) {
      return false;
    }


    if (doc.vehicletype === VehicleType.None) {
      return false;
    }
    if (doc.vehicleuuid.length === 0) {
      return false;
    }

    if (doc.projectuuid.length === 0) {
      return false;
    }

    if (doc.companyuuid.length === 0) {
      return false;
    }

    if (doc.mileageuuid.length === 0) {
      return false;
    }

    if (doc.path.length === 0) {
      return false;
    }

    if (that.isSameDateAndTimeWrong(doc)) {
      return false;
    }

    if (doc.pointuuids) {
      for (const item of doc.pointuuids) {
        if (item === undefined) {
          return false
        }
      }
    }

    return true;
  }

  public getExcludeDateIntervals(
    type: 'after' | 'before',
    date?: Date,
  ): Array<{ start: Date; end: Date }> {
    if (!date) {
      return [];
    }
    if (type === 'before') {
      return [
        {
          start: new Date(0),
          end: new Date(date.getTime() - 24 * 60 * 60 * 1000),
        },
      ];
    }
    if (type === 'after') {
      return [
        {
          start: new Date(date.getTime()),
          end: new Date('9999-12-31T23:59:59'),
        },
      ];
    }
    return [];
  }

  public isSameDateAndTimeWrongPlanned(doc: DocumentData): boolean {
    if (
      doc.departureDatePlanned &&
      doc.arrivalDatePlanned &&
      (new Date(doc.departureDatePlanned)).toISOString().substring(0,10) ===
      (new Date(doc.arrivalDatePlanned)).toISOString().substring(0,10)
      // doc.departureDatePlanned.getTime() === doc.arrivalDatePlanned.getTime()
    ) {
      if (doc.departureHourPlanned.length && doc.arrivalHourPlanned.length) {
        if (
          parseInt(doc.departureHourPlanned) > parseInt(doc.arrivalHourPlanned)
        ) {
          return true;
        }
        if (
          parseInt(doc.departureHourPlanned) ===
          parseInt(doc.arrivalHourPlanned)
        ) {
          if (
            parseInt(doc.departureMinutePlanned) >
            parseInt(doc.arrivalMinutePlanned)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public isSameDateAndTimeWrong(doc: DocumentData): boolean {
    if (
      doc.departureDate &&
      doc.arrivalDate &&
      (new Date(doc.departureDate)).toISOString().substring(0,10) ===
      (new Date(doc.arrivalDate)).toISOString().substring(0,10)
      // doc.departureDate.getTime() === doc.arrivalDate.getTime()
    ) {
      if (doc.departureHour.length && doc.arrivalHour.length) {
        if (parseInt(doc.departureHour) > parseInt(doc.arrivalHour)) {
          return true;
        }
        if (parseInt(doc.departureHour) === parseInt(doc.arrivalHour)) {
          if (parseInt(doc.departureMinute) > parseInt(doc.arrivalMinute)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public showStatus(
    Status: string,
    status: DocumentStatus,
    className: string,
  ): JSX.Element {
    return (
      <span className={clsx('document-status', className ? className : '')}>
        <span title="status" className={'ds' + status}>
          {Status}
        </span>
      </span>
    );
  }

  public getVehicleOccupancyBackground(
    my: boolean,
  ): 'my-occupancy' | 'not-my-occupancy' {
    return my ? 'my-occupancy' : 'not-my-occupancy';
  }

  public getVehiclesOccupancy(that): JSX.Element[] {
    const vo: JSX.Element[] = []
    const usedVehicles: string[] = []
    for (const item of that.state.reservations) {
      if (that.state.vehicleuuid && that.state.vehicleuuid !== item.vehicleuuid) {continue}
      vo.push (<div key = {item.uuid} title = {item.useruuid === that.props.userData.uuid ? 'Moja rezervacija' : 'Tuja rezervacija'} className = {clsx('pr vo', item.useruuid === that.props.userData.uuid ? 'vo-mine' : 'vo-foreign')}>
        &nbsp;
        <span className = "pa r1">{that.getUserName(item.useruuid, that)}</span>
        <span className = "pa r2">{that.getVehicleEither(that, item.vehicleuuid, item.vehicletype)}</span>
        <span className = "pa r3">{that.getShortDate(item.departure)} - {that.getShortDate(item.arrival)}</span>
        <span className = "pa r4">{item.path}</span>
      </div>)
      usedVehicles.push(
        item.vehicleuuid
      )
    }

    if (that.state.vehicles) {
      for (const vehicle of this.state.vehicles) {
        if (vehicle.type === VehicleType.Company && vehicle.active) {
          let found: boolean = false
          for (const usedVehicle of usedVehicles) {
            if (usedVehicle === vehicle.uuid) {
              found = true
              break
            }
          }
          if (!found) {
            vo.push (<div key = {vehicle.uuid} title = "Ni rezervacije" className = "pr vo">
            &nbsp;
            <span className = "pa r1">Nezasedeno!</span>
            <span className = "pa r2">{that.getVehicleEither(that, vehicle.uuid, vehicle.type)}</span>
            <span className = "pa r3">V zgornjem terminu!</span>
          </div>)
          }
        }
      }
    }

    return vo
  }

  public getShortDate (date: string): string {
    return `${date.substring(8, 10)}/${date.substring(5, 7)} ${date.substring(11, 16)}`
  }

  public getVehicleOccupancy(that): JSX.Element {
    return that.state.reservations ? (
      <>
        <div className="title mt pr">
          Zasedenost vozil
          {that.showStatus(
            that.getStatus(that.state.document.status),
            that.state.document.status,
            'pa',
          )}
        </div>
        <hr />
        <div>
          <div className="clearfix datepicker-short">
            <div className="fl">Vozilo: </div>
            <select
              style = {{width: '259px'}}
              className="ml select select-input dbl fl mt3"
              value={that.state.vehicleuuid}
              onChange={e => that.setState({vehicleuuid: e.target.value})}
            >
              {that.getVehicleOptions(VehicleType.Company, 'vsa vozila')}
            </select>
          </div>

          <div className="clearfix datepicker-short">
            <div className="fl">Od: </div>
            <DatePicker
              calendarStartDay={1}
              onKeyDown={that.handleDateChangeRaw}
              placeholderText={placeholderDatePicker}
              dateFormat={dateFormatSlash}
              locale={locale}
              selected={that.state.fDeparture}
              excludeDateIntervals={that.getExcludeDateIntervals(
                'after',
                that.state.fArrival,
              )}
              onChange={fDeparture =>
                that.switchToVehicleOccupancy(that, 'departure', fDeparture)
              }
            />
            <div style = {{marginLeft: '28px'}} className="fl">do:</div>
            <DatePicker
              calendarStartDay={1}
              onKeyDown={that.handleDateChangeRaw}
              placeholderText={placeholderDatePicker}
              dateFormat={dateFormatSlash}
              locale={locale}
              selected={that.state.fArrival}
              excludeDateIntervals={that.getExcludeDateIntervals(
                'before',
                that.state.fDeparture,
              )}
              onChange={fArrival =>
                that.switchToVehicleOccupancy(that, 'arrival', fArrival)
              }
            />
          </div>
        </div>
        <div>
          {that.getVehiclesOccupancy(that)}
        </div>
        <div className="mt5">
          <button
            onClick={() => that.setState({ vehicleOccupancy: false })}
            className="button button-red button-thin"
          >
            Zapri zasedenost vozil
          </button>
        </div>
      </>
    ) : (
      <div />
    );
  }

  public getAddingPoints(that): JSX.Element {
    return <div>
        <div className="title mt pr">
          Vnos nove točke
          {that.showStatus(
            that.getStatus(that.state.document.status),
            that.state.document.status,
            'pa',
          )}
        </div>
        <hr />
        <div className="mt5 clearfix">
          <div className="tar beforeinput fl">Naziv:</div>
          <input
            autoComplete="new-password"
            maxLength={stringLongLength}
            onChange={e => this.edit('Point', e.target.value)}
            className="input dbl fl"
            type="text"
            value={this.state.Point}
          />
        </div>

        <div className="clearfix">
          <div className="tar beforeinput fl">Naslov:</div>
          <input
            autoComplete="new-password"
            maxLength={stringLongLength}
            onChange={e => this.edit('PointAddress', e.target.value)}
            className="input dbl fl"
            type="text"
            value={this.state.PointAddress}
          />
        </div>
        <div className="clearfix">
          <div className="tar beforeinput fl">Kratko:</div>
          <input
            autoComplete="new-password"
            maxLength={stringShortLength}
            onChange={e => this.edit('PointShortName', e.target.value)}
            className="input input-narrow dbl fl"
            type="text"
            value={this.state.PointShortName}
          />
        </div>

        <div className="mt5 tal">
          <button
            disabled={this.isNewPointDisabled()}
            onClick={() => this.newPoint(true)}
            className="button button-thin button-green mr"
          >
            Vnesi novo točko
          </button>
        </div>

        <div className="mt5">
          <button
            onClick={() => that.setState({ addingPoints: false })}
            className="button button-red button-thin"
          >
            Zapri vnos nove točke
          </button>
        </div>
    </div>
  }

  public getReservationBasicInformation(that): JSX.Element {
    return (
      <>
        <div className="title mt pr">
          Osnovni podatki
          {that.showStatus(
            that.getStatus(that.state.document.status),
            that.state.document.status,
            'pa',
          )}
        </div>

        {this.props.userData.usertype === UserType.Super ?
          <div className="clearfix pr">
            <div className="tar before-input fl">
              ! Uporabnik:
            </div>
            <input list="User" style={{paddingLeft: '5px'}} className = "ml select select-input dbl fl mt3" value = {that.state.document.userUuid} onChange={(e) =>
              that.setNewDocument(
                that,
                'userUuid',
                e.target.value
              )}
              placeholder = {that.getUserName(that.props.userData.uuid, that)}
            />
            <datalist id = "User">
              {that.getUserDatalist()}
            </datalist>
            {that.countUserDatalist(that.state.document.userUuid) === 0 ? <span className = "red pa nohit111">Brez zadetka!</span> : null}
          </div> : null}

        <div className="clearfix pr">
          {that.state.document.departureDatePlanned === null ||
          that.state.document.departureHourPlanned.length === 0 ||
          that.state.document.departureMinutePlanned.length === 0
            ? that.showExclamation('departure-date-planned')
            : null}
          {that.isSameDateAndTimeWrongPlanned(that.state.document)
            ? that.showExclamation('time-planned')
            : null}

          <div title="Planiran odhod" className="tar before-input fl">
            Plan. odhod:
          </div>
          <DatePicker
            className = {that.state.document.departureDatePlanned ? '' : 'transparent-datepicker'}
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            id="np0"
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={that.state.document.departureDatePlanned ? that.state.document.departureDatePlanned : new Date()}
            excludeDateIntervals={that.getExcludeDateIntervals(
              'after',
              that.state.document.arrivalDatePlanned,
            )}
            onChange={departureDate =>
              that.setNewDocument(
                that,
                'departureDatePlanned',
                departureDate,
                undefined,
                undefined,
                'np1',
              )
            }
          />
          <input
            id="np1"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml input input-hm dbl fl"
            value={that.state.document.departureHourPlanned}
            onChange={e =>
              that.setNewDocument(
                that,
                'departureHourPlanned',
                e.target.value,
                undefined,
                undefined,
                e.target.value.length === stringHourMinuteLength
                  ? 'np2'
                  : undefined,
              )
            }
          />
          <div className="fl ml5">:</div>
          <input
            id="np2"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml5 input input-hm dbl fl"
            value={that.state.document.departureMinutePlanned}
            onChange={e =>
              that.setNewDocument(
                that,
                'departureMinutePlanned',
                e.target.value,
                undefined,
                undefined,
                e.target.value.length === stringHourMinuteLength
                  ? 'np3'
                  : undefined,
              )
            }
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.departureDatePlanned === null ? 'Vnesi datum planiranega odhoda!' :
           that.state.document.departureHourPlanned.length === 0 ? 'Vnesi uro planiranega odhoda!' :
           that.state.document.departureMinutePlanned.length === 0 ? 'Vnesi minuto planiranega odhoda!' :
           that.isSameDateAndTimeWrongPlanned(that.state.document) ? 'Čas odhoda je po času prihoda!':
           ''}
        </div>
        <div className="clearfix pr">
          {that.state.document.arrivalDatePlanned === null ||
          that.state.document.arrivalHourPlanned.length === 0 ||
          that.state.document.arrivalMinutePlanned.length === 0
            ? that.showExclamation('arrival-date-planned')
            : null}

          <div title="Planiran prihod" className="tar before-input fl">
            Plan.prihod:
          </div>
          <DatePicker
            className = {that.state.document.arrivalDatePlanned ? '' : 'transparent-datepicker'}
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            id="np3"
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={that.state.document.arrivalDatePlanned ? that.state.document.arrivalDatePlanned : new Date()}
            excludeDateIntervals={that.getExcludeDateIntervals(
              'before',
              that.state.document.departureDatePlanned,
            )}
            onChange={arrivalDate =>
              that.setNewDocument(
                that,
                'arrivalDatePlanned',
                arrivalDate,
                undefined,
                undefined,
                'np4',
              )
            }
          />
          <input
            id="np4"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml input input-hm dbl fl"
            value={that.state.document.arrivalHourPlanned}
            onChange={e =>
              that.setNewDocument(
                that,
                'arrivalHourPlanned',
                e.target.value,
                undefined,
                undefined,
                e.target.value.length === stringHourMinuteLength
                  ? 'np5'
                  : undefined,
              )
            }
          />
          <div className="fl ml5">:</div>
          <input
            id="np5"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml5 input input-hm dbl fl"
            value={that.state.document.arrivalMinutePlanned}
            onChange={e =>
              that.setNewDocument(that, 'arrivalMinutePlanned', e.target.value)
            }
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.arrivalDatePlanned === null ? 'Vnesi datum planiranega prihoda!' :
           that.state.document.arrivalHourPlanned.length === 0 ? 'Vnesi uro planiranega prihoda!' :
           that.state.document.arrivalMinutePlanned.length === 0 ? 'Vnesi minuto planiranega prihoda!' :
           ''}
        </div>

        <div className="title mt">Podatki o vozilu</div>
        <hr />
        <div className="clearfix pr">
          {that.state.document.vehicleTypePlanned === VehicleType.None
            ? that.showExclamation('vehicle-type-planned')
            : null}
          <div className="tar before-input fl">Tip vozila:</div>
          <select
            className="ml select select-input dbl fl mt3"
            value={that.state.document.vehicleTypePlanned}
            onChange={e =>
              that.setNewDocument(that, 'vehicleTypePlanned', e.target.value)
            }
          >
            {that.getVehicleTypeOptions()}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.vehicleTypePlanned === VehicleType.None ? 'Tip vozila ni izbran!' : ''}
        </div>
        <div className="clearfix pr">
          {that.state.document.vehicleuuidPlanned.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Vozilo:</div>
          <select
            disabled={
              that.state.document.vehicleTypePlanned === VehicleType.Companion
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.vehicleuuidPlanned}
            onChange={e =>
              that.setNewDocument(that, 'vehicleuuidPlanned', e.target.value)
            }
          >
            {that.getVehicleOptions(that.state.document.vehicleTypePlanned)}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.vehicleuuidPlanned.length === 0 ? 'Vozilo ni izbrano!' : ''}
        </div>

        <div className="clearfix pr">
          {that.state.document.companyuuidPlanned.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Plačnik:</div>
          <select
            onChange={e =>
              that.setNewDocument(that, 'companyuuidPlanned', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.companyuuidPlanned}
          >
            {that.getCompanyOptions()}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.companyuuidPlanned.length === 0 ? 'Plačnik ni izbran!' : ''}
        </div>
        <div className="clearfix">
          <div className="tar before-input fl">Projekt:</div>
          <select
            onChange={e =>
              that.setNewDocument(that, 'projectuuidPlanned', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.projectuuidPlanned}
          >
            {that.getProjectOptions(that.state.document.companyuuidPlanned)}
          </select>
        </div>
        {/*
        <div className="clearfix">
          <div className="tar before-input fl">Stranka:</div>
          <select
            onChange={e =>
              that.setNewDocument(that, 'customeruuidPlanned', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.customeruuidPlanned}
          >
            {that.getCustomerOptions()}
          </select>
        </div>
        */}

        {/*
        <div className="clearfix">
          <div className="tar before-input fl lh20 mt5">Z namenom:</div>
          <input
            maxLength={stringLongLength}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.intentPlanned}
            onChange={e =>
              that.setNewDocument(that, 'intentPlanned', e.target.value)
            }
          />
        </div>
        */}
        <div className="clearfix">
          <div className="tar before-input fl lh20 mt5">Opis namena:</div>
          <textarea
            maxLength={stringDescriptionLength}
            className="ml input dbl fl"
            value={that.state.document.descriptionPlanned}
            onChange={e =>
              that.setNewDocument(that, 'descriptionPlanned', e.target.value)
            }
          />
        </div>
        <div className="clearfix">
          <div className="tar before-input fl lh20 mt5">Sopotniki:</div>
          <input
            maxLength={stringLongLength}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.companionsPlanned}
            onChange={e =>
              that.setNewDocument(that, 'companionsPlanned', e.target.value)
            }
          />
        </div>
        <div className="clearfix mt">
          <button
            onClick={() => that.setState({ nrn: NRN.Notification })}
            className="button button-green button-thin"
          >
            Naprej
          </button>
          <button
            onClick={() => that.switchToVehicleOccupancy(that)}
            className="button button-green button-thin ml8"
          >
              Zasedenost vozil
          </button>
          {/*
          <button
            onClick={() => that.newDocument(that)}
            className="ml8 button button-green button-thin"
            title="Nova rezervacija"
          >
            Nova rezerv.
          </button>
          */}
          {/*that.state.document.status !== DocumentStatusInserted ? (
            <button
              disabled={!that.isNewDocumentPossible(that, that.state.document)}
              onClick={() => that.insertDocument(that, DocumentStatusSaved)}
              className="ml button button-green button-thin"
            >
              Shrani
            </button>
          ) : null*/}
        </div>
      </>
    );
  }

  public toLocalString(date: Date): string {
    const tzoffset: number = date.getTimezoneOffset() * 60000;
    return new Date((new Date(date)).getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
  }

  public async filterDocuments(
    type:
      | 'departure'
      | 'arrival'
      | 'only-company-vehicles'
      | 'company'
      | 'user'
      | 'order'
      | 'project'
      | 'plate'
      | 'status',
    value?: Date,
    values?: string,
  ) {
    const departure: Date = type === 'departure' ? value : this.state.fDep;
    const arrival: Date = type === 'arrival' ? value : this.state.fArr;

    if (type === 'departure' || type === 'arrival') {
        if (arrival < departure) {
            if (ALERT_SERVER_REQUESTS) alert(`INFO:\nExit of func: filterDocuments.\n\ndeparture=${departure.toDateString()}   >   arrival=${arrival.toDateString()})`);

            if (this.state.navigation === Navigation.AnalyticsUser) {
                this.setState({ pNum13: 0, fDep: departure, fArr: arrival, analyticsUser: [] });
            }
            else {
                this.setState({ pNum12: 0, fDep: departure, fArr: arrival, analyticsData: [] });
            }

            return;
        }
    }

    const companyVehicle: boolean =
      type === 'only-company-vehicles'
        ? !this.state.fCompanyVehicle
        : this.state.fCompanyVehicle;

    const companyuuidname: string = type === 'company' ? values : this.state.FCompanyuuid;
    const companyuuid: string = type === 'company' ? this.getCompanyUuid(values) : this.state.fCompanyuuid;

    const useruuidname: string = type === 'user' ? values : this.state.FUseruuid;
    const useruuid: string = type === 'user' ? this.getUserUuid(values) : this.state.fUseruuid;

    const orderuuidname: string = type === 'order' ? values : this.state.FOrderuuid;
    const orderuuid: string = type === 'order' ? this.getUserUuid(values) : this.state.fOrderuuid;

    const projectuuidname: string = type === 'project' ? values : this.state.FProjectuuid;
    const projectuuid: string = type === 'project' ? this.getProjectUuid(values) : this.state.fProjectuuid;

    const vehicleuuidplate: string = type === 'plate' ? values : this.state.FVehicleuuid;
    const vehicleuuid: string = type === 'plate' ? this.getLicensePlateUuid(values) : this.state.fVehicleuuid;

    const status = type === 'status' ? values : this.state.fStatus

    const params: FilterDocumentsParams = {
      token: this.props.userData.token,
      departure: departure ? this.toLocalString(departure) : undefined,
      arrival: arrival ? this.toLocalString(arrival) : undefined,
      companyVehicle,
      companyuuid,
      useruuid: this.state.navigation === Navigation.AnalyticsUser ? this.props.userData.uuid : useruuid,
      orderuuid: this.state.navigation === Navigation.AnalyticsUser ? '' : orderuuid,
      vehicleuuid,
      projectuuid,
      status,
      analyticsUser: this.state.navigation === Navigation.AnalyticsUser
    };
    params['DEV'] = DEV

    if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: filterDocuments)');

    const response = await axios.get(analytics, { params });

    if (response && response.data && response.data.resolved) {
      if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: filterDocuments)');
      if (type === 'status') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fStatus: status, analyticsUser: response.data.data });
        } else {
          this.setState({ pNum12: 0, fStatus: status, analyticsData: response.data.data });
        }
      } else if (type === 'departure') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fDep: departure, analyticsUser: response.data.data });
        } else {
          this.setState({ pNum12: 0, fDep: departure, analyticsData: response.data.data });
        }
      } else if (type === 'arrival') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fArr: arrival, analyticsUser: response.data.data });
        } else {
          this.setState({ pNum12: 0, fArr: arrival, analyticsData: response.data.data });
        }
      } else if (type === 'only-company-vehicles') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fCompanyVehicle: companyVehicle, fVehicleuuid: '', analyticsUser: response.data.data });
        } else {
          this.setState({
            pNum12: 0,
            fCompanyVehicle: companyVehicle,
            fVehicleuuid: '',
            analyticsData: response.data.data,
          });
        }
      } else if (type === 'company') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fCompanyuuid: companyuuid, FCompanyuuid: companyuuidname, analyticsUser: response.data.data });
        } else {
          this.setState({
            pNum12: 0,
            fCompanyuuid: companyuuid,
            FCompanyuuid: companyuuidname,
            analyticsData: response.data.data,
          });
        }
      } else if (type === 'user') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fUseruuid: useruuid, FUseruuid: useruuidname, analyticsUser: response.data.data });
        } else {
          this.setState({ pNum12: 0, fUseruuid: useruuid, FUseruuid: useruuidname, analyticsData: response.data.data });
        }
      } else if (type === 'order') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fOrderuuid: orderuuid, FOrderuuid: orderuuidname, analyticsUser: response.data.data });
        } else {
          this.setState({ pNum12: 0, fOrderuuid: orderuuid, FOrderuuid: orderuuidname, analyticsData: response.data.data });
        }
      } else if (type === 'project') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fProjectuuid: projectuuid, FProjectuuid: projectuuidname, analyticsUser: response.data.data });
        } else {
          this.setState({ pNum12: 0, fProjectuuid: projectuuid, FProjectuuid: projectuuidname, analyticsData: response.data.data });
        }
      } else if (type === 'plate') {
        if (this.state.navigation === Navigation.AnalyticsUser) {
          this.setState({ pNum13: 0, fVehicleuuid: vehicleuuid, FVehicleuuid: vehicleuuidplate, analyticsUser: response.data.data });
        } else {
          this.setState({
            pNum12: 0,
            fVehicleuuid: vehicleuuid,
            FVehicleuuid: vehicleuuidplate,
            analyticsData: response.data.data,
          });
        }
      }
    }
    else if (response && response.data && response.data.resolved === false && response.data.message) {
        if (ALERT_SERVER_REQUESTS) alert(`Error: Failed response from server! (func: filterDocuments)\nmsg: ${response.data.message}`);
    }
    else {
        if (ALERT_SERVER_REQUESTS) alert('Error: Failed response from server! (func: filterDocuments)');
    }
  }

  public switchToAddingPoints(that) {
    that.setState({vehicleOccupancy: false, addingPoints: true, viewingCurrentMonth: false})
  }

  public async switchToViewingCurrentMonth(that) {
    const params = {
      token: that.props.userData.token,
      useruuid: that.props.userData.uuid,
      type: 'Analytics'
    }
    if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: switchToAddingPoints)');
    const response = await axios.get(documentRoute, { params });
    if (response && response.data && response.data.resolved) {
      if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: switchToAddingPoints)');
      that.setState({
        viewingCurrentMonth: true,
        vehicleOccupancy: false,
        addingPoints: false,
        viewingCurrentMonthData: response.data.data,
      });
    }
    else if (response && response.data && response.data.resolved === false && response.data.message) {
        if (ALERT_SERVER_REQUESTS) alert(`Error: Failed response from server! (func: switchToAddingPoints)\nmsg: ${response.data.message}`);
    }
    else {
        if (ALERT_SERVER_REQUESTS) alert('Error: Failed response from server! (func: switchToAddingPoints)');
    }
  }

  public async switchToVehicleOccupancy(
    that,
    type?: 'departure' | 'arrival',
    value?: Date,
  ) {
    const departure: Date = type === 'departure' ? value : that.state.fDeparture;
    const arrival: Date = type === 'arrival' ? value : that.state.fArrival;

    if (type === 'departure') {
      departure.setHours(12)
    } else if (type === 'arrival') {
      arrival.setHours(12)
    }

    const params: VehicleOccupancyParams = {
      token: that.props.userData.token,
      departure: departure ? that.toLocalString(departure) : undefined,
      arrival: arrival ? that.toLocalString(arrival) : undefined,
    };
    const response = await axios.get(reservation, { params });
    if (response && response.data && response.data.resolved) {
      if (type === 'departure') {
        that.setState({
          fDeparture: departure,
          reservations: response.data.data,
        });
      } else if (type === 'arrival') {
        that.setState({ fArrival: arrival, reservations: response.data.data });
      } else {
        that.setState({
          viewingCurrentMonth: false,
          vehicleOccupancy: true,
          addingPoints: false,
          reservations: response.data.data,
        });
      }
    }
  }

  public getReservationNotification(that): JSX.Element {
    return (
      <>
        <div className="title mt pr">
          Obveščanje
          {that.showStatus(
            that.getStatus(that.state.document.status),
            that.state.document.status,
            'pa',
          )}
        </div>
        <hr />
        <div className="clearfix mt">
          <div className="tar before-input fl">Nadrejeni:</div>
          <select
            onChange={e =>
              that.setNewDocument(that, 'orderuuidPlanned', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.orderuuidPlanned}
          >
            {that.getUserOptions(undefined, undefined, true)}
          </select>
        </div>
        <div className="clearfix mt">
          <button
            onClick={() => that.setState({ nrn: NRN.PathInformation })}
            className="button button-green button-thin"
          >
            Naprej
          </button>
          {/*
          <button
            disabled={!that.isNewDocumentPossible(that, that.state.document)}
            onClick={() => that.insertDocument(that, DocumentStatusInserted)}
            className="ml button button-green button-thin"
          >
            {that.state.document.status === DocumentStatusInserted
              ? 'Popravi rezervacijo'
              : 'Oddaj rezervacijo'}
          </button>
          <button
            onClick={() => that.cancelDocument(that, that.state.document.uuid)}
            className="ml button button-red button-thin"
          >
            {that.state.document.status === DocumentStatusInserted ? 'Izbriši' : 'Prekliči'}
          </button>
            */}
        </div>
      </>
    );
  }

  public getReservationPathInformation(that): JSX.Element {
    return (
      <>
        <div className="title mt pr">
          Pot
          {that.showStatus(
            that.getStatus(that.state.document.status),
            that.state.document.status,
            'pa',
          )}
        </div>
        <hr />
        <div className="mt">{that.getPath(that)}</div>
        <div className="clearfix">
          <button
            title = "Shrani rezervacijo"
            disabled={!that.isNewDocumentPossible(that, that.state.document)}
            onClick={() => that.insertDocument(that, DocumentStatusUpdated)}
            className="button button-green button-thin"
          >
            Shrani
            {/*that.state.document.status === DocumentStatusInserted
              ? 'Popravi rezervacijo'
            : 'Oddaj rezervacijo'*/}
          </button>
          {/*that.state.document.status !== DocumentStatusInserted ? (
            <button
              disabled={!that.isNewDocumentPossible(that, that.state.document)}
              onClick={() => that.insertDocument(that, DocumentStatusSaved)}
              className="ml8 button button-green button-thin"
            >
              Shrani
            </button>
          ) : null*/}

          <button
            title = "Izbriši rezervacijo"
            onClick={() => that.cancelDocument(that, that.state.document.uuid)}
            className="ml4 button button-red button-thin"
          >
            Izbriši
          </button>
          <button
              title = "Vnesi novo točko"
              onClick={() => that.switchToAddingPoints(that)}
              className="button button-green button-thin ml4"
          >
            T+
          </button>
          <button
            disabled={!that.isNewDocumentPossible(that, that.state.document, true)}
            onClick={() => that.insertDocument(that, DocumentStatusUpdatedPlus)}
            className="button button-green button-thin ml4"
          >
            Oddaj nadrejenemu
          </button>

        </div>
      </>
    );
  }

  public getNewReservation(that): JSX.Element {
    return that.state.document ? (
      <div>
        <div className="new-document-navigation clearfix">
          {that.getNewReservationNavigation(that)}
        </div>

        {that.state.vehicleOccupancy
          ? that.getVehicleOccupancy(that) :
          that.state.addingPoints
          ? that.getAddingPoints(that)
          : that.state.nrn === NRN.BasicInformation
          ? that.getReservationBasicInformation(that)
          : that.state.nrn === NRN.Notification
          ? that.getReservationNotification(that)
          : that.state.nrn === NRN.PathInformation
          ? that.getReservationPathInformation(that)
          : null}
      </div>
    ) : (
      <div />
    );
  }

  public formatDate(date: string): string {
    return date ?(
      date.substring(8, 10) +
      '/' +
      date.substring(5, 7) +
      '/' +
      date.substring(0, 4) +
      date.substring(10, 16)
    ) : '';
  }

  public getStatus(status: DocumentStatus) {
    return DocumentStatusString[status];
  }

  public async confirmDocument(that, uuid: string, newStatus: DocumentStatus, gotoNotification?: boolean, noCheck?: boolean) {
    if (newStatus === undefined) {
      alert ('Novi status ne sme biti undefined!')
    } else if (noCheck || confirm(newStatus === DocumentStatusUpdatedPlus ? 'Ali ste prepričani, da želite potni nalog oddati nadrejenemu?' :
    'Ali ste prepričani, da želite potrditi potni nalog?')) {
      if (that.state.documents) {
        for (let i = 0; i < that.state.documents.length; i++) {
          if (that.state.documents[i].uuid === uuid) {
            const response = await axios.put(status, {
              token: that.props.userData.token,
              uuid,
              status: newStatus,
            });
            if (!noCheck && response && response.data && response.data.resolved) {
              const documents: DocumentData[] = Object.assign(
                [],
                that.state.documents,
              );
              const Documents: number[] = Object.assign([], that.state.Documents)
              documents.splice(i, 1)
              Documents.splice(i, 1)
              if (newStatus === DocumentStatusUpdatedPlus || gotoNotification) {
                that.setState({ document: that.initDocument(), Documents, documents, attachment: -1, tabIndex: TabIndex.Notifications, lastTabIndex: TabIndex.Reservation });
              } else {
                that.setState({ Documents, documents });
              }
            }
            break;
          }
        }
      }
    }
  }

  public getHoursSum(): number {
    let sum: number = 0
    if (this.state.kedata && this.state.kedata.userWorking) {
      for (const item of this.state.kedata.userWorking) {
        if (!item.hours || item.hours.length === 0) {continue}
        sum += parseInt(item.hours)
      }
    }
    return sum
  }

  public getHoursPercent(value: number, sum: number): number {
    return parseFloat((100 / sum * value).toFixed(1))
  }

  public getHoursPercentage(index: number): string {
    if (this.state.kedata && this.state.kedata.userWorking) {
      if (!this.state.kedata.userWorking[index] || !this.state.kedata.userWorking[index].hours || this.state.kedata.userWorking[index].hours.length === 0) {
        if (this.state.kedata.userWorking.length === 1) {
          return '100 %'
        }
        return '0 %'
      }
      const value: number = parseInt(this.state.kedata.userWorking[index].hours)
      const sum: number = this.getHoursSum()
      if (sum === 0) {return '-'}
      let Value: string = (100/sum * value).toFixed(1)
      if (Value.endsWith('.0')) {Value = Value.substring(0, Value.length - 2)}
      return  Value + ' %'
    }
    return ''
  }

  public addUserWorking() {
    const kedata: Kedata = Object.assign({}, this.state.kedata)
    const kedataold: Kedata = Object.assign({}, this.state.kedataold)
    kedata.userWorking.push({companyuuid: undefined, companyuuid_: '', hours: ''})
    kedataold.userWorking.push({companyuuid: undefined, companyuuid_: '', hours: ''})
    this.setState({kedata, kedataold})
  }

  public removeUserWorking(index: number) {
    const kedata: Kedata = Object.assign({}, this.state.kedata)
    const kedataold: Kedata = Object.assign({}, this.state.kedataold)
    kedata.userWorking.splice(index, 1)
    kedataold.userWorking.splice(index, 1)
    this.setState({kedata, kedataold})
  }

  public async editKE(field: 'address'|'addresstemp'|'birthdate'|'email'|'email2'|'title'|'emso'|'endwork'|'firstname'|'lastname'
  |'medical'|'workatheight'|'permitnumber'|'additionalpermits'|'registered'|'sectionuuid'|'sex'|'specialpermits'|'startwork'|'taxnumber'|'telephone2'|'userWorking'
  |'workall'|'workhere'|'working'|'workingbefore1'|'workingbefore2'|'workingbefore3'|'workingstatus'|'workposition'|'worksecurity'
  , value: string, valueDate?: Date, valueNumber?: number, field2?: 'companyuuid_'|'hours') {
    const kedata: Kedata = Object.assign({}, this.state.kedata)
    if (field === 'lastname' || field === 'firstname' || field === 'address' || field === 'addresstemp' || field === 'emso'
    || field === 'taxnumber' || field === 'permitnumber' || field === 'additionalpermits' || field === 'email2' || field === 'workingbefore1' || field === 'workingbefore2'
    || field === 'workingbefore3' || field === 'sectionuuid' || field === 'telephone2' || field === 'email') {
      if (value.length === 0) {
        kedata[field] = null
      } else {
        kedata[field] = value
      }
    } else if (field === 'userWorking') {
      if (field2 === 'hours') {
        if (isNaN(value as any) || value.indexOf('-') !== -1 || value.indexOf('.') !== -1) {
          return
        }
      }
      kedata.userWorking[valueNumber][field2] = value
      if (field2 === 'companyuuid_') {
        kedata.userWorking[valueNumber].companyuuid = this.getCompanyUuid(value)
        if (kedata.userWorking[valueNumber].companyuuid.length === 0) {
          kedata.userWorking[valueNumber].companyuuid = undefined
        }
      }
    } else if (field === 'birthdate' || field === 'medical' || field === 'workatheight' || field === 'worksecurity' || field === 'startwork' || field === 'endwork') {
      kedata[field] = valueDate
    } else if (field === 'workingstatus' || field === 'sex') {
      kedata[field] = valueNumber
    }

    this.setState({kedata})
  }

  public async updateKE() {
    const data: Kedata = Object.assign({}, this.state.kedata)

    data.workingbefore = ''
    if (data.workingbefore1 && data.workingbefore1.length) {
      if (data.workingbefore1.length === 1) {data.workingbefore += '0'}
      data.workingbefore += data.workingbefore1
    } else {data.workingbefore += '00'}
    data.workingbefore += '.'

    if (data.workingbefore2 && data.workingbefore2.length) {
      if (data.workingbefore2.length === 1) {data.workingbefore += '0'}
      data.workingbefore += data.workingbefore2
    } else {data.workingbefore += '00'}
    data.workingbefore += '.'

    if (data.workingbefore3 && data.workingbefore3.length) {
      if (data.workingbefore3.length === 1) {data.workingbefore += '0'}
      data.workingbefore += data.workingbefore3
    } else {data.workingbefore += '00'}

    if (data.birthdate) {
      data.birthdate_ = this.toLocalString(data.birthdate).substring(0,10)
    }

    if (data.medical) {
      data.medical_ = this.toLocalString(data.medical).substring(0,10) //yyyy-mm-dd
      data.medical__ = data.medical_.substring(8,10) + '.' + data.medical_.substring(5,7) + '.' + data.medical_.substring(0,4); //dd.mm.yyyy
    }
    else {
      data.medical_ = null;
      data.medical__ = null;
    }

    if (data.workatheight) {
      data.workatheight_ = this.toLocalString(data.workatheight).substring(0,10); //yyyy-mm-dd
      data.workatheight__ = data.workatheight_.substring(8,10) + '.' + data.workatheight_.substring(5,7) + '.' + data.workatheight_.substring(0,4); //dd.mm.yyyy
    }
    else {
      data.workatheight_ = null;
      data.workatheight__ = null;
    }

    if (data.worksecurity) {
      data.worksecurity_ = this.toLocalString(data.worksecurity).substring(0,10); //yyyy-mm-dd
      data.worksecurity__ = data.worksecurity_.substring(8,10) + '.' + data.worksecurity_.substring(5,7) + '.' + data.worksecurity_.substring(0,4); //dd.mm.yyyy
    }
    else {
      data.worksecurity_ = null;
      data.worksecurity__ = null;
    }

    if (data.startwork) {
      data.startwork_ = this.toLocalString(data.startwork).substring(0,10)
    }

    if (data.endwork) {
      data.endwork_ = this.toLocalString(data.endwork).substring(0,10)
    }

    try {
      const response = await axios.put(user, {data, token: this.props.userData.token})
      if (response && response.data && response.data.resolved) {
        alert('Kadrovska evidenca je bila uspešno shranjena!')
        this.setState({kedataold: JSON.parse(JSON.stringify(this.state.kedata)), kedataolduserworking: this.state.kedata.userWorking ? this.state.kedata.userWorking.length : 0})
      }
      else {
        alert('Napake pri shranjevanju kadrovske evidence!\nProsim ponovite vnose.');
      }
    }
    catch (error) {
        alert(`Error: (func: updateKE)\n\nmsg: ${error}`);
    }
  }

  public dateDiff(startingDate, endingDate): {days: number, months: number, years: number} {
    let startDate = new Date((new Date(startingDate)).toISOString().substring(0, 10));
    if (!endingDate) {
        endingDate = new Date().toISOString().substring(0, 10);
    }
    let endDate = new Date(endingDate);
    if (startDate > endDate) {
        let swap = startDate;
        startDate = endDate;
        endDate = swap;
    }
    let startYear = startDate.getFullYear();
    let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth(); //getMonth() returns the month as a zero-based value (where zero indicates the first month of the year)
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate(); //getDate() returns the day of the month
    if (dayDiff < 0) {
        if (monthDiff > 0) {
            monthDiff--;
        }
        else { //a case when monthDiff = 0
            yearDiff--;
            monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
    }
    return {years: yearDiff, months: monthDiff, days: dayDiff}
  }

  public convertSymbolToString(s: Symbol): string {
    return s.toString().replace('Symbol(' ,'').replace('))' ,')').replace(')_)' ,')_')
  }

  public async exportKE(csv?: boolean, xlsx?: boolean) {
    const response = await axios.get(user, {params: {token: this.props.userData.token, DEV}})
    if (response && response.data && response.data.data && response.data.resolved) {
      const data: KedataAll[] = []
      const Now = new Date()
      const now: string = this.toLocalString(Now).substring(0,10)

      for (let i: number = 0; i < response.data.data.length; i++) {
        response.data.data[i].education = this.getCodeRegisterValue(education, response.data.data[i].education, true)
        response.data.data[i].section = this.getCodeRegisterValue(sections, response.data.data[i].section, true)
        response.data.data[i].sex = this.getCodeRegisterValue(sex, response.data.data[i].sex, true)
        response.data.data[i].specialpermits = this.getCodeRegisterValue(specialPermits, response.data.data[i].specialpermits, true)
        response.data.data[i].usertype = this.getCodeRegisterValue(usertype, response.data.data[i].usertype, true)
        response.data.data[i].working = this.getCodeRegisterValue(working, response.data.data[i].working, true)
        response.data.data[i].workingstatus = this.getCodeRegisterValue(workingStatus, response.data.data[i].workingstatus, true)
        response.data.data[i].workposition = this.getCodeRegisterValue(workPosition, response.data.data[i].workposition, true)
        response.data.data[i].title = this.getCodeRegisterValue(title, response.data.data[i].title, true)

        let sumHoursPerWeek: number = 0
        const hoursPerWeek: number[] = new Array(this.state.companies ? this.state.companies.length : 3)
        const companyN: number[] = new Array(hoursPerWeek.length)
        const companyName: string[] = new Array(hoursPerWeek.length)
        for (let h = 0; h < hoursPerWeek.length; h++) {
          companyN[h] = 0
          hoursPerWeek[h] = 0
          if (this.state.companies && h < this.state.companies.length) {
            companyN[h] = +this.state.companies[h].n
            companyName[h] = this.state.companies[h].name
          }
        }
        // let hoursPerWeek1: number = 0 // IRGO
        // let hoursPerWeek2: number = 0 // IC
        // let hoursPerWeek3: number = 0 // GRI
        // console.log(this.state.companies)

        for (let j: number = 0; j < response.data.data[i].userWorking.length; j++) {
          // console.log(response.data.data[i].userWorking[j])
          response.data.data[i].userWorking[j]['Odstotek ur'] = 0
          response.data.data[i].userWorking[j]['Podjetje'] = this.getCompanyName(response.data.data[i].userWorking[j].companyuuid)
          delete(response.data.data[i].userWorking[j].companyuuid)
          if (response.data.data[i].userWorking[j].hours) {
            response.data.data[i].userWorking[j]['Št. ur'] = parseInt(response.data.data[i].userWorking[j].hours)
            sumHoursPerWeek += response.data.data[i].userWorking[j]['Št. ur']
            for (let h = 0; h < hoursPerWeek.length; h++) {
              if (response.data.data[i].userWorking[j]['Podjetje'] === companyName[h]) {
                hoursPerWeek[h] = response.data.data[i].userWorking[j]['Št. ur']
              }
            }


            // if (response.data.data[i].userWorking[j]['Podjetje'] === 'IRGO') {hoursPerWeek1 = response.data.data[i].userWorking[j]['Št. ur']}
            // if (response.data.data[i].userWorking[j]['Podjetje'] === 'IRGO Consulting d.o.o.') {hoursPerWeek2 = response.data.data[i].userWorking[j]['Št. ur']}
            // if (response.data.data[i].userWorking[j]['Podjetje'] === 'GR Investicije d.o.o.') {hoursPerWeek3 = response.data.data[i].userWorking[j]['Št. ur']}
          } else {
            response.data.data[i].userWorking[j]['Št. ur'] = 0
          }
          delete(response.data.data[i].userWorking[j].hours)
        }
        if (sumHoursPerWeek === 0) {sumHoursPerWeek = null}
        for (let h = 0; h < hoursPerWeek.length; h++) {
          if (hoursPerWeek[h] === 0) {hoursPerWeek[h] = null}
        }
        // if (hoursPerWeek1 === 0) {hoursPerWeek1 = null}
        // if (hoursPerWeek2 === 0) {hoursPerWeek2 = null}
        // if (hoursPerWeek3 === 0) {hoursPerWeek3 = null}

        if (sumHoursPerWeek) {
          for (let j: number = 0; j < response.data.data[i].userWorking.length; j++) {
            response.data.data[i].userWorking[j]['Odstotek ur'] = this.getHoursPercent(response.data.data[i].userWorking[j]['Št. ur'], sumHoursPerWeek)
          }
        }

        let wbd: number = 0
        let wbm: number = 0
        let wby: number = 0

        if (response.data.data[i].workingbefore) {
          response.data.data[i].workingbefore1 = response.data.data[i].workingbefore.substring(0,2)
          wbd = parseInt(response.data.data[i].workingbefore1)
          response.data.data[i].workingbefore2 = response.data.data[i].workingbefore.substring(3,5)
          wbm = parseInt(response.data.data[i].workingbefore2)
          response.data.data[i].workingbefore3 = response.data.data[i].workingbefore.substring(6,8)
          wby = parseInt(response.data.data[i].workingbefore3)
        }

        if (response.data.data[i].birthdate) {
          response.data.data[i].birthdate_ = response.data.data[i].birthdate.substring(8,10) + '.' + response.data.data[i].birthdate.substring(5,7) + '.' + response.data.data[i].birthdate.substring(0,4)
          response.data.data[i].Birthdate = new Date(response.data.data[i].birthdate)
          const md: string = this.toLocalString(response.data.data[i].Birthdate).substring(5,10)
          response.data.data[i].birthdate__ = parseInt(now.substring(0,4)) - response.data.data[i].Birthdate.getFullYear()
          if (md > now.substring(5, 10)) {
            response.data.data[i].birthdate__--
          }
        }
        if (response.data.data[i].startwork) {
          response.data.data[i].startwork_ = response.data.data[i].startwork.substring(8,10) + '.' + response.data.data[i].startwork.substring(5,7) + '.' + response.data.data[i].startwork.substring(0,4)
          response.data.data[i].Startwork = new Date(response.data.data[i].startwork)

          const datediff = this.dateDiff(response.data.data[i].Startwork, Now)
          response.data.data[i].workhere1 = (datediff.days < 10 ? '0' : '') + datediff.days
          response.data.data[i].workhere2 = (datediff.months < 10 ? '0' : '') + datediff.months
          response.data.data[i].workhere3 = (datediff.years < 10 ? '0' : '') + datediff.years
          wbd += datediff.days
          wbm += datediff.months
          wby += datediff.years
          if (wbd > 30) {
            wbm++
            wbd-=30
          }
          if (wbm > 11) {
            wby++
            wbm-=12
          }
          response.data.data[i].workall1 = (wbd < 10 ? '0' : '') + wbd
          response.data.data[i].workall2 = (wbm < 10 ? '0' : '') + wbm
          response.data.data[i].workall3 = (wby < 10 ? '0' : '') + wby
        }

        if (response.data.data[i].endwork) {
          response.data.data[i].endwork_ = response.data.data[i].endwork.substring(8,10) + '.' + response.data.data[i].endwork.substring(5,7) + '.' + response.data.data[i].endwork.substring(0,4)
          response.data.data[i].EndWork = new Date(response.data.data[i].endwork)
        }

        if (response.data.data[i].medical) {
          response.data.data[i].medical = new Date(response.data.data[i].medical); //Date
          response.data.data[i].medical_ = this.toLocalString(response.data.data[i].medical).substring(0,10); //yyyy-mm-dd
          response.data.data[i].medical__ = response.data.data[i].medical_.substring(8,10) + '.' + response.data.data[i].medical_.substring(5,7) + '.' + response.data.data[i].medical_.substring(0,4); //dd.mm.yyyy

          //response.data.data[i].Medical = new Date(response.data.data[i].medical)
          //response.data.data[i].medical____ = response.data.data[i].medical.substring(8,10) + '.' + response.data.data[i].medical.substring(5,7) + '.' + response.data.data[i].medical.substring(0,4)
          //response.data.data[i].medical_ = (parseInt(this.toLocalString(response.data.data[i].Medical).substring(0,4)) + 3).toString() + this.toLocalString(response.data.data[i].Medical).substring(4,10); //validity of 3 years
          //response.data.data[i].medical___ = response.data.data[i].medical_ >= now
          //response.data.data[i].medical__ = response.data.data[i].medical_.substring(8,10) + '.' + response.data.data[i].medical_.substring(5,7) + '.' + response.data.data[i].medical_.substring(0,4)
        }

        if (response.data.data[i].workatheight) {
          response.data.data[i].workatheight = new Date(response.data.data[i].workatheight); //Date
          response.data.data[i].workatheight_ = this.toLocalString(response.data.data[i].workatheight).substring(0,10); //yyyy-mm-dd
          response.data.data[i].workatheight__ = response.data.data[i].workatheight_.substring(8,10) + '.' + response.data.data[i].workatheight_.substring(5,7) + '.' + response.data.data[i].workatheight_.substring(0,4); //dd.mm.yyyy


          //response.data.data[i].Workatheight = new Date(response.data.data[i].workatheight)
          //response.data.data[i].workatheight____ = response.data.data[i].workatheight.substring(8,10) + '.' + response.data.data[i].workatheight.substring(5,7) + '.' + response.data.data[i].workatheight.substring(0,4)
          //response.data.data[i].workatheight_ = (parseInt(this.toLocalString(response.data.data[i].Workatheight).substring(0,4)) + 5).toString() + this.toLocalString(response.data.data[i].Workatheight).substring(4,10); //validity of 5 years
          //response.data.data[i].workatheight___ = response.data.data[i].workatheight_ >= now
          //response.data.data[i].workatheight__ = response.data.data[i].workatheight_.substring(8,10) + '.' + response.data.data[i].workatheight_.substring(5,7) + '.' + response.data.data[i].workatheight_.substring(0,4)
        }

        if (response.data.data[i].worksecurity) {
          response.data.data[i].worksecurity = new Date(response.data.data[i].worksecurity); //Date
          response.data.data[i].worksecurity_ = this.toLocalString(response.data.data[i].worksecurity).substring(0,10); //yyyy-mm-dd
          response.data.data[i].worksecurity__ = response.data.data[i].worksecurity_.substring(8,10) + '.' + response.data.data[i].worksecurity_.substring(5,7) + '.' + response.data.data[i].worksecurity_.substring(0,4); //dd.mm.yyyy


          //response.data.data[i].Worksecurity = new Date(response.data.data[i].worksecurity)
          //response.data.data[i].worksecurity____ = response.data.data[i].worksecurity.substring(8,10) + '.' + response.data.data[i].worksecurity.substring(5,7) + '.' + response.data.data[i].worksecurity.substring(0,4)
          //response.data.data[i].worksecurity_ = (parseInt(this.toLocalString(response.data.data[i].Worksecurity).substring(0,4)) + 2).toString() + this.toLocalString(response.data.data[i].Worksecurity).substring(4,10); //validity of 2 years
          //response.data.data[i].worksecurity___ = response.data.data[i].worksecurity_ >= now
          //response.data.data[i].worksecurity__ = response.data.data[i].worksecurity_.substring(8,10) + '.' + response.data.data[i].worksecurity_.substring(5,7) + '.' + response.data.data[i].worksecurity_.substring(0,4)
        }

        data.push({
          'Stalni naslov': response.data.data[i].address,
          'Začasni naslov': response.data.data[i].addresstemp,
          'Datum rojstva': response.data.data[i].birthdate_ ? response.data.data[i].birthdate_ : null,
          'Datum rojstva_': response.data.data[i].birthdate,
          'Starost': response.data.data[i].birthdate__ ? response.data.data[i].birthdate__ : null,
          'Izobrazba': response.data.data[i].education,
          'Elektronski naslov': response.data.data[i].email2,
          'EMŠO': response.data.data[i].emso,
          'Zaključek dela': response.data.data[i].endwork_ ? response.data.data[i].endwork_ : null,
          'Zaključek dela_': response.data.data[i].endwork,
          'Ime': response.data.data[i].firstname,
          'Priimek': response.data.data[i].lastname,
          'Veljavnost zdravniškega pregleda': response.data.data[i].medical__ ? response.data.data[i].medical__ : null,  //for output: dd.mm.yyyy
          'Veljavnost zdravniškega pregleda_': response.data.data[i].medical_ ? response.data.data[i].medical_ : null,   //for sorting: yyyy-mm-dd
          //'Zdravniški pregled': response.data.data[i].medical___ === true ? yesno[0].value : response.data.data[i].medical___ === false ? yesno[1].value : null,
          'Veljavnost dela na višini': response.data.data[i].workatheight__ ? response.data.data[i].workatheight__ : null, //for output: dd.mm.yyyy
          'Veljavnost dela na višini_': response.data.data[i].workatheight_ ? response.data.data[i].workatheight_ : null,  //for sorting: yyyy-mm-dd
          //'Delo na višini': response.data.data[i].workatheight___ === true ? yesno[0].value : response.data.data[i].workatheight___ === false ? yesno[1].value : null,
          'Naziv': response.data.data[i].title,
          'Št. pooblastila': response.data.data[i].permitnumber,
           // 'Čas registracije': response.data.data[i].registered.substring(11,19),
           // 'Datum registracije': response.data.data[i].registered.substring(0,10),
          'Dodatna dovoljenja': response.data.data[i].additionalpermits,
          'Oddelek': response.data.data[i].section,
          'Spol': response.data.data[i].sex,
          'Posebna pooblastila': response.data.data[i].specialpermits,
          [this.convertSymbolToString(startWork)]: response.data.data[i].startwork_ ? response.data.data[i].startwork_ : null,
          [this.convertSymbolToString(startWork_)]: response.data.data[i].startwork,
          'Davčna št.': response.data.data[i].taxnumber,
          'Telefonska št.': response.data.data[i].telephone2,
          // 'Podjetje zaposlitve': response.data.data[i].userWorking,
          'Uporabniški nivo': response.data.data[i].usertype,
          'Vrsta zaposlitve': response.data.data[i].working,
          'Status zaposlitve': response.data.data[i].workingstatus,
          'Delovna doba - predhodna': response.data.data[i].workingbefore ? response.data.data[i].workingbefore.substring(6,8) + '-' + response.data.data[i].workingbefore.substring(3,5) + '-' + response.data.data[i].workingbefore.substring(0,2) : null,
          [this.convertSymbolToString(workHere)]: response.data.data[i].workhere1 && response.data.data[i].workhere2 && response.data.data[i].workhere3 ?
          response.data.data[i].workhere3 + '-' + response.data.data[i].workhere2 + '-' + response.data.data[i].workhere1 : null,
          'Delovna doba - skupna': response.data.data[i].workall1 && response.data.data[i].workall2 && response.data.data[i].workall3 ?
          response.data.data[i].workall3 + '-' + response.data.data[i].workall2 + '-' + response.data.data[i].workall1 : null,

          'Delovno mesto': response.data.data[i].workposition,
          'Veljavnost izpita iz varstva pri delu': response.data.data[i].worksecurity__ ? response.data.data[i].worksecurity__ : null,
          'Veljavnost izpita iz varstva pri delu_': response.data.data[i].worksecurity_ ? response.data.data[i].worksecurity_ : null,
          //'Izpit iz varstva pri delu': response.data.data[i].worksecurity___ === true ? yesno[0].value : response.data.data[i].worksecurity___ === false ? yesno[1].value : null,
          [this.convertSymbolToString(hoursWeekCompany1)]: hoursPerWeek.length > 0 ? hoursPerWeek[0] : null,
          [this.convertSymbolToString(hoursWeekCompany2)]: hoursPerWeek.length > 1 ? hoursPerWeek[1] : null,
          [this.convertSymbolToString(hoursWeekCompany3)]: hoursPerWeek.length > 2 ? hoursPerWeek[2] : null,
          'Skupno št. ur na teden': sumHoursPerWeek
        })
      }

      if (csv === undefined) {
        /* this is how initials are set, but we do not need it as it is saved in the database
        const keys_: string[] = Object.keys(data[0])
        const keys: string[] = []
        for (const item of keys_) {
          let Continue: boolean = false
          for (const Item of sortVariables) {
            if (Item === item) {
              Continue = true
              break
            }
          }
          if (!Continue) {keys.push(item)}
        }
        keys.sort((a,b) => a > b ? 1 : -1)

        const Keys: string[] = keys.slice(0,21)
        Keys.push(keys[keys.length - 1])
        Keys.push(...keys.slice(21, keys.length - 1))

        let variablesSelected: string[][] = Object.assign([], this.state.variablesSelected)
        if (variablesSelected.length === 0) {
          variablesSelected = new Array(Keys.length)
          for (let i: number = 0; i < variablesSelected.length; i++) {
            variablesSelected[i] = []
          }
        }

        const variablesNull: boolean[] = new Array(Keys.length)
        const Keys_: boolean[] = new Array(Keys.length)
        for (let i: number = 0; i < Keys_.length; i++) {
          variablesNull[i] = true
          let found: boolean = false
          for (let j: number = 0; j < this.state.variables.length; j++) {
            if (this.state.variables[j] === i) {
              found = true
              break
            }
          }
          Keys_[i] = found
        }

        const keysSearch: string[] = []

        for (let i: number = 0; i < Keys.length; i++) {
          let found: boolean = false
          for (let j: number = 0; j < specialVariables.length; j++) {
            if (Keys[i] === specialVariables[j]) {
              found = true
              keysSearch.push(sortVariables[j])
              break
            }
          }
          if(!found) {
            keysSearch.push(Keys[i])
          }
        }
        const keysType: string[] = []
        let arr: number = -1
        for (let i: number = 0; i < Keys.length; i++) {
          let value: string = 'string'
          if (Keys[i] === numberVariables[0] || Keys[i] === numberVariables[1]) {
            value = 'number'
          }

          if (Keys[i] === specialVariable2) {
            value = 'array'
            arr = i
          }
          keysType.push(value)
        }

        const keysType2: string[] = []
        if (arr !== -1) {
          for (let i: number = 0; i < allVariables2.length; i++) {
            let value: string = 'string'
            if (allVariables2[i] === numberVariables2[0] || allVariables2[i] === numberVariables2[1]) {
              value = 'number'
            }
            keysType2.push(value)
          }
        }

        const Keys2: boolean[] = new Array(allVariables2.length)
        for (let i: number = 0; i < Keys2.length; i++) {
          let found: boolean = false
          for (let j: number = 0; j < this.state.variables2.length; j++) {
            if (this.state.variables2[j] === i) {
              found = true
              break
            }
          }
          Keys2[i] = found
        }
*/
        this.setState({kedatall: data,
          // keysType2, Keys2, // to do later if
          // keys: Keys,
          // keysSearch,
          // keysType,
          // variablesSelected
          // Keys: Keys_, variablesNull,
        })
      } else {
        const now_: string = this.toLocalString(new Date());
        const date: string =
          now_.substring(8, 10) +
          '/' +
          now_.substring(5, 7) +
          '/' +
          now_.substring(0, 4) +
          ' ob ' +
          now_.substring(11, 16);
        if (xlsx) {
          const datas: any[][] = []
          const keys = Object.keys(data[0])
          const dataItem = []
          for (const key of keys) {
            if (!key.endsWith('_')) {
              dataItem.push(key)
            }
          }
          datas.push(dataItem)

          for (const item of data) {
            const dataitem = []
            for (const key of keys) {
              if (!key.endsWith('_')) {
                dataitem.push(item[key])
              }
            }
            datas.push(dataitem)
          }
          const workbook = new ExcelJS.Workbook();
          workbook.creator = 'Web';
          workbook.lastModifiedBy = 'Web';
          workbook.created = new Date();
          workbook.modified = new Date();
          workbook.lastPrinted = new Date();
          const title = 'Izvoz kadrovske evidence';
          const worksheet = workbook.addWorksheet(title);
          for (const item of datas) {
            worksheet.addRow(item);
          }
          const buffer = await workbook.xlsx.writeBuffer();
          this.download(undefined, EXCEL_TYPE, 'Izvoz kadrovske evidence' + date.replace(':', ' ').replace('/', ' ').replace('/', ' '), buffer)

        } else if (csv) {
          const parser = new Parser({quote: "'"});
          const csvText = parser.parse(data);
          this.download(
            csvText,
            'text/csv',
            'Izvoz kadrovske evidence ' +
              date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
          );
        } else {
          const json: string = JSON.stringify(data, null, 4);
          this.download(
            json,
            'application/json',
            'Izvoz kadrovske evidence ' +
              date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
          );
        }
      }
    }
    else if (response && response.data && response.data.resolved === false && response.data.message) {
        alert(`Error: Failed response from server! (func: exportKE)\nmsg: ${response.data.message}`);
    }
    else {
        alert(`Error: Failed response from server! (func: exportKE)`);
    }
  }

  public async openKE(uuid: string) {
    const params = {
      uuid,
      token: this.props.userData.token
    }
    const response = await axios.get(user, {params})

    if (response && response.data && response.data.data && response.data.resolved) {
      if (response.data.data.section) {
        response.data.data.section_ = this.getCodeRegisterValue(sections, response.data.data.section)
      } else {
        response.data.data.section_ = ''
      }

      if (response.data.data.education) {
        response.data.data.education_ = this.getCodeRegisterValue(education, response.data.data.education)
      } else {
        response.data.data.education_ = ''
      }

      if (response.data.data.title) {
        response.data.data.title_ = this.getCodeRegisterValue(title, response.data.data.title)
      } else {
        response.data.data.title_ = ''
      }


      if (response.data.data.working) {
        response.data.data.working_ = this.getCodeRegisterValue(working, response.data.data.working)
      } else {
        response.data.data.working_ = ''
      }

      if (response.data.data.workposition) {
        response.data.data.workposition_ = this.getCodeRegisterValue(workPosition, response.data.data.workposition)
      } else {
        response.data.data.workposition_ = ''
      }

      if (response.data.data.specialpermits) {
        response.data.data.specialpermits_ = this.getCodeRegisterValue(specialPermits, response.data.data.specialpermits)
      } else {
        response.data.data.specialpermits_ = ''
      }

      const Now = new Date()
      const now: string = this.toLocalString(Now).substring(0,10)
      let wbd: number = 0
      let wbm: number = 0
      let wby: number = 0

      if (response.data.data.workingbefore) {
        response.data.data.workingbefore1 = response.data.data.workingbefore.substring(0,2)
        wbd = parseInt(response.data.data.workingbefore1)
        response.data.data.workingbefore2 = response.data.data.workingbefore.substring(3,5)
        wbm = parseInt(response.data.data.workingbefore2)
        response.data.data.workingbefore3 = response.data.data.workingbefore.substring(6,8)
        wby = parseInt(response.data.data.workingbefore3)
      }

      if (response.data.data.birthdate) {
        response.data.data.birthdate = new Date(response.data.data.birthdate)
        const md: string = this.toLocalString(response.data.data.birthdate).substring(5,10)
        response.data.data.birthdate__ = parseInt(now.substring(0,4)) - response.data.data.birthdate.getFullYear()
        if (md > now.substring(5, 10)) {
          response.data.data.birthdate__--
        }
      }

      if (response.data.data.startwork) {
        response.data.data.startwork = new Date(response.data.data.startwork)
        const datediff = this.dateDiff(response.data.data.startwork, Now)
        response.data.data.workhere1 = (datediff.days < 10 ? '0' : '') + datediff.days
        response.data.data.workhere2 = (datediff.months < 10 ? '0' : '') + datediff.months
        response.data.data.workhere3 = (datediff.years < 10 ? '0' : '') + datediff.years
        wbd += datediff.days
        wbm += datediff.months
        wby += datediff.years
        if (wbd > 30) {
          wbm++
          wbd-=30
        }
        if (wbm > 11) {
          wby++
          wbm-=12
        }
        response.data.data.workall1 = (wbd < 10 ? '0' : '') + wbd
        response.data.data.workall2 = (wbm < 10 ? '0' : '') + wbm
        response.data.data.workall3 = (wby < 10 ? '0' : '') + wby
      }

      if (response.data.data.endwork) {
        response.data.data.endwork = new Date(response.data.data.endwork)
      }

      if (response.data.data.medical) {
        response.data.data.medical = new Date(response.data.data.medical);
        response.data.data.medical_ = this.toLocalString(response.data.data.medical).substring(0,10); //yyyy-mm-dd
        response.data.data.medical__ = response.data.data.medical_.substring(8,10) + '.' + response.data.data.medical_.substring(5,7) + '.' + response.data.data.medical_.substring(0,4); //dd.mm.yyyy

        //response.data.data.medical_ = (parseInt(this.toLocalString(response.data.data.medical).substring(0,4)) + 3).toString() + this.toLocalString(response.data.data.medical).substring(4,10); //validity of 3 years
        //response.data.data.medical___ = response.data.data.medical_ >= now
        //response.data.data.medical__ = response.data.data.medical_.substring(8,10) + '.' + response.data.data.medical_.substring(5,7) + '.' + response.data.data.medical_.substring(0,4)
      }
      else {
        response.data.data.medical_ = null;
        response.data.data.medical__ = null;
      }

      if (response.data.data.workatheight) {
        response.data.data.workatheight = new Date(response.data.data.workatheight);
        response.data.data.workatheight_ = this.toLocalString(response.data.data.workatheight).substring(0,10); //yyyy-mm-dd
        response.data.data.workatheight__ = response.data.data.workatheight_.substring(8,10) + '.' + response.data.data.workatheight_.substring(5,7) + '.' + response.data.data.workatheight_.substring(0,4); //dd.mm.yyyy
      }
      else {
        response.data.data.workatheight_ = null;
        response.data.data.workatheight__ = null;
      }

      if (response.data.data.worksecurity) {
        response.data.data.worksecurity = new Date(response.data.data.worksecurity);
        response.data.data.worksecurity_ = this.toLocalString(response.data.data.worksecurity).substring(0,10); //yyyy-mm-dd
        response.data.data.worksecurity__ = response.data.data.worksecurity_.substring(8,10) + '.' + response.data.data.worksecurity_.substring(5,7) + '.' + response.data.data.worksecurity_.substring(0,4); //dd.mm.yyyy

        //response.data.data.worksecurity = new Date(response.data.data.worksecurity)
        //response.data.data.worksecurity_ = this.toLocalString(response.data.data.worksecurity).substring(0,10)  //redundant row
        //response.data.data.worksecurity_ = (parseInt(this.toLocalString(response.data.data.worksecurity).substring(0,4)) + 2).toString() + this.toLocalString(response.data.data.worksecurity).substring(4,10); //validity of 2 years
        //
        //response.data.data.worksecurity___ = response.data.data.worksecurity_ >= now
        //response.data.data.worksecurity__ = response.data.data.worksecurity_.substring(8,10) + '.' + response.data.data.worksecurity_.substring(5,7) + '.' + response.data.data.worksecurity_.substring(0,4)
      }
      else {
        response.data.data.worksecurity_ = null;
        response.data.data.worksecurity__ = null;
      }

      if (!response.data.data.userWorking || response.data.data.userWorking.length === 0) {
        response.data.data.userWorking = [{companyuuid: undefined, companyuuid_: '', hours: ''}]
      }

      for (let i: number = 0; i < response.data.data.userWorking.length; i++) {
        if (response.data.data.userWorking[i].companyuuid) {
          response.data.data.userWorking[i].companyuuid_ = this.getCompanyName(response.data.data.userWorking[i].companyuuid)
        }
      }

      this.setState({
        ke: uuid,
        kedata: response.data.data,
        kedataold: JSON.parse(JSON.stringify(response.data.data)),
        kedataolduserworking: response.data.data.userWorking.length
      })
    }
    else if (response && response.data && response.data.resolved === false && response.data.message) {
        alert(`Error: Failed response from server! (func: openKE)\nmsg: ${response.data.message}`);
    }
    else {
        alert(`Error: Failed response from server! (func: openKE)`);
    }
  }

  public async openDocument(
    that,
    document: DocumentData,
    tabIndex: TabIndex.Reservation | TabIndex.Document,
  ) {
    const params: OpenDocumentParams = {
      token: this.props.userData.token,
      type: TabIndex[tabIndex],
      uuid: document.uuid,
    };

    if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: openDocument)');
    const response = await axios.get(documentRoute, { params });
    if (
      response &&
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.data.length === 1
    ) {
      if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: openDocument)');
      if (document.mileage !== null && document.mileage !== undefined) {
        document.mileage = document.mileage.toString();
      } else {
        document.mileage = ''
      }
      if (tabIndex === TabIndex.Document) {
        document.attachments = response.data.data[0].attachments;

        for (let i = 0; i < document.attachments.length; i++) {
          if (document.attachments[i].files) {
            for (let j = 0; j < document.attachments[i].files.length; j++) {
              if (document.attachments[i].files[j]['isbinary'] && document.attachments[i].files[j].binaryValue) {
                document.attachments[i].files[j].binaryValue = new Uint8Array(document.attachments[i].files[j].binaryValue['data']).buffer;
              }

              if (!document.attachments[i].files[j]['isbinary'] && document.attachments[i].files[j].value && !document.attachments[i].files[j].value.startsWith('data:')) {
                document.attachments[i].files[j].value =
                  'data:' +
                  document.attachments[i].files[j].type +
                  ';base64,' +
                  document.attachments[i].files[j].value;
              }
              // document.attachments[i].files[j].encoded = undefined;
            }
          }
        }

        document.Attachments = JSON.parse(JSON.stringify(document.attachments)) // not correct because of binary but corrected below
        for(let i = 0; i < document.Attachments.length; i++) {
          for (let j = 0; j < document.Attachments[i].files.length; j++) {
            if (document.attachments[i].files[j].binaryValue) {
              document.Attachments[i].files[j].binaryValue = document.attachments[i].files[j].binaryValue
            }
          }
        }
        document.subsistence = response.data.data[0].subsistence;
        document.expenses = response.data.data[0].expenses;

        document.attachment = {
          name: '',
          description: '',
          files: [],
        };

        if (response.data.data[0].companyuuid === null) {
          document.companyuuid = '';
        } else {
          document.companyuuid = response.data.data[0].companyuuid;
        }

        if (response.data.data[0].mileageuuid === null) {
          document.mileageuuid = '';
        } else {
          document.mileageuuid = response.data.data[0].mileageuuid;
        }

        if (response.data.data[0].advance === null) {
          document.advance = '';
        } else {
          document.advance = response.data.data[0].advance;
        }

        if (response.data.data[0].advancedate === null) {
          document.advancedate = null;
        } else {
          document.advancedate = new Date(response.data.data[0].advancedate);
        }

        if (response.data.data[0].arrivalmileage === null) {
          document.arrivalmileage = '';
        } else {
          document.arrivalmileage = response.data.data[0].arrivalmileage;
        }

        if (response.data.data[0].departuremileage === null) {
          document.departuremileage = '';
        } else {
          document.departuremileage = response.data.data[0].departuremileage;
        }

        if (response.data.data[0].mileageuuid === null) {
          document.mileageuuid = '';
        } else {
          document.mileageuuid = response.data.data[0].mileageuuid;
        }
        if (response.data.data[0].name === null) {
          document.name = '';
        } else {
          document.name = response.data.data[0].name;
        }
      }

      document.pointuuids = response.data.data[0].pointuuids;
      document.Pointuuids = []
      for (const item of document.pointuuids) {
        document.Pointuuids.push(that.getPointname(that, item))
      }

      document.arrivalDate = new Date(
        response.data.data[0].arrival.substring(0, 10),
      );
      document.arrivalHour = response.data.data[0].arrival.substring(11, 13);
      document.arrivalMinute = response.data.data[0].arrival.substring(14, 16);

      document.departureDate = new Date(
        response.data.data[0].departure.substring(0, 10),
      );
      document.departureHour = response.data.data[0].departure.substring(
        11,
        13,
      );
      document.departureMinute = response.data.data[0].departure.substring(
        14,
        16,
      );

      if (response.data.data[0].companions === null) {
        document.companions = '';
      } else {
        document.companions = response.data.data[0].companions;
      }

      if (response.data.data[0].description === null) {
        document.description = '';
      } else {
        document.description = response.data.data[0].description;
      }
      if (response.data.data[0].intent === null) {
        document.intent = '';
      } else {
        document.intent = response.data.data[0].intent;
      }

      if (response.data.data[0].orderuuid === null) {
        document.orderuuid = '';
      } else {
        document.orderuuid = response.data.data[0].orderuuid;
      }
      if (response.data.data[0].path === null) {
        document.path = '';
      } else {
        document.path = response.data.data[0].path;
      }
      if (response.data.data[0].projectuuid === null) {
        document.projectuuid = '';
      } else {
        document.projectuuid = response.data.data[0].projectuuid;
      }
      if (response.data.data[0].vehicletype === null) {
        document.vehicletype = VehicleType.None;
      } else {
        document.vehicletype = response.data.data[0].vehicletype;
      }

      if (response.data.data[0].vehicleuuid === null) {
        document.vehicleuuid = '';
      } else {
        document.vehicleuuid = response.data.data[0].vehicleuuid;
      }

      document.arrivalDatePlanned = document.arrivalDate;
      document.arrivalHourPlanned = document.arrivalHour;
      document.arrivalMinutePlanned = document.arrivalMinute;

      document.departureDatePlanned = document.departureDate;
      document.departureHourPlanned = document.departureHour;
      document.departureMinutePlanned = document.departureMinute;

      document.companionsPlanned = document.companions;
      document.vehicleTypePlanned = document.vehicletype;
      document.vehicleuuidPlanned = document.vehicleuuid;
      document.descriptionPlanned = document.description;

      document.projectuuidPlanned = document.projectuuid;
      document.intentPlanned = document.intent;
      document.companyuuidPlanned = document.companyuuid;
      document.orderuuidPlanned = document.orderuuid;

      if (tabIndex === TabIndex.Document) {
        that.setState({
          document,
          tabIndex,
          lastTabIndex: tabIndex,
          ndn: NDN.BasicInformation,
          attachment: -1,
        });
      } else if (tabIndex === TabIndex.Reservation) {
        that.setState({
          document,
          tabIndex,
          lastTabIndex: tabIndex,
          nrn: NRN.BasicInformation,
          attachment: -1,
        });
      }
    }
    else if (response && response.data && response.data.resolved === false && response.data.message) {
        alert(`Error: Failed response from server! (func: openDocument)\nmsg: ${response.data.message}`);
    }
    else {
        alert('Error: Failed response from server! (func: openDocument)');
    }
  }

  public getLeaderuuid(that, companyuuid: string): string {
    if (that.state.companies) {
      for (const item of that.state.companies) {
        if (item.uuid === companyuuid) {
          return item.leaderuuid;
        }
      }
    }
    return '';
  }

  public getViewingCurrentMonth(that): JSX.Element[] {
    const nn: JSX.Element[] = [];
    const viewingCurrentMonthData: DocumentData[] = Object.assign([], that.state.viewingCurrentMonthData);
    if (viewingCurrentMonthData) {
      for (const item of viewingCurrentMonthData) {
        nn.push(<div key={item.uuid} className={'pr ds' + item.status}>
          <div className="pa triangle">&nbsp;</div>
          <div className="pa triangle-text">
            {DocumentStatusString[item.status].charAt(0)}
          </div>
          <div>
            <strong className="dibl fw">Status:</strong>
            {that.showStatus(
              that.getStatus(item.status),
              item.status,
              'inline',
            )}
            <strong>&nbsp;&nbsp;Km:</strong> {item.mileage}
          </div>
          <div>
            <strong className="dibl fw">Nosilec:</strong>
            {that.getUserName(item.useruuid, that)}
          </div>
          <div>
            <strong className="dibl fw">Odhod:</strong>
            {daysinweek[(new Date(item.departure)).getDay()]}, {that.formatDate(item.departure)}
          </div>
          <div>
            <strong className="dibl fw">Prihod:</strong>
            {daysinweek[(new Date(item.arrival)).getDay()]}, {that.formatDate(item.arrival)}
          </div>
          {/*
          <div>
            <strong className="dibl fw">Potrdi:</strong>
            <div
              className={clsx(
                'dibl',
                item.orderuuid && item.orderuuid.length ? '' : 'red',
              )}
            >
              {item.orderuuid && item.orderuuid.length
                ? that.getUserName(item.orderuuid, that)
                : 'Nadrejeni ni izbran!'}
            </div>
          </div>
            */}
          <div>
            <strong className="dibl fw">Pot:</strong>
            {item.path ? item.path : ''}
          </div>
          <div className="mt5">
            <button
              onClick={() =>
                that.deleteDocument(
                  that,
                  item.uuid,
                  true
                )
              }
              className="button button-thin button-red"
            >
              Izbriši potni nalog
            </button>

            <button
              onClick={() => that.setState({viewingCurrentMonth: false})}
              className="button button-thin button-green ml"
            >
              Nazaj na obvestila
            </button>

          </div>
        </div>
        )
      }
    }
    if (nn.length === 0) {
      nn.push(
        <div key="0" className="pr ds">
          <span className="pa">
            Trenutno nimate nobenega oddanega potnega naloga!
          </span>
        </div>,
      );
      nn.push(
        <button
          key="1"
          onClick={() => that.setState({viewingCurrentMonth: false})}
          className="button button-thin button-green mt"
        >
          Nazaj na obvestila
        </button>
      )
    }

    return nn
  }

  public async confirmDocuments(that, value: 0|1|2) {
    const Documents: number[] = Object.assign([], that.state.Documents)
    if (Documents) {
      const n: number[] = [];
      for (let i: number = 0; i < Documents.length; i++) {
        if (value === 0 && Documents[i] || value !== 0 && Documents[i] === value) {
          await that.confirmDocument(that, that.state.documents[i].uuid, Documents[i] === 1 ? DocumentStatusConfirmed : Documents[i] === 2 ? DocumentStatusFinalized : undefined, undefined, true)
          n.push(i)
        }
      }
      if (n.length) {
        const documents: DocumentData[] = []
        for (let i: number = 0; i < Documents.length; i++) {
          let found = false
          for (let j: number = 0; j < n.length; j++) {
            if (n[j] === i) {
              found = true
              break
            }
          }
          if (!found) {
            documents.push(JSON.parse(JSON.stringify(that.state.documents[i])))
            for(let k = 0; i < documents.length; k++) {
              for(let i = 0; i < documents[k].attachments.length; i++) {
                for (let j = 0; j < documents[k].attachments[i].files.length; j++) {
                  if (documents[k].attachments[i].files[j].binaryValue) {
                    documents[k].attachments[i].files[j].binaryValue = documents[k].attachments[i].files[j].binaryValue
                  }
                  if (documents[k].Attachments[i].files[j].binaryValue) {
                    documents[k].Attachments[i].files[j].binaryValue = documents[k].Attachments[i].files[j].binaryValue
                  }

                }
              }
            }
          }
        }
        that.setState({documents, Documents: new Array(documents.length)})
        switch(n.length) {
          case 1:
            alert('1 potni nalog je bilo uspešno potrjen!')
          break
          case 2:
            alert('2 potna naloga sta bilo uspešno potrjena!')
          break
          case 3:
            alert('3 potni nalogi so bili uspešno potrjeni!')
          break
          default:
            alert(n.length + ' potnih nalogov je bilo uspešno potrjenih!')
          break
        }
      }
    }
  }

  public selectDocuments(that, toConfirm: Array<0|1|2>, value: 0|1|2) {
    const Documents: number[] = Object.assign([], that.state.Documents)
    for (let i: number = 0; i < that.state.documents.length; i++) {
      if (value === 0 && toConfirm[i] || value !== 0 && toConfirm[i] === value) {
        Documents[i] = toConfirm[i]
      }
    }

    that.setState({Documents})
  }

  public selectDocument(that, i: number, value: 1|2) {
    const Documents: number[] = Object.assign([], that.state.Documents)
    if (Documents) {
      if (Documents[i] === value) {
        Documents[i] = 0
      } else {
        Documents[i] = value
      }
      this.setState({Documents})
    }
  }

  public getNotificationsNavigation(that): {jsx: JSX.Element[], count: number} {
    const nnButtons: JSX.Element[] = [];
    const nnMy: JSX.Element[] = [];
    const nnToConfirm: JSX.Element[] = [];
    const nnEnd: JSX.Element[] = [];
    let toConfirm1: number = 0
    let toConfirm2: number = 0
    let ToConfirm1: number = 0
    let ToConfirm2: number = 0
    // const fullYear = (new Date()).getFullYear() + 1
    const toConfirm: Array<0|1|2> = []

    let isLeader = that.isUserLeader(that.state.leaders, that.props.userData.uuid) || that.isUserLeader(that.state.Leaders, that.props.userData.uuid)
    let count=0
    if (that.state.documents) {
      for (let i: number = 0; i < that.state.documents.length; i++) {
        const item: DocumentData = that.state.documents[i]
        if (item.status === DocumentStatusUpdatedPlus && that.props.userData.uuid === item.orderuuid) {
          toConfirm1++
          if (that.state.Documents && that.state.Documents[i]) {ToConfirm1++}
          toConfirm.push(1)
        } else if (item.status === DocumentStatusConfirmed && that.props.userData.uuid === that.getLeaderuuid(that, item.companyuuid)) {
          toConfirm2++
          if (that.state.Documents && that.state.Documents[i]) {ToConfirm2++}
          toConfirm.push(2)
        } else {
          toConfirm.push(0)
        }

        let nn_: JSX.Element[]
        if (isLeader) {
          if (item.useruuid === that.props.userData.uuid) {
            nn_ = nnMy
          } else {
            nn_ = nnToConfirm
          }
        } else {
          nn_ = nnMy
        }

        nn_.push(
          <div key={item.uuid} className={'pr ds' + item.status}>
            <div className="pa triangle">&nbsp;</div>
            <div className="pa triangle-text">
              {DocumentStatusString[item.status].charAt(0)}
            </div>
            <div>
              <strong className="dibl fw">Status:</strong>
              {that.showStatus(
                that.getStatus(item.status),
                item.status,
                'inline',
              )}
              <strong>&nbsp;&nbsp;Km:</strong> {item.mileage}
            </div>
            <div>
              <strong className="dibl fw">Nosilec:</strong>
              {that.getUserName(item.useruuid, that)}
            </div>
            <div>
              <strong className="dibl fw">Odhod:</strong>
              {/*item.departure && item.departure.substring(0,10) === fullYear + '-01-01' ? 'še ni določen!' :*/ daysinweek[(new Date(item.departure)).getDay()] + ', ' + that.formatDate(item.departure)}
            </div>
            <div>
              <strong className="dibl fw">Prihod:</strong>
              {/*item.arrival && item.arrival.substring(0,10) === fullYear + '-01-01' ? 'še ni določen!' :*/ daysinweek[(new Date(item.arrival)).getDay()] + ', ' + that.formatDate(item.arrival)}
            </div>
            <div>
              <strong className="dibl fw">Vozilo:</strong>
              {that.getVehicleEither(that, item.vehicleuuid, item.vehicletype)}
            </div>
            <div>
              <strong className="dibl fw">Relacija:</strong>
              {item.path}
            </div>
            <div>
              <strong className="dibl fw">Potrdi:</strong>
              <div
                className={clsx(
                  'dibl',
                  item.orderuuid && item.orderuuid.length ? '' : 'red',
                )}
              >
                {item.orderuuid && item.orderuuid.length
                  ? that.getUserName(item.orderuuid, that)
                  : 'Nadrejeni ni izbran!'}
              </div>
            </div>
            {/*
            <div>
              <strong className="dibl fw">Stranka:</strong>
              <div
                className={clsx(
                  'dibl',
                  item.customeruuid && item.customeruuid.length ? '' : 'red',
                )}
              >
                {item.customeruuid && item.customeruuid.length
                  ? that.getCustomerName(item.customeruuid)
                  : 'Stranka ni izbrana!'}
              </div>
            </div>
            */}
            <div className="mt5">
              {that.props.userData.uuid === item.useruuid &&
              (item.status === DocumentStatusSaved ||
                item.status === DocumentStatusInserted) ? (
                <button
                  onClick={() =>
                    that.openDocument(that, item, TabIndex.Reservation)
                  }
                  className={clsx(
                    'button button-thin button-green',
                    that.state.lastTabIndex === TabIndex.Reservation &&
                      that.state.document &&
                      that.state.document.uuid === item.uuid
                      ? 'active'
                      : '',
                  )}
                >
                  Odpri rezervacijo
                </button>
              ) : null}

              {that.props.userData.uuid === item.useruuid &&
              (item.status === DocumentStatusUpdated ||
                item.status === DocumentStatusInserted) ? (
                <>
                <button
                  onClick={() =>
                    that.openDocument(that, item, TabIndex.Document)
                  }
                  className={clsx(
                    'button button-thin button-green',
                    that.state.lastTabIndex === TabIndex.Document &&
                      that.state.document &&
                      that.state.document.uuid === item.uuid
                      ? 'active'
                      : '',
                    that.props.userData.uuid === item.useruuid &&
                      (item.status === DocumentStatusSaved ||
                        item.status === DocumentStatusInserted)
                      ? 'ml5'
                      : '',
                  )}
                >
                  Dopolni potni nalog
                  {/*item.status === DocumentStatusInserted ? 'Dopolni potni nalog' : 'Dopolni nalog'*/}
                </button>
                <button
                  title="Izbriši potni nalog"
                  onClick={() =>
                    that.deleteDocument(
                      that,
                      item.uuid,
                    )
                  }
                  className="button button-thin button-red ml"
                >
                  Izbriši p. nalog
                </button>
                </>
              ) : null}

              {item.status === DocumentStatusUpdatedPlus && that.props.userData.uuid === item.orderuuid ? (
                <>
                  <button
                    onClick={() =>
                      that.openDocument(that, item, TabIndex.Document)
                    }
                    className={clsx(
                      'button button-thin button-green',
                      that.state.document &&
                        that.state.document.uuid === item.uuid
                        ? 'active'
                        : '',
                    )}
                  >
                    Odpri potni nalog
                  </button>
                  <button
                    title = "Potrdi potni nalog"
                    onClick={() =>
                      that.confirmDocument(
                        that,
                        item.uuid,
                        DocumentStatusConfirmed,
                      )
                    }
                    className="button button-thin button-red ml7"
                  >
                    Potrdi
                  </button>
                  <button
                    title = "Izbriši potni nalog"
                    onClick={() =>
                      that.deleteDocument(
                        that,
                        item.uuid
                      )
                    }
                    className="button button-thin button-red ml7"
                  >
                    Izbriši
                  </button>
                  <button onClick = {()=> that.selectDocument(that, i, 1)} title = {that.state.Documents && that.state.Documents[i] ? 'Odstrani potni nalog s spiska za potrjevanje z enim klikom' : 'Dodaj potni nalog na spisek za potrjevanje z enim klikom'} style = {{width: '24px'}} className={clsx('button button-thin button-red ml7 bold', that.state.Documents && that.state.Documents[i] ? 'active' : '')}>
                    {that.state.Documents && that.state.Documents[i] ? '–' : '+'}
                  </button>
                </>
              ) : null}

              {item.status === DocumentStatusConfirmed &&
              that.props.userData.uuid ===
                that.getLeaderuuid(that, item.companyuuid) ? (
                <>
                  <button
                    onClick={() =>
                      that.openDocument(that, item, TabIndex.Document)
                    }
                    className={clsx(
                      'button button-thin button-green',
                      that.state.document &&
                        that.state.document.uuid === item.uuid
                        ? 'active'
                        : '',
                    )}
                  >
                    Odpri potni nalog
                  </button>
                  <button
                    title = "Zaključi potni nalog"
                    onClick={() =>
                      that.confirmDocument(
                        that,
                        item.uuid,
                        DocumentStatusFinalized,
                      )
                    }
                    className="button button-thin button-red ml7"
                  >
                    Zaklj.
                  </button>
                  <button
                    title = "Izbriši potni nalog"
                    onClick={() =>
                      that.deleteDocument(
                        that,
                        item.uuid
                      )
                    }
                    className="button button-thin button-red ml7"
                  >
                    Izbriši
                  </button>
                  <button onClick = {()=> that.selectDocument(that, i, 2)} title = {that.state.Documents && that.state.Documents[i] ? 'Odstrani potni nalog s spiska za potrjevanje z enim klikom' : 'Dodaj potni nalog na spisek za potrjevanje z enim klikom'} style = {{width: '24px'}} className={clsx('button button-thin button-red ml7 bold', that.state.Documents && that.state.Documents[i] ? 'active' : '')}>
                    {that.state.Documents && that.state.Documents[i] ? '–' : '+'}
                  </button>
                </>
              ) : null}
            </div>
          </div>,
        );
      }
    }

    if (toConfirm1) {
      nnEnd.push (<div key = "00" style = {{border: '0px', padding: '0px', height: '24px'}}>
        <button onClick={() => that.selectDocuments(that, toConfirm, 1)} className = "button button-thin button-green">Izberi oddane [{toConfirm1}]</button>
        <button onClick={() => that.confirmDocuments(that, 1)} disabled = {toConfirm1 !== ToConfirm1} className = "button button-thin button-red ml7" title="Potrdi vse izbrane oddane naloge">Potrdi naenkrat vse</button>
      </div>)
      count--
    }

    if (toConfirm2) {
      nnEnd.push (<div key = "000" style = {{border: '0px', padding: '0px', height: '24px'}}>
        <button onClick={() => that.selectDocuments(that, toConfirm, 2)} className = "button button-thin button-green" title = "Izberi potrjene naloge">Izberi potr. [{toConfirm2}]</button>
        <button onClick={() => that.confirmDocuments(that, 2)} disabled = {toConfirm2 !== ToConfirm2} className = "button button-thin button-red ml7" title="Zaključi vse izbrane potrjene naloge">Zaključi naenkrat vse</button>
      </div>)
      count--
    }

    if (toConfirm1 && toConfirm2) {
      nnEnd.push (<div key = "0000" style = {{border: '0px', padding: '0px', height: '24px'}}>
        <button onClick={() => that.selectDocuments(that, toConfirm, 0)} className = "button button-thin button-green" title="Izberi vse naloge">Izberi VSE [{toConfirm1 + toConfirm2}]</button>
        <button onClick={() => that.confirmDocuments(that, 0)} disabled = {toConfirm1 + toConfirm2 !== ToConfirm1 + ToConfirm2} className = "button button-thin button-red ml7">Potrdi naenkrat VSE</button>
      </div>)
      count--
    }

    if (nnMy.length === 0 && nnToConfirm.length === 0 && nnEnd.length === 0) {
      nnButtons.push(
        <div key="0" className="pr ds">
          <span className="pa">
            Trenutno nimate nobenega aktivnega obvestila!
          </span>
        </div>,
      );
    }

    nnButtons.unshift(
      <div key="-1" className = "empty-notification">
        <button
          onClick={() => that.initializeNewReservation(that)}
          className="button button-green button-thin"
        >
          Nova rezervacija
        </button>
        <button
          onClick={() => that.switchToVehicleOccupancy(that)}
          className="button button-green button-thin ml5"
        >
          Zasedenost vozil
        </button>

        <button
          onClick={() => that.switchToAddingPoints(that)}
          className="button button-green button-thin ml5"
          title = "Vnesi novo točko"
        >
          T+
        </button>

        <button
          onClick={() => that.switchToViewingCurrentMonth(that)}
          className="button button-green button-thin"
          title="Poglej že oddane potne naloge za pretekli in tekoči mesec"
        >
          Poglej oddane za pretekli/tekoči mesec
        </button>

      </div>
      );

    if (isLeader) {
      if (nnMy.length) {
        nnMy.unshift(<div key = "-2" style = {{border: '0px', padding: '0px', height: '24px'}}>
          <h3>Moji potni nalogi</h3>
        </div>)
        count--
      }

      if (nnToConfirm.length) {
        nnToConfirm.unshift(<div key = "-3" style = {{border: '0px', padding: '0px', height: '24px'}}>
          <h3>Potni nalogi sodelavcev za potrjevanje</h3>
        </div>)
        count--
      }
    }

    // console.log(nnMy)
    // console.log(nnToConfirm)
    // console.log(nnEnd)
    count += nnMy.length + nnToConfirm.length + nnEnd.length
    return {jsx: [...nnButtons, ...nnMy, ...nnToConfirm, ...nnEnd], count};
  }

  public isNewDocument(document: DocumentData): boolean {
    if (document.Attachments.length > 0) {return false}
    if (document.advance) {return false}
    if (document.advancedate) {return false}
    if (document.arrivalDate) {return false}
    if (document.arrivalDatePlanned) {return false}
    if (document.arrivalHour) {return false}
    if (document.arrivalHourPlanned) {return false}
    if (document.arrivalMinute) {return false}
    if (document.arrivalMinutePlanned) {return false}
    if (document.arrivalmileage) {return false}
    if (document.attachments.length > 0) {return false}
    if (document.companions) {return false}
    if (document.companionsPlanned) {return false}
    if (document.departureDate) {return false}
    if (document.departureDatePlanned) {return false}
    if (document.departureHour) {return false}
    if (document.departureHourPlanned) {return false}
    if (document.departureMinute) {return false}
    if (document.departureMinutePlanned) {return false}
    if (document.departuremileage) {return false}
    if (document.description) {return false}
    if (document.descriptionPlanned) {return false}
    if (document.expenses.length > 0) {return false}
    if (document.intent) {return false}
    if (document.intentPlanned) {return false}
    if (document.mileage) {return false}
    if (document.mileageuuid) {return false}
    if (document.name) {return false}
    if (document.orderuuid) {return false}
    if (document.orderuuidPlanned) {return false}
    if (document.path) {return false}
    if (document.projectuuid) {return false}
    if (document.projectuuidPlanned) {return false}
    if (document.subsistence.length > 0) {return false}
    if (document.uuid) {return false}
    if (document.vehicleTypePlanned) {return false}
    if (document.vehicletype) {return false}
    if (document.vehicleuuid) {return false}
    if (document.vehicleuuidPlanned) {return false}

    return true
  }

  public initializeNewReservation(that) {
    const newDocument: boolean = this.isNewDocument(that.state.document)
    if (newDocument || confirm('Podatki znotraj trenutne rezervacije bodo v primeru odprtja nove rezervacije izgubljeni!')) {
      that.setState({
        document: that.initDocument(),
        tabIndex: TabIndex.Reservation,
        lastTabIndex: TabIndex.Reservation,
        nrn: NRN.BasicInformation
      });
    }
  }

  public getNotifications(that): JSX.Element {
    return (
      that.state.vehicleOccupancy
      ? that.getVehicleOccupancy(that) :
      that.state.addingPoints
      ? that.getAddingPoints(that) :
      that.state.viewingCurrentMonth
      ?
      <div className="notifications clearfix">
        {that.getViewingCurrentMonth(that)}
      </div>:

      <div className="notifications clearfix">
        {that.getNotificationsNavigation(that).jsx}
      </div>
    );
  }

  public enlargeImage(that) {
    that.setState({ enlargeImage: true });
  }

  public cancelImage(that) {
    that.setState({ showImage: undefined, enlargeImage: false });
  }

  public getNewDocumentBasicInformation(
    that,
    disabled: boolean,
    doNotIncludeNavigation: boolean,
  ): JSX.Element {
    return (
      <>
        <div className="title mt pr">
          Osnovni podatki
          {doNotIncludeNavigation
            ? null
            : that.showStatus(
                that.getStatus(that.state.document.status),
                that.state.document.status,
                'pa',
              )}
        </div>
        <hr />
        <div className="clearfix">
          <div className="tar before-input fl">Potnik:</div>
          <select
            disabled={disabled || doNotIncludeNavigation}
            onChange={e =>
              that.setNewDocument(that, 'useruuid', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.useruuid}
          >
            <option value={that.state.document.useruuid}>
              {that.getUserName(that.state.document.useruuid, that)}
            </option>
          </select>
        </div>

        <div className="clearfix pr">
          {that.state.document.departureDate === null ||
          that.state.document.departureHour.length === 0 ||
          that.state.document.departureMinute.length === 0
            ? that.showExclamation('departure-date-planned')
            : null}

          {that.isSameDateAndTimeWrong(that.state.document)
            ? that.showExclamation('time-planned')
            : null}
          <div className="tar before-input fl">Odhod:</div>
          <DatePicker
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            disabled={disabled}
            id="pn0"
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={that.state.document.departureDate}
            excludeDateIntervals={that.getExcludeDateIntervals(
              'after',
              that.state.document.arrivalDate,
            )}
            onChange={departureDate =>
              that.setNewDocument(
                that,
                'departureDate',
                departureDate,
                undefined,
                undefined,
                'pn1',
              )
            }
          />
          <input
            disabled={disabled}
            id="pn1"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml input input-hm dbl fl"
            value={that.state.document.departureHour}
            onChange={e =>
              that.setNewDocument(
                that,
                'departureHour',
                e.target.value,
                undefined,
                undefined,
                e.target.value.length === stringHourMinuteLength
                  ? 'pn2'
                  : undefined,
              )
            }
          />
          <div className="fl ml5">:</div>
          <input
            disabled={disabled}
            id="pn2"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml5 input input-hm dbl fl"
            value={that.state.document.departureMinute}
            onChange={e =>
              that.setNewDocument(
                that,
                'departureMinute',
                e.target.value,
                undefined,
                undefined,
                e.target.value.length === stringHourMinuteLength
                  ? 'pn3'
                  : undefined,
              )
            }
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.departureDate === null ? 'Vnesi datum odhoda!' :
           that.state.document.departureHour.length === 0 ? 'Vnesi uro odhoda!' :
           that.state.document.departureMinute.length === 0 ? 'Vnesi minuto odhoda!' :
           that.isSameDateAndTimeWrong(that.state.document) ? 'Čas odhoda je po času prihoda!':
           ''}
        </div>

        <div className="clearfix pr">
          {that.state.document.arrivalDate === null ||
          that.state.document.arrivalHour.length === 0 ||
          that.state.document.arrivalMinute.length === 0
            ? that.showExclamation('arrival-date-planned')
            : null}
          <div className="tar before-input fl">Prihod:</div>
          <DatePicker
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            disabled={disabled}
            id="pn3"
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={that.state.document.arrivalDate}
            excludeDateIntervals={that.getExcludeDateIntervals(
              'before',
              that.state.document.departureDate,
            )}
            onChange={arrivalDate =>
              that.setNewDocument(
                that,
                'arrivalDate',
                arrivalDate,
                undefined,
                undefined,
                'pn4',
              )
            }
          />
          <input
            disabled={disabled}
            id="pn4"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml input input-hm dbl fl"
            value={that.state.document.arrivalHour}
            onChange={e =>
              that.setNewDocument(
                that,
                'arrivalHour',
                e.target.value,
                undefined,
                undefined,
                e.target.value.length === stringHourMinuteLength
                  ? 'pn5'
                  : undefined,
              )
            }
          />
          <div className="fl ml5">:</div>
          <input
            disabled={disabled}
            id="pn5"
            maxLength={stringHourMinuteLength}
            type="text"
            className="ml5 input input-hm dbl fl"
            value={that.state.document.arrivalMinute}
            onChange={e =>
              that.setNewDocument(that, 'arrivalMinute', e.target.value)
            }
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.arrivalDate === null ? 'Vnesi datum prihoda!' :
           that.state.document.arrivalHour.length === 0 ? 'Vnesi uro prihoda!' :
           that.state.document.arrivalMinute.length === 0 ? 'Vnesi minuto prihoda!' :
           ''}
        </div>
        <div className="clearfix pr">
          {that.state.document.projectuuid.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Projekt:</div>
          <select
            disabled={disabled}
            onChange={e =>
              that.setNewDocument(that, 'projectuuid', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.projectuuid}
          >
            {that.getProjectOptions(that.state.document.companyuuid)}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.projectuuid.length === 0 ? 'Vnesi ime projekta!' : ''}
        </div>
        <div className="clearfix pr">
          {that.state.document.orderuuid.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Po nalogu:</div>
          <select
            disabled={disabled}
            onChange={e =>
              that.setNewDocument(that, 'orderuuid', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.orderuuid}
          >
            {that.getUserOptions(undefined, undefined, true)}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.orderuuid.length === 0 ? 'Vnesi ime nalogodajalca!' : ''}
        </div>
        {/*
        <div className="clearfix pr">
          {that.state.document.customeruuid.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Stranka:</div>
          <select
            disabled={disabled}
            onChange={e =>
              that.setNewDocument(that, 'customeruuid', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.customeruuid}
          >
            {that.getCustomerOptions()}
          </select>
        </div>
        */}
        {/*
        <div className="clearfix">
          <div className="tar before-input fl">Z namenom:</div>
          <input
            disabled={disabled}
            maxLength={stringLongLength}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.intent}
            onChange={e => that.setNewDocument(that, 'intent', e.target.value)}
          />
        </div>
        */}
        <div className="clearfix">
          <div className="tar before-input fl lh20 mt5">Opis namena:</div>
          <textarea
            disabled={disabled}
            maxLength={stringDescriptionLength}
            className="ml input dbl fl"
            value={that.state.document.description}
            onChange={e =>
              that.setNewDocument(that, 'description', e.target.value)
            }
          />
        </div>
        <div className="title mt">Podatki o vozilu</div>
        <hr />
        <div className="clearfix pr">
          {that.state.document.vehicletype === VehicleType.None
            ? that.showExclamation('vehicle-type-planned')
            : null}
          <div className="tar before-input fl">Tip vozila:</div>
          <select
            disabled={disabled}
            className="ml select select-input dbl fl mt3"
            value={that.state.document.vehicletype}
            onChange={e =>
              that.setNewDocument(that, 'vehicletype', e.target.value)
            }
          >
            {that.getVehicleTypeOptions()}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.vehicletype === VehicleType.None ? 'Tip vozila ni izbran!' : ''}
        </div>
        <div className="clearfix pr">
          {that.state.document.vehicleuuid.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Vozilo:</div>
          <select
            disabled={
              disabled ||
              that.state.document.vehicletype === VehicleType.Companion
            }
            onChange={e =>
              that.setNewDocument(that, 'vehicleuuid', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.vehicleuuid}
          >
            {that.getVehicleOptions(that.state.document.vehicletype)}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.vehicleuuid.length === 0 ? 'Vozilo ni izbrano!' : ''}
        </div>
        {that.state.document.vehicletype === VehicleType.Company ?
        <>
        <div className="clearfix pr">
          {that.state.document.departuremileage.length === 0 || that.state.document.mileage.length === 0 || +that.state.document.mileage <= 0
            ? that.showExclamation('mileage-planned')
            : null}

          <div className="tar before-input fl">Odhod (km):</div>
          <input
            disabled={disabled}
            maxLength={stringShortLength}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.departuremileage}
            onChange={e =>
              that.setNewDocument(that, 'departuremileage', e.target.value)
            }
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.departuremileage.length === 0 ? 'Odhod (km) ne obstaja!' :
          that.state.document.mileage.length === 0 || +that.state.document.mileage <= 0 ? 'Odhod (km) je večji ali enak Prihod (km)!'
          : ''}
        </div>
        <div className="clearfix pr">
          {that.state.document.arrivalmileage.length === 0 || that.state.document.mileage.length === 0 || +that.state.document.mileage <= 0
            ? that.showExclamation('mileage-planned')
            : null}
          <div className="tar before-input fl">Prihod (km):</div>
          <input
            disabled={disabled}
            maxLength={stringShortLength}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.arrivalmileage}
            onChange={e =>
              that.setNewDocument(that, 'arrivalmileage', e.target.value)
            }
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.arrivalmileage.length === 0 ? 'Prihod (km) ne obstaja!' :
          that.state.document.mileage.length === 0 || +that.state.document.mileage <= 0 ? 'Odhod (km) je večji ali enak Prihod (km)!'
          : ''}
        </div>
        </> : null}
        {that.state.document.vehicletype === VehicleType.Private ?
        <>
        <div className="clearfix pr">
          {that.state.document.mileage.length === 0 || +that.state.document.mileage === 0
            ? that.showExclamation('mileage-planned')
            : null}
          <div className="tar before-input fl">Kilometrina:</div>
          <input
            disabled={disabled}
            maxLength={stringShortLength}
            type="text"
            className="ml input dbl fl"
            value={that.state.document.mileage}
            onChange={e => that.setNewDocument(that, 'mileage', e.target.value)}
          />
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.mileage.length === 0 ? 'Kilometrina ne obstaja!' :
          +that.state.document.mileage === 0 ? 'Kilometrina je enaka 0!' : ''}
        </div>

        </>
        : null}
        <div className="title mt">Podatki o poti</div>
        <hr />
        {that.getPath(that, disabled, doNotIncludeNavigation)}
        {doNotIncludeNavigation ? null : (
          <div className="clearfix mt">
            <button
              onClick={() => that.setState({ ndn: NDN.AccountingInformation })}
              className="button button-green button-thin"
            >
              Naprej
            </button>
            {disabled ? null : (
              <button
                title = "Vnesi novo točko"
                onClick={() => that.switchToAddingPoints(that)}
                className="button button-green button-thin ml"
              >
                T+
              </button>
            )}

            {/*disabled ? null : (
              <>
              <button
                onClick={() => that.addDocument(that)}
                className="button button-green button-thin ml"
              >
                Dopolni potni nalog
              </button>

              <button
                title = "Vnesi novo točko"
                onClick={() => that.switchToAddingPoints(that)}
                className="button button-green button-thin ml"
              >
                T+
              </button>
              </>
            )*/}
          </div>
        )}
      </>
    );
  }

  public getNewDocumentAccountingInformation(
    that,
    disabled: boolean,
    doNotIncludeNavigation: boolean,
    previewDocument: boolean,
  ): JSX.Element {
    return (
      <>
        <div className="title mt pr">
          Osnovni podatki
          {doNotIncludeNavigation
            ? null
            : that.showStatus(
                that.getStatus(that.state.document.status),
                that.state.document.status,
                'pa',
              )}
        </div>
        <hr />
        <div className="clearfix">
          <div className="tar before-input fl">Predplačilo:</div>
          <input
            disabled={disabled}
            style = {{paddingRight: '3px'}}
            placeholder="znesek (€)"
            maxLength={stringShortLength}
            type="text"
            className="ml input input-advance dbl fl tar"
            value={that.state.document.advance}
            onChange={e => that.setNewDocument(that, 'advance', e.target.value)}
          />
          <DatePicker
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            disabled={disabled}
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={that.state.document.advancedate}
            onChange={advancedate =>
              that.setNewDocument(that, 'advancedate', advancedate)
            }
          />
        </div>
        <div className="clearfix pr">
          {that.state.document.companyuuid && that.state.document.companyuuid.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Strošek:</div>
          <select
            disabled={disabled}
            onChange={e =>
              that.setNewDocument(that, 'companyuuid', e.target.value)
            }
            className="ml select select-input dbl fl mt3"
            value={that.state.document.companyuuid}
          >
            {that.getCompanyOptions()}
          </select>
        </div>
        <div className="clearfix pr">
          {that.state.document.mileageuuid.length === 0
            ? that.showExclamation('vehicle-planned')
            : null}
          <div className="tar before-input fl">Kilometrina:</div>
          <select
            disabled={disabled}
            onChange={e =>
              that.setNewDocument(that, 'mileageuuid', e.target.value)
            }
            className="ml select select-input select-km dbl fl mt3"
            value={that.state.document.mileageuuid}
          >
            {that.getMileageOptions(that, undefined, '', that.state.document.mileageuuid)}
          </select>
        </div>
        <div className = "red semi-bold lh20">
          {that.state.document.mileageuuid.length === 0 ?
          'Izberi vrsto kilometrine!' : ''}
        </div>
        <div className="title mt">Dnevnice</div>
        <hr />
        <div id="subsistence-data">
          {that.getSubsistenceData(that, disabled)}
        </div>
        {disabled ? null : (
          <div className="clearfix mt3">
            <div className="tal before-input-whole fl pr">
              Vnesi novo dnevnico
              <button
                onClick={() => that.setNewDocumentAdd(that, 'subsistence')}
                className="pa button button-green button-thin add-point mt3"
              >
                +
              </button>
            </div>
          </div>
        )}
        <div className="title mt">Dodatni stroški</div>
        <hr />
        <div id="expenses-data">
          {that.getExpensesData(that, disabled)}
        </div>
        <div className="clearfix">
          {that.state.document.expenses !== undefined && !disabled ? (
            <div
              className={clsx(
                'tar fl pr',
                that.state.document.expenses.length
                  ? 'before-input'
                  : 'before-input-whole',
              )}
            >
              {that.state.document.expenses.length
                ? 'Skupaj:'
                : 'Vnesi nov dodatni strošek'}
              {disabled ? null : (
                <button
                  onClick={() => that.setNewDocumentAdd(that, 'expenses')}
                  className="pa button button-green button-thin add-point mt3"
                >
                  +
                </button>
              )}
            </div>
          ) : null}
          {that.state.document.expenses.length ? (
            <input
              readOnly={true}
              disabled={true}
              type="text"
              className="ml input dbl fl tar"
              value={that.getExpensesSum(that)}
            />
          ) : null}
        </div>
        <div className="title mt">Povzetek</div>
        <hr />
        <div className="clearfix">
          <div className="tar before-input fl pr">Kilometrina:</div>
          <input
            disabled={true}
            readOnly={true}
            type="text"
            className="ml input input-advance0 dbl fl tal"
            value={that.showExpense(that, 0, 0)}
          />
          <input
            readOnly={true}
            disabled={true}
            type="text"
            className="ml input input-advance dbl fl tar"
            value={that.showExpense(that, 0, 1)}
          />
        </div>
        <div className="clearfix">
          <div className="tar before-input fl pr">Dnevnice:</div>
          <input
            readOnly={true}
            disabled={true}
            type="text"
            className="ml input input-advance0 dbl fl tal op0"
          />
          <input
            readOnly={true}
            disabled={true}
            type="text"
            className="ml input input-advance dbl fl tar"
            value={that.showExpense(that, 1, 1)}
          />
        </div>
        <div className="clearfix">
          <div title="Dodatni stroški" className="tar before-input fl pr">
            Dod. stroški:
          </div>
          <input
            readOnly={true}
            disabled={true}
            type="text"
            className="ml input input-advance0 dbl fl tal op0"
          />
          <input
            readOnly={true}
            disabled={true}
            type="text"
            className="ml input input-advance dbl fl tar"
            value={that.showExpense(that, 2, 1)}
          />
        </div>
        <hr />
        <div className="clearfix">
          <div className="tal before-input-wide fl pr">
            Skupen strošek naloga:
          </div>
          <input
            readOnly={true}
            disabled={true}
            type="text"
            className="ml input input-advance dbl fl tar"
            value={that.calculateExpense(that)}
          />
        </div>
        {that.state.document.advance &&
        that.state.document.advance.length &&
        parseFloat(that.state.document.advance) > 0 ? (
          <>
            <div className="clearfix">
              <div className="tal before-input-wide fl pr">Predplačilo:</div>
              <input
                readOnly={true}
                disabled={true}
                type="text"
                className="ml input input-advance dbl fl tar bg-red"
                value={that.state.document.advance + ' €'}
              />
            </div>
            <div className="clearfix">
              <div className="tal before-input-wide fl pr">
                Ostalo izplačilo naloga:
              </div>
              <input
                readOnly={true}
                disabled={true}
                type="text"
                className="ml input input-advance dbl fl tar"
                value={that.calculateExpense(that, that.state.document.advance)}
              />
            </div>
          </>
        ) : null}
        {doNotIncludeNavigation ? null : (
          <div className="clearfix mt">
            <button
              onClick={() => that.setState({ ndn: NDN.Attachments })}
              className="button button-green button-thin"
            >
              Naprej
            </button>
            {disabled ? null : (
              <button
                disabled={previewDocument ? !that.isAddDocumentPossible(that, that.state.document/*, true*/) : !that.isAddDocumentPossible(that, that.state.document)}
                onClick={() => that.addDocument(that)}
                className="button button-green button-thin ml"
              >
                Shrani
              </button>
            )}
            {/*
              <button
                title = "Oddaja nadrejenemu"
                disabled={
                  !that.isAddDocumentPossible(that, that.state.document)
                }
                onClick={() => that.addDocument(that, undefined, true)}
                className={clsx('button button-thin button-green ml')}
              >
                Oddaja
              </button>
              </>
              )*/}
          </div>
        )}
      </>
    );
  }

  public getNewDocumentAttachments(
    that,
    disabled: boolean,
    previewDocument: boolean,
  ): JSX.Element {
    return (
      <>
        <div className="title mt pr">
          Vnesi prilogo
          {that.showStatus(
            that.getStatus(that.state.document.status),
            that.state.document.status,
            'pa',
          )}
        </div>
        <hr />
        <div className="clearfix pr">
          {that.state.document.attachment.name.length === 0
            ? that.showExclamation('vehicle')
            : null}
          <div title="Naziv priloge" className="tar before-input fl pr">
            Naziv pril.:
          </div>
          <input
            disabled={disabled}
            maxLength={stringDefaultLength}
            type="text"
            className="ml input dbl fl tal"
            value={that.state.document.attachment.name}
            onChange={e =>
              that.setNewDocumentAttachment(that, e.target.value, 'name')
            }
          />
        </div>
        <div className="clearfix pr">
          {that.state.document.attachment.description.length === 0
            ? that.showExclamation('vehicle')
            : null}
          <div className="tar before-input fl pr">Opis priloge:</div>
          <textarea
            disabled={disabled}
            maxLength={stringDefaultLength}
            className="ml input dbl fl tal"
            value={that.state.document.attachment.description}
            onChange={e =>
              that.setNewDocumentAttachment(that, e.target.value, 'description')
            }
          />
        </div>

        {disabled ? null : (
          <div className="clearfix mt5 pr">
            {that.state.document.attachment.files.length === 0
              ? that.showExclamation('files-left')
              : null}
            <div className="tar before-input fl mt6 lh20">Prenos datotek:</div>
            <div className="input-file-wrapper pr">
              <div className="input-file-message">
                Klikni (največ 8 MB)
              </div>
              <input
                disabled={disabled}
                multiple={true}
                className="input input-file pa"
                type="file"
                accept="*"
                onChange={e => that.setNewDocumentImage(that, e)}
              />
            </div>
          </div>
        )}
        {that.state.document &&
        that.state.document.attachment &&
        that.state.document.attachment.files &&
        that.state.document.attachment.files.length ? (
          <>
            <div className="clearfix mt">
              <div className="tar before-input fl mt6 lh20">
                Pregled datotek:
              </div>
              <div className="fl">{that.getAttachment(that)}</div>
            </div>
            {that.state.showImage ? (
              <>
                <div className="pr enlarge-image">
                  <a
                    onClick={() => that.enlargeImage(that)}
                    className="enlarge-image"
                  >
                    Povečaj sliko
                  </a>
                  <a
                    onClick={() => that.cancelImage(that)}
                    className="cancel-image pa"
                  >
                    ✗
                  </a>
                </div>
                {that.state.showImage ? (
                  <div
                    className={clsx(
                      'shown',
                      that.state.showImage ? '' : 'no-border',
                    )}
                  >
                    <img
                      src={that.state.src}
                      onClick={() => that.enlargeImage(that)}
                      className={that.state.showImage ? 'shown' : 'hidden'}
                    />
                  </div>
                ) : null}
                <div className="show-image dbl tac">{that.state.showImage}</div>
              </>
            ) : null}
          </>
        ) : null}
        {disabled ? null : (
          <div className="clearfix mt">
            <button
              disabled={
                that.state.document &&
                that.state.document.attachment &&
                that.state.document.attachment.files
                  ? that.state.document.attachment.files.length === 0
                    ? true
                    : false
                  : true
              }
              onClick={() => that.removeAttachmentFiles(that)}
              className="button button-red button-thin"
              title={
                'Število datotek: ' +
                that.state.document.attachment.files.length
              }
            >
              Odstrani datoteke
            </button>
            <button
              disabled={
                that.state.document &&
                that.state.document.attachment &&
                that.state.document.attachments
                  ? that.state.document.attachment.name.length === 0 ||
                    that.state.document.attachment.description.length === 0 ||
                    that.state.document.attachment.files.length === 0
                  : true
              }
              onClick={() => that.addAttachment(that)}
              className="ml5 button button-green button-thin"
            >
              {that.state.attachment === -1 ? 'Vnesi prilogo' : 'Uredi prilogo'}
            </button>
            {that.state.attachment === -1 ? null : (
              <button
                disabled={
                  that.state.document &&
                  that.state.document.attachment &&
                  that.state.document.attachments
                    ? that.state.document.attachment.name.length === 0 ||
                      that.state.document.attachment.description.length === 0 ||
                      that.state.document.attachment.files.length === 0
                    : true
                }
                title="Zapri prilogo"
                onClick={() => that.closeAttachment(that)}
                className="ml5 button button-red button-thin"
              >
                Zapri
              </button>
            )}
          </div>
        )}
        <div className="title mt">Seznam prilog</div>
        <hr />
        {that.getAttachments(that, disabled)}
        {disabled ? null : (
          <div className="clearfix mt">
            <button
              disabled={previewDocument ? !that.isAddDocumentPossible(that, that.state.document/*, true*/) : !that.isAddDocumentPossible(that, that.state.document)}
              onClick={() => that.addDocument(that, previewDocument)}
              className={clsx(
                'fl button button-thin',
                previewDocument ? 'button-red' : 'button-green',
              )}
            >
              {previewDocument ? 'Shrani spremembe' : 'Shrani'}
            </button>
            {previewDocument ? (
              <>
                <button
                  title = "Izvozi .json"
                  onClick={() => this.exportJson1()}
                  className="fl button button-orange button-thin ml5"
                >
                  ⬇ .json
                </button>

                <button
                  title="Izvozi .pdf"
                  onClick={() => this.print1(true)}
                  className="fl button button-orange button-thin ml5"
                >
                  ⬇ .pdf
                </button>

                <button
                  onClick={() => this.exportRawJson1()}
                  className="fl button button-orange button-thin ml5"
                >
                  Surovi .json
                </button>

                <button
                  onClick={() => this.print1(false)}
                  className="fl button button-green button-thin ml5"
                >
                  Tiskaj
                </button>
              </>
            ) :
            that.state.document.status === DocumentStatusUpdated ?
            <button
              disabled={
                !that.isAddDocumentPossible(that, that.state.document, true)
              }
              onClick={() => that.addDocument(that, undefined, true)}
              className={clsx('fl button button-thin button-green ml')}
            >
              Oddaja nadrejenemu
            </button>
            :
            that.state.document.status === DocumentStatusUpdatedPlus ?
            <button
              title = "Potrdi potni nalog"
              onClick={() =>
                that.confirmDocument(
                  that,
                  that.state.document.uuid,
                  DocumentStatusConfirmed,
                  true
                )
              }
              className="fl button button-thin button-red ml"
            >
              Potrdi
            </button>
            : that.state.document.status === DocumentStatusConfirmed ?
            <button
              title = "Potrdi potni nalog"
              onClick={() =>
                that.confirmDocument(
                  that,
                  that.state.document.uuid,
                  DocumentStatusFinalized,
                  true
                )
              }
              className="fl button button-thin button-red ml"
            >
              Potrdi
            </button>
            : null }
          </div>
        )}
      </>
    );
  }

  public getNewDocument(that): JSX.Element {
    const disabled: boolean = false
/*
    that.state.document && that.props.userData
        ? that.state.document.useruuid !== that.props.userData.uuid
        : true;
*/
    return that.state.document ? (
      <div>
        <div className="new-document-navigation clearfix">
          {that.getNewDocumentNavigation(that)}
        </div>
        {  that.state.addingPoints ? that.getAddingPoints(that) :
        that.state.ndn === NDN.BasicInformation
          ? that.getNewDocumentBasicInformation(that, disabled, false)
          : that.state.ndn === NDN.AccountingInformation
          ? that.getNewDocumentAccountingInformation(that, disabled, false, false)
          : that.state.ndn === NDN.Attachments
          ? that.getNewDocumentAttachments(that, disabled, false)
          : null}
      </div>
    ) : (
      <div />
    );
  }

  public getExposedCountries(): JSX.Element {
    return (
      <div>
        {this.state.countries ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.countries}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize10) {
                  this.setState({ pSize10: pageSize, pNum10: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum10: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: '',
                  width: 75,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'uuid',
                  Cell: row => (
                    <div className="pr">
                      &nbsp;
                      <img
                        className="flag flag-centered pa"
                        src={this.getFlagName(row.value)}
                      />
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Država',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'uuid',
                  Cell: row =>
                    this.props.userData.usertype === UserType.Admin ? (
                      <div>{row.original.name}</div>
                    ) : (
                      <select
                        onChange={e =>
                          this.updateValue(
                            'countries',
                            'country',
                            'uuid',
                            row.index,
                            row.original.uuid,
                            e.target.value,
                          )
                        }
                        className="select select-table mt2"
                        value={row.value}
                      >
                        {this.getCountriesOptions(true)}
                      </select>
                    ),
                },
                {
                  sortable: false,
                  Header: 'Vrstni red',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  width: 90,
                  accessor: 'ordinal',
                  Cell: row =>
                    this.props.userData.usertype === UserType.Admin ? (
                      <div>{row.value}.</div>
                    ) : (
                      <select
                        onChange={e =>
                          this.updateValue(
                            'countries',
                            'country',
                            'ordinal',
                            row.index,
                            row.original.uuid,
                            e.target.value,
                          )
                        }
                        className="select select-table mt2"
                        value={row.value}
                      >
                        {this.getOrdinalOptions(this.state.countries.length)}
                      </select>
                    ),
                },
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div>
                      {this.state.countries.length === row.original.ordinal ? (
                        <a
                          onClick={() =>
                            this.deleteCountry(
                              row.original.name,
                              row.original.uuid,
                              row.index,
                            )
                          }
                          className="delete"
                        >
                          ✗
                        </a>
                      ) : (
                        <span
                          title="Brisanje iz spiska izpostavljenih držav je možno samo za zadnjo državo"
                          className="no-delete"
                        >
                          ✗
                        </span>
                      )}
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.countries.length <= this.state.pSize10 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.countries.length < this.state.pSize10
                  ? this.state.countries.length
                  : this.state.pSize10
              }
              page={this.state.pNum10}
              pageSizeOptions={[defaultPageSize]}
            />
            <div>
              Skupno št. izpostavljenih držav: <strong>{this.state.countries.length}</strong>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">
                Registracija nove izpostavljene države
              </span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationCountry:
                      !this.state.showRegistrationCountry,
                  })
                }
              >
                {this.state.showRegistrationCountry ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationCountry ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Država:</div>
                  <select
                    className="ml fl dbl select select-input mt3"
                    value={this.state.Country}
                    onChange={e => this.setState({ Country: e.target.value })}
                  >
                    {this.getCountriesOptions(undefined, true)}
                  </select>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewCountryDisabled()}
                    onClick={() => this.newCountry()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi novo izpostavljeno državo
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getActive(activeArray: Array<{active: boolean}>): number {
    let active: number = 0
    if (activeArray) {
      for (const item of activeArray) {
        if (item.active) {
          active++
        }
      }
    }
    return active
  }

  public getExpense(): JSX.Element {
    return (
      <div>
        {this.state.expense ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.expense}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize9) {
                  this.setState({ pSize9: pageSize, pNum9: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum9: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv tipa stroška',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'expense',
                              'expense',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Status',
                  width: 75,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <button
                        onClick={() =>
                          this.updateValue(
                            'expense',
                            'expense',
                            'active',
                            row.index,
                            row.original.uuid,
                            !row.value,
                          )
                        }
                        title={
                          row.value ? 'Aktiven dodatni strošek' : 'Neaktiven dodatni strošek'
                        }
                        className={clsx(
                          'mt2 button button-thin',
                          row.value ? 'button-green' : 'button-red',
                        )}
                      >
                        {row.value ? 'Aktiven' : 'Neakt.'}
                      </button>
                    </div>
                  ),
                },
/*
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div>
                      <a
                        onClick={() =>
                          this.deleteExpense(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
*/
              ]}
              showPaginationTop={
                this.state.expense.length <= this.state.pSize9 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.expense.length < this.state.pSize9
                  ? this.state.expense.length
                  : this.state.pSize9
              }
              page={this.state.pNum9}
              pageSizeOptions={[defaultPageSize]}
            />
            <div className="pr">
              Skupno št. tipov dodatnih stroškov: <strong>{this.state.expense.length}</strong>
              <div className="pa paright0">
                od tega aktivnih: <strong>{this.getActive(this.state.expense)}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega tipa dodatnega stroška</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationExpense:
                      !this.state.showRegistrationExpense,
                  })
                }
              >
                {this.state.showRegistrationExpense ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationExpense ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Expense', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Expense}
                  />
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewExpenseDisabled()}
                    onClick={() => this.newExpense()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi nov tip dodatnega stroška
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getMileage(): JSX.Element {
    return (
      <div>
        {this.state.mileage ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.mileage}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize7) {
                  this.setState({ pSize7: pageSize, pNum7: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum7: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Naziv tipa kilometrine',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'mileage',
                              'mileage',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Znesek (€/km)',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'value',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            e.target.value.length
                              ? this.updateValue(
                                  'mileage',
                                  'mileage',
                                  'value',
                                  row.index,
                                  row.original.uuid,
                                  e.target.value,
                                )
                              : {}
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringShortLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Status',
                  width: 75,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <button
                        onClick={() =>
                          this.updateValue(
                            'mileage',
                            'mileage',
                            'active',
                            row.index,
                            row.original.uuid,
                            !row.value,
                          )
                        }
                        title={
                          row.value ? 'Aktiven tip kilometrine' : 'Neaktiven tip kilometrine'
                        }
                        className={clsx(
                          'mt2 button button-thin',
                          row.value ? 'button-green' : 'button-red',
                        )}
                      >
                        {row.value ? 'Aktiven' : 'Neakt.'}
                      </button>
                    </div>
                  ),
                },
/*
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div>
                      {parseFloat(row.original.value) === 0 ?
                        <span
                        title="Brisanje tipa kilometrine z vrednostjo 0 ni dovoljeno"
                        className="no-delete"
                      >
                        ✗
                      </span>
                      :
                      <a
                        onClick={() =>
                          this.deleteMileage(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>}
                    </div>
                  ),
                },
*/
              ]}
              showPaginationTop={
                this.state.mileage.length <= this.state.pSize7 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.mileage.length < this.state.pSize7
                  ? this.state.mileage.length
                  : this.state.pSize7
              }
              page={this.state.pNum7}
              pageSizeOptions={[defaultPageSize]}
            />
            <div className="pr">
              Skupno št. tipov kilometrin: <strong>{this.state.mileage.length}</strong>
              <div className="pa paright0">
                od tega aktivnih: <strong>{this.getActive(this.state.mileage)}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega tipa kilometrine</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationMileage:
                      !this.state.showRegistrationMileage,
                  })
                }
              >
                {this.state.showRegistrationMileage ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationMileage ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Mileage', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Mileage}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Znesek:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringShortLength}
                    onChange={e => this.edit('MileageValue', e.target.value)}
                    className="input input-narrow dbl fl"
                    type="text"
                    value={this.state.MileageValue}
                  />
                  <div className="ml fl">€</div>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewMileageDisabled()}
                    onClick={() => this.newMileage()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi nov tip kilometrine
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getActiveBackground(active: boolean): string {
    return active ? 'bg-green' : 'bg-red'
  }

  public getProjects(): JSX.Element {
    return (
      <div>
        {this.state.projects ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              className="rt-no-white-space mt"
              data={this.state.projects}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize4) {
                  this.setState({ pSize4: pageSize, pNum4: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum4: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Koda projekta',
                  width: 150,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'code',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'projects',
                              'project',
                              'code',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringDefaultLength}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'Naziv projekta',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'name',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      {this.props.userData.usertype === UserType.Admin ? (
                        row.value
                      ) : (
                        <input
                          onChange={e =>
                            this.updateValue(
                              'projects',
                              'project',
                              'name',
                              row.index,
                              row.original.uuid,
                              e.target.value,
                            )
                          }
                          type="text"
                          className={clsx('input input-table', this.getActiveBackground(row.original.active))}
                          value={row.value}
                          maxLength={stringLongLength}
                        />
                      )}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Projekt',
                  width: 75,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <button
                        onClick={() =>
                          this.updateValue(
                            'projects',
                            'project',
                            'active',
                            row.index,
                            row.original.uuid,
                            !row.value,
                          )
                        }
                        title={
                          row.value ? 'Aktiven projekt' : 'Neaktiven projekt'
                        }
                        className={clsx(
                          'mt2 button button-thin',
                          row.value ? 'button-green' : 'button-red',
                        )}
                      >
                        {row.value ? 'Aktiven' : 'Neakt.'}
                      </button>
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'uuid',
                  Cell: row => (
                    <div className = {this.getActiveBackground(row.original.active)}>
                      <a
                        onClick={() =>
                          this.deleteProject(
                            row.original.name,
                            row.original.uuid,
                            row.index,
                          )
                        }
                        className="delete"
                      >
                        ✗
                      </a>
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.projects.length <= this.state.pSize4 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.projects.length < this.state.pSize4
                  ? this.state.projects.length
                  : this.state.pSize4
              }
              page={this.state.pNum4}
              pageSizeOptions={[defaultPageSize]}
            />
            <div className ="pr">
              Skupno št. projektov: <strong>{this.state.projects.length}</strong>
              <div className="pa paright0">
                od tega aktivnih: <strong>{this.getActive(this.state.projects)}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega projekta</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistrationProject:
                      !this.state.showRegistrationProject,
                  })
                }
              >
                {this.state.showRegistrationProject ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistrationProject ? (
              <>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Koda:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('ProjectCode', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.ProjectCode}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Naziv:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringLongLength}
                    onChange={e => this.edit('Project', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Project}
                  />
                </div>
                <div className="mt5 tal">
                  <button
                    disabled={this.isNewProjectDisabled()}
                    onClick={() => this.newProject()}
                    className="button button-thin button-green mr"
                  >
                    Vnesi nov projekt
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getUserTableBackground(usertype: UserType, active: boolean): string {
    return !active ? '#fdd' : usertype === UserType.Regular ? '#dfd' : usertype === UserType.Admin ? '#ddf' : '#fff';
  }

  public getFilenames(a: Attachment[]): string {
    let f = '';
    for (const item of a) {
      for (const Item of item.files) {
        if (f.length) {
          f += ', ';
        }
        f += `${Item.name} (${Item.filename})`;
      }
    }
    return f;
  }

  public getStatusButtons(count: AnalyticsCount) {
    return <>
      <button title = {'Vsi potni nalogi [' + count.all + ']'} onClick = {() => this.filterDocuments('status', undefined, '*')} className = {clsx('dbl fl ds__ ml asterisk dsA', this.state.fStatus === '*' ? 'selected' : '')}>*</button>
      <button title = {DocumentStatusString[DocumentStatusUpdated] + ' [' + count.updated + ']'} onClick = {() => this.filterDocuments('status', undefined, DocumentStatusUpdated)} className = {clsx('dbl fl ds__ ml3 dsU', this.state.fStatus === DocumentStatusUpdated ? 'selected' : '')}>S</button>
      <button title = {DocumentStatusString[DocumentStatusUpdatedPlus] + ' [' + count.updatedPlus + ']'} onClick = {() => this.filterDocuments('status', undefined, DocumentStatusUpdatedPlus)} className = {clsx('dbl fl ds__ ml3 dsV', this.state.fStatus === DocumentStatusUpdatedPlus ? 'selected' : '')}>O</button>
      <button title = {DocumentStatusString[DocumentStatusConfirmed] + ' [' + count.confirmed + ']'} onClick = {() => this.filterDocuments('status', undefined, DocumentStatusConfirmed)} className = {clsx('dbl fl ds__ ml3 dsC', this.state.fStatus === DocumentStatusConfirmed ? 'selected' : '')}>P</button>
      <button title = {DocumentStatusString[DocumentStatusFinalized] + ' [' + count.finalized + ']'} onClick = {() => this.filterDocuments('status', undefined, DocumentStatusFinalized)} className = {clsx('dbl fl ds__ ml3 dsF', this.state.fStatus === DocumentStatusFinalized ? 'selected' : '')}>Z</button>
    </>
  }

  public exportTxt() {
    const now: string = this.toLocalString(new Date());
    const date: string =
      now.substring(8, 10) +
      '/' +
      now.substring(5, 7) +
      '/' +
      now.substring(0, 4) +
      ' ob ' +
      now.substring(11, 16);
    let txt: string = `Izvoz narejen dne ` + date + '\n\n';

    const dep: string = this.toLocalString(this.state.fDep).substring(0, 10);
    const arr: string = this.toLocalString(this.state.fArr).substring(0, 10);
    txt += `Izvoz potnih nalogov med ${dep.substring(8, 10)}/${dep.substring(
      5,
      7,
    )}/${dep.substring(0, 4)} in ${arr.substring(8, 10)}/${arr.substring(
      5,
      7,
    )}/${arr.substring(0, 4)}`;

    if (this.state.fUseruuid === '') {
      txt += ' za vse uporabnike';
    } else {
      txt += ' za uporabnika ' + this.getCompanyName(this.state.fCompanyuuid);
    }

    if (this.state.fCompanyuuid === '') {
      txt += ' za vsa podjetja';
    } else {
      txt += ' za podjetje ' + this.getCompanyName(this.state.fCompanyuuid);
    }

    if (this.state.fCompanyVehicle) {
      txt += ` (samo službena vozila)`;
    }

    if (this.state.fVehicleuuid !== '') {
      txt +=
        ' z registrsko tablico ' +
        this.getVehiclePlate(this, this.state.fVehicleuuid);
    }
    txt += '\n\n';
    let sum = 0;
    if (this.state.analyticsData && this.state.analyticsData.length) {
      for (let i = 0; i < this.state.analyticsData.length; i++) {
        txt +=
          i +
          1 +
          '. ' +
          this.getUserName(this.state.analyticsData[i].useruuid) +
          ' po nalogu ' +
          this.getUserName(this.state.analyticsData[i].orderuuid) +
          ', ';
        txt +=
          'od ' +
          this.formatDate(this.state.analyticsData[i].departure) +
          ' do ' +
          this.formatDate(this.state.analyticsData[i].arrival) +
          ', ';
        txt +=
          this.getProjectName(this.state.analyticsData[i].projectuuid, this.state.analyticsData[i].companyuuid) +
          ', ';

        txt += 'pot: ' + this.state.analyticsData[i].path + ', ';
        txt += vehicleDesc[this.state.analyticsData[i].vehicletype] + ', ';
        const adv: string = this.state.analyticsData[i].advance
          ? this.state.analyticsData[i].advance
          : '0';
        txt +=
          'št. prilog (' + this.state.analyticsData[i].attachments.length + '), ';
        txt +=
          'datoteke (' +
          this.getFilenames(this.state.analyticsData[i].attachments) +
          '), ';

        txt += 'predplačilo ' + adv + ' €, ';
        const km: string = this.showExpenseMileage(this.state.analyticsData[i]);
        txt +=
          this.state.analyticsData[i].mileage +
          ' km, znesek kilometrine ' +
          km +
          ' €, ';
        const su: string = this.showExpenseSubsistence(this.state.analyticsData[i]);
        txt +=
          'št. dnevnic (' +
          this.state.analyticsData[i].subsistence.length +
          ') v vrednosti ' +
          su +
          ' €, ';
        const ex: string = this.showExpenseExpense(this.state.analyticsData[i]);
        txt +=
          'št. stroškov (' +
          this.state.analyticsData[i].expenses.length +
          ') v vrednosti ' +
          ex +
          ' €, ';
        const value: number =
          Math.round(
            100 *
              (-parseFloat(adv) +
                parseFloat(km) +
                parseFloat(su) +
                parseFloat(ex)),
          ) / 100;
        sum += value;
        txt += 'skupaj ' + value.toFixed(2) + ' €\n';
      }
      txt += '\nSkupni znesek: ' + sum.toFixed(2) + ' €';
    } else {
      txt += 'Ni nobenega potnega naloga!';
    }

    this.download(
      txt,
      'text/plain',
      'Izvoz potnih nalogov ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
    );
  }

  public download(
    txt: string,
    mime: 'text/plain' | 'application/json' | 'application/pdf' | 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    filename: string,
    buffer?: Buffer
  ) {
    const ending =
      mime === 'text/plain'
        ? 'txt'
        : mime === 'application/json'
        ? 'json'
        : mime === 'text/csv'
        ? 'csv'
        : mime === 'application/pdf'
        ? 'pdf'
        : mime === EXCEL_TYPE
        ? 'xlsx'
        : '';
    const element = document.createElement('a');
    const file = new Blob([buffer ? buffer : txt], { type: mime });
    element.href = URL.createObjectURL(file);
    element.download = filename + '.' + ending;
    element.dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
  }

  public exportRawJson1() {
    if (this.state.document) {
      const now: string = this.toLocalString(new Date());
      const date: string =
        now.substring(8, 10) +
        '/' +
        now.substring(5, 7) +
        '/' +
        now.substring(0, 4) +
        ' ob ' +
        now.substring(11, 16);


      const cutDocument: DocumentData = JSON.parse(
        JSON.stringify(this.state.document),
      );
      if (cutDocument) {
        if (cutDocument.attachments) {
          for (let i = 0; i < cutDocument.attachments.length; i++) {
            for (let j = 0; j < cutDocument.attachments[i].files.length; j++) {
              if (cutDocument.attachments[i].files[j].value) {
                delete cutDocument.attachments[i].files[j].value;
              }
              if (cutDocument.attachments[i].files[j].binaryValue) {
                delete cutDocument.attachments[i].files[j].binaryValue;
              }
            }
          }
        }
      }

      const json: string = JSON.stringify(cutDocument, null, 4);
      this.download(
        json,
        'application/json',
        'Surovi izvoz potnega naloga ' +
          date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
      );
    } else {
      alert('Ni podatkov za izvoz!');
    }
  }

  public exportRawJson() {
    if (this.state.analyticsData && this.state.analyticsData.length) {
      const now: string = this.toLocalString(new Date());
      const date: string =
        now.substring(8, 10) +
        '/' +
        now.substring(5, 7) +
        '/' +
        now.substring(0, 4) +
        ' ob ' +
        now.substring(11, 16);

      const json: string = JSON.stringify(this.state.analyticsData, null, 4);
      this.download(
        json,
        'application/json',
        'Surovi izvoz potnih nalogov ' +
          date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
      );
    } else {
      alert('Ni podatkov za izvoz!');
    }
  }

  public exportJson1() {
    if (this.state.analyticsData && this.state.analyticsData.length) {
      const now: string = this.toLocalString(new Date());
      const date: string =
        now.substring(8, 10) +
        '/' +
        now.substring(5, 7) +
        '/' +
        now.substring(0, 4) +
        ' ob ' +
        now.substring(11, 16);
      const Narejen: string = `Izvoz narejen dne ` + date;
      const dep: string = this.toLocalString(this.state.fDep).substring(0, 10);
      const arr: string = this.toLocalString(this.state.fArr).substring(0, 10);
      let title = `Izvoz potnih nalogov med ${dep.substring(
        8,
        10,
      )}/${dep.substring(5, 7)}/${dep.substring(0, 4)} in ${arr.substring(
        8,
        10,
      )}/${arr.substring(5, 7)}/${arr.substring(0, 4)}`;
      if (this.state.fUseruuid === '') {
        title += ' za vse uporabnike';
      } else {
        title +=
          ' za uporabnika ' + this.getCompanyName(this.state.fCompanyuuid);
      }

      if (this.state.fCompanyuuid === '') {
        title += ' za vsa podjetja';
      } else {
        title += ' za podjetje ' + this.getCompanyName(this.state.fCompanyuuid);
      }

      if (this.state.fCompanyVehicle) {
        title += ` (samo službena vozila)`;
      }

      if (this.state.fVehicleuuid !== '') {
        title +=
          ' z registrsko tablico ' +
          this.getVehiclePlate(this, this.state.fVehicleuuid);
      }

      const a = {
        Naslov: title,
        Podatki: [],
        vsota: 0,
        Valuta: '€',
        Vsota: '',
        Narejen,
      };
      for (let i = 0; i < this.state.analyticsData.length; i++) {
        const adv: string = this.state.analyticsData[i].advance
          ? this.state.analyticsData[i].advance
          : '0';
        const km: string = this.showExpenseMileage(this.state.analyticsData[i]);
        const su: string = this.showExpenseSubsistence(this.state.analyticsData[i]);
        const ex: string = this.showExpenseExpense(this.state.analyticsData[i]);
        const value: number =
          Math.round(
            100 *
              (-parseFloat(adv) +
                parseFloat(km) +
                parseFloat(su) +
                parseFloat(ex)),
          ) / 100;

        const Dnevnice = [];
        for (let j = 0; j < this.state.analyticsData[i].subsistence.length; j++) {
          Dnevnice.push({
            Dan: j + 1,
            'Vrsta dnevnice': this.getSubsistenceName(
              this.state.analyticsData[i].subsistence[j].uuid,
            ),
            'Odbitek zajtrk': this.state.analyticsData[i].subsistence[j].breakfast
              ? 'Da'
              : 'Ne',
            'Odbitek kosilo': this.state.analyticsData[i].subsistence[j].lunch
              ? 'Da'
              : 'Ne',
            'Odbitek večerja': this.state.analyticsData[i].subsistence[j].dinner
              ? 'Da'
              : 'Ne',
            Znesek: this.getSubsistenceValue(
              this.state.analyticsData[i].subsistence[j],
            ),
          });
        }
        const Expenses = [];
        for (let j = 0; j < this.state.analyticsData[i].expenses.length; j++) {
          Expenses.push({
            'Strošek št.': j + 1,
            'Vrsta dodatnega stroška': this.getExpenseName(
              this.state.analyticsData[i].expenses[j].uuid,
            ),
            Znesek: this.state.analyticsData[i].expenses[j].value,
          });
        }

        const Points = [];
        for (let j = 0; j < this.state.analyticsData[i].pointuuids.length; j++) {
          Points.push({
            'Točka št.': j + 1,
            Naziv: this.getPointName(this.state.analyticsData[i].pointuuids[j]),
          });
        }

        const Priloge = [];
        for (let j = 0; j < this.state.analyticsData[i].attachments.length; j++) {
          const Datoteke = [];
          for (
            let k = 0;
            k < this.state.analyticsData[i].attachments[j].files.length;
            k++
          ) {
            Datoteke.push({
              'Datoteka št.': k + 1,
              Ime: this.state.analyticsData[i].attachments[j].files[k].name,
            });
          }

          Priloge.push({
            'Priloga št.': j + 1,
            Naziv: this.state.analyticsData[i].attachments[j].name,
            Opis: this.state.analyticsData[i].attachments[j].description,
            'Št. datotek': this.state.analyticsData[i].attachments[j].files.length,
            Datoteke,
          });
        }

        a.Podatki.push({
          Uporabnik: this.getUserName(this.state.analyticsData[i].useruuid),
          Nalogodajalec: this.getUserName(this.state.analyticsData[i].orderuuid),
          Odhod: this.formatDate(this.state.analyticsData[i].departure),
          Prihod: this.formatDate(this.state.analyticsData[i].arrival),
          Projekt: this.getProjectName(this.state.analyticsData[i].projectuuid, this.state.analyticsData[i].companyuuid),
          Pot: this.state.analyticsData[i].path ? this.state.analyticsData[i].path : '',
          Namen: this.state.analyticsData[i].intent
            ? this.state.analyticsData[i].intent
            : '',
          'Opis namena': this.state.analyticsData[i].description
            ? this.state.analyticsData[i].description
            : '',
          Podjetje: this.getCompanyName(this.state.analyticsData[i].companyuuid),
          'Tip vozila': vehicleDesc[this.state.analyticsData[i].vehicletype],
          Vozilo: this.getVehicle(this, this.state.analyticsData[i].vehicleuuid),
          Tablica: this.getVehiclePlate(
            this,
            this.state.analyticsData[i].vehicleuuid,
          ),
          Sopotniki: this.state.analyticsData[i].companions
            ? this.state.analyticsData[i].companions
            : '',
          'Št. točk': this.state.analyticsData[i].pointuuids.length,
          Točke: Points,

          'Št. prilog': this.state.analyticsData[i].attachments.length,
          Priloge,
          Predplačilo: adv + ' €',
          'Št. kilometrov (odhod)':
            (this.state.analyticsData[i].departuremileage
              ? this.state.analyticsData[i].departuremileage
              : '') + ' km',
          'Št. kilometrov (prihod)':
            (this.state.analyticsData[i].arrivalmileage
              ? this.state.analyticsData[i].arrivalmileage
              : '') + ' km',
          'Št. kilometrov': this.state.analyticsData[i].mileage + ' km',
          Kilometrina:
            this.getMileageValue(this.state.analyticsData[i].mileageuuid) + ' km',
          'Znesek kilometrine': km + ' €',
          'Št. dnevnic': this.state.analyticsData[i].subsistence.length,
          Dnevnice,
          'Znesek dnevnic': su + ' €',
          'Št. dodatnih stroškov': this.state.analyticsData[i].expenses.length,
          Stroški: Expenses,
          'Znesek dodatnih stroškov': ex + ' €',
          'Znesek skupaj': value + ' €',
        });

        a.vsota += value;
      }
      a.Vsota = a.vsota.toFixed(2) + ' €';
      const json: string = JSON.stringify(a, null, 4);

      this.download(
        json,
        'application/json',
        'Izvoz potnega naloga ' +
          date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
      );
    } else {
      alert('Ni podatkov za izvoz!');
    }
  }

  public exportJson(csv?: boolean) {
    if (this.state.analyticsData && this.state.analyticsData.length) {
      const now: string = this.toLocalString(new Date());
      const date: string =
        now.substring(8, 10) +
        '/' +
        now.substring(5, 7) +
        '/' +
        now.substring(0, 4) +
        ' ob ' +
        now.substring(11, 16);
      const Narejen: string = `Izvoz narejen dne ` + date;
      const dep: string = this.toLocalString(this.state.fDep).substring(0, 10);
      const arr: string = this.toLocalString(this.state.fArr).substring(0, 10);
      let title = `Izvoz potnih nalogov med ${dep.substring(
        8,
        10,
      )}/${dep.substring(5, 7)}/${dep.substring(0, 4)} in ${arr.substring(
        8,
        10,
      )}/${arr.substring(5, 7)}/${arr.substring(0, 4)}`;
      if (this.state.fUseruuid === '') {
        title += ' za vse uporabnike';
      } else {
        title +=
          ' za uporabnika ' + this.getCompanyName(this.state.fCompanyuuid);
      }

      if (this.state.fCompanyuuid === '') {
        title += ' za vsa podjetja';
      } else {
        title += ' za podjetje ' + this.getCompanyName(this.state.fCompanyuuid);
      }

      if (this.state.fCompanyVehicle) {
        title += ` (samo službena vozila)`;
      }

      if (this.state.fVehicleuuid !== '') {
        title +=
          ' z registrsko tablico ' +
          this.getVehiclePlate(this, this.state.fVehicleuuid);
      }

      const a = {
        Naslov: title,
        Podatki: [],
        vsota: 0,
        Valuta: '€',
        Vsota: '',
        Narejen,
      };
      for (let i = 0; i < this.state.analyticsData.length; i++) {
        const adv: string = this.state.analyticsData[i].advance
          ? this.state.analyticsData[i].advance
          : '0';
        const km: string = this.showExpenseMileage(this.state.analyticsData[i]);
        const su: string = this.showExpenseSubsistence(this.state.analyticsData[i]);
        const ex: string = this.showExpenseExpense(this.state.analyticsData[i]);
        const value: number =
          Math.round(
            100 *
              (-parseFloat(adv) +
                parseFloat(km) +
                parseFloat(su) +
                parseFloat(ex)),
          ) / 100;

        if (csv) {
          a.Podatki.push({
            Uporabnik: this.getUserName(this.state.analyticsData[i].useruuid),
            Nalogodajalec: this.getUserName(this.state.analyticsData[i].orderuuid),
            Odhod: this.formatDate(this.state.analyticsData[i].departure),
            Prihod: this.formatDate(this.state.analyticsData[i].arrival),
            Projekt: this.getProjectName(this.state.analyticsData[i].projectuuid, this.state.analyticsData[i].companyuuid),
            Pot: this.state.analyticsData[i].path
              ? this.state.analyticsData[i].path
              : '',
            Namen: this.state.analyticsData[i].intent
              ? this.state.analyticsData[i].intent.replace(/\n/g, '. ') //to avoid using the new line character in csv inside the fields
              : '',
            'Opis namena': this.state.analyticsData[i].description
              ? this.state.analyticsData[i].description.replace(/\n/g, '. ') //to avoid using the new line character in csv inside the fields
              : '',
            Podjetje: this.getCompanyName(this.state.analyticsData[i].companyuuid),
            'Tip vozila': vehicleDesc[this.state.analyticsData[i].vehicletype],
            Vozilo: this.getVehicle(this, this.state.analyticsData[i].vehicleuuid),
            Tablica: this.getVehiclePlate(
              this,
              this.state.analyticsData[i].vehicleuuid,
            ),
            Sopotniki: this.state.analyticsData[i].companions
              ? this.state.analyticsData[i].companions
              : '',
            'Št. prilog': this.state.analyticsData[i].attachments.length,
            Datoteke: this.getFilenames(this.state.analyticsData[i].attachments),
            Predplačilo: adv + ' €',
            'Št. kilometrov (odhod)':
              (this.state.analyticsData[i].departuremileage
                ? this.state.analyticsData[i].departuremileage
                : '?') + ' km',
            'Št. kilometrov (prihod)':
              (this.state.analyticsData[i].arrivalmileage
                ? this.state.analyticsData[i].arrivalmileage
                : '?') + ' km',
            'Št. kilometrov':
              (this.state.analyticsData[i].mileage
                ? this.state.analyticsData[i].mileage
                : '?') + ' km',
            Kilometrina:
              this.getMileageValue(this.state.analyticsData[i].mileageuuid) + ' €',
            'Znesek kilometrine': km + ' €',
            'Št. dnevnic': this.state.analyticsData[i].subsistence.length,
            'Znesek dnevnic': su + ' €',
            'Št. dodatnih stroškov': this.state.analyticsData[i].expenses.length,
            'Znesek dodatnih stroškov': ex + ' €',
            'Znesek skupaj': value.toFixed(2) + ' €',
          });
        }
        else { //json
          const Dnevnice = [];
          for (let j = 0; j < this.state.analyticsData[i].subsistence.length; j++) {
            Dnevnice.push({
              'Dan št.': j + 1,
              'Vrsta dnevnice': this.getSubsistenceName(
                this.state.analyticsData[i].subsistence[j].uuid,
              ),
              'Odbitek zajtrk': this.state.analyticsData[i].subsistence[j].breakfast
                ? 'Da'
                : 'Ne',
              'Odbitek kosilo': this.state.analyticsData[i].subsistence[j].lunch
                ? 'Da'
                : 'Ne',
              'Odbitek večerja': this.state.analyticsData[i].subsistence[j].dinner
                ? 'Da'
                : 'Ne',
              Znesek: this.getSubsistenceValue(
                this.state.analyticsData[i].subsistence[j],
              ),
            });
          }
          const Expenses = [];
          for (let j = 0; j < this.state.analyticsData[i].expenses.length; j++) {
            Expenses.push({
              'Strošek št.': j + 1,
              'Vrsta dodatnega stroška': this.getExpenseName(
                this.state.analyticsData[i].expenses[j].uuid,
              ),
              Znesek: this.state.analyticsData[i].expenses[j].value,
            });
          }

          const Priloge = [];
          for (let j = 0; j < this.state.analyticsData[i].attachments.length; j++) {
            const Datoteke = [];
            for (
              let k = 0;
              k < this.state.analyticsData[i].attachments[j].files.length;
              k++
            ) {
              Datoteke.push({
                'Datoteka št.': k + 1,
                Ime: this.state.analyticsData[i].attachments[j].files[k].name,
              });
            }

            Priloge.push({
              'Priloga št.': j + 1,
              Naziv: this.state.analyticsData[i].attachments[j].name,
              Opis: this.state.analyticsData[i].attachments[j].description,
              'Št. datotek':
                this.state.analyticsData[i].attachments[j].files.length,
              Datoteke,
            });
          }

          a.Podatki.push({
            Uporabnik: this.getUserName(this.state.analyticsData[i].useruuid),
            Nalogodajalec: this.getUserName(this.state.analyticsData[i].orderuuid),
            Odhod: this.formatDate(this.state.analyticsData[i].departure),
            Prihod: this.formatDate(this.state.analyticsData[i].arrival),
            Projekt: this.getProjectName(this.state.analyticsData[i].projectuuid, this.state.analyticsData[i].companyuuid),
            Pot: this.state.analyticsData[i].path
              ? this.state.analyticsData[i].path
              : '',
            Namen: this.state.analyticsData[i].intent
              ? this.state.analyticsData[i].intent
              : '',
            'Opis namena': this.state.analyticsData[i].description
              ? this.state.analyticsData[i].description
              : '',
            Podjetje: this.getCompanyName(this.state.analyticsData[i].companyuuid),
            'Tip vozila': vehicleDesc[this.state.analyticsData[i].vehicletype],
            Vozilo: this.getVehicle(this, this.state.analyticsData[i].vehicleuuid),
            Tablica: this.getVehiclePlate(
              this,
              this.state.analyticsData[i].vehicleuuid,
            ),
            Sopotniki: this.state.analyticsData[i].companions
              ? this.state.analyticsData[i].companions
              : '',
            'Št. prilog': this.state.analyticsData[i].attachments.length,
            Priloge,
            Predplačilo: adv + ' €',
            'Št. kilometrov (odhod)':
              (this.state.analyticsData[i].departuremileage
                ? this.state.analyticsData[i].departuremileage
                : '') + ' km',
            'Št. kilometrov (prihod)':
              (this.state.analyticsData[i].arrivalmileage
                ? this.state.analyticsData[i].arrivalmileage
                : '') + ' km',
            'Št. kilometrov': this.state.analyticsData[i].mileage + ' km',
            Kilometrina:
              this.getMileageValue(this.state.analyticsData[i].mileageuuid) + ' km',
            'Znesek kilometrine': km + ' €',
            'Št. dnevnic': this.state.analyticsData[i].subsistence.length,
            Dnevnice,
            'Znesek dnevnic': su + ' €',
            'Št. dodatnih stroškov': this.state.analyticsData[i].expenses.length,
            Stroški: Expenses,
            'Znesek dodatnih stroškov': ex + ' €',
            'Znesek skupaj': value + ' €',
          });
        } //else { //json
        a.vsota += value;
      }
      a.Vsota = a.vsota.toFixed(2) + ' €';
      if (csv) {
        const parser = new Parser({quote: "'"});

        const csvText = parser.parse(a.Podatki);

        const exportFileName = 'Izvoz potnih nalogov ' + date.replace(':', ' ').replace('/', ' ').replace('/', ' ');

        this.download(
          csvText,
          'text/csv',
          exportFileName,
        );

        alert(`csv file *${exportFileName}.csv* has been exported.\n\n(content: ${a.Naslov}).`);
      }
      else {
        const json: string = JSON.stringify(a, null, 4);
        this.download(
          json,
          'application/json',
          'Izvoz potnih nalogov ' +
            date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
        );
      }
    } else {
      alert('Ni podatkov za izvoz!');
    }
  }
  public print1(download: boolean) {
    const now: string = this.toLocalString(new Date());
    const date: string =
      now.substring(8, 10) +
      '/' +
      now.substring(5, 7) +
      '/' +
      now.substring(0, 4) +
      ' ob ' +
      now.substring(11, 16);
    const opt = {
      margin: [10, 20, 1, 1],
      filename:
        'Izvoz potnih nalogov ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' ') +
        '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const element = document.getElementById('sheet00');
    if (element) {
      if (download) {
        html2pdf().from(element).set(opt).save();
      } else {
        html2pdf()
          .from(element)
          .set(opt)
          .toPdf()
          .get('pdf')
          .then(pdfObj => {
            pdfObj.autoPrint();
            window.open(pdfObj.output('bloburl'), '_blank');
          });
      }
    }
  }

  public kePrint(download: boolean) {
    const now: string = this.toLocalString(new Date());
    const date: string =
      now.substring(8, 10) +
      '/' +
      now.substring(5, 7) +
      '/' +
      now.substring(0, 4) +
      ' ob ' +
      now.substring(11, 16);

    const lines: number = this.state.kedataAll0.length
    let n: number = Math.ceil(lines / linesPerPage)
    if (n === 0) {n = 1}

    const opt = {
      margin: 1,
      filename:
        'Izvoz kadrovske evidence ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' ') +
        '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };

    const element0 = document.getElementById('sheet1');
    let doc = html2pdf().from(element0).set(opt).toPdf();

    for (let i: number = 2; i <= n; i++) {
      const element = document.getElementById('sheet' + i);
      doc = doc.get('pdf').then(
        pdf => { pdf.addPage() }
      ).from(element).toContainer().toCanvas().toPdf()
    }

    const elementLast = document.getElementById('sheet' + (n+1));
    if (elementLast) {
      doc = doc.get('pdf').then(
        pdf => { pdf.addPage() }
      ).from(elementLast).toContainer().toCanvas().toPdf()
    }

    if (download) {
      doc.save()
    } else {
      doc.get('pdf').then((pdfObj) => {
        pdfObj.autoPrint();
        window.open(pdfObj.output('bloburl'), '_blank');
      })
    }
  }

  public ketoPrint(download: boolean) {
    const now: string = this.toLocalString(new Date());
    const date: string =
      now.substring(8, 10) +
      '/' +
      now.substring(5, 7) +
      '/' +
      now.substring(0, 4) +
      ' ob ' +
      now.substring(11, 16);
    const opt = {
      margin: 1,
      filename:
        'Izvoz kadrovske evidence za uporabnika z e-pošto ' + this.state.kedata.email + ' ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' ') +
        '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const element = document.getElementById('ketoprint');
    if (element) {
      if (download) {
        html2pdf().from(element).set(opt).save();
      } else {
        html2pdf()
          .from(element)
          .set(opt)
          .toPdf()
          .get('pdf')
          .then(pdfObj => {
            pdfObj.autoPrint();
            window.open(pdfObj.output('bloburl'), '_blank');
          });
      }
    }
  }

  public print(download: boolean) {
    const now: string = this.toLocalString(new Date());
    const date: string =
      now.substring(8, 10) +
      '/' +
      now.substring(5, 7) +
      '/' +
      now.substring(0, 4) +
      ' ob ' +
      now.substring(11, 16);
    const opt = {
      margin: 1,
      filename:
        'Izvoz potnih nalogov ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' ') +
        '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };

    const element = document.getElementById('sheet');
    if (element) {
      if (download) {
        html2pdf().from(element).set(opt).save();
      } else {
        html2pdf()
          .from(element)
          .set(opt)
          .toPdf()
          .get('pdf')
          .then(pdfObj => {
            pdfObj.autoPrint();
            window.open(pdfObj.output('bloburl'), '_blank');
          });
      }
    }
  }

  public handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  public getUserUuid(name: string): string {
    if (this.state.userData) {
      for (const item of this.state.userData) {
        if (name === (item.lastname + ' ' + item.firstname)) {return item.uuid}
      }
    }
    return ''
  }

  public getCompanyUuid(name: string): string {
    if (this.state.companies) {
      for (const item of this.state.companies) {
        if (name === item.name) {return item.uuid}
      }
    }
    return ''
  }

  public getProjectUuid(codename: string): string {
    if (this.state.projects) {
      for (const item of this.state.projects) {
        if (codename === item.code.replace('X', '1') + ' - ' + item.name
        || codename === item.code.replace('X', '2') + ' - ' + item.name
        || codename === item.code.replace('X', '3') + ' - ' + item.name) {
          return item.uuid
        }
      }
    }
    return ''
  }

  public getLicensePlateUuid(plate: string): string {
    if (this.state.vehicles) {
      for (const item of this.state.vehicles) {
        if (plate === item.plate) {return item.uuid}
      }
    }
    return ''
  }

  public countPointsDatalist(that, name: string): boolean {
    if (!name || name.length === 0) {return true}
    if (that.state.points) {
      for (const item of this.state.points) {
        if (!item.active) {continue}
        if (item.name && item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1) {
          return true
        }
      }
    }
    return false
  }

  public countUserDatalist(fullName: string): number {
    if (!fullName) {return 1}
    if (this.state.userData) {
      for (const item of this.state.userData) {
        if (item && (item.lastname + ' ' + item.firstname).toLowerCase().indexOf(fullName.toLowerCase()) !== -1) {
          return 1
        }
      }
    }
    return 0
  }

  public async setSequential(index: number, value: string) {
    if (value.length <= 10 && !isNaN(value as any) && value.indexOf('.') === -1 && value.indexOf('-') === -1) {
      const Value: number = +value
      if (Value <= 2147483647) {
        const response = await axios.put(sequential, {
          uuid: this.state.analyticsData[index].uuid,
          value: Value,
          token: this.props.userData.token
        })
        if (response && response.data && response.data.resolved) {
          const analyticsData: DocumentData[] = Object.assign([], this.state.analyticsData)
          analyticsData[index].sequential = Value
          this.setState({analyticsData})
        }
      }
    }
  }

  public async copyDocument(dd: DocumentData) {
    const date = new Date()
    const thisYear = date.getFullYear()
    const thisMonth = date.getMonth()
    date.setMonth(thisMonth + 1)
    date.setDate(1)
    date.setDate(0)
    let lastDayInMonth = date.getDate()
    let thisMonthFormatted: string = (thisMonth + 1).toString()
    if (thisMonthFormatted.length === 1) {thisMonthFormatted = '0' + thisMonthFormatted}
    // const initd = this.initDocument()
    const data: InsertDocument = {
      uuid: null,
      email: this.props.userData.email,
      useruuid: this.props.userData.uuid,
      // uuid: initd.uuid,
      // useruuid: initd.useruuid,
      departure: thisYear + '-' + thisMonthFormatted + '-01',
      arrival: thisYear + '-' + thisMonthFormatted + '-' + lastDayInMonth,
      departureDatePlanned: new Date(thisYear,thisMonth,1,12),
      arrivalDatePlanned: new Date(thisYear,thisMonth,lastDayInMonth,12),
      departureHourPlanned: '00',
      departureMinutePlanned: '00',
      arrivalHourPlanned: '23',
      arrivalMinutePlanned: '59',
      vehicleTypePlanned: dd.vehicletype,
      vehicleuuidPlanned: dd.vehicleuuid,
      projectuuidPlanned: dd.projectuuid ? dd.projectuuid : '',
      companyuuidPlanned: dd.companyuuid ? dd.companyuuid : '',
      intentPlanned: dd.intent ? dd.intent : '',
      descriptionPlanned: dd.description ? dd.description : '',
      companionsPlanned: dd.companions ? dd.companions : '',
      orderuuidPlanned: dd.orderuuid ? dd.orderuuid : '',
      pointuuids: dd.pointuuids,
      path: dd.path ? dd.path : '',
      mileageGoogle: dd.mileageGoogle,
      status: DocumentStatusUpdated
    }
    data['departureDate'] = new Date(data.departure + 'T12:00:00.000Z');
    data.departure += ' ' + data.departureHourPlanned + ':' + data.departureMinutePlanned;
    data['departureHour'] = data.departureHourPlanned;
    data['departureMinute'] = data.departureMinutePlanned;

    data['arrivalDate'] = new Date(data.arrival + 'T12:00:00.000Z');
    data.arrival += ' ' + data.arrivalHourPlanned + ':' + data.arrivalMinutePlanned;
    data['arrivalHour'] = data.arrivalHourPlanned;
    data['arrivalMinute'] = data.arrivalMinutePlanned;

    data['vehicletype'] = data.vehicleTypePlanned;
    data['vehicleuuid'] = data.vehicleuuidPlanned;
    data['projectuuid'] = data.projectuuidPlanned;
    data['companyuuid'] = data.companyuuidPlanned;
    data['intent'] = data.intentPlanned;
    data['description'] = data.descriptionPlanned;
    data['companions'] = data.companionsPlanned;
    data['orderuuid'] = data.orderuuidPlanned;

    data['token'] = this.props.userData.token;

    if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: copyDocument)');
    const response = await axios.post(documentRoute, data);
    if (
      response &&
      response.data &&
      response.data.resolved &&
      response.data.uuid
    ) {
      if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: copyDocument)');
      delete data['token'];
      data.uuid = response.data.uuid
      const documents: DocumentData[] = Object.assign(
        [],
        this.state.documents,
      );
      documents.unshift(data as DocumentData);
      const Documents: number[] = Object.assign([], this.state.Documents)
      Documents.unshift(0)
      this.setState({
        Documents,
        documents,
      });
      alert('Rezervacija uspešno shranjena');
    }
    else if (response && response.data.resolved === false && response.data.message) {
      if (ALERT_SERVER_REQUESTS) alert(`Error: Failed response from server! (func: copyDocument)\nmsg: ${response.data.message}`);
    }
    else {
      if (ALERT_SERVER_REQUESTS) alert('Error: Failed response from server! (func: copyDocument)');
    }
  }

  public getAnalytics(): JSX.Element {
    const filtered: Filter[] = []
    if (this.state.navigation === Navigation.AnalyticsUser) {
      if (this.state.showJustMine && !this.state.showMineBoss) {
        filtered.push({id: 'customfilter', value: '1'})
      } else if (!this.state.showJustMine && this.state.showMineBoss) {
        filtered.push({id: 'customfilter', value: '0'})
      }
    } else {
      if (this.state.showProblematic) {
        filtered.push({id: 'customfilter', value: '1'})
      }
    }
    return (
      <div className = "pr">
        <div className="clearfix datepicker-shorter">
          <div className="fl bold">Filtri:</div>
          {this.state.navigation === Navigation.AnalyticsUser ? null :
          <div className = "clearfix pr fl mt-2">
            <input
              list="fuser"
              className = "ml fl dbl input input1"
              value = {this.state.FUseruuid}
              onChange={(e) => {
                                   if (e.target.value.length === 0 || e.target.value.length >= 3) {
                                       this.filterDocuments('user', undefined, e.target.value);
                                   }
                                   else {
                                       this.setState({FUseruuid: e.target.value, fUseruuid: ''})
                                   }
                               }
                       }
              placeholder = "Izberi uporabnika"
            />
            <datalist id = "fuser">
              {this.getUserDatalist(this.state.FUseruuid, undefined, undefined, false, false, true)}
            </datalist>
            {this.countUserDatalist(this.state.FUseruuid) === 0 ? <span className = "red pa nohit9">Brez zadetka!</span> : null}
            {this.state.FUseruuid && this.state.FUseruuid.length > 0 ? <span onClick={() => this.filterDocuments('user', undefined, '')} className="red pa" style = {{cursor: 'pointer', top: '7px', right: '1px', lineHeight: '13px'}}>✗</span> : null}
          </div>}
          {this.state.navigation === Navigation.AnalyticsUser ? null :
          <div className = "clearfix pr fl mt-2">
            <input
              list="fuser2"
              className = "ml fl dbl input input1"
              value = {this.state.FOrderuuid}
              onChange={(e) => {
                                    if (e.target.value.length === 0 || e.target.value.length >= 3) {
                                        this.filterDocuments('order', undefined, e.target.value);
                                    }
                                    else {
                                        this.setState({FOrderuuid: e.target.value, fOrderuuid: ''})
                                    }
                                }
                       }
              placeholder = "Izberi nosilca"
            />
            <datalist id = "fuser2">
              {this.getUserDatalist(undefined, undefined, undefined, true, undefined, true)}
            </datalist>
            {this.countUserDatalist(this.state.FOrderuuid) === 0 ? <span className = "red pa nohit9">Brez zadetka!</span> : null}
            {this.state.FOrderuuid && this.state.FOrderuuid.length > 0 ? <span onClick={() => this.filterDocuments('order', undefined, '')} className="red pa" style = {{cursor: 'pointer', top: '7px', right: '1px', lineHeight: '13px'}}>✗</span> : null}
          </div>}
          <div className="fl ml5">od:</div>
          <DatePicker
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={this.state.fDep}
            onChange={fDep => this.filterDocuments('departure', fDep)}
          />
          <div className="fl ml5">do:</div>
          <DatePicker
            calendarStartDay={1}
            onKeyDown={this.handleDateChangeRaw}
            placeholderText={placeholderDatePicker}
            dateFormat={dateFormatSlash}
            locale={locale}
            selected={this.state.fArr}
            onChange={fArr => this.filterDocuments('arrival', fArr)}
          />
          <div className = "clearfix pr fl mt-2">
            <input style = {{width: '190px'}} list="fcompany" className = "ml fl dbl input input1" value = {this.state.FCompanyuuid} onChange={(e) =>
              this.filterDocuments('company', undefined, e.target.value)}
              placeholder = "Izberi podjetje"
            />
            <datalist id = "fcompany">
              {this.getCompanyDatalist()}
            </datalist>
            {this.countCompanyDatalist(this.state.FCompanyuuid) === 0 ? <span className = "red pa nohit99">Brez zadetka!</span> : null}
            {this.state.FCompanyuuid && this.state.FCompanyuuid.length > 0 ? <span onClick={() => this.filterDocuments('company', undefined, '')} className="red pa" style = {{cursor: 'pointer', top: '7px', right: '1px', lineHeight: '13px'}}>✗</span> : null}
          </div>

          <input
            className="fl ml dbl mt8"
            checked={this.state.fCompanyVehicle}
            type="checkbox"
            onChange={() => this.filterDocuments('only-company-vehicles')}
          />
          <div className="fl ml5">samo službena vozila</div>
          <div className = "fl mt-2 pr">
            <input style = {{width: '115px'}} list="fplate" className = "ml dbl input input2" value = {this.state.FVehicleuuid} onChange={(e) =>
              this.filterDocuments('plate', undefined, e.target.value)}
              placeholder = "Izberi vozilo"
            />
            <datalist id = "fplate">
              {this.getVehiclePlateDatalist()}
            </datalist>
            {this.countVehiclePlateDatalist(this.state.FVehicleuuid) === 0 ? <span className = "red pa nohit2">Brez zadetka!</span> : null}
          </div>

          <div className = "fl mt-2 pr">
            <input style = {{width: '130px'}} list="fproject" className = "ml dbl input input3" value = {this.state.FProjectuuid} onChange={(e) =>
              this.filterDocuments('project', undefined, e.target.value)}
              placeholder = "Izberi projekt"
            />
            <datalist id = "fproject">
              {this.getProjectDatalist()}
            </datalist>
            {this.countProjectDatalist(this.state.FProjectuuid) === 0 ? <span className = "red pa nohit3">Brez zadetka!</span> : null}
          </div>

          {this.getStatusButtons(this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUserCount : this.state.analyticsDataCount)}

          {this.state.navigation === Navigation.AnalyticsUser ?
          null
          :
          <>
            <button
              onClick={() => this.print(false)}
              className="fr button button-green button-thin ml"
            >
              Tiskaj
            </button>

            <button
              onClick={() => this.exportRawJson()}
              className="fr button button-orange button-thin ml"
            >
              Surovi .json
            </button>

            <button
              title="Shrani .pdf"
              onClick={() => this.print(true)}
              className="fr button button-orange button-thin ml"
            >
              ⬇ .pdf
            </button>

            <button
              title="Izvozi .json"
              onClick={() => this.exportJson()}
              className="fr button button-orange button-thin ml"
            >
              ⬇ .json
            </button>

            <button
              title="Izvozi .csv"
              onClick={() => this.exportJson(true)}
              className="fr button button-orange button-thin ml"
            >
              ⬇ .csv
            </button>
            <button
              title="Izvozi .txt"
              onClick={() => this.exportTxt()}
              className="fr button button-orange button-thin"
            >
              ⬇ .txt
            </button>
          </>}
        </div>
        {this.state.navigation === Navigation.AnalyticsUser && this.state.analyticsUser || this.state.navigation === Navigation.Analytics && this.state.analyticsData ? (
          <ReactTable
            defaultFilterMethod={(filter, row) =>
              this.state.navigation === Navigation.AnalyticsUser && filter.id === 'customfilter' && filter.value === '1' ?
                this.props.userData.uuid === row._original.useruuid
              : this.state.navigation === Navigation.AnalyticsUser && filter.id === 'customfilter' && filter.value === '0' ?
                this.props.userData.uuid === row._original.orderuuid
              : this.state.navigation === Navigation.Analytics && filter.id === 'customfilter' ?
                row._original.useruuid === row._original.orderuuid
              : null}
            filtered = {filtered}
            {...reactTableTranslation}
            className="rt-no-white-space mt"
            data={this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser : this.state.analyticsData}
            onPageSizeChange={(pageSize, pageIndex) => {
              if (this.state.navigation === Navigation.AnalyticsUser) {
                if (pageSize !== this.state.pSize13) {
                  this.setState({ pSize13: pageSize, pNum13: pageIndex });
                }
              } else {
                if (pageSize !== this.state.pSize12) {
                  this.setState({ pSize12: pageSize, pNum12: pageIndex });
                }
              }
            }}
            onPageChange={pageIndex => {
              if (this.state.navigation === Navigation.AnalyticsUser) {
                this.setState({ pNum13: pageIndex });
              } else {
                this.setState({ pNum12: pageIndex });
              }
            }}
            columns={[
              {
                sortable: false,
                Header: <span title = "Status dokumenta">St</span>,
                width: 32,
                className: 'rt-smallrow tac',
                headerClassName: 'tac',
                accessor: 'status',
                Cell: row => <div className = {'ds_ ds' + row.value} title = {DocumentStatusString[row.value]}>
                  {DocumentStatusString[row.value].charAt(0)}</div>,
              },
              {
                show: this.props.userData.usertype === UserType.Super && this.state.fStatus === DocumentStatusFinalized,
                sortable: false,
                Header: <span title = "Identifikacijska zaporedna št.">Ident. št.</span>,
                width: 90,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'sequential',
                Cell: row => <input disabled={this.state.navigation === Navigation.AnalyticsUser} maxLength={10} style = {{ width: '89px'}} onChange = {(e) => this.setSequential(row.index, e.target.value)} value = {row.value} type = "text"/>,
              },
              {
                show: this.state.navigation === Navigation.Analytics,
                sortable: false,
                Header: 'Uporabnik',
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'useruuid',
                Cell: row => <div>{this.getUserName(row.value)}</div>,
              },
              {
                show: this.state.navigation === Navigation.AnalyticsUser,
                sortable: false,
                Header: 'Kopiraj/Nosilec PN',
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'customfilter',
                Cell: row => <div>
                  {this.props.userData.uuid !== row.original.useruuid ? this.getUserName(row.original.useruuid) :
                  <button title = "Kopiraj potni nalog" onClick = {() => this.copyDocument(row.original)} className = "button button-green button-thin mt5 dbl">
                    Kopiraj
                  </button>}
                </div>,
              },
              {
                sortable: false,
                Header: 'Po nalogu',
                width: 170,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'orderuuid',
                Cell: row => <div>{this.getUserName(row.value)}</div>,
              },
              {
                sortable: false,
                Header: 'Odhod',
                width: this.state.fStatus === DocumentStatusFinalized ? 95 : 140,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'departure',
                Cell: row => <div>{this.formatDate(row.value)}</div>,
              },
              {
                sortable: false,
                Header: 'Prihod',
                width: this.state.fStatus === DocumentStatusFinalized ? 95 : 140,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'arrival',
                Cell: row => <div>{this.formatDate(row.value)}</div>,
              },

              {
                sortable: false,
                Header: 'Projekt',
                width: 100,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'projectuuid',
                Cell: row => <div>{this.getProjectName(row.value, row.original.companyuuid)}</div>,
              },
/*
              {
                sortable: false,
                Header: 'Stranka',
                width: 90,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'customeruuid',
                Cell: row => <div>{this.getCustomerName(row.value)}</div>,
              },
*/
              {
                sortable: false,
                Header: 'Podjetje',
                width: 77,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'companyuuid',
                Cell: row => <div>{this.getCompanyName(row.value)}</div>,
              },
              {
                sortable: false,
                Header: 'Vozilo',
                width: 100,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'vehicleuuid',
                Cell: row => <div>{this.getVehicle(this, row.value)}</div>,
              },
              {
                sortable: false,
                Header: <span title = "Tip vozila">V</span>,
                width: 22,
                className: 'rt-smallrow tac semi-bold',
                headerClassName: 'tac',
                accessor: 'vehicletype',
                Cell: row => (
                  <div title={vehicleDesc[row.value]}>
                    {vehicleType[row.value]}
                  </div>
                ),
              },

              {
                sortable: false,
                Header: 'Tablica',
                width: 100,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'vehicleuuid',
                Cell: row => (
                  <div className="license-plate-table">
                    {this.getVehiclePlate(this, row.value)}
                  </div>
                ),
              },

              {
                sortable: false,
                Header: 'Relacija',
                width: 190,
                className: 'rt-smallrow tal',
                headerClassName: 'tal',
                accessor: 'path',
                Cell: row => (
                  <div>
                    {row.value}
                  </div>
                ),
              },
/*
              {
                sortable: false,
                Header: 'Predpl.',
                width: 70,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'advance',
                Cell: row => (
                  <div>
                    {(
                      Math.round(
                        (row.value ? parseFloat(row.value) : 0) * 100,
                      ) / 100
                    ).toFixed(2)} €
                  </div>
                ),
              },
*/
              {
                sortable: false,
                Header: 'Km od.',
                width: 70,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'departuremileage',
                Cell: row => <div>{row.value}</div>,
              },
              {
                sortable: false,
                Header: 'Km pr.',
                width: 70,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'arrivalmileage',
                Cell: row => <div>{row.value}</div>,
              },
              {
                sortable: false,
                Header: 'km',
                width: 50,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'mileage',
                Cell: row => <div>{row.value}</div>,
              },
              /*
              {
                sortable: false,
                Header: 'km€',
                width: 46,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'mileageuuid',
                Cell: row => <div>{this.getMileageValue(row.value)}</div>,
              },
              */
              {
                sortable: false,
                Header: 'Znesek',
                width: 70,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'mileageuuid',
                Cell: row => (
                  <div>{this.showExpenseMileage(row.original)} €</div>
                ),
              },
              {
                sortable: false,
                Header: <span title = "število dnevnic">#D</span>,
                width: 35,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'subsistence',
                Cell: row => <div>{row.value.length}</div>,
              },
              {
                sortable: false,
                Header: 'Dnevnice',
                width: 90,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'subsistence',
                Cell: row => (
                  <div>{this.showExpenseSubsistence(row.original)} €</div>
                ),
              },
              /*
              {
                sortable: false,
                Header: <span title = "število stroškov">#S</span>,
                width: 35,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'expenses',
                Cell: row => <div>{row.value.length}</div>,
              },
              */
              {
                sortable: false,
                Header: 'Stroški',
                width: 80,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'expenses',
                Cell: row => (
                  <div>{this.showExpenseExpense(row.original)} €</div>
                ),
              },
              {
                sortable: false,
                Header: 'Skupaj',
                width: 80,
                className: 'rt-smallrow tar',
                headerClassName: 'tar',
                accessor: 'advance',
                Cell: row => (
                  <div>
                    {(
                      Math.round(
                        100 *
                          (-(row.value ? parseFloat(row.value) : 0) +
                            parseFloat(this.showExpenseExpense(row.original)) +
                            parseFloat(
                              this.showExpenseSubsistence(row.original),
                            ) +
                            parseFloat(this.showExpenseMileage(row.original))),
                      ) / 100
                    ).toFixed(2)} €
                  </div>
                ),
              },

              {
                show: this.state.navigation === Navigation.Analytics && this.props.userData.usertype === UserType.Super,
                sortable: false,
                Header: <span title="Brisanje potnega naloga">🗑</span>,
                width: 30,
                className: 'rt-smallrow tac',
                headerClassName: 'tac',
                accessor: 'customfilter',
                Cell: row => (
                  <div>
                    <button
                      title="Izbriši potni nalog"
                      className="button button-red button-thin button-transparent"
                      onClick={() => this.deleteDocument(this, row.original.uuid, undefined, true)}
                    >
                      🗑
                    </button>
                  </div>
                ),
              },

              {
                show: this.state.navigation === Navigation.Analytics,
                sortable: false,
                Header: 'Odpri',
                width: 65,
                className: 'rt-smallrow tac',
                headerClassName: 'tac',
                accessor: 'customfilter',
                Cell: row => (
                  <div>
                    <button
                      className="button button-green button-thin"
                      onClick={() => this.openDoc(row.original)}
                    >
                      Odpri
                    </button>
                  </div>
                ),
              },
            ]}
            showPaginationTop={
              this.state.navigation === Navigation.AnalyticsUser ?
              this.state.analyticsUser.length <= this.state.pSize13 ? false : true
              :
              this.state.analyticsData.length <= this.state.pSize12 ? false : true
            }
            showPaginationBottom={false}
            pageSize={
              this.state.navigation === Navigation.AnalyticsUser ?
              this.state.analyticsUser.length < this.state.pSize13
                ? this.state.analyticsUser.length
                : this.state.pSize13
              :
              this.state.analyticsData.length < this.state.pSize12
                ? this.state.analyticsData.length
                : this.state.pSize12
            }
            page={this.state.navigation === Navigation.AnalyticsUser ? this.state.pNum13 : this.state.pNum12}
            pageSizeOptions={[extendedPlusPageSize]}
          />
        ) : null}
        <div className="pr">
          <div className="pa paright">
            <strong>Skupaj:&nbsp;</strong>
            Kilometrine: <strong>{this.getFullAmountMileage(this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser : this.state.analyticsData)} €</strong>,
            Št. dnevnic: <strong>{this.getFullAmountSubsistenceN(this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser : this.state.analyticsData)}</strong> (<strong>{this.getFullAmountSubsistence(this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser : this.state.analyticsData)} €</strong>),
            Stroški: <strong>{this.getFullAmountExpense(this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser : this.state.analyticsData)} €</strong>,
            Skupaj: <strong>{this.getFullAmount(this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser : this.state.analyticsData)} €</strong>
          </div>
          Skupno št. potnih nalogov (upoštevaje filter): <strong>
            {this.state.navigation === Navigation.AnalyticsUser ? this.state.analyticsUser ? this.state.analyticsUser.length : 0 : this.state.analyticsData ? this.state.analyticsData.length : 0}
          </strong> {/*this.state.navigation === Navigation.AnalyticsUser ? <>
            <input onChange={() => this.setState({showJustMine: !this.state.showJustMine})} className="ml" type="checkbox" checked={this.state.showJustMine}/> prikaži naloge, kjer sem nosilec
            <input onChange={() => this.setState({showMineBoss: !this.state.showMineBoss})} className="ml" type="checkbox" checked={this.state.showMineBoss}/> prikaži naloge, kjer sem nalogodajalec
          </> : <>
            <input onChange={() => this.setState({showProblematic: !this.state.showProblematic})} className="ml" type="checkbox" checked={this.state.showProblematic}/> uporabnik isti kot nalogodajalec
          </>*/}
        </div>
        {/*
        <div className="pr">
            Št. potnih nalogov: <strong>{this.state.analyticsCount.updated}</strong>, oddanih: <strong>{this.state.analyticsCount.updatedPlus}</strong>, potrjenih: <strong>{this.state.analyticsCount.confirmed}</strong>, finaliziranih: <strong>{this.state.analyticsCount.finalized}</strong>, vseh: <strong>{this.state.analyticsCount.all}</strong>
        </div>
        */}
        <div id="Sheet">
          <div id="sheet">
            <div className="clearfix datepicker-shorter">
              <div className="fl bold">Filtri:</div>
              <div className = "clearfix pr fl mt-2">
                <input readOnly={true} style={{ background: '#fff', paddingLeft: '5px', width: '135px' }} list="fuser_" className = "ml fl dbl input input1" value = {this.state.FUseruuid}
                  placeholder = "Vsi uporabniki"
                />
                <datalist id = "fuser_">
                  {this.getUserDatalist()}
                </datalist>
              </div>
              <div className="fl ml">od</div>
              <DatePicker
                calendarStartDay={1}
                style = {{marginLeft: '5px'}}
                onKeyDown={this.handleDateChangeRaw}
                placeholderText={placeholderDatePicker}
                dateFormat={dateFormatSlash}
                locale={locale}
                selected={this.state.fDep}
                excludeDateIntervals={this.getExcludeDateIntervals(
                  'after',
                  this.state.fArr,
                )}
                onChange={fDep => this.filterDocuments('departure', fDep)}
              />
              <div className="fl ml">do</div>
              <DatePicker
                calendarStartDay={1}
                style = {{marginLeft: '5px'}}
                onKeyDown={this.handleDateChangeRaw}
                placeholderText={placeholderDatePicker}
                dateFormat={dateFormatSlash}
                locale={locale}
                selected={this.state.fArr}
                excludeDateIntervals={this.getExcludeDateIntervals(
                  'before',
                  this.state.fDep,
                )}
                onChange={fArr => this.filterDocuments('arrival', fArr)}
              />
              <div className = "clearfix pr fl mt-2">
                <input readOnly={true} style={{ background: '#fff', paddingLeft: '5px', width: '175px' }} list="fcompany_" className = "ml fl dbl input input1" value = {this.state.FCompanyuuid}
                  placeholder = "Vsa podjetja"
                />
                <datalist id = "fcompany_">
                  {this.getCompanyDatalist()}
                </datalist>
              </div>

              <input
                readOnly={true}
                style={{background: '#fff'}}
                className="fl ml dbl mt8"
                checked={this.state.fCompanyVehicle}
                type="checkbox"
              />
              <div className="fl ml5">samo službena vozila</div>
              <div className = "fl mt-2 pr">
                <input readOnly={true} style={{background: '#fff'}} list="fplate_" className = "ml dbl input input2" value = {this.state.FVehicleuuid}
                  placeholder = "Vsa vozila"
                />
                <datalist id = "fplate_">
                  {this.getVehiclePlateDatalist()}
                </datalist>
              </div>

              <div className = "fl mt-2 pr">
                <input readOnly={true} style = {{background: '#fff'}} list="fproject_" className = "ml dbl input input3" value = {this.state.FProjectuuid}
                  placeholder = "Vsi projekti"
                />
                <datalist id = "fproject_">
                  {this.getProjectDatalist()}
                </datalist>
              </div>
            </div>

            {this.state.navigation === Navigation.Analytics && this.state.analyticsData ? (
              <>
              <ReactTable
                {...reactTableTranslation}
                className="rt-no-white-space mt"
                data={this.state.analyticsData}
                columns={[
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        St
                      </span>
                    ),
                    width: 20,
                    className: 'rt-smallrow tac',
                    headerClassName: 'tac',
                    accessor: 'status',
                    Cell: row => (
                      <div
                        style={{ fontSize: '10px', lineHeight: '18px' }}
                      >
                        {DocumentStatusString[row.value].charAt(0)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Uporabnik
                      </span>
                    ),
                    width: 100,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'useruuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getUserName(row.value)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Po nalogu
                      </span>
                    ),
                    width: 100,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'orderuuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getUserName(row.value)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Odhod
                      </span>
                    ),
                    width: 93,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'departure',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.formatDate(row.value)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Prihod
                      </span>
                    ),
                    width: 93,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'arrival',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.formatDate(row.value)}
                      </div>
                    ),
                  },

                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Projekt
                      </span>
                    ),
                    width: 60,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'projectuuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getProjectName(row.value, row.original.companyuuid)}
                      </div>
                    ),
                  },
/*
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Stranka
                      </span>
                    ),
                    width: 55,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'customeruuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getCustomerName(row.value)}
                      </div>
                    ),
                  },
*/
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Podjetje
                      </span>
                    ),
                    width: 55,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'companyuuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getCompanyName(row.value)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Vozilo
                      </span>
                    ),
                    width: 70,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'vehicleuuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getVehicle(this, row.value)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        T
                      </span>
                    ),
                    width: 20,
                    className: 'rt-smallrow tac',
                    headerClassName: 'tac',
                    accessor: 'vehicletype',
                    Cell: row => (
                      <div
                        style={{ fontSize: '10px', lineHeight: '18px' }}
                        title={vehicleDesc[row.value]}
                      >
                        {vehicleType[row.value]}
                      </div>
                    ),
                  },

                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Tablica
                      </span>
                    ),
                    width: 60,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'vehicleuuid',
                    Cell: row => (
                      <div
                        style={{ fontSize: '10px', lineHeight: '18px' }}
                        className="license-plate-table"
                      >
                        {this.getVehiclePlate(this, row.value)}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Relacija
                      </span>
                    ),
                    width: 125,
                    className: 'rt-smallrow tal',
                    headerClassName: 'tal',
                    accessor: 'path',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {row.value}
                      </div>
                    ),
                  },
/*
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Predpl.
                      </span>
                    ),
                    width: 50,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'advance',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {(
                          Math.round(
                            (row.value ? parseFloat(row.value) : 0) * 100,
                          ) / 100
                        ).toFixed(2)} €
                      </div>
                    ),
                  },
*/
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        od.
                      </span>
                    ),
                    width: 30,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'departuremileage',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {row.value}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        pr.
                      </span>
                    ),
                    width: 30,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'arrivalmileage',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {row.value}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        km
                      </span>
                    ),
                    width: 30,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'mileage',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {row.value}
                      </div>
                    ),
                  },
                  /*
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        km€
                      </span>
                    ),
                    width: 35,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'mileageuuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.getMileageValue(row.value)}
                      </div>
                    ),
                  },
                  */
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Znesek
                      </span>
                    ),
                    width: 50,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'mileageuuid',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.showExpenseMileage(row.original)} €
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        #D
                      </span>
                    ),
                    width: 25,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'subsistence',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {row.value.length}
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Dnevn.
                      </span>
                    ),
                    width: 50,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'subsistence',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.showExpenseSubsistence(row.original)} €
                      </div>
                    ),
                  },
                  /*
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        #S
                      </span>
                    ),
                    width: 25,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'expenses',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {row.value.length}
                      </div>
                    ),
                  },
                  */
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Stroški
                      </span>
                    ),
                    width: 50,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'expenses',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {this.showExpenseExpense(row.original)} €
                      </div>
                    ),
                  },
                  {
                    sortable: false,
                    Header: (
                      <span style={{ fontWeight: 600, fontSize: '11px' }}>
                        Skupaj
                      </span>
                    ),
                    width: 50,
                    className: 'rt-smallrow tar',
                    headerClassName: 'tar',
                    accessor: 'advance',
                    Cell: row => (
                      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
                        {(
                          Math.round(
                            100 *
                              (-(row.value ? parseFloat(row.value) : 0) +
                                parseFloat(
                                  this.showExpenseExpense(row.original),
                                ) +
                                parseFloat(
                                  this.showExpenseSubsistence(row.original),
                                ) +
                                parseFloat(
                                  this.showExpenseMileage(row.original),
                                )),
                          ) / 100
                        ).toFixed(2)} €
                      </div>
                    ),
                  },
                ]}
                showPaginationTop={false}
                showPaginationBottom={false}
                pageSize={this.state.analyticsData.length}
              />

              <div
                className="pr"
                style={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  marginTop: '10px',
                }}
              >
                <div
                  className="pa paright"
                  style={{ fontSize: '12px', lineHeight: '18px' }}
                >
                  <strong style={{ fontSize: '12px', lineHeight: '18px' }}>Skupaj:&nbsp;</strong>
                  Kilometrine: <strong style={{ fontSize: '12px', lineHeight: '18px' }}>{this.getFullAmountMileage(this.state.analyticsData)} €</strong>,
                  Št. dnevnic: <strong style={{ fontSize: '12px', lineHeight: '18px' }}>{this.getFullAmountSubsistenceN(this.state.analyticsData)}</strong> (<strong style={{ fontSize: '12px', lineHeight: '18px' }}>{this.getFullAmountSubsistence(this.state.analyticsData)} €</strong>),
                  Stroški: <strong style={{ fontSize: '12px', lineHeight: '18px' }}>{this.getFullAmountExpense(this.state.analyticsData)} €</strong>,
                  Skupaj: <strong style={{ fontSize: '12px', lineHeight: '18px' }}>{this.getFullAmount(this.state.analyticsData)} €</strong>
                </div>
                Skupno št. potnih nalogov (upoštevaje filter): <strong style={{ fontSize: '12px', lineHeight: '18px' }}>
                  {this.state.analyticsData ? this.state.analyticsData.length : 0}
                </strong>
              </div>
          </> ): null}
          </div>
        </div>

      </div>
    );
  }

  public getFullAmount(documentData: DocumentData[]): string {
    let sum = 0;
    if (documentData) {
      for (const row of documentData) {
        sum +=
          Math.round(
            100 *
              (-(row.advance ? parseFloat(row.advance) : 0) +
                parseFloat(this.showExpenseExpense(row)) +
                parseFloat(this.showExpenseSubsistence(row)) +
                parseFloat(this.showExpenseMileage(row))),
          ) / 100;
      }
    }
    return sum.toFixed(2);
  }

  public getFullAmountExpense(documentData: DocumentData[]): string {
    let sum = 0;
    if (documentData) {
      for (const row of documentData) {
        sum +=
          Math.round(
            100 * parseFloat(this.showExpenseExpense(row)),
          ) / 100;
      }
    }
    return sum.toFixed(2);
  }

  public getFullAmountSubsistence(documentData: DocumentData[]): string {
    let sum = 0;
    if (documentData) {
      for (const row of documentData) {
        sum +=
          Math.round(
            100 * parseFloat(this.showExpenseSubsistence(row)),
          ) / 100;
      }
    }
    return sum.toFixed(2);
  }

  public getFullAmountSubsistenceN(documentData: DocumentData[]): number {
    let sum = 0;
    if (documentData) {
      for (const row of documentData) {
        sum += this.showExpenseSubsistenceN(row)
      }
    }
    return sum;
  }

  public getFullAmountMileage(documentData: DocumentData[]): string {
    let sum = 0;
    if (documentData) {
      for (const row of documentData) {
        sum +=
          Math.round(
            100 * parseFloat(this.showExpenseMileage(row)),
          ) / 100;
      }
    }
    return sum.toFixed(2);
  }

  public async openDoc(doc: DocumentData) { // startsWith
    if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: openDoc)');

    try {
      const response = await axios.get(analytics, {
        params: {
          token: this.props.userData.token,
          uuid: doc.uuid
        },
      });
      const document: DocumentData = Object.assign({}, doc);
      document.companionsPlanned = '';
      document.vehicleTypePlanned = VehicleType.None;
      document.vehicleuuidPlanned = '';
      document.descriptionPlanned = '';
      document.departureDatePlanned = null;
      document.arrivalDatePlanned = null;
      document.departureHourPlanned = '';
      document.arrivalHourPlanned = '';
      document.departureMinutePlanned = '';
      document.arrivalMinutePlanned = '';
      document.projectuuidPlanned = '';
      document.intentPlanned = '';
      document.companyuuidPlanned = '';
      document.orderuuidPlanned = '';

      if (
        response &&
        response.data &&
        response.data.resolved &&
        response.data.data
      ) {
        if (ALERT_SERVER_REQUESTS) alert('Successful response from server with a match. (func: openDoc)');
        document.pointuuids = response.data.data.pointuuids;
        document.Pointuuids = []
        for (const item of response.data.data.pointuuids) {
          document.Pointuuids.push(this.getPointname(this, item))
        }

        document.attachments = response.data.data.attachments;
        document.Attachments = []
        if (document.attachments) {
          for (let i = 0; i < document.attachments.length; i++) {
            if (document.attachments[i].files) {
              for (let j = 0; j < document.attachments[i].files.length; j++) {
                console.log(document.attachments[i].files[j])
                if (document.attachments[i].files[j]['isbinary'] && document.attachments[i].files[j].binaryValue) {
                  document.attachments[i].files[j].binaryValue = new Uint8Array(document.attachments[i].files[j].binaryValue['data']).buffer;
                }

                if (!document.attachments[i].files[j]['isbinary'] && document.attachments[i].files[j].value && !document.attachments[i].files[j].value.startsWith('data:')) {
                  document.attachments[i].files[j].value =
                    'data:' +
                    document.attachments[i].files[j].type +
                    ';base64,' +
                    document.attachments[i].files[j].value;
                }
                // document.attachments[i].files[j].encoded = undefined;
              }
            }
          }
          document.Attachments = JSON.parse(JSON.stringify(document.attachments))
          for(let i = 0; i < document.Attachments.length; i++) {
            for (let j = 0; j < document.Attachments[i].files.length; j++) {
              if (document.attachments[i].files[j].binaryValue) {
                document.Attachments[i].files[j].binaryValue = document.attachments[i].files[j].binaryValue
              }
            }
          }
        }
      } else {
        if (ALERT_SERVER_REQUESTS)  alert('Probably successful response from server without a match. (func: openDoc)');
        document.pointuuids = [];
        document.Pointuuids = [];
      }

      document.attachment = {
        name: '',
        description: '',
        files: [],
      };
      if (document.arrival === null) {
        document.arrival = '';
        document.arrivalDate = null;
        document.arrivalHour = '';
        document.arrivalMinute = '';
      } else {
        document.arrivalDate = new Date(document.arrival.substring(0, 10));
        document.arrivalHour = document.arrival.substring(11, 13);
        document.arrivalMinute = document.arrival.substring(14, 16);
      }
      if (document.departure === null) {
        document.departure = '';
        document.departureDate = null;
        document.departureHour = '';
        document.departureMinute = '';
      } else {
        document.departureDate = new Date(document.departure.substring(0, 10));
        document.departureHour = document.departure.substring(11, 13);
        document.departureMinute = document.departure.substring(14, 16);
      }

      if (document.advance === null) {
        document.advance = '';
      }
      if (document.advancedate === null) {
      } else {
        document.advancedate = new Date(document.advancedate);
      }

      if (document.arrivalmileage === null) {
        document.arrivalmileage = '';
      } else {
        document.arrivalmileage = document.arrivalmileage.toString();
      }
      if (document.companions === null) {
        document.companions = '';
      }
      if (document.companyuuid === null) {
        document.companyuuid = '';
      }
/*
      if (document.customeruuid === null) {
        document.customeruuid = '';
      }
*/
      if (document.departuremileage === null) {
        document.departuremileage = '';
      } else {
        document.departuremileage = document.departuremileage.toString();
      }
      if (document.description === null) {
        document.description = '';
      }
      if (document.intent === null) {
        document.intent = '';
      }
      if (document.mileage === null) {
        document.mileage = '';
      } else {
        document.mileage = document.mileage.toString();
      }
      if (document.mileageuuid === null) {
        document.mileageuuid = '';
      }
      if (document.name === null) {
        document.name = '';
      }
      if (document.orderuuid === null) {
        document.orderuuid = '';
      }
      if (document.path === null) {
        document.path = '';
      }
      if (document.projectuuid === null) {
        document.projectuuid = '';
      }
      if (document.useruuid === null) {
        document.useruuid = '';
      }
      if (document.vehicleuuid === null) {
        document.vehicleuuid = '';
      }

      this.setState({ popup: true, document, attachment: -1 });
    }
    catch (error) {
        alert(`Error: (func: openDoc)\n\nmsg: ${error}`);
    }
  }

  public getUsers(): JSX.Element {
    const filtered: Filter[] = [];
    if (this.state.fLevel !== UserType.None) {
      filtered.push({ id: 'usertype', value: this.state.fLevel.toString() });
    }
    if (this.state.fUserUuid.length) {
      filtered.push({ id: 'uuid', value: this.state.fUserUuid });

    }
    return (
      <div>
        <div className = "pr">
          {this.state.userData.length ?
          <div className="pa paright0">
            <strong>Kadrovska evidenca:</strong>
            <button
              title="Izvozi .csv (vsi uporabniki)"
              onClick={() => this.exportKE(true)}
              className="button button-green button-thin ml"
            >
              ⬇ .csv (vsi uporabniki)
            </button>

            <button
              title="Izvozi .json (vsi uporabniki)"
              onClick={() => this.exportKE(false)}
              className="button button-green button-thin ml"
            >
              ⬇ .json (vsi uporabniki)
            </button>

            <button
              title="Izvozi .xlsx (vsi uporabniki)"
              onClick={() => this.exportKE(true, true)}
              className="button button-green button-thin ml"
            >
              ⬇ .xlsx (vsi uporabniki)
            </button>


            <button
              onClick={() => this.exportKE()}
              className="button button-green button-thin ml"
            >
              Odpri
            </button>
          </div> : null}
          <div className="fl bold">Filtri:</div>
          <div className = "pr fl mt-2 ml">
            <input list="user" className = "dbl input" value = {this.state.FUserUuid} onChange={(e) =>
              this.setState({FUserUuid: e.target.value, fUserUuid: this.getUserUuid(e.target.value)})}
              placeholder = "Izberi uporabnika"
            />
            <datalist id = "user">
              {this.getUserDatalist()}
            </datalist>
            {this.countUserDatalist(this.state.FUserUuid) === 0 ? <span className = "red pa nohit0">Brez zadetka!</span> : null}
          </div>
          <select
            value={this.state.fLevel}
            onChange={e => this.setState({ pNum0: 0, fLevel: +e.target.value })}
            className="ml select select-input-no-width"
          >
            {this.getLevels(true)}
          </select>
        </div>
        {this.state.userData ? (
          <>
            <ReactTable
              {...reactTableTranslation}
              filtered={filtered}
              className="rt-no-white-space mt"
              data={this.state.userData}
              onPageSizeChange={(pageSize, pageIndex) => {
                if (pageSize !== this.state.pSize0) {
                  this.setState({ pSize0: pageSize, pNum0: pageIndex });
                }
              }}
              onPageChange={pageIndex => {
                this.setState({ pNum0: pageIndex });
              }}
              columns={[
                {
                  sortable: false,
                  Header: 'Priimek',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'lastname',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },

                {
                  sortable: false,
                  Header: 'Ime',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'firstname',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  Header: 'E-pošta',
                  width: 300,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'email',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
/*
                {
                  sortable: false,
                  Header: 'Telefonska št.',
                  width: 150,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'telephone',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value ? row.value : 'Ni telefonske št.'}
                    </div>
                  ),
                },
*/
                {
                  sortable: false,
                  // Header: <span title="Telefonska v kadrovski evidenci">Telefonska KE.</span>,
                  Header: 'Telefonska št.',
                  width: 150,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'telephone2',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value ? row.value : 'Ni telefonske št.'}
                    </div>
                  ),
                },

/*
                {
                  sortable: false,
                  Header: 'Podjetje',
                  width: 180,
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'companyuuid',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value ? this.getCompanyName(row.value) : '-'}
                    </div>
                  ),
                },
*/
                {
                  sortable: false,
                  width: 150,
                  Header: 'Čas registracije',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'registered',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.value ? this.getDateTime(row.value) : ''}
                    </div>
                  ),
                },
                {
                  sortable: false,
                  width: 80,
                  Header: 'Nivo',
                  className: 'rt-smallrow tal',
                  headerClassName: 'tal',
                  accessor: 'uuid',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {this.getUserType(row.original.usertype)}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 85,
                  Header: 'Super',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: '',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.original.username === this.props.userData.email ||
                      row.original.usertype === 0 ? (
                        '-'
                      ) : (
                        <button
                          onClick={() =>
                            this.setAdmin(row.original.uuid, UserType.Super, row.original.lastname, row.original.firstname)
                          }
                          className="button button-green button-thin"
                        >
                          Nastavi
                        </button>
                      )}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 85,
                  Header: 'Admin',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: '',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.original.username === this.props.userData.email ||
                      row.original.usertype === 1 ? (
                        '-'
                      ) : (
                        <button
                          onClick={() =>
                            this.setAdmin(row.original.uuid, UserType.Admin, row.original.lastname, row.original.firstname)
                          }
                          className="button button-green button-thin"
                        >
                          Nastavi
                        </button>
                      )}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 85,
                  Header: 'Navaden',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: '',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.original.username === this.props.userData.email ||
                      row.original.usertype === UserType.Regular ? (
                        '-'
                      ) : (
                        <button
                          onClick={() =>
                            this.setAdmin(row.original.uuid, UserType.Regular, row.original.lastname, row.original.firstname)
                          }
                          className="button button-green button-thin"
                        >
                          Nastavi
                        </button>
                      )}
                    </div>
                  ),
                },

                {
                  show: this.props.userData.usertype === UserType.Super || this.props.userData.usertype === UserType.Admin,
                  sortable: false,
                  width: 70,
                  Header: <span title = "Kadrovska evidenca">Kd. ev.</span>,
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: '',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      <button
                        onClick={() =>
                          this.openKE(row.original.uuid)
                        }
                        className={clsx('button button-orange button-thin', this.state.ke === row.original.uuid ? 'button-orange-active' : '')}
                      >
                        {this.props.userData.usertype === UserType.Super ? 'Uredi' : 'Odpri'}
                      </button>
                    </div>
                  ),
                },
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 75,
                  Header: 'Aktiven',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'active',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.original.email === this.props.userData.email ? (
                          <span
                          title="Deaktiviranje lastnega uporabniškega računa ni dovoljeno"
                          className="no-delete"
                        >
                          ✓
                        </span>
                      ) : (
                        <a
                          title = {row.value ? 'Deaktiviraj' : 'Aktiviraj'}
                          onClick={() =>
                            this.deactivateUser(
                              row.original.email,
                              row.original.uuid,
                              row.index,
                              row.value
                            )
                          }
                          className={row.value ? 'nodelete' : 'delete'}
                        >
                          {row.value ? '✓' : '✗'}
                        </a>
                      )}
                    </div>
                  ),
                },
                {
                  show: this.props.userData.usertype === UserType.Super,
                  sortable: false,
                  width: 55,
                  Header: 'Briši',
                  className: 'rt-smallrow tac',
                  headerClassName: 'tac',
                  accessor: 'usertype',
                  Cell: row => (
                    <div
                      style={{
                        background: this.getUserTableBackground(
                          row.original.usertype, row.original.active
                        ),
                      }}
                    >
                      {row.original.email === this.props.userData.email ? (
                          <span
                          title="Brisanje lastnega uporabniškega računa ni dovoljeno"
                          className="no-delete"
                        >
                          ✗
                        </span>
                      ) : (
                        <a
                          onClick={() =>
                            this.deleteUser(
                              row.original.email,
                              row.original.uuid,
                              row.index,
                            )
                          }
                          className="delete"
                        >
                          ✗
                        </a>
                      )}
                    </div>
                  ),
                },
              ]}
              showPaginationTop={
                this.state.userData.length <= this.state.pSize0 ? false : true
              }
              showPaginationBottom={false}
              pageSize={
                this.state.userData.length < this.state.pSize0
                  ? this.state.userData.length
                  : this.state.pSize0
              }
              page={this.state.pNum0}
              pageSizeOptions={[extendedPlusPageSize]}
            />

            <div className="pr">
              {filtered.length ? (
                <div className="pa paright">
                  Filtrirano št. uporabnikov: <strong>{this.getUsersByFilter()}</strong>
                </div>
              ) : null}
              <div>
                Skupno št. uporabnikov: <strong>{this.state.userData.length}</strong>
              </div>
            </div>
            {this.props.userData.usertype === UserType.Super ? <>
            <hr />
            <div>
              <span className="bold">Registracija novega uporabnika</span>
              <button
                className="ml button button-red button-thin"
                onClick={() =>
                  this.setState({
                    showRegistration: !this.state.showRegistration,
                  })
                }
              >
                {this.state.showRegistration ? 'Skrij' : 'Prikaži'}
              </button>
            </div>
            </> : null}
            {this.state.showRegistration ? (
              <>
                <div className="mt5 clearfix">
                  <div className="tar beforeinput fl">E-pošta:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={stringMaxLength}
                    onChange={e => this.edit('Email', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Email}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Geslo:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={passwordMaxLength}
                    onChange={e => this.edit('Password1', e.target.value)}
                    className="input dbl fl input-pass"
                    type={this.state.ShowPassword ? 'text' : 'password'}
                    value={this.state.Password1}
                  />
                  <input
                    type="checkbox"
                    className="dbl fl checkbox ml"
                    onChange={() =>
                      this.setState({ ShowPassword: !this.state.ShowPassword })
                    }
                    checked={this.state.ShowPassword}
                  />
                  <div title="Prikaži geslo" className="fl">
                    Prikaži
                  </div>
                </div>
                <div className="clearfix">
                  <button
                    onClick={() => this.generatePassword()}
                    className="dbl fl button button-thin button-green mt5"
                  >
                    Ustvari naključno geslo
                  </button>
                  <div className="fl ml3">dolgo</div>
                  <select
                    className="ml3 fl dbl select select-password-length mt3"
                    value={this.state.randomCharacters}
                    onChange={e => this.setState({ randomCharacters: +e.target.value })}
                  >
                    {this.getPasswordLengthOptions()}
                  </select>
                  <div title="znakov" className="fl ml3">
                    zn.
                  </div>
                </div>
                <div className="clearfix mt3">
                  <div title = "Ponovi geslo" className="tar beforeinput fl">Ponovi:</div>
                  <input
                    autoComplete="new-password"
                    maxLength={passwordMaxLength}
                    onChange={e => this.edit('Password2', e.target.value)}
                    className="input dbl fl"
                    type={this.state.ShowPassword ? 'text' : 'password'}
                    value={this.state.Password2}
                  />
                  {this.PasswordsDoNotMatch() ? (
                    <div className="fl ml red">Gesli se ne ujemata!</div>
                  ) : null}
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Ime:</div>
                  <input
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Firstname', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Firstname}
                  />
                </div>
                <div className="clearfix">
                  <div className="tar beforeinput fl">Priimek:</div>
                  <input
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Lastname', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Lastname}
                  />
                </div>
                {/*
                <div className="clearfix">
                  <div className="tar beforeinput fl">Naslov:</div>
                  <select
                    className="ml select select-input dbl fl mt3"
                    value={this.state.Pointuuid}
                    onChange={e => this.edit('Pointuuid', e.target.value)}
                  >
                    {this.getPointOptions(undefined, 'Izberi naslov')}
                  </select>
                </div>
                */}

                <div className="clearfix">
                  <div className="tar beforeinput fl">Telefon:</div>
                  <input
                    maxLength={stringDefaultLength}
                    onChange={e => this.edit('Telephone', e.target.value)}
                    className="input dbl fl"
                    type="text"
                    value={this.state.Telephone}
                  />
                </div>

                <div className="clearfix">
                  <div className="tar beforeinput fl">Nivo:</div>
                  <select
                    className="ml fl dbl select select-input mt3"
                    value={this.state.Level}
                    onChange={e => this.setState({ Level: +e.target.value })}
                  >
                    {this.getLevels()}
                  </select>
                </div>

                <div className="mt5 tal">
                  <button
                    disabled={this.isNewRegistrationDisabled()}
                    onClick={() => this.newRegistraton()}
                    className="button button-thin button-green mr"
                  >
                    Registracija
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  public getContent(): JSX.Element {
    switch (this.state.navigation) {
      case Navigation.AboutMe:
        return this.getAboutMe();
      case Navigation.User:
        return this.getUsers();
      case Navigation.Analytics:
      case Navigation.AnalyticsUser:
        return this.getAnalytics();
      case Navigation.Structure:
        return this.getStructure();
      case Navigation.Point:
        return this.getPoints();
      case Navigation.Vehicle:
        return this.getVehicles();
      case Navigation.Subsistence:
        return this.getSubsistence();
      case Navigation.Settings:
        return this.getSettings();
      case Navigation.Dashboard:
        return this.getDashboard();

      default:
        return <div />;
    }
  }

  public generatePassword() {
    const password: string = secureRandomPassword.randomPassword({
      length: this.state.randomCharacters,
      characters: [
        secureRandomPassword.lower,
        secureRandomPassword.upper,
        secureRandomPassword.digits,
        secureRandomPassword.fullSymbols,
      ],
    });
    this.setState({ Password1: password, Password2: password });
  }

  public getPasswordLengthOptions(): JSX.Element[] {
    const plo: JSX.Element[] = [];
    for (let i: number = passwordMinLength; i <= passwordMaxLength; i++) {
      plo.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return plo;
  }

  public logout() {
    if (confirm('Ali se resnično želite odjaviti?')) {
      Cookies.remove('token', { path: '/', secure: true });
      this.props.router(false, true, {
        token: '',
        email: '',
        firstname: '',
        telephone: '',
        lastname: '',
        companyuuid: '',
        uuid: '',
        usertype: UserType.Regular,
      });
    }
  }

  public aboutMe() {
    this.setState({
      navigation: Navigation.AboutMe,
      email: this.props.userData.email,
      password1: '',
      password2: '',
      firstname: this.props.userData.firstname,
      telephone: this.props.userData.telephone,
      lastname: this.props.userData.lastname,
      companyuuid: this.props.userData.companyuuid,
    });
  }

  public message(): JSX.Element {
    return <div className="pf message">{this.state.message}</div>;
  }

  public async loadInitialData() {
    const departure: string = this.toLocalString(
      this.state.fDep ? this.state.fDep : new Date(),
    );
    const arrival: string = this.toLocalString(
      this.state.fArr ? this.state.fArr : new Date(),
    );
    const params: FilterDocumentsParams = {
      token: this.props.userData.token,
      departure,
      arrival,
      useruuid: this.state.navigation === Navigation.AnalyticsUser ? this.props.userData.uuid : undefined
    };
    params['DEV'] = DEV
    if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: loadInitialData)');
    const response = await axios.get(init, { params });
    if (response.data && response.data.resolved && response.data.data) {
      if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: loadInitialData)');
      const reservationsDay = response.data.data.reservations;
      const userData = response.data.data.userData;
      const vehicles = response.data.data.vehicles;
      const documents = response.data.data.documents;
      const analyticsData = response.data.data.analytics;
      const analyticsDataCount = response.data.data.analyticsCount;
      const analyticsUser = response.data.data.analyticsUser;
      const analyticsUserCount = response.data.data.analyticsUserCount;

      const f = response.data.data.f
      const variables = response.data.data.variables
      const Keys = response.data.data.Keys
      const variablesNull = response.data.data.variablesNull
      const keys = response.data.data.keys
      const keysSearch = response.data.data.keysSearch
      const keysType = response.data.data.keysType
      const variablesSelected = response.data.data.variablesSelected
      const variablesOrderType = response.data.data.variablesOrderType
      const variablesOrder = response.data.data.variablesOrder
      const groupsColorsDefault = response.data.data.groupsColors
      const mean = response.data.data.mean
      const median = response.data.data.median

      const Leaders: string[] = [];
      const leaders: string[] = [];
      const companies = response.data.data.companies || [];
      for (const item of companies) {
        if (item.leaderuuid && item.leaderuuid.length) {
          Leaders.push(item.leaderuuid);
          leaders.push(item.leaderuuid);
        }
      }

      const sections = response.data.data.sections || [];
      for (const item of sections) {
        if (item.leaderuuid && item.leaderuuid.length) {
          leaders.push(item.leaderuuid);
        }
      }

      const points = response.data.data.points || [];
      const projects = response.data.data.projects || [];
      const subsistence = response.data.data.subsistence || [];
      const mileage = response.data.data.mileage || [];
      const expense = response.data.data.expense || [];
      const countries = response.data.data.countries || [];
      const pointExposed = response.data.data.pointExposed || [];

      const doc = Object.assign({}, this.state.document);

      if (this.props.userData && doc) {
        if (this.initVehicle) {
          const vehicleuuid: string = this.initVehicleuuid(
            response.data.data.vehicles,
          );
          if (vehicleuuid) {
            doc.vehicleuuid = vehicleuuid;
            doc.vehicleuuidPlanned = vehicleuuid;
          }
        }
        doc.companyuuid = this.props.userData.companyuuid;
        if (this.props.userData.companyuuid) {
          const pointuuid = this.initPointuuid(response.data.data.companies);
          if (pointuuid) {
            doc.pointuuids[0] = pointuuid;
            doc.Pointuuids[0] = this.getPointname(this, pointuuid);

            doc.pointuuids[2] = pointuuid;
            doc.Pointuuids[2] = this.getPointname(this, pointuuid);
          }
        }
        const mileageuuid: string = this.initMileageuuid(
          response.data.data.mileage,
        );
        if (mileageuuid) {
          doc.mileageuuid = mileageuuid;
        }
      }

      this.setState({
        reservationsDay,
        Leaders,
        leaders,
        document: doc,
        Documents: new Array(documents.length),
        documents,
        countries,
        pointExposed,
        expense,
        subsistence,
        mileage,
        projects,
        points,
        userData,
        vehicles,
        analyticsData,
        analyticsDataCount,
        analyticsUser,
        analyticsUserCount,
        companies,
        sections,
        f,
        variables,
        Keys,
        variablesNull,
        keys,
        keysSearch,
        keysType,
        variablesSelected,
        variablesOrderType,
        variablesOrder,
        groupsColorsDefault,
        mean,
        median
      });
    }
    else if (response.data && response.data.resolved === false && response.data.message) {
        if (ALERT_SERVER_REQUESTS) alert(`Error: Failed response from server! (func: loadInitialData)\nmsg: ${response.data.message}`);
    }
    else {
        if (ALERT_SERVER_REQUESTS) alert('Error: Failed response from server! (func: loadInitialData)');
    }
  }

  public onDocumentLoadSuccess = PDFDocumentProxy => {
    this.setState({
      numPages:
        PDFDocumentProxy &&
        PDFDocumentProxy._pdfInfo &&
        PDFDocumentProxy._pdfInfo.numPages
          ? PDFDocumentProxy._pdfInfo.numPages
          : 0,
      pageNumber: 1,
    });
  };

  public getZoom(): JSX.Element {
    return (
      <input
        type="range"
        value={this.state.scale}
        min={0.05}
        max={5}
        step={0.05}
        onChange={e => this.setState({ scale: parseFloat(e.target.value) })}
      />
    );
  }

  public getOrientation(): JSX.Element[] {
    const o: JSX.Element[] = [];
    for (let i = 0; i < orientation.length; i++) {
      o.push(
        <button
          key={i}
          onClick={() => this.setState({ orientation: orientation[i].value })}
          className={clsx(
            'ml5 button button-thin button-orange',
            this.state.orientation === orientation[i].value
              ? 'button-orange-active'
              : '',
          )}
        >
          {orientation[i].name}
        </button>,
      );
    }
    return o;
  }

  public getPageNumbers(): JSX.Element[] {
    const pn: JSX.Element[] = [];
    for (let i = 1; i <= this.state.numPages; i++) {
      pn.push(
        <button
          title={'Stran ' + i}
          className={clsx(
            'button button-orange button-thin ml5',
            this.state.pageNumber === i ? 'button-orange-active' : '',
          )}
          onClick={() => this.setState({ pageNumber: i })}
          key={i}
        >
          {i}
        </button>,
      );
    }
    return pn;
  }

  public getUserWorking(): JSX.Element[] {
    const uw: JSX.Element[] = []
    if (this.state.kedata && this.state.kedata.userWorking) {
      for (let i: number = 0; i < this.state.kedata.userWorking.length; i++) {
        uw.push(<div key = {i} className="clearfix pr">
          {i === 0 ?
          <div className="tar beforeinputwide2 fl semi-bold">Podjetje zaposlitve:</div>
          :
          <div style = {{width: '420px'}} className = "fl">&nbsp;</div>}
          <input style = {{paddingLeft: '5px', width: '195px'}} list="companies" className = {clsx('ml fl dbl input input1', this.state.kedata.userWorking[i].companyuuid !== this.state.kedataold.userWorking[i].companyuuid ? 'edited' : '')} value = {this.state.kedata.userWorking[i].companyuuid_}
            onChange={e => this.editKE('userWorking', e.target.value, undefined, i, 'companyuuid_')}
            placeholder = "Izberi podjetje"
          />
          {this.countCompanyDatalist(this.state.kedata.userWorking[i].companyuuid_) === 0 ? <span className = "red pa nohit12">Brez zadetka!</span> : null}
          <input
            title = "št. ur na teden"
            placeholder="št. ur"
            maxLength={2}
            style={{width: '60px', paddingLeft: '5px', marginTop: '4px', height: '26px'}}
            disabled={this.props.userData.usertype !== UserType.Super}
            value={this.state.kedata.userWorking[i].hours}
            onChange={e => this.editKE('userWorking', e.target.value, undefined, i, 'hours')}
            className={clsx('ml5 dbl fl', this.state.kedata.userWorking[i].hours !== this.state.kedataold.userWorking[i].hours ? 'edited' : '')}
          />
          <input
            title = "odstotek ur"
            placeholder="% ur"
            style={{width: '60px', paddingLeft: '5px', marginTop: '4px', height: '26px'}}
            disabled={true}
            value={this.getHoursPercentage(i)}
            className="ml5 dbl fl"
          />
          {this.state.kedata.userWorking[i].hours && this.state.kedata.userWorking[i].hours.length ?
          <div style = {{left: '667px', top: '2px'}} className = "pa">h</div> : null}
          {i === 0 ?
          <>
            {this.state.kedata.userWorking.length < this.state.companies.length ?
            <button
              style={{top: '4px', left: '230px'}}
              onClick={() => this.addUserWorking()}
              className="pa button button-green button-thin add-point"
            >
              +
            </button> : null}
            {this.getHoursSum() ?
            <div style = {{top: '1px', left: '0px', width: '250px', textAlign: 'right'}}
              className = "pa"
            >
              Skupno št. ur na teden: <strong>{this.getHoursSum()}</strong>
            </div> : null}
          </>
          :
          <button
            style={{ top: '4px', left: '334px'}}
            disabled={this.props.userData.usertype !== UserType.Super}
            onClick={() => this.removeUserWorking(i)}
            className="pa button button-thin button-wide button-red"
          >
            Odstrani
          </button>
          }
        </div>
        )
      }
    }
    uw.push(<datalist key = "-1" id = "companies">
        {this.getCompanyDatalist(undefined, undefined, undefined, true)}
      </datalist>
    )
    return uw
  }

  public toggleNull(i: number) {
    const variablesNull: boolean[] = Object.assign([], this.state.variablesNull)
    variablesNull[i] = !variablesNull[i]
    this.setState({variablesNull})
  }

  public toggleKey(i: number, initial: boolean) {
    const Keys: boolean[] = Object.assign([], this.state.Keys)
    Keys[i] = !Keys[i]
    const variables: number[] = Object.assign([], this.state.variables)
    const variablesOrder: number[] = Object.assign([], this.state.variablesOrder)
    const variablesOrderType: boolean[] = Object.assign([], this.state.variablesOrderType)

    if (initial) {
      for (let j: number = 0; j < variables.length; j++) {
        if (variables[j] === i) {
          let found: number = -1
          for (let k: number = 0; k < variablesOrder.length; k++) {
            if (variablesOrder[k] === i) {
              found = k
              break
            }
          }

          if (found !== -1) {
            variablesOrder.splice(found, 1)
            variablesOrderType.splice(found, 1)
          }

          variables.splice(j, 1)
          break
        }
      }
    } else {
      variables.push(i)
    }
    this.setState({Keys, variables, variablesOrder, variablesOrderType})
  }

  public getVariables(): JSX.Element[] {
    const v: JSX.Element[] = []
    for (let i: number = 0; i < this.state.keys.length; i++) {
      /*
      if (i === 14 || i === 26 || i === 27 ) {
        console.log (this.state.keys[i])
        continue
      }
      */
     if (this.state.keys[i] === 'Podjetje zaposlitve') {
       continue
     }
      v.push(<button key = {i} onClick = {() => this.toggleKey(i, this.state.Keys[i])} className = {clsx('button button-thin button-red mr2', this.state.Keys[i] ? 'active' : '')}>
        {/*this.state.keys[i] === 'Datum opravljanja izpita iz varstva pri delu' ? 'Veljavnost izpita iz varstva pri delu' :
        this.state.keys[i] === 'Datum zadnjega zdravniškega pregleda' ? 'Veljavnost zdravniškega pregleda' :
        */
        this.state.keys[i]}
      </button>)
    }
    return v
  }

  public isVariableInCodeRegister(what: string): boolean {
    for (const item of codeRegister) {
      if (item.what === what) {
        return true
      }
    }
    return false
  }

  public isOrderVariableSelected(ascending: boolean, which: number): boolean {
    for (let i: number = 0; i < this.state.variablesOrder.length; i++) {
      if (this.state.variablesOrder[i] === which && this.state.variablesOrderType[i] === ascending) {
        return true
      }
    }
    return false
  }

  public removeOrderVariable(which: number) {
    const variablesOrder: number[] = Object.assign([], this.state.variablesOrder)
    const variablesOrderType: boolean[] = Object.assign([], this.state.variablesOrderType)

    variablesOrder.splice(which, 1)
    variablesOrderType.splice(which, 1)

    this.setState({variablesOrder, variablesOrderType})
  }

  public orderVariable(ascending: boolean, which: number) {
    const variablesOrder: number[] = Object.assign([], this.state.variablesOrder)
    const variablesOrderType: boolean[] = Object.assign([], this.state.variablesOrderType)
    let found: number = -1
    let isSame: boolean
    for (let i: number = 0; i < variablesOrder.length; i++) {
      if (variablesOrder[i] === which) {
        found = i
        isSame = variablesOrderType[i] === ascending
        break
      }
    }
    if (found !== -1) {
      if (isSame) {
        variablesOrder.splice(found, 1)
        variablesOrderType.splice(found, 1)
      } else {
        variablesOrderType[found] = !variablesOrderType[found]
      }
    } else {
      variablesOrder.push(which)
      variablesOrderType.push(ascending)
    }

    this.setState({variablesOrder, variablesOrderType})
  }

  public moveOrder(up: boolean, which: number) {
    const variablesOrder: number[] = Object.assign([], this.state.variablesOrder)
    const variablesOrderType: boolean[] = Object.assign([], this.state.variablesOrderType)
    const temp: number = variablesOrder[which]
    const tempType: boolean = variablesOrderType[which]
    if (up) {
      variablesOrder[which] = variablesOrder[which - 1]
      variablesOrder[which - 1] = temp

      variablesOrderType[which] = variablesOrderType[which - 1]
      variablesOrderType[which - 1] = tempType


    } else {
      variablesOrder[which] = variablesOrder[which + 1]
      variablesOrder[which + 1] = temp

      variablesOrderType[which] = variablesOrderType[which + 1]
      variablesOrderType[which + 1] = tempType
    }

    this.setState({variablesOrderType, variablesOrder})
  }

  public moveVariable(up: boolean, which: number) {
    const variables: number[] = Object.assign([], this.state.variables)
    const temp: number = variables[which]
    if (up) {
      variables[which] = variables[which - 1]
      variables[which - 1] = temp
    } else {
      variables[which] = variables[which + 1]
      variables[which + 1] = temp
    }
    this.setState({variables})
  }

  public isCodeRegisterSelected(j: number, value: string): boolean {
    for (const item of this.state.variablesSelected[j]) {
      if (item === value) {
        return true
      }
    }
    return false
  }

  public selectCodeRegisterValue(j: number, value: string, isSelected: boolean) {
    const variablesSelected: string[][] = Object.assign([], this.state.variablesSelected)
    if (isSelected) {
      for (let i: number = 0; i < variablesSelected[j].length; i++) {
        if (variablesSelected[j][i] === value) {
          variablesSelected[j].splice(i, 1)
          break
        }
      }
    } else {
      variablesSelected[j].push(value)
    }
    this.setState({variablesSelected})
  }

  public getVariablesCodeRegister(i: number): JSX.Element[] {
    let coderegister: CodeRegister[] = []
    for (const item of codeRegister) {
      if (item.what === this.state.keys[this.state.variables[i]]) {
        coderegister = item.value
        break
      }
    }

    const vcr: JSX.Element[] = []
    for (const item of coderegister) {
      const isSelected: boolean = this.isCodeRegisterSelected(this.state.variables[i], item.value)
      vcr.push(<button title = {!isSelected ? 'Izberi uporabnike s kategorijo "' + item.value + '"' : 'Odstrani uporabnike s kategorijo "' + item.value + '"'} onClick={()=> this.selectCodeRegisterValue(this.state.variables[i], item.value, isSelected)} key = {item.id} className = {clsx('button button-thin button-orange mr2', isSelected ? 'button-orange-active' : '')}>
        {item.value}
      </button>)
    }
    return vcr
  }

  public getVariablesOrder(): JSX.Element[] {
    const vo: JSX.Element[] = []
    for (let i: number = 0; i < this.state.variablesOrder.length; i++) {
      vo.push(<button title = "Odstrani nastavitev razvrščanja" onClick = {() => this.removeOrderVariable(i)} style = {{display: 'inline-block', border: '1px solid #60a3bc', color: '#60a3bc', background: '#fff', lineHeight: '22px', padding: '0px 4px'}} key = {i} className = "mr2 bb">
        {i+1}. {this.state.keys[this.state.variablesOrder[i]]} <strong style = {{lineHeight: '22px'}}>{this.state.variablesOrderType[i] ? 'N' : 'P'}</strong>
      </button>)

      vo.push(<button onClick = {() => this.moveOrder(true, i)} disabled = {i === 0} style = {{display: 'inline-block', border: '1px solid #60a3bc', color: '#60a3bc', background: '#fff', lineHeight: '22px', padding: '0px 4px'}} key = {i + '_'} className = "mr2 bb">
        ↜
      </button>)

      vo.push(<button onClick = {() => this.moveOrder(false, i)} disabled = {i === this.state.variablesOrder.length - 1} style = {{display: 'inline-block', border: '1px solid #60a3bc', color: '#60a3bc', background: '#fff', lineHeight: '22px', padding: '0px 4px'}} key = {i + '__'} className = {i === this.state.variablesOrder.length - 1 ? 'bb' : 'mr bb'}>
        ↝
      </button>)
    }
    return vo
  }

  public changeGroup(i: number, j: number, k: number, value: string) {
    const f: string[][][] = Object.assign([], this.state.f)
    if (this.state.keysType[i] === 'number' && value.length) {
      if (value.indexOf('.') !== -1 || value.indexOf('-') !== -1 || isNaN(value as any)) {
        return
      }
    }
    f[i][j][k] = value
    this.setState({f})
  }

  public addGroup(i: number) {
    const f: string[][][] = Object.assign([], this.state.f)
    f[i].push(['', ''])
    this.setState({f})
  }

  public removeGroup(i: number, j: number) {
    const f: string[][][] = Object.assign([], this.state.f)
    f[i].splice(j, 1)
    this.setState({f})
  }

  public setGroup(group: number) {
    this.setState({group: group === this.state.group ? undefined : group})
  }

  public toggleMean(i: number) {
    const mean: boolean[] = Object.assign([], this.state.mean)
    mean[i] = !mean[i]
    this.setState({mean})
  }

  public toggleMedian(i: number) {
    const median: boolean[] = Object.assign([], this.state.median)
    median[i] = !median[i]
    this.setState({median})
  }

  public getGroups(i: number): JSX.Element[] {
    let maxLength: number
    if (i === 17 || i === 20 || i === 29 || i === 30 || i === 31) {
      maxLength = 2
    }
    else {
      maxLength = ffp[i].length
    }

    const g: JSX.Element[] = []
    const gr: JSX.Element[] = []
    for (let j: number = 0; j < this.state.f[i].length; j++) {
      const groups: JSX.Element[] = []
      for (let k: number = 0; k < this.state.f[i][j].length; k++) {
        if (j > 0 && k === 0) {
          groups.push(<button title = {'Odstrani kategorijo ' + (j+1) + '. (' + this.state.f[i][j][0] + ' - ' + this.state.f[i][j][1] + ')'} key={j+'_'} onClick = {() => this.removeGroup(i, j)} className = "fl dbl ml3 mt3 mr5 button button-thin button-orange">
            {j !== 0 ? (j+1) + '.' : '-'}
          </button>)
        }
        groups.push(<input title = {(k === 0 ? 'od' : 'do') + ' [' + ffp[i] + ']'} placeholder = {ffp[i]} onChange={(e)=> this.changeGroup(i, j, k, e.target.value)} style = {{background: !this.state.f[i][j][k] || this.state.f[i][j][k] && this.state.f[i][j][k].length !== ffp[i].length ? '#fdd' : '', height: '24px', marginBottom: '0px', paddingLeft: '4px', paddingRight: '2px', width: (14 + maxLength * 8) + 'px'}} maxLength = {maxLength} className = {clsx('fl dbl mt3', k === 1 ? 'ml3' : '')} key = {k} value = {this.state.f[i][j][k]} />)
        if (k === 0) {
          groups.push(<span key="2" className="fl dbl ml3 mt3 cursordefault" style={{lineHeight: '22px', background: 'transparent', border: '0px', paddingLeft: '0px', paddingRight: '0px'}}>
            -
          </span>)
          /*
          groups.push(<button title = {j === 0 ? undefined : 'Odstrani kategorijo ' + (j+1) + '. (' + this.state.f[i][j][0] + ' - ' + this.state.f[i][j][1] + ')'} style={j === 0 ? {lineHeight: '22px', background: 'transparent', border: '0px', paddingLeft: '0px', paddingRight: '0px'} : {}} key="2" onClick = {() => j === 0 ? {} : this.removeGroup(i, j)} className = {clsx('fl dbl ml3 mt3', j === 0 ? 'cursordefault' : 'button button-thin button-orange')}>
            {j !== 0 ? (j+1) + '.' : '-'}
          </button>)
          */
        }
      }
      gr.push(<div className = "fl clearfix mr7" key = {j}>
        {groups}
        {j === this.state.f[i].length - 1 ?
          <button title = "Dodaj novo kategorijo" onClick = {() => this.addGroup(i)} className = "fl dbl button button-thin button-green mt3 ml7">
            Dodaj
          </button>
          : null}
      </div>)
    }

    g.push(<Fragment key = {0}>
      {gr}
      <button title = "Združi podatke glede na kategorije te spremenljivke" onClick = {() => this.setGroup(i)} className = {clsx('dbl fl mt3 button button-blue button-thin', this.state.group === i ? 'button-blue-active' : '')}>Združi</button>
      {this.state.mean[i] !== null && this.state.mean[i] !== undefined ?
      <button title = "Izračunaj povprečje" onClick = {() => this.toggleMean(i)} className = {clsx('dbl fl mt3 button button-blue button-thin ml7', this.state.mean[i] ? 'button-blue-active' : '')}>Povprečje</button>
      : null}

      {this.state.median[i] !== null && this.state.mean[i] !== undefined ?
      <button title = "Izračunaj mediano" onClick = {() => this.toggleMedian(i)} className = {clsx('dbl fl mt3 button button-blue button-thin ml7', this.state.median[i] ? 'button-blue-active' : '')}>Mediana</button>
      : null}
    </Fragment>)
    return g
  }

  public getVariablesShow(): JSX.Element[] {
    const v: JSX.Element[] = []
    let count: number = 0
    for (let i: number = 0; i < this.state.variables.length; i++) {
      if (this.state.Keys[this.state.variables[i]]) {
        if (
          // this.state.keys[this.state.variables[i]] === 'Veljavnost izpita iz varstva pri delu' ||
          // this.state.keys[this.state.variables[i]] === 'Veljavnost zdravniškega pregleda' ||
          this.state.keys[this.state.variables[i]] === 'Podjetje zaposlitve')
        {
            continue
        }
        count++
        v.push(<div key={i} className = "clearfix">
          <div className = "fl bold" style = {{width: '30px', textAlign: 'left'}}>{count}.</div>
          <div className = "fl" style = {{width: '354px', textAlign: 'right'}}>
            <button title = "Odstrani spremenljivko" onClick = {() => this.toggleKey(this.state.variables[i], this.state.Keys[this.state.variables[i]])} className = "button button-thin button-red">
              {/*this.state.keys[this.state.variables[i]] === 'Datum opravljanja izpita iz varstva pri delu' ? 'Veljavnost izpita iz varstva pri delu' :
              this.state.keys[this.state.variables[i]] === 'Datum zadnjega zdravniškega pregleda' ? 'Veljavnost zdravniškega pregleda' :*/
              this.state.keys[this.state.variables[i]]}
            </button>
          </div>
          <div className = "fl">
            <button onClick = {() => this.moveVariable(true, i)} title = {i === 0 ? undefined : 'Premakni navzgor'} style = {{fontSize: '16px', height: '24px', width: '20px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px'}} disabled = {i === 0} className = "button button-thin button-green ml3">⬆</button>
            <button onClick = {() => this.moveVariable(false, i)} title = {i === this.state.variables.length - 1 ? undefined : 'Premakni navzdol'} style = {{fontSize: '16px', height: '24px', width: '20px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px'}} disabled = {i === this.state.variables.length - 1} className = "button button-thin button-green ml3">⬇</button>
            <button onClick = {() => this.orderVariable(true, this.state.variables[i])} title = "Razvrstite naraščajoče" style = {{fontSize: '16px', height: '24px', width: '20px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px', fontWeight: 600}} className = {clsx('button button-thin button-green ml3', this.isOrderVariableSelected(true, this.state.variables[i]) ? 'active' : '')}>N</button>
            <button onClick = {() => this.orderVariable(false, this.state.variables[i])} title = "Razvrstite padajoče" style = {{fontSize: '16px', height: '24px', width: '20px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px', fontWeight: 600}} className = {clsx('button button-thin button-green ml3', this.isOrderVariableSelected(false, this.state.variables[i]) ? 'active' : '')}>P</button>
          </div>
          {this.isVariableInCodeRegister(this.state.keys[this.state.variables[i]]) ?
          <div className = "fl code-register ml3">
            <button title = "Prikaži uporabnika, tudi če podatek ne obstaja" onClick = {() => this.toggleNull(this.state.variables[i])} className = {clsx('fl dbl mt3 button button-green button-thin mr7', this.state.variablesNull[this.state.variables[i]] ? 'active' : '')}>ni podatka</button>
            {this.getVariablesCodeRegister(i)}
            <button title = "Združi podatke glede na kategorije te spremenljivke" onClick = {() => this.setGroup(this.state.variables[i])} className = {clsx('mt3 button button-blue button-thin ml7', this.state.group === this.state.variables[i] ? 'button-blue-active' : '')}>Združi</button>
          </div>
          : <div className = "fl ml3 code-register clearfix">
              <button title = "Prikaži uporabnika, tudi če podatek ne obstaja" onClick = {() => this.toggleNull(this.state.variables[i])} className = {clsx('fl dbl mt3 button button-green button-thin mr7', this.state.variablesNull[this.state.variables[i]] ? 'active' : '')}>ni podatka</button>
              {this.state.keys[this.state.variables[i]] !== 'Elektronski naslov'
              && this.state.keys[this.state.variables[i]] !== 'Stalni naslov'
              && this.state.keys[this.state.variables[i]] !== 'Ime'
              && this.state.keys[this.state.variables[i]] !== 'Priimek'
              && this.state.keys[this.state.variables[i]] !== 'Davčna št.'
              && this.state.keys[this.state.variables[i]] !== 'Št. pooblastila'
              && this.state.keys[this.state.variables[i]] !== 'Telefonska št.'
              && this.state.keys[this.state.variables[i]] !== 'Začasni naslov'
              && this.state.keys[this.state.variables[i]] !== 'EMŠO'
              && this.state.keys[this.state.variables[i]] !== 'Dodatna dovoljenja'
              ? this.getGroups(this.state.variables[i]) : null}
          </div>}
        </div>)
      }
    }
    return v
  }

  public selectAllVariables(select: boolean) {
    const Keys: boolean[] = Object.assign([], this.state.Keys)
    const variables: number[] = []
    for (let i: number = 0; i < Keys.length; i++) {
      Keys[i] = (i === 14 || i === 26 || i === 27) ? false : select ? true : false
      if (Keys[i]) {
        variables.push(i)
      }
    }
    if (select === false) {
      this.setState({Keys, variables, variablesOrder: [], variablesOrderType: []})
    } else {
      this.setState({Keys, variables})
    }
  }

  public testIfItemOK(item: any, keys: string[], keysSearch: string[]): boolean {
    // console.log(item)
    for (let i: number = 0; i < keys.length; i++) {
      // console.log ('LET THROUGH', this.state.keys[i])
      if (item[keys[i]] === null && this.state.variablesNull[this.state.variables[i]] === false) {
        // console.log('1 false')
        return false
      }
      if (item[keys[i]] === null) {continue}

      let isCodeRegisterVariable: number = -1
      for (let j: number = 0; j < codeRegister.length; j++) {
        if (codeRegister[j].what === keys[i]) {
          isCodeRegisterVariable = j
          break
        }
      }
      // this is where the bug is
      // console.log('isCodeRegisterVariable', isCodeRegisterVariable, this.state.variables[i])
      if (isCodeRegisterVariable !== -1) {
        let found: boolean = false
        // console.log(this.state.variablesSelected)
        // console.log ('variables selected:')
        // console.log(this.state.variablesSelected[this.state.variables[i]])
        for (const Item of this.state.variablesSelected[this.state.variables[i]]) {
          // console.log(Item)
          if (item[keys[i]] === Item) {
            found = true
            break
          }
        }
        if (!found) {
          console.log ('WARNING', keys[i])
          console.log(item)
          console.log('reason 2')
          return false
        }
      } else {
        if (this.state.f[this.state.variables[i]]) {
          if (item[keys[i]] === null) {continue}

          const toCompare: number = keys[i] === 'Starost' || keys[i] === 'Skupno št. ur na teden' || keys[i].startsWith('Št. ur na teden (') ? +item[keys[i]] : null
          let found: boolean = false
          for (let j: number = 0; j < this.state.f[this.state.variables[i]].length; j++) {
            let lower: string = this.state.f[this.state.variables[i]][j][0]
            let upper: string = this.state.f[this.state.variables[i]][j][1]
            if (toCompare !== null) {
              if (toCompare >= +lower && toCompare <= +upper) {
                found = true
                break
              }
            } else {
              if (ffp[this.state.variables[i]]) {
                if (ffp[this.state.variables[i]] === ddmmllll) {
                  lower = lower.substring(6,10) + '-' + lower.substring(3,5) + '-' + lower.substring(0,2)
                  upper = upper.substring(6,10) + '-' + upper.substring(3,5) + '-' + upper.substring(0,2)
                } else if (ffp[this.state.variables[i]] === llmmdd) {
                  lower = lower.substring(0,2) + '-' + lower.substring(3,5) + '-' + lower.substring(6,8)
                  upper = upper.substring(0,2) + '-' + upper.substring(3,5) + '-' + upper.substring(6,8)
                }
              }
              if (item[keysSearch[i]] >= lower && item[keysSearch[i]] <= upper) {
                found = true
                break
              }
            }
          }
          if (!found) {
            console.log ('WARNING', keys[i])
            console.log(item)
            console.log('reason 3')
            return false
          }
        }
      }
    }

    return true
  }

  public prepareKE() {

    const keys: string[] = []
    const keysSearch: string[] = []
    for (const item of this.state.variables) {
      if (this.state.keys[item] === 'Podjetje zaposlitve') {
        continue
      }
      keys.push(this.state.keys[item])
      keysSearch.push(this.state.keysSearch[item])
    }
    // console.log(keys)
    // console.log(this.state.kedatall)
    const kedataAll0 = []
    for (let i: number = 0; i < this.state.kedatall.length; i++) {
      const item = {}
      for (let j: number = 0; j < keys.length; j++) {
        item[keys[j]] = this.state.kedatall[i][keys[j]]
        if (keys[j] !== keysSearch[j]) {
          item[keysSearch[j]] = this.state.kedatall[i][keysSearch[j]]
        }
      }
      if (this.testIfItemOK(item, keys, keysSearch)) {
        kedataAll0.push(item)
      }
    }

    if (kedataAll0.length && this.state.variablesOrder.length) {
      for (let i: number = this.state.variablesOrder.length - 1 ; i >= 0; i--) {
        const key: string = this.state.keysSearch[this.state.variablesOrder[i]]
        if (this.state.variablesOrderType[i]) {
          kedataAll0.sort((a, b) => a[key] === b[key] ? 0 : a[key] === null ? 1 : b[key] === null ? -1 : a[key] < b[key] ? -1 : 1)
        } else {
          kedataAll0.sort((a, b) => a[key] === b[key] ? 0 : a[key] === null ? 1 : b[key] === null ? -1 : a[key] < b[key] ? 1 : -1)
        }
      }
    }

    if (this.state.group !== undefined && kedataAll0[0][this.state.keys[this.state.group]] !== undefined) {
      const groups: string[] = []
      const numerical: boolean = this.state.keys[this.state.group] === 'Starost' || this.state.keys[this.state.group] === 'Skupno št. ur na teden' || this.state.keys[this.state.group].startsWith('Št. ur na teden (')

      if (this.state.f[this.state.group]) {
        for (let j: number = 0; j < this.state.f[this.state.group].length; j++) {
          if (numerical) {
            if (+this.state.f[this.state.group][j][0] === +this.state.f[this.state.group][j][1]) {
              groups.push((+this.state.f[this.state.group][j][0]).toString())
            } else {
              groups.push(+this.state.f[this.state.group][j][0] + ' - ' + (+this.state.f[this.state.group][j][1]))
            }
          } else {
            if (this.state.f[this.state.group][j][0] === this.state.f[this.state.group][j][1]) {
              groups.push(this.state.f[this.state.group][j][0])
            } else {
              groups.push(this.state.f[this.state.group][j][0] + ' - ' + (this.state.f[this.state.group][j][1]))
            }
          }
        }
      } else {
        for (let j: number = 0; j < this.state.variablesSelected[this.state.group].length; j++) {
          groups.push(this.state.variablesSelected[this.state.group][j])
        }
      }
      if (this.state.variablesNull[this.state.group]) {
        groups.push('ni podatka')
      }

      const groupsColors: string[] = []
      for (let i: number = 0; i < groups.length; i++) {
        groupsColors.push(this.state.groupsColorsDefault[i % this.state.groupsColorsDefault.length])
      }

      const kedataAll: any[][] = new Array(groups.length)
      for (let i: number = 0; i < kedataAll.length; i++) {
        kedataAll[i] = []
      }

      for (let i: number = 0; i < kedataAll0.length; i++) {
        for (let j: number = 0; j < groups.length; j++) {
          if (groups[j] === 'ni podatka') {
            if (kedataAll0[i][this.state.keys[this.state.group]] === null) {
              kedataAll[j].push(kedataAll0[i])
              break
            }
          } else {
            if (kedataAll0[i][this.state.keys[this.state.group]] === null) {continue}
            if (this.state.f[this.state.group]) {
              let lower: string = this.state.f[this.state.group][j][0]
              let upper: string = this.state.f[this.state.group][j][1]

              if (numerical) {
                const toCompare = +kedataAll0[i][this.state.keysSearch[this.state.group]]
                if (toCompare >= +lower && toCompare <= +upper) {
                  kedataAll[j].push(kedataAll0[i])
                  break
                }
              } else {
                if (ffp[this.state.group]) {
                  if (ffp[this.state.group] === 'dd.mm.llll') {
                    lower = lower.substring(6,10) + '-' + lower.substring(3,5) + '-' + lower.substring(0,2)
                    upper = upper.substring(6,10) + '-' + upper.substring(3,5) + '-' + upper.substring(0,2)
                  } else if (ffp[this.state.group] === 'dd.mm.ll') {
                    lower = lower.substring(6,8) + '-' + lower.substring(3,5) + '-' + lower.substring(0,2)
                    upper = upper.substring(6,8) + '-' + upper.substring(3,5) + '-' + upper.substring(0,2)
                  }
                }
                if (kedataAll0[i][this.state.keysSearch[this.state.group]] >= lower && kedataAll0[i][this.state.keysSearch[this.state.group]] <= upper) {
                  kedataAll[j].push(kedataAll0[i])
                  break
                }
              }
            } else {
              if (kedataAll0[i][this.state.keys[this.state.group]] === groups[j]) {
                kedataAll[j].push(kedataAll0[i])
                break
              }
            }
          }
        }
      }
      // console.log(kedataAll0)
      this.setState({kedataAll, kedataAll0, groups, groupsColors})
    } else {
      // console.log(kedataAll0)
      this.setState({kedataAll: [kedataAll0], kedataAll0, groups: undefined})
    }
  }

  public async loadSettings() {
    if (confirm('Ali potrjujete, da želite naložiti shranjeno konfiguracijo spremenljivk?')) {
      const response = await axios.get(settings, {params: {token: this.props.userData.token}})
      if (response && response.data && response.data.data && response.data.resolved) {

        const f = response.data.data.f
        const variables = response.data.data.variables
        const Keys = response.data.data.Keys
        const variablesNull = response.data.data.variablesNull
        const keys = response.data.data.keys
        const keysSearch = response.data.data.keysSearch
        const keysType = response.data.data.keysType
        const variablesSelected = response.data.data.variablesSelected
        const variablesOrderType = response.data.data.variablesOrderType
        const variablesOrder = response.data.data.variablesOrder
        const groupsColorsDefault = response.data.data.groupsColors
        const mean = response.data.data.mean
        const median = response.data.data.median
        this.setState({f, variables, Keys, variablesNull, keys, keysSearch, keysType, variablesSelected, variablesOrderType, variablesOrder, groupsColorsDefault, mean, median})
      }
    }
  }
  public async saveSettingsColor(groupsColors: string[]) {
    if (confirm('Ali potrjujete, da želite shraniti konfiguracijo barvne palete?')) {
      const response = await axios.put(settings, {
        groupsColors,
        token: this.props.userData.token
      })
      if (response && response.data && response.data.resolved) {
        alert ('Konfiguracija barvne palete uspešno shranjena!')
      }
    }
  }
  public async saveSettings(f: string[][][], variables: number[], Keys: boolean[], variablesNull: boolean[], keys: string[], keysSearch: string[], keysType: string[], variablesSelected: string[][], variablesOrderType: boolean[], variablesOrder: number[], mean: boolean[], median: boolean[]) {
    if (confirm('Ali potrjujete, da želite shraniti konfiguracijo spremenljivk?')) {
      const response = await axios.put(settings, {
        f,
        variables,
        Keys,
        variablesNull,
        keys,
        keysSearch,
        keysType,
        variablesSelected,
        variablesOrderType,
        variablesOrder,
        mean,
        median,
        token: this.props.userData.token
      })
      if (response && response.data && response.data.resolved) {
        alert ('Konfiguracija spremenljivk uspešno shranjena!')
      }
    }
  }

  public getTableBody(): JSX.Element[] {
    const tr: JSX.Element[] = []
    for (let i: number = 0; i < this.state.kedataAll.length; i++) {
      for (let j: number = 0; j < this.state.kedataAll[i].length; j++) {
        const t_r: JSX.Element[] = []
        for (const item of this.state.variables) {
          if (this.state.keys[item] === 'Podjetje zaposlitve') {continue}

          t_r.push(<td key={item}>
            {this.state.kedataAll[i][j][this.state.keys[item]]} {this.getUnit(this.state.keys[item], this.state.kedataAll[i][j][this.state.keys[item]])}
          </td>)
        }

        if (this.state.group !== undefined && this.state.groupNumber && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined) {
          t_r.push(<td key={-1} title={'Kategorija: ' + this.state.groups[i]}>
            {i+1}.
          </td>)
        }
        tr.push(<tr style = {{background: this.state.group === undefined || !this.state.groupColor ? '' : this.state.groupsColors[i]}} key = {i + '_' + j}>{t_r}</tr>)
      }
    }
    return tr
  }

  public getTableHeader(): JSX.Element[] {
    const th: JSX.Element[] = []
    for (const item of this.state.variables) {
      if (this.state.keys[item] === 'Podjetje zaposlitve') {continue}
      th.push(<th key={item}>{
        this.state.keys[item]}
      </th>)
    }
    if (this.state.group !== undefined && this.state.groupNumber && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined) {
      th.push(<th title = "kategorija" key={-1}>Kat.</th>)
    }
    return th
  }

  public setGroups(i: number, value: string) {
    const groups: string[] = Object.assign([], this.state.groups)
    groups[i] = value
    this.setState({groups})
  }

  public setGroupsColors(i: number, value: string) {
    const groupsColors: string[] = Object.assign([], this.state.groupsColors)
    groupsColors[i] = value
    this.setState({groupsColors})
  }

  public getMembersTitle(n: number): string {
    let r: string = n + ' uporabnik'
    if (n === 1) {return r}
    if (n === 2) {return r + 'a'}
    if (n === 3 || n === 4) {return r + 'i'}
    return r + 'ov'
  }

  public showGroups(variable: string[], type: Statistics[], means: any, print?: boolean): JSX.Element[] {
    const g: JSX.Element[] = []
    if (this.state.groups && this.state.kedataAll) {
      for (let i: number = 0; i < this.state.groups.length; i++) {
        if (!this.state.kedataAll[i]) {continue}
        if (!this.state.groupEmpty && this.state.kedataAll[i].length === 0) {
          continue
        }
        g.push(<div className = "mt3 group" key = {i}>
            {this.state.groupNumber ? <span className = "groupnumber">{i+1}.</span> : null}
            <input title = {this.state.groups[i]} className = {clsx('groupname', this.state.groupNumber ? 'ml' : '')} onChange={(e)=>this.setGroups(i, e.target.value)} style = {{height: print ? '24px' : '', paddingTop: print ? '0px' : '', paddingLeft: this.state.groupColor ? '10px' : '0px', background: this.state.groupColor ? this.state.groupsColors[i] : 'transparent'}} value={this.state.groups[i]}/>
            <span title = {this.getMembersTitle(this.state.kedataAll[i].length)} className = "groupmembers">{this.state.kedataAll[i].length}</span>
            {this.state.groupColor && !print ? <input className = "groupcolor" onChange={(e)=>this.setGroupsColors(i, e.target.value)} style = {{background: this.state.groupColor ? this.state.groupsColors[i] : ''}} value={this.state.groupsColors[i]}/> : null}
            {this.getAveragesBody(this.state.groups[i], variable, type, means, print)}
        </div>)
      }
    }
    return g
  }

public calculateMedian(values: number[]): number {
  const half: number = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

public getUnit(variable: string, value?: string): string {
  if (value === null) {return ''}
  if (variable === 'Starost') {return 'let'}
  if (variable === 'Skupno št. ur na teden' || variable.startsWith('Št. ur na teden (')) {return 'ur'}
  return ''
}

public getAverages(): {means: any, element: JSX.Element[], variable: string[], type: Statistics[]} {
  const element: JSX.Element[] = []
  const variable: string[] = []
  const type: Statistics[] = []

  const means = {}
  for (let k: number = 0; k < this.state.kedataAll.length; k++) {
    if (this.state.kedataAll[k].length) {

      if (this.state.groups && this.state.groups.length) {
        for (let i: number = 0; i < this.state.mean.length; i++) {
          if (this.state.mean[i]) {
            let unit: string = this.getUnit(this.state.keys[i])
            let meansum: number = 0
            let mean: number = 0
            if (this.state.kedataAll[k] && this.state.kedataAll[k][0] && this.state.kedataAll[k][0][this.state.keys[i]] !== undefined) {
              for (let j: number = 0; j < this.state.kedataAll[k].length; j++) {
                if (this.state.kedataAll[k][j][this.state.keys[i]] === null) {continue}
                meansum += +this.state.kedataAll[k][j][this.state.keys[i]]
                mean++
              }
            }
            if (mean) {
              means[this.state.groups[k] + ' Povprečje ' + this.state.keys[i]] = (meansum/mean).toFixed(2) + ' ' + unit
            }
          }

          if (this.state.median[i]) {
            let unit: string = this.getUnit(this.state.keys[i])
            let median: number[] = []
            if (this.state.kedataAll[k] && this.state.kedataAll[k][0] && this.state.kedataAll[k][0][this.state.keys[i]] !== undefined) {
              for (let j: number = 0; j < this.state.kedataAll[k].length; j++) {
                if (this.state.kedataAll[k][j][this.state.keys[i]] === null) {continue}
                median.push(+this.state.kedataAll[k][j][this.state.keys[i]])
              }
            }
            if (median.length) {
              median.sort((a,b) => +a === +b ? 0 : +a < +b ? 1 : -1)
              means[this.state.groups[k] + ' Mediana ' + this.state.keys[i]] = (this.calculateMedian(median).toFixed(2)) + ' ' + unit
            }
          }
        }
      }
    }
  }

  for (let i: number = 0; i < this.state.mean.length; i++) {
    if (this.state.mean[i]) {
      let unit: string = this.getUnit(this.state.keys[i])
      let meansum: number = 0
      let mean: number = 0
      if (this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[i]] !== undefined) {
        for (let j: number = 0; j < this.state.kedataAll0.length; j++) {
          if (this.state.kedataAll0[j][this.state.keys[i]] === null) {continue}
          meansum += +this.state.kedataAll0[j][this.state.keys[i]]
          mean++
        }
      }
      if (mean) {
        element.push(<div className = "average" key = {'mean' + i}>Povprečje vrednosti za spremenljivko "{this.state.keys[i]}": {(meansum/mean).toFixed(2)} {unit}</div>)
        variable.push(this.state.keys[i])
        type.push('Povprečje')
      }
    }

    if (this.state.median[i]) {
      let unit: string = this.getUnit(this.state.keys[i])
      let median: number[] = []
      if (this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[i]] !== undefined) {
        for (let j: number = 0; j < this.state.kedataAll0.length; j++) {
          if (this.state.kedataAll0[j][this.state.keys[i]] === null) {continue}
          median.push(+this.state.kedataAll0[j][this.state.keys[i]])
        }
      }
      if (median.length) {
        median.sort((a,b) => +a === +b ? 0 : +a < +b ? 1 : -1)
        element.push(<div className = "average" key = {'median' + i}>
          Mediana vrednosti za spremenljivko "{this.state.keys[i]}": {this.calculateMedian(median).toFixed(2)} {unit}
        </div>)
        variable.push(this.state.keys[i])
        type.push('Mediana')
      }
    }
  }
  if (element.length) {
    element.unshift(<div className = "mt bold">Povprečja/mediane</div>)
  }
  return {means, element, variable, type}
}

public getAveragesBody(group: string, variable: string[], type: Statistics[], means: any, print?: boolean): JSX.Element[] {
  const ab: JSX.Element[] = []
  for (let i: number = 0; i < variable.length; i++) {
    const width: string = variable[i] === 'Starost' ? (print ? '155px' : '160px') : variable[i] === 'Skupno št. ur na teden' || variable[i].startsWith('Št. ur na teden (') ? (print ? '195px' : '280px') : '200px'
    ab.push(<span className = "ml dibl" style = {{width}} key = {i + type[i]}>
      {means[group + ' ' + type[i] + ' ' + variable[i] + '']}
    </span>)
  }
  return ab
}

public getAveragesHeader(variable: string[], type: Statistics[], print?: boolean): JSX.Element[] {
  const ah: JSX.Element[] = []
  for (let i: number = 0; i < variable.length; i++) {
    const width: string = variable[i] === 'Starost' ? (print ? '155px' : '160px') : variable[i] === 'Skupno št. ur na teden' || variable[i].startsWith('Št. ur na teden (') ? (print ? '195px' : '280px') : '200px'
    ah.push(<span className = "ml dibl" style = {{width, marginLeft: print && i === 0 ? '40px' : ''}} key = {i + type[i]}>
      {type[i]} "{variable[i] === 'Skupno št. ur na teden' ? 'Skupno št. ur' :
      variable[i].startsWith('Št. ur na teden (') ? variable[i].replace('Št. ur na teden', 'Št. ur') : variable[i]}"
    </span>)
  }
  return ah
}

public async keExportJson(csv?: boolean) {
  const now: string = this.toLocalString(new Date());
  const date: string =
    now.substring(8, 10) +
    '/' +
    now.substring(5, 7) +
    '/' +
    now.substring(0, 4) +
    ' ob ' +
    now.substring(11, 16);

  if (csv) {
    const parser = new Parser({quote: "'"});
    const csvText = parser.parse(this.state.kedataAll0);
    this.download(
      csvText,
      'text/csv',
      'Izvoz kadrovske evidence ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
    );
  } else if (!csv) { //xlsx
    const datas: any[][] = []
    const keys = Object.keys(this.state.kedataAll0[0])
    const dataItem = []
    for (const key of keys) {
      if (!key.endsWith('_')) {
        dataItem.push(key)
      }
    }
    datas.push(dataItem)
    for (const item of this.state.kedataAll0) {
      const dataitem = []
      for (const key of keys) {
        if (!key.endsWith('_')) {
          dataitem.push(item[key])
        }
      }
      datas.push(dataitem)
    }
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    const title = 'Izvoz kadrovske evidence';
    const worksheet = workbook.addWorksheet(title);
    for (const item of datas) {
      worksheet.addRow(item);
    }
    const buffer = await workbook.xlsx.writeBuffer();
    this.download(undefined, EXCEL_TYPE, 'Izvoz kadrovske evidence' + date.replace(':', ' ').replace('/', ' ').replace('/', ' '), buffer)

/*
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {type: EXCEL_TYPE});
      // Given name
      FileSaver.saveAs(blob, `download${EXCEL_EXTENSION}`);
    });
*/


    // const buffer: Buffer = xlsx.build([{name: 'Izvoz kadrovske evidence', data, options: {}}]);
    // buffer.toString('utf8')
    // Buffer.from(buffer).toString()
    // const workSheetsFromBuffer =
    // xlsx.parse(fs.readFileSync(`${__dirname}/Izvoz_kadrovske_evidence.xlsx`));
    // fs.writeFile('Izvoz_kadrovske_evidence.xlsx', Buffer.from(buffer).toString(), (err) => {
      // if(!err) console.log('Data written');
    // });
  } else {
    const json: string = JSON.stringify(this.state.kedataAll0, null, 4);
    this.download(
      json,
      'application/json',
      'Izvoz kadrovske evidence ' +
        date.replace(':', ' ').replace('/', ' ').replace('/', ' '),
    );
  }
}

public getHeader(): JSX.Element {
  return <>
    <div style = {{fontSize: '18px'}} className = "bold pr">
      Kadrovska evidenca
      {this.state.kedataAll ? <>
        {this.state.group !== undefined && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined ? <span className = "pa kps0">Kategorizacija po stolpcu: <strong>{this.state.keys[this.state.group]}</strong></span> : null}
        </> : null}
    </div>
  </>
}

public getBody(page: number, linesPerPage: number): JSX.Element {
  const tr: JSX.Element[] = []
  let count: number = 0;
  if (this.state.kedataAll) {
    for (let i: number = 0; i < this.state.kedataAll.length; i++) {
      for (let j: number = 0; j < this.state.kedataAll[i].length; j++) {
        count++
        if (count <= page * linesPerPage) {continue}
        if (count > (page + 1) * linesPerPage) {break}
        const t_r: JSX.Element[] = []
        for (const item of this.state.variables) {
          t_r.push(<td key={item}>
            {this.state.kedataAll[i][j][this.state.keys[item]]} {this.getUnit(this.state.keys[item], this.state.kedataAll[i][j][this.state.keys[item]])}
          </td>)
        }
        if (this.state.group !== undefined && this.state.groupNumber && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined) {
          t_r.push(<td key={-1} title={'Kategorija: ' + this.state.groups[i]}>
            {i+1}.
          </td>)
        }
        tr.push(<tr style = {{background: this.state.group === undefined || !this.state.groupColor ? '' : this.state.groupsColors[i]}} key = {i + '_' + j}>{t_r}</tr>)
      }
    }
  }
  return <tbody>{tr}</tbody>
}

public getHead(): JSX.Element {
  const th: JSX.Element[] = []
  for (const item of this.state.variables) {
    th.push(<th key={item}>
      {/*this.state.keys[item] === 'Datum opravljanja izpita iz varstva pri delu' ? 'Veljavnost izpita iz varstva pri delu' :
        this.state.keys[item] === 'Datum zadnjega zdravniškega pregleda' ? 'Veljavnost zdravniškega pregleda' :*/
        this.state.keys[item]}
    </th>)
  }
  if (this.state.group !== undefined && this.state.groupNumber && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined) {
    th.push(<th title = "kategorija" key={-1}>Kat.</th>)
  }
  return <thead><tr>{th}</tr></thead>
}

public getTable(i: number, linesPerPage: number): JSX.Element {
  return <table style= {{width: '100%', marginTop: '15px', borderSpacing: '0px', borderBottom: '1px solid #000'}} className = "styled-table print">
    {this.getHead()}
    {this.getBody(i, linesPerPage)}
  </table>
}

public getPageNumber(i: number, n: number): JSX.Element {
  const elementLast = document.getElementById('sheet' + (n+1));
  if (elementLast) {
    n++
  }
  return <div style = {{position: 'absolute', width: '100%', bottom: '-10px', textAlign: 'center', fontSize: '10px', fontWeight: 400}}>
    stran {i} od {n}
  </div>
}

public getSheets(averages: Averages): JSX.Element[] {
  const sheet: JSX.Element[] = []
  if (this.state.kedataAll0) {
    const lines: number = this.state.kedataAll0.length
    let n: number = Math.ceil(lines / linesPerPage)
    if (n === 0) {n = 1}
    for (let i: number = 0; i < n; i++) {
      sheet.push(<div key={i} style={{padding: '1%', width: '1110px', height: '785px'}} id = {'sheet' + (i+1)} className = "fl sheet0 box-shadow mr pr">
        {this.getHeader()}
        {this.getTable(i, linesPerPage)}
        {this.getPageNumber(i + 1, n)}
      </div>
      )
    }

    if (averages.element.length || this.state.group !== undefined && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined) {
      sheet.push(<div key={n} style={{padding: '1%', width: '1110px', height: '785px'}} id = {'sheet' + (n+1)} className = "fl sheet0 box-shadow mr pr">
        <div className = "mt averages">
          {averages.element}
        </div>

        {this.state.group !== undefined && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined ?
        <div className = "mt groups">
          <div>
            <strong style = {{marginRight: this.state.groupNumber ? '200px' : '170px'}}>Kategorizacija</strong>
            {averages.element.length ? this.getAveragesHeader(averages.variable, averages.type, true) : null}
          </div>
          {this.showGroups(averages.variable, averages.type, averages.means, true)}
        </div> : null}
        {this.getPageNumber(n + 1, n)}
      </div>)
    }
  }
  return sheet
}

public keNavigation(): JSX.Element {
  return <>
  <button
      title = "Tiskaj kadrovsko evidenco"
      onClick={() => this.kePrint(false)}
      className="button button-green button-thin"
    >
      Tiskaj kadrovsko evidenco
  </button>
  <button
      title="Shrani .pdf"
      onClick={() => this.kePrint(true)}
      className="button button-green button-thin ml"
    >
      ⬇ .pdf
  </button>
  <button
      title="Izvozi .json"
      onClick = {() => this.keExportJson()}
      className = "ml button button-green button-thin"
    >
      ⬇ .json
  </button>
  <button
      title="Izvozi .csv"
      onClick = {() => this.keExportJson(true)}
      className = "ml button button-green button-thin"
    >
      ⬇ .csv
  </button>
  <button
    title="Izvozi .xlsx"
    onClick = {() => this.keExportJson(false)}
    className = "ml button button-green button-thin"
  >
    ⬇ .xlsx
  </button></>


}

public render() {
    if (this.props.userData.token && this.state.userData === undefined) {
      this.setState({ userData: null });
      this.loadInitialData();
      return <div>&nbsp;</div>;
    }

    let averages: Averages = {
      element: [],
      variable: [],
      type: [],
      means: {}
    }
    if (this.state && this.state.kedatall && this.state.kedataAll) {
      averages = this.getAverages()
    }
    // console.log(this.state.variables)

/*
    console.log(this.state.keys)
    console.log(this.state.keysSearch)
    console.log(this.state.variablesSelected)
    console.log(this.state.f)
    console.log(this.state.Keys)
    console.log(this.state.keysType)
    console.log(this.state.variablesOrderType)
    console.log(this.state.variablesOrder)
    console.log(this.state.mean)
    console.log(this.state.median)
*/
    const popupShown = this.state.kedatall && this.state.navigation === Navigation.User
                        || this.state.ke && this.state.kedata && this.state.kedataold
                        || this.state.popup || this.state.showPdf || this.state.enlargeImage

    return this.state ? (
      <div
        className="h100 pr"
        style={{ overflowY: this.state.enlargeImage ? 'hidden' : 'scroll' }}
      >
        {this.state.loader ? <Loader className = "loader"/> : null}
        {popupShown ? <div className = "popup-shown">&nbsp;</div> : null}
        {this.state.kedatall && this.state.navigation === Navigation.User ? (
          <div style ={{padding: '10px 15px', background: '#eee', overflowX: 'auto', overflowY: 'scroll', left: 20, right: 20, marginLeft: 'auto', marginRight: 'auto'}} className="popup pf">
            <a
              onClick={() => this.setState({ kedatall: undefined, kedataAll: undefined})}
              className="popup-close-scroll-wide pf"
            >
              ✗
            </a>
            <div id = "Sheet0" className ="Sheet0">
              {this.getSheets(averages)}
            </div>

            <div style = {{fontSize: '18px'}} className = "bold pr">
              Kadrovska evidenca {this.state.kedataAll ? <>(izbranih {this.state.kedataAll0 ? this.state.kedataAll0.length : '?'} od {this.state.userData.length} uporabnikov)</> : '(' + this.state.userData.length +  ' uporabnikov)'}
              {this.state.kedataAll ? <>
                {this.state.group !== undefined && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined ? <span className = "pa kps">Kategorizacija po stolpcu: <strong>{this.state.keys[this.state.group]}</strong></span> : null}
                <span style={{marginLeft: '30px'}}>
                  {this.keNavigation()}
                </span>
                <a onClick = {()=>this.setState({kedataAll: undefined})} className = "pa zpp button button-red button-thin">
                  Zapri prikaz podatkov
                </a>
                </> : null}
            </div>
            {this.state.kedataAll ?
            <>
              <table className = "styled-table">
                <thead>
                    <tr>
                      {this.getTableHeader()}
                    </tr>
                </thead>
                <tbody>
                  {this.getTableBody()}
                </tbody>
              </table>
              <div className = "mt averages">
                {averages.element}
              </div>

              {this.state.group !== undefined && this.state.kedataAll0 && this.state.kedataAll0[0] && this.state.kedataAll0[0][this.state.keys[this.state.group]] !== undefined ? <div className = "mt groups">
                <div>
                  <strong>Kategorizacija</strong>
                  <input onChange={()=>this.setState({groupNumber: !this.state.groupNumber})} type="checkbox" className="ml20" checked={this.state.groupNumber}/>
                  <span title = "prikaži zaporedno številko kategorije" className="ml3">zap. št. kat.</span>
                  <input onChange={()=>this.setState({groupColor: !this.state.groupColor})} type="checkbox" className="ml15" checked={this.state.groupColor}/>
                  <span style = {{marginRight: this.state.groupColor ? '' : '30px'}} title = "prikaži barve" className="ml3">barve</span>
                  {this.state.groupColor ?
                  <a style = {{marginRight: this.state.groupNumber ? '12px' : '0px', marginLeft: this.state.groupNumber ? '30px' : '11px', textAlign: 'center'}} onClick = {() => this.saveSettingsColor(this.state.groupsColors)} title = "Shrani barvno paleto" className = "button button-thin button-red">
                    Shrani
                  </a> : null}
                  {averages.element.length ? this.getAveragesHeader(averages.variable, averages.type) : null}
                </div>
                {this.showGroups(averages.variable, averages.type, averages.means)}
                <div className="mt">
                  <input className="ml0" onChange={()=>this.setState({groupEmpty: !this.state.groupEmpty})} type="checkbox" checked={this.state.groupEmpty}/>
                  <span className="ml3">prikaži prazne kategorije</span>
                </div>
              </div> : null}

              <div className="mt">
                {this.keNavigation()}
              </div>
            </>
            :
            <>
              <div className = "clearfix mb">
                <div className = "bold fl">Izbira spremenljivk za prikaz</div>
                <button title = {this.state.variables.length === this.state.Keys.length - 1 ? 'Vse spremenljivke so izbrane' : undefined} disabled = {this.state.variables.length === this.state.Keys.length} onClick = {() => this.selectAllVariables(true)} className = "button button-thin button-red ml dbl fl mt3">
                  Izberi vse spremenljivke
                </button>
                <button title = {this.state.variables.length === 0 ? 'Nobena spremenljivka ni izbrana' : undefined} disabled = {this.state.variables.length === 0} onClick = {() => this.selectAllVariables(false)} className = "button button-thin button-red ml dbl fl mt3">
                  Odstrani vse spremenljivke
                </button>
                <button onClick = {() => this.saveSettings(this.state.f, this.state.variables, this.state.Keys, this.state.variablesNull, this.state.keys, this.state.keysSearch, this.state.keysType, this.state.variablesSelected, this.state.variablesOrderType, this.state.variablesOrder, this.state.mean, this.state.median)} className = "button button-thin button-green ml dbl fl mt3">
                  Shrani konfiguracijo spremenljivk
                </button>
                <button onClick = {() => this.loadSettings()} className = "button button-thin button-green ml dbl fl mt3">
                  Naloži shranjeno konfiguracijo spremenljivk
                </button>
                <button disabled = {this.state.variables.length === 0} onClick = {() => this.prepareKE()} className = "button button-thin button-green ml dbl fl mt3 active">
                  Prikaži podatke
                </button>
              </div>
              {this.getVariables()}
              {/*this.state.Keys[14] ?
              <div className = "bold mb mt">Izbira spremenljivk za Podjetje zaposlitve</div>
              : null*/}
              {this.state.variables.length ?
              <>
              <div className = "bold mb mt">Način prikaza spremenljivk</div>
              {this.getVariablesShow()}
              {this.state.variablesOrder.length ?
              <div className = "bold mb mt">Nastavitev razvrščanja</div> : null}
              {this.getVariablesOrder()}
              </> : null}
            </>}
          </div>
        ) : null}
        {this.state.ke && this.state.kedata && this.state.kedataold ? (
          <div style ={{background: '#fff', width: '800px', overflowY: 'scroll', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto'}} className="popup pf">
            <a
              onClick={() => this.setState({ ke: undefined, kedata: undefined, kedataold: undefined, kedataolduserworking: undefined })}
              className="popup-close-scroll pf"
            >
              ✗
            </a>
            <div id = "ketoprint" style ={{paddingLeft: '20px', paddingTop: '10px'}}>
              <div>
                <div className="tar beforeinputwide2 fl semi-bold">
                  <b>{this.props.userData.usertype === UserType.Super ? 'Urejanje' : 'Pregled'} kadrovske evidence</b> za uporabnika z e-pošto
                </div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={30}
                  onChange={e => this.editKE('email', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.email !== this.state.kedataold.email ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.email}
                />
              </div>
              <div className="mt clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Ime:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={30}
                  onChange={e => this.editKE('firstname', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.firstname !== this.state.kedataold.firstname ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.firstname}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Priimek:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={30}
                  onChange={e => this.editKE('lastname', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.lastname !== this.state.kedataold.lastname ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.lastname}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Elektronski naslov:</div>
                <input
                  style = {{width: '325px'}}
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={100}
                  onChange={e => this.editKE('email2', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.email2 !== this.state.kedataold.email2 ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.email2}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Stalni naslov:</div>
                <input
                  style = {{width: '325px'}}
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={50}
                  onChange={e => this.editKE('address', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.address !== this.state.kedataold.address ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.address}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Začasni naslov:</div>
                <input
                  style = {{width: '325px'}}
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={50}
                  onChange={e => this.editKE('addresstemp', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.addresstemp !== this.state.kedataold.addresstemp ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.addresstemp}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Telefonska št.:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={30}
                  onChange={e => this.editKE('telephone2', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.telephone2 !== this.state.kedataold.telephone2 ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.telephone2}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">EMŠO:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  style = {{width: '150px'}}
                  placeholder="vpiši EMŠO"
                  maxLength={13}
                  onChange={e => this.editKE('emso', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.emso !== this.state.kedataold.emso ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.emso}
                />
              </div>

              <div className="clearfix datepicker-short">
                <div className="tar beforeinputwide2 fl semi-bold">Datum rojstva:</div>
                <DatePicker
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  calendarStartDay={1}
                  style = {{marginLeft: '5px'}}
                  placeholderText="izberi datum"
                  dateFormat={dateFormatDot}
                  locale={locale}
                  selected={this.state.kedata.birthdate}
                  onChange={value => this.editKE('birthdate', undefined, value)}
                />
                {this.state.kedata.birthdate__ ?
                <span style = {{marginLeft: '5px'}} className = "red bold">
                  {this.state.kedata.birthdate__} let
                </span> : null}
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Davčna št.:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  style = {{width: '110px'}}
                  placeholder="vpiši št."
                  maxLength={8}
                  onChange={e => this.editKE('taxnumber', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.taxnumber !== this.state.kedataold.taxnumber ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.taxnumber}
                />
              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Spol:</div>
                <button disabled={this.props.userData.usertype !== UserType.Super} onClick = {()=>this.editKE('sex', undefined, undefined, 1)} className = {clsx('mt3 fl dbl ml button button-thin button-green', this.state.kedata.sex === 1 ? 'active' : '')}>
                  {sex[0].value}
                </button>
                <button disabled={this.props.userData.usertype !== UserType.Super} onClick = {()=>this.editKE('sex', undefined, undefined, 2)} className = {clsx('mt3 fl dbl ml button button-thin button-green', this.state.kedata.sex === 2 ? 'active' : '')}>
                  {sex[1].value}
                </button>
              </div>

              <div className="clearfix pr">
                <div className="tar beforeinputwide2 fl semi-bold">Naziv:</div>
                <input style = {{paddingLeft: '5px', width: '110px'}} list="title" className = {clsx('ml fl dbl input input1', this.state.kedata.title !== this.state.kedataold.title ? 'edited' : '')} value = {this.state.kedata.title_} onChange={(e) =>
                  this.setCodeRegister('title', title, 'title_', e.target.value)}
                  placeholder = "Izberi naziv"
                />
                <datalist id = "title">
                  {this.getDatalist(title)}
                </datalist>
                {this.countDatalist(title, this.state.kedata.title_) === false ? <span className = "red pa nohit10">Brez zadetka!</span> : null}
              </div>


              <div className="clearfix pr">
                <div className="tar beforeinputwide2 fl semi-bold">Izobrazba:</div>
                <input style = {{paddingLeft: '5px', width: '325px'}} list="education" className = {clsx('ml fl dbl input input1', this.state.kedata.education !== this.state.kedataold.education ? 'edited' : '')} value = {this.state.kedata.education_} onChange={(e) =>
                  this.setCodeRegister('education', education, 'education_', e.target.value)}
                  placeholder = "Izberi izobrazbo"
                />
                <datalist id = "education">
                  {this.getDatalist(education)}
                </datalist>
                {this.countDatalist(education, this.state.kedata.education_) === false ? <span className = "red pa nohit10">Brez zadetka!</span> : null}
              </div>

              <div className="clearfix pr">
                <div className="tar beforeinputwide2 fl semi-bold">Delovno mesto:</div>
                <input style = {{paddingLeft: '5px', width: '325px'}} list="workposition" className = {clsx('ml fl dbl input input1', this.state.kedata.workposition !== this.state.kedataold.workposition ? 'edited' : '')} value = {this.state.kedata.workposition_} onChange={(e) =>
                  this.setCodeRegister('workposition', workPosition, 'workposition_', e.target.value)}
                  placeholder = "Izberi delovno mesto"
                />
                <datalist id = "workposition">
                  {this.getDatalist(workPosition)}
                </datalist>
                {this.countDatalist(workPosition, this.state.kedata.workposition_) === false ? <span className = "red pa nohit10">Brez zadetka!</span> : null}
              </div>

              <div className="clearfix pr">
                <div className="tar beforeinputwide2 fl semi-bold">Posebna pooblastila:</div>
                <input style = {{paddingLeft: '5px', width: '325px'}} list="specialpermits" className = {clsx('ml fl dbl input input1', this.state.kedata.specialpermits !== this.state.kedataold.specialpermits ? 'edited' : '')} value = {this.state.kedata.specialpermits_} onChange={(e) =>
                  this.setCodeRegister('specialpermits', specialPermits, 'specialpermits_', e.target.value)}
                  placeholder = "Izberi posebna pooblastila"
                />
                <datalist id = "specialpermits">
                  {this.getDatalist(specialPermits)}
                </datalist>
                {this.countDatalist(specialPermits, this.state.kedata.specialpermits_) === false ? <span className = "red pa nohit10">Brez zadetka!</span> : null}
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Št. pooblastila:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  placeholder="vpiši št. pooblastila"
                  maxLength={50}
                  onChange={e => this.editKE('permitnumber', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.permitnumber !== this.state.kedataold.permitnumber ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.permitnumber}
                />
              </div>

              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Dodatna dovoljenja:</div>
                <input
                  style = {{width: '325px'}}
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  maxLength={145}
                  onChange={e => this.editKE('additionalpermits', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.additionalpermits !== this.state.kedataold.additionalpermits ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.additionalpermits}
                />
              </div>

              <div className="clearfix datepicker-short">
                <div className="tar beforeinputwide2 fl semi-bold">Veljavnost zdravniškega pregleda (velja 3 leta):</div>
                <DatePicker
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  calendarStartDay={1}
                  style = {{marginLeft: '5px'}}
                  placeholderText={placeholderDatePicker}
                  dateFormat={dateFormatDot}
                  locale={locale}
                  selected={this.state.kedata.medical}
                  onChange={value => this.editKE('medical', undefined, value)}
                />
                {/*
                <input
                  placeholder="velja do"
                  style = {{width: '110px', marginLeft: '5px', height: '24px'}}
                  readOnly={true}
                  value = {this.state.kedata.medical__}
                  className={clsx('pli input dbl fl', this.state.kedata.medical_ && this.state.kedata.medical_.length ? this.state.kedata.medical___ ? 'greenbg' : 'redbg' : '')}
                />
                */}
              </div>
              <div className="clearfix datepicker-short">
                <div className="tar beforeinputwide2 fl semi-bold">Veljavnost izpita dela na višini (velja 5 let):</div>
                <DatePicker
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  calendarStartDay={1}
                  style = {{marginLeft: '5px'}}
                  placeholderText={placeholderDatePicker}
                  dateFormat={dateFormatDot}
                  locale={locale}
                  selected={this.state.kedata.workatheight}
                  onChange={value => this.editKE('workatheight', undefined, value)}
                />
              </div>
              <div className="clearfix datepicker-short">
                <div className="tar beforeinputwide2 fl semi-bold">Veljavnost izpita iz varstva pri delu (velja 2 leti):</div>
                <DatePicker
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  calendarStartDay={1}
                  style = {{marginLeft: '5px'}}
                  placeholderText={placeholderDatePicker}
                  dateFormat={dateFormatDot}
                  locale={locale}
                  selected={this.state.kedata.worksecurity}
                  onChange={value => this.editKE('worksecurity', undefined, value)}
                />
                {/*
                <input
                  placeholder="velja do"
                  style = {{width: '110px', marginLeft: '5px', height: '24px'}}
                  readOnly={true}
                  value = {this.state.kedata.worksecurity__}
                  className={clsx('pli input dbl fl', this.state.kedata.worksecurity_ && this.state.kedata.worksecurity_.length ? this.state.kedata.worksecurity___ ? 'greenbg' : 'redbg' : '')}
                />
                */}
              </div>
              {this.getUserWorking()}
              <div className="clearfix pr">
                <div className="tar beforeinputwide2 fl semi-bold">Oddelek:</div>
                <input style = {{paddingLeft: '5px', width: '325px'}} list="sections" className = {clsx('ml fl dbl input input1', this.state.kedata.section !== this.state.kedataold.section ? 'edited' : '')} value = {this.state.kedata.section_} onChange={(e) =>
                  this.setCodeRegister('section', sections, 'section_', e.target.value)}
                  placeholder = "Izberi oddelek"
                />
                <datalist id = "sections">
                  {this.getDatalist(sections)}
                </datalist>
                {this.countDatalist(sections, this.state.kedata.section_) === false ? <span className = "red pa nohit10">Brez zadetka!</span> : null}
              </div>
              <div className="clearfix pr">
                <div className="tar beforeinputwide2 fl semi-bold">Vrsta zaposlitve:</div>
                <input style = {{paddingLeft: '5px', width: '325px'}} list="working" className = {clsx('ml fl dbl input input1', this.state.kedata.working !== this.state.kedataold.working ? 'edited' : '')} value = {this.state.kedata.working_} onChange={(e) =>
                  this.setCodeRegister('working', working, 'working_', e.target.value)}
                  placeholder = "Izberi vrsto zaposlitve"
                />
                <datalist id = "working">
                  {this.getDatalist(working)}
                </datalist>
                {this.countDatalist(working, this.state.kedata.working_) === false ? <span className = "red pa nohit10">Brez zadetka!</span> : null}

              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Status zaposlitve:</div>
                <button disabled={this.props.userData.usertype !== UserType.Super} onClick = {()=>this.editKE('workingstatus', undefined, undefined, 1)} className = {clsx('mt3 fl dbl ml button button-thin button-green', this.state.kedata.workingstatus === 1 ? 'active' : '')}>
                  {workingStatus[0].value}
                </button>
                <button disabled={this.props.userData.usertype !== UserType.Super} onClick = {()=>this.editKE('workingstatus', undefined, undefined, 2)} className = {clsx('mt3 fl dbl ml button button-thin button-red', this.state.kedata.workingstatus === 2 ? 'active' : '')}>
                  {workingStatus[1].value}
                </button>
              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Delovna doba - predhodna:</div>
                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  title = "let"
                  style = {{width: '35px'}}
                  maxLength={2}
                  onChange={e => this.editKE('workingbefore3', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.workingbefore !== this.state.kedataold.workingbefore ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.workingbefore3}
                />
                <div className = "fl ml5">let</div>

                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  title = "mesecev"
                  style = {{width: '35px'}}
                  maxLength={2}
                  onChange={e => this.editKE('workingbefore2', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.workingbefore !== this.state.kedataold.workingbefore ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.workingbefore2}
                />
                <div className = "fl ml5">mes.</div>

                <input
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  title = "dni"
                  style = {{width: '35px'}}
                  maxLength={2}
                  onChange={e => this.editKE('workingbefore1', e.target.value)}
                  className={clsx('pli input dbl fl', this.state.kedata.workingbefore !== this.state.kedataold.workingbefore ? 'edited' : '')}
                  type="text"
                  value={this.state.kedata.workingbefore1}
                />
                <div className = "fl ml5">dni</div>

              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Začetek nastopa dela ({c1s}/{c2s}/{c3s}):</div>
                <DatePicker
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  calendarStartDay={1}
                  style = {{marginLeft: '5px'}}
                  placeholderText={placeholderDatePicker}
                  dateFormat={dateFormatDot}
                  locale={locale}
                  selected={this.state.kedata.startwork}
                  onChange={value => this.editKE('startwork', undefined, value)}
                />
              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Zaključek dela:</div>
                <DatePicker
                  readOnly={this.props.userData.usertype !== UserType.Super}
                  calendarStartDay={1}
                  style = {{marginLeft: '5px'}}
                  placeholderText="nedoločen čas"
                  dateFormat={dateFormatDot}
                  locale={locale}
                  selected={this.state.kedata.endwork}
                  onChange={value => this.editKE('endwork', undefined, value)}
                />

              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Delovna doba ({c1s}/{c2s}/{c3s}):</div>

                <input
                  readOnly={true}
                  title = "let"
                  style = {{background: '#fff', width: '35px'}}
                  maxLength={2}
                  className={clsx('pli input dbl fl')}
                  type="text"
                  value={this.state.kedata.workhere3}
                />
                <div className = "fl ml5">let</div>

                <input
                  readOnly={true}
                  title = "mesecev"
                  style = {{background: '#fff', width: '35px'}}
                  maxLength={2}
                  className={clsx('pli input dbl fl')}
                  type="text"
                  value={this.state.kedata.workhere2}
                />
                <div className = "fl ml5">mes.</div>

                <input
                  readOnly={true}
                  title = "dni"
                  style = {{background: '#fff', width: '35px'}}
                  maxLength={2}
                  className={clsx('pli input dbl fl')}
                  type="text"
                  value={this.state.kedata.workhere1}
                />
                <div className = "fl ml5">dni</div>

              </div>
              <div className="clearfix">
                <div className="tar beforeinputwide2 fl semi-bold">Skupna delovna doba:</div>

                <input
                  readOnly={true}
                  title = "let"
                  style = {{background: '#fff', width: '35px'}}
                  maxLength={2}
                  className={clsx('pli input dbl fl')}
                  type="text"
                  value={this.state.kedata.workall3}
                />
                <div className = "fl ml5">let</div>

                <input
                  readOnly={true}
                  title = "mesecev"
                  style = {{background: '#fff', width: '35px'}}
                  maxLength={2}
                  className={clsx('pli input dbl fl')}
                  type="text"
                  value={this.state.kedata.workall2}
                />
                <div className = "fl ml5">mes.</div>

                <input
                  readOnly={true}
                  title = "dni"
                  style = {{background: '#fff', width: '35px'}}
                  maxLength={2}
                  className={clsx('pli input dbl fl')}
                  type="text"
                  value={this.state.kedata.workall1}
                />
                <div className = "fl ml5">dni</div>

              </div>
            </div>
            <div className = "mt ml20 mb5">
              <button
                title = "Tiskaj kadrovsko evidenco"
                onClick={() => this.ketoPrint(false)}
                className="button button-green button-thin"
              >
                Tiskaj kadrovsko ev.
              </button>
              <button
                onClick={() => this.ketoPrint(true)}
                className="button button-green button-thin ml"
              >
                Shrani .pdf
              </button>
              {this.props.userData.usertype === UserType.Super ?
              <button
                title = {this.state.kedata.userWorking && this.state.kedataolduserworking === this.state.kedata.userWorking.length && JSON.stringify(this.state.kedata) === JSON.stringify(this.state.kedataold) ? 'Kadrovska evidenca je shranjena!' : 'Kadrovsko evidenco je potrebno shraniti ali pa bodo podatki izgubljeni!'}
                onClick = {() => this.updateKE()} disabled = {this.state.kedata.userWorking && this.state.kedataolduserworking === this.state.kedata.userWorking.length && JSON.stringify(this.state.kedata) === JSON.stringify(this.state.kedataold)} className = "button button-red button-thin active ml">
                Shrani kadrovsko evidenco
              </button> : null}
              <button
                onClick={() => this.setState({ke: undefined, kedata: undefined, kedataold: undefined, kedataolduserworking: undefined})}
                className="button button-red button-thin ml"
              >
                Zapri pojavno okno
              </button>
            </div>
          </div>
        ) : null}
        {this.state.popup ? (
          <div style={{padding: '0px 20px'}} className="popup pf clearfix">
            <a
              onClick={() => this.setState({ popup: false })}
              className="popup-close pf"
            >
              ✗
            </a>
            <div className="third fl">
              {this.getNewDocumentBasicInformation(this, false, true)}
            </div>
            <div className="third fl">
              {this.getNewDocumentAccountingInformation(this, false, true, true)}
            </div>
            <div className="third fl">
              {this.getNewDocumentAttachments(this, false, true)}
              <div className = "bold mt">ID potnega naloga:</div>
              <div>{this.state.document.uuid}</div>
            </div>
            <div id="Sheet00">
              <div id="sheet00" style = {{margin: '10px'}} className = "clearfix">
                <div className = "half fl">
                  {this.getCompanyBasicInformation(this.state.document.companyuuid)}
                  <div className = "bold mt">ID potnega naloga:</div>
                  <div>{this.state.document.uuid}</div>
                  <div className = "bold mt">Osnovni podatki</div>
                  <div>Potnik: {this.getUserName(this.state.document.useruuid)}</div>
                  <div>Odhod: {this.formatDate(this.state.document.departure)}</div>
                  <div>Prihod: {this.formatDate(this.state.document.arrival)}</div>
                  <div>Projekt: {this.getProjectName(this.state.document.projectuuid, this.state.document.companyuuid)}</div>
                  <div>Po nalogu: {this.getUserName(this.state.document.orderuuid)}</div>
                  <div>Strošek: {this.getCompanyName(this.state.document.companyuuid)}</div>
                  <div>Opis namena: {this.state.document.description ? this.state.document.description : '-'}</div>
                  <div className = "bold mt">Vozilo</div>
                  <div>Tip vozila: {this.getVehicleType(this.state.document.vehicletype)}</div>
                  <div>Vozilo: {this.getVehicle(this, this.state.document.vehicleuuid)}</div>
                  <div>Reg. označba: {(this.state.document.vehicleType !== VehicleType.Companion
                                       ||
                                       this.state.document.vehicleType !== VehicleType.None) ?
                                       this.getVehiclePlate(this, this.state.document.vehicleuuid) :
                                       '-'
                                     }
                  </div>
                  <div>Odhod (km): {this.state.document.departuremileage ? this.state.document.departuremileage : ''}</div>
                  <div>Prihod (km): {this.state.document.arrivalmileage ? this.state.document.arrivalmileage : ''}</div>
                  <div>Kilometrina: {this.state.document.mileage}</div>
                  <div className = "bold mt">Relacija</div>
                  {this.state.document.pointuuids.map((pointuuid, index) =>
                    <div key={index}>Točka št. {index+1}: {this.getPointName(pointuuid)}</div>
                  )}
                  <div>Relacija: {this.state.document.path ? this.state.document.path : '-'}</div>
                </div>
                <div className = "half fl">
                    <div className = "bold">Kilometrina</div>
                    <div>Vrednost kilometra: {this.state.document.mileageuuid ? this.getMileageValue(this.state.document.mileageuuid) + ' €': '-'}</div>
                    <div>Vrednost: {this.showExpense(this, 0, 1)}</div>

                    <div className = "bold">Dnevnice</div>
                    {this.state.document.subsistence.map((item, index) =>
                      <div key={index}>
                        <div className = "mt5">Dan št. {index+1}: {this.getSubsistenceName(item.uuid)} ({this.getSubsistenceValue(item) + ' €'})</div>
                        <div>{this.getSubsistenceCountry(item.uuid)}</div>
                        <div>Odbitek zajtrk: {item.breakfast ? 'Da' : 'Ne'}</div>
                        <div>Odbitek kosilo: {item.lunch ? 'Da' : 'Ne'}</div>
                        <div>Odbitek večerja: {item.dinner ? 'Da' : 'Ne'}</div>
                      </div>
                    )}
                    <div>Vrednost: {this.showExpense(this, 1, 1)}</div>

                    <div className = "bold mt">Dodatni stroški</div>
                    {this.state.document.expenses.map((item, index) =>
                      <div key={index}>
                        <div className = "mt5">Strošek št. {index+1}: {this.getExpenseName(item.uuid)}</div>
                        <div>Vrednost stroška: {item.value + ' €'}</div>
                      </div>
                    )}
                    <div>Vrednost: {this.showExpense(this, 2, 1)}</div>

                    <div className = "mt">Skupen strošek naloga: {this.calculateExpense(this)}</div>
                    <div className = "mt5">Predplačilo: {this.state.document.advance ? this.state.document.advance + ' €' : '-'} {this.state.document.advancedate ? '(' + this.formatDate(this.state.document.advancedate.toISOString()).substring(0,10) +')' : ''}</div>
                    <div className = "mt5 bold">Ostalo izplačilo naloga: {this.calculateExpense(this, this.state.document.advance)}</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.showPdf ? (
          <div className="pf large-pdf">
            <div className="pr">
              <Document
                file={this.state.src}
                onLoadSuccess={this.onDocumentLoadSuccess}
                rotate={this.state.orientation}
                loading="Nalaganje..."
              >
                <Page
                  scale={this.state.scale}
                  pageNumber={this.state.pageNumber}
                  renderAnnotationLayer={false}
                />
              </Document>
              <div className="zoom pf">{this.getZoom()}</div>
              <div className="orientation pf">{this.getOrientation()}</div>
              <div className="page-numbers pf">{this.getPageNumbers()}</div>
              <a
                onClick={() => this.setState({ showPdf: undefined })}
                className="pf pdf-close"
              >
                ✗
              </a>
            </div>
          </div>
        ) : null}
        {this.state.enlargeImage ? (
          <div className="pf large-image">
            <div className="pr">
              <img
                style={{
                  transform:
                    'rotate(' +
                    this.state.orientation +
                    'deg)  scale(' +
                    this.state.scale +
                    ' )',
                }}
                className="pa"
                src={this.state.src}
              />
              <a
                onClick={() => this.setState({ enlargeImage: false })}
                className="pf image-close"
              >
                ✗
              </a>
              <div className="zoom pf">{this.getZoom()}</div>
              <div className="orientation pf">{this.getOrientation()}</div>
            </div>
          </div>
        ) : null}
        <div
          className={clsx(
            'header pr',
            this.props.userData.usertype === UserType.Regular
              ? 'header-regular'
              : this.props.userData.usertype === UserType.Admin
              ? 'header-admin'
              : '',
          )}
        >
          <div className="clearfix">
            <div
              className={clsx(
                'fl clearfix nav',
                this.state.message && 'opacity-less',
              )}
            >
              {this.getNavigation()}
            </div>
            <div className="fr userdata">
              <a
                className={
                  this.state.navigation === Navigation.AboutMe
                    ? 'active'
                    : undefined
                }
                onClick={() => this.aboutMe()}
              >
                <span
                  title={
                    this.props.userData.firstname +
                    ' ' +
                    this.props.userData.lastname +
                    ' (' +
                    (this.props.userData.usertype === UserType.Regular
                      ? 'Navaden'
                      : this.props.userData.usertype === UserType.Super
                      ? 'Super'
                      : 'Admin') +
                    ')'
                  }
                >
                  {this.props.userData.email}
                </span>
              </a>
              |
              <a className="logout" onClick={() => this.logout()}>
                Odjava
              </a>
            </div>
          </div>
        </div>
        <div className="content">
          <div className={this.state.message ? 'opacity-less' : ''}>
            {this.getContent()}
          </div>
          {this.state.message ? this.message() : null}
        </div>
      </div>
    ) : null;
  }
}
